import { axiosDelete, axiosPatch, axiosGet, axiosPost } from '~/api/axiosClient';
import { ITag } from './tag.models';

export const addTag = (data): Promise<ITag[]> => {
  return axiosPost('/tags', data);
};

export const getTag = (idTag: string): Promise<ITag> => {
  return axiosGet(`/tags/${idTag}`);
};

export const patchTag = (idTag: string, data: any): Promise<ITag> => {
  return axiosPatch(`/tags/${idTag}`, data);
};

export const deleteTag = (idTag: string) => {
  return axiosDelete(`/tags/${idTag}`);
};
