import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useContext } from 'react';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { CircularProgress, Divider, Grid2, Box } from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IProduct } from '~/interfaces';
import { IEquipment } from '~/interfaces/equipment';
import { getEquipmentImages, deleteEquipment, api } from '~/api';
import { ForceDownload } from '~/utils/ForceDownload';
import BasicModal from '~/app/components/BasicModal/BasicModal';
import { periodicityRef, referentielEventTypes } from '~/utils/references';
import { ModalContentType } from '~/interfaces';
import { Gallery } from '~/app/components/Gallery/Gallery';
import { View3d } from '~/app/components/View3d/View3d';
import { ConfirmationDialog } from '~/app/components/ConfirmationDialog/ConfirmationDialog';
import { useDialog } from '~/utils/useDialog';
import { AppContext } from '~/utils/context/AppContext';
import _ from 'lodash';
import './EquipmentDetail.scss';

export const EquipmentDetail = ({
  setWizzardEquipmentCreationMode = null,
  setWizzardEventCreationMode = null,
  idEquipment = null,
  activeId = null,
  setWizzardEquipmentOpen = null,
  setWizzardEventOpen = null,
  refreshRequired = null,
  setSelectedUUIDEvent = null,
  refreshEquipments = null
}) => {
  type TuseParams = {
    id: string;
  };
  // const equipid = activeId.id;
  const { id } = useParams<TuseParams>();
  const [data, setData] = useState<null | IEquipment>(null);
  const [headerImage, setHeaderImage] = useState([]);
  const [images, setImages] = useState([]);
  const [modalType, setModalType] = useState<ModalContentType>();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [productData, setProductData] = useState<null | IProduct>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState({ isError: false, error: {} });
  const [Docs, setDocs] = useState([]);
  const [equipid, setEquipid] = useState('');
  const [rows, setRows] = useState([]);
  const [uuidEventToRowId, setUuidEventToRowId] = useState({});
  const [deleteDialogOpen, handleOpenDeleteDialog, handleCloseDeleteDialog] = useDialog();
  const [galleryIndex, setGalleryIndex] = useState(0);
  const [pathContext, setPathContext] = useState('');
  const { equipmentReferencial } = useContext(AppContext);

  const navigate = useNavigate();

  const deleteDraftEquipment = (): void => {
    handleOpenDeleteDialog();
  };

  const confirmDeleteEquipment = (): void => {
    deleteEquipment(idEquipment)
      .then((res) => {
        handleCloseDeleteDialog();
        navigate(`/${pathContext}/${id}/equipments`);
        refreshEquipments();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const setRowsData = (events) => {
    const rowsByProduct = {};
    const rows = [];
    events.forEach((event, index: number) => {
      if (event.status === 3 || event.status === 2) {
        rows.push({
          id: index,
          diagnosticType: referentielEventTypes[event.kind],
          status: event.status,
          date: event.created_at,
          desc: event.desc,
          action: 'action',
          uuid: event.uuid,
          uuid_owner: event.uuid_owner,
          urgent: event.urgent,
          kind: event.kind
        });
        rowsByProduct[event.uuid] = index;
      }
    });
    setUuidEventToRowId(rowsByProduct);
    setRows(rows);
  };

  useEffect(() => {
    setPathContext('product');
  }, [id]);

  useEffect(() => {
    if (pathContext !== '') {
      api[pathContext]
        .get(id)
        .then((res) => {
          setProductData(res);
          setIsLoading(false);
        })
        .catch((error) => {
          setError({ isError: true, error });
          setIsLoading(false);
        });
    }
  }, [pathContext]);

  const loadEquipment = () => {
    if (idEquipment !== null && idEquipment !== undefined && idEquipment !== '') {
      api.equipments
        .get(idEquipment)
        .then((equipment) => {
          setData(equipment);
          const eve = equipment.events;
          if (eve !== null && eve !== undefined) {
            setRowsData(eve);
          } else {
            setRowsData([]);
          }
          const docmt = equipment.documents;
          if (docmt !== null && docmt !== undefined) {
            setDocs(docmt);
          }
          const imgsStates = [];
          const imgsTags = [];
          if (equipment?.states && equipment?.states?.length > 0)
            equipment?.states?.map((state) => {
              if (state?.images && state?.images?.length > 0) imgsStates.push(...state.images);
              if (state?.tags && state?.tags?.length > 0) {
                state?.tags?.map((tag) => {
                  if (tag?.images && tag?.images?.length > 0) imgsTags.push(...tag.images);
                });
              }
            });

          getEquipmentImages(idEquipment)
            .then((images) => {
              setImages([...images, ...imgsStates, ...imgsTags]);
              const feat = images.filter((image) => {
                return image.kind === 'thumbnail';
              });
              setHeaderImage(feat);
            })
            .catch((err) => {});
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    if (activeId !== null) {
      setEquipid(activeId);
    }
  }, []);

  useEffect(() => {
    loadEquipment();
  }, [refreshRequired]);

  useEffect(() => {
    if (idEquipment !== null) {
      setEquipid(idEquipment);
      loadEquipment();
    }
  }, [idEquipment]);
  // Get equipment
  useEffect(() => {
    loadEquipment();
  }, [equipid]);

  const handleModal = (type: ModalContentType) => {
    setModalOpen(!modalOpen);
    setModalType(type);
  };

  const modalClosed = () => {};

  const handleContentModal = (type: ModalContentType, uuidMessage): ReactNode => {
    switch (type) {
      case 'View3d':
        return <View3d mode="event-viewer" mattertagsData={data} />;
      case 'Gallery':
        return images ? <Gallery images={images} startIndex={galleryIndex} /> : null;
    }
  };

  const EnergyClass = (kind) => {
    switch (kind) {
      case 0:
        return 'Non renseigné';
      case 1:
        return 'A';
      case 2:
        return 'B';
      case 3:
        return 'C';
      case 4:
        return 'D';
      case 5:
        return 'E';
      case 6:
        return 'F';
      case 7:
        return 'G';
    }
  };

  const getVariant = (type: any): 'error' | 'success' | 'primary' | 'secondary' | 'warning' => {
    switch (type) {
      case 1:
        return 'success';
      case 2:
        return 'error';
      case 3:
        return 'warning';
      case 4:
        return 'success';
      case 5:
        return 'secondary';
      default:
        return 'success';
    }
  };

  const getRoomName = (equipment) => {
    return equipment.room !== '' && equipment.room !== null && equipment.room !== undefined
      ? ' - ' + equipment.room.name
      : '';
  };

  const baseUrlApi = process.env.REACT_APP_BASEURL_MEDIAS + '/';
  if (isLoading) return <CircularProgress />;

  if (error.isError) return <p></p>;

  return (
    <div className="EquipmentDetail" style={{ minWidth: 'auto', overflow: 'scroll' }}>
      <div>
        <BasicModal
          modalOpen={modalOpen}
          onModalOpen={(open: boolean) => setModalOpen(open)}
          onModalClose={modalClosed}
        >
          {handleContentModal(modalType, '')}
        </BasicModal>
      </div>
      <div className="EquipmentDetail__top">
        {data && data.kind && (
          <div className="EquipmentDetail__top__titles">
            {/* <h4>{GetEquipmentKind(data.kind)}</h4> */}
            {(data.title === '' || data.title === undefined || data.title === null) && (
              <h3>{`${_.find(equipmentReferencial['equipments'], { id: data.kind }).name}${getRoomName(data)}`}</h3>
            )}
            {data.title !== '' && data.title !== undefined && data.title !== null && (
              <h3>{`${_.find(equipmentReferencial['equipments'], { id: data.kind }).name} : ${data.title}${getRoomName(
                data
              )}`}</h3>
            )}
          </div>
        )}
        <div className="EquipmentDetail__top__actions">
          <div
            className="EquipmentDetail__top__actions__action"
            onClick={(e) => {
              deleteDraftEquipment();
            }}
          >
            <DeleteIcon style={{ color: '#062746' }} />
            Supprimer
          </div>
          <div
            className="EquipmentDetail__top__actions__action"
            onClick={(e) => {
              setWizzardEquipmentOpen(true);
              setWizzardEquipmentCreationMode(false);
            }}
          >
            <EditIcon style={{ color: '#062746' }} />
            Editer
          </div>
        </div>
      </div>
      <Grid2 container spacing={2}>
        {images && images.length > 0 && (
          <Grid2 size={12}>
            <div
              className="product_sheet_preview"
              onClick={() => handleModal('Gallery')}
              {...(images && {
                ...(images[0] && {
                  style: { backgroundImage: `url(${process.env.REACT_APP_BASEURL_MEDIAS}/${images[0].uri})` }
                })
              })}
            ></div>
          </Grid2>
        )}
        {images === null ? null : (
          <Grid2 container spacing={2} className="zer">
            <Grid2 size={12}>
              <div style={{ width: '100%', overflowX: 'scroll' }}>
                <div style={{ position: 'relative', display: 'inline-flex' }}>
                  {images.map((image, index) => (
                    <div
                      key={index}
                      className="product_sheet_image"
                      onClick={() => {
                        handleModal('Gallery');
                        setGalleryIndex(index);
                      }}
                      style={{ backgroundImage: `url(${process.env.REACT_APP_BASEURL_MEDIAS}/${image.uri})` }}
                    ></div>
                  ))}
                </div>
              </div>
            </Grid2>
          </Grid2>
        )}
      </Grid2>
      <div className="EquipmentDetail__dates">
        {data && (
          <div className="EquipmentDetail__dates__block">
            {data.installed_at === null ? null : (
              <div>
                <div className="EquipmentDetail__dates__block__date">
                  {new Date(data.installed_at).toLocaleDateString()}
                </div>
                <p className="EquipmentDetail__dates__block__text">Mise en service</p>
              </div>
            )}
          </div>
        )}
        <Divider orientation="vertical" flexItem></Divider>
        {data && (
          <div className="EquipmentDetail__dates__block">
            {data.warranty_end === null ? null : (
              <div>
                <div className="EquipmentDetail__dates__block__date">
                  {new Date(data.warranty_end).toLocaleDateString()}
                </div>
                <p className="EquipmentDetail__dates__block__text">Fin de garantie</p>
              </div>
            )}
          </div>
        )}
        <Divider orientation="vertical" flexItem></Divider>
        {data && (
          <div className="EquipmentDetail__dates__block">
            {data.maintenance_next_date == null ? null : (
              <div>
                <div className="EquipmentDetail__dates__block__date">
                  {new Date(data.maintenance_next_date).toLocaleDateString()}
                </div>
                <p className="EquipmentDetail__dates__block__text">Date de prochaine intervention</p>
              </div>
            )}
          </div>
        )}
      </div>
      {data && (
        <>
          <div className="EquipmentDetail__model">
            <h3>Informations utiles</h3>
            {data.data && data.data.model_brand && <p>Marque: {data.data.model_brand}</p>}
            {/* {data.data && data.data.model_name && <p>Modèle: {data.data.model_name}</p>} */}
            {data.data && data.data.model_id && <p>Modèle: {data.data.model_id}</p>}
            {data.data && data.data.model_serial_number && <p>Numéro de série: {data.data.model_serial_number}</p>}

            {data.energy_class !== 0 && data.energy_class !== null && data.energy_class !== undefined && (
              <p>Classe énergétique: {EnergyClass(data.energy_class)}</p>
            )}

            {data.data && data.data.meter_pmr && <p>Point de livraison: {data.data.meter_pmr}</p>}
            {data.maintenance_periodicity !== 0 &&
              data.maintenance_periodicity !== null &&
              data.maintenance_periodicity !== undefined && (
                <p>Périodicité de la maintenance: {periodicityRef[data.maintenance_periodicity]}</p>
              )}
            {/* <p>Type de garantie:</p> */}
          </div>

          {data.states &&
            data.states.map((state, index) => {
              return (
                <div className="EquipmentDetail__model">
                  <h3>{`Etat du ${new Date(state.created_at).toLocaleDateString()} à ${new Date(
                    state.created_at
                  ).toLocaleTimeString()}`}</h3>
                  {state.data && state.data.serialNumber && <p>Numéro de série: {state.data.serialNumber}</p>}
                  {state.data && state.data.meterValue && state.data.meterUnity && (
                    <p>Mesure: {state.data.meterValue + ' ' + state.data.meterUnity}</p>
                  )}

                  {state && state.comment && state.comment !== '' && <p>Commentaire état: {state.comment}</p>}
                </div>
              );
            })}
        </>
      )}
      {data && data.data && data.data.tech_specs && (
        <div className="EquipmentDetail__techniques">
          <h3>Caractéristiques techniques</h3>
          {data.data && data.data.tech_specs && <p>{data.data.tech_specs}</p>}
        </div>
      )}

      {data && data.data && data.data.more_infos && (
        <div className="EquipmentDetail__techniques">
          <h3>Inormations complémentaires</h3>
          {data.data && data.data.more_infos && <p>{data.data.more_infos}</p>}
        </div>
      )}
      {Docs && Docs.length > 0 && (
        <div className="EquipmentDetail__documents">
          <h3 className="EquipmentDetail__documents__title">Documents joints</h3>
          {Docs &&
            Docs.map((document, index) => (
              <div
                className="EquipmentDetail__documents__document"
                key={index}
                onClick={() => {
                  ForceDownload(baseUrlApi + document.uri, document.name);
                }}
              >
                <div className="EquipmentDetail__documents__document__icon">
                  <InsertDriveFileIcon style={{ color: '#062746' }} />
                </div>
                <a className="EquipmentDetail__documents__document__file">{document.name}</a>
              </div>
            ))}
        </div>
      )}
      <div className="EquipmentDetail__events">
        <div style={{ paddingTop: '20px' }}>
          <Box
            className="Events__link"
            onClick={() => {
              setWizzardEventCreationMode(true);
              setWizzardEventOpen(true);
            }}
            sx={{ textAlign: 'right', position: 'relative', right: '0px' }}
          >
            <AddOutlinedIcon /> Ajouter un ticket
          </Box>
        </div>
      </div>
      <ConfirmationDialog
        message={'Etes vous certain de vouloir supprimer définitivement cet équipement'}
        onCancel={handleCloseDeleteDialog}
        onConfirm={confirmDeleteEquipment}
        open={deleteDialogOpen}
        title={'Suppression'}
        variant={'error'}
        cancelText="Non"
        confirmText="Oui"
      />
    </div>
  );
};
