import QrCode2Icon from '@mui/icons-material/QrCode2';
import LinkIcon from '@mui/icons-material/Link';
import { Grid, IconButton, Snackbar } from '@mui/material';
import { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { IProduct, ModalContentType } from '~/interfaces';
import EnergyClass from '~/app/components/EnergyClass/EnergyClass';
import { ForceDownload } from '~/utils/ForceDownload';
import { appTheme } from '~/utils/theme';
import { AppContext } from '~/utils/context/AppContext';
import { energyClassRef, periodicityRef, type_references } from '~/utils/references';
import _ from 'lodash';
import { IBuilding } from '~/api/building.models';
import '~/app/pages/Product/Product.scss';
import dayjs from 'dayjs';
import normalizedString from '~/utils/normalizedString';

interface EquipmentInfos {
  onOpen: (open: boolean) => void;
  onModalType: (type: ModalContentType) => void;
  data: any;
  buildingData: any;
  isBuilding?: boolean;
}

const EquipmentInfos = ({ onOpen, onModalType, data, buildingData, isBuilding }: EquipmentInfos) => {
  type TuseParams = {
    id: string;
  };
  const { id } = useParams<TuseParams>();
  const [qrCodeSrc, setQrCodeSrc] = useState(null);
  const [qrCodeViewerSrc, setQrCodeViewerSrc] = useState(null);
  const [declarationLink, setDeclarationLink] = useState('');
  const [viewer3DLink, setViewer3DLink] = useState('');
  const { REACT_APP_BASEURL_AVRT } = process.env;
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const { referencial } = useContext(AppContext);
  const [propertyKindName, setPropertyKindName] = useState('');
  const [constructionPeriode, setConstructionPeriode] = useState(null);

  const onCloseHandler = (e, reason) => {
    if (reason === 'timeout') {
      setSnackBarOpen(false);
    }
  };

  useEffect(() => {
    if (data.links) {
      const qrcodeBaseUrl = process.env.REACT_APP_API_URL + '/links';
      data.links.forEach((link) => {
        if (link.kind === 'viewer') {
          setViewer3DLink(REACT_APP_BASEURL_AVRT + '/' + link.uuid);
          setQrCodeViewerSrc(`${qrcodeBaseUrl}/${link.uuid}/qrcode`);
        } else if (link.kind === 'declaration') {
          setDeclarationLink(REACT_APP_BASEURL_AVRT + '/' + link.uuid);
          setQrCodeSrc(`${qrcodeBaseUrl}/${link.uuid}/qrcode`);
        }
      });
    }
  }, []);

  const handleCopy = (e) => {
    if (e.currentTarget.dataset.linktype === 'viewer') {
      navigator.clipboard.writeText(viewer3DLink);
      setSnackBarMessage('Le lien de la vue 3D a été copié dans le presse papier');
    } else if (e.currentTarget.dataset.linktype === 'declaration') {
      navigator.clipboard.writeText(declarationLink);
      setSnackBarMessage("Le lien de l'outil de ticketing a été copié dans le presse papier");
    }
    setSnackBarOpen(true);
  };

  useEffect(() => {
    if (referencial) {
      if (isBuilding) {
        const kind = _.find(referencial['building.kind'], { id: data.kind.toString() });
        setPropertyKindName(kind ? kind.name : 'non renseigné');
      } else {
        const kind = data?.kind ? _.find(referencial['product.kind'], { id: data.kind.toString() }) : null;
        setPropertyKindName(kind ? kind.name : 'non renseigné');
      }
      setConstructionPeriode(_.sortBy(referencial['building.construction_period'], ['id']));
    }
  }, [referencial, data]);

  const EquipmentData = data?.data;

  const EquipmentDataValues = {
    model_brand: { name: 'Marque', type: 'string', reference: 'model_brand' },
    model_id: { name: 'Modèle', type: 'string', reference: 'model_id' },
    model_serial_number: { name: 'Numéro de série', type: 'string', reference: 'model_serial_number' },
    energy_class: { name: 'Classe énergie', type: 'index', reference: 'energy_class' },
    meter_pmr: { name: 'Point de livraison', type: 'string', reference: 'meter_pmr' },
    tech_specs: { name: 'Caractéristiques techniques', type: 'string', reference: 'tech_specs' },
    installed_at: { name: "Date d'installation", type: 'date' },
    maintenance_periodicity: {
      name: 'Périodicité de maintenance',
      type: 'index',
      reference: 'maintenance_periodicity'
    },
    warranty_end: { name: 'Fin de garantie', type: 'date' },
    maintenance_next_date: { name: 'Date de prochaine intervention', type: 'date' },
    more_infos: { name: 'Informations complémentaires', type: 'string' }
  };

  const convertValue = (key, object) => {
    if (object === null) {
      return null;
    }
    if (object.type === 'index') {
      return type_references[object.reference][object.value];
    }

    if (object.type === 'number') {
      if (object.value === '' || object.value === null || object.value === undefined || object.value === 0) {
        return null;
      }
      return Math.floor(object.value * 100) / 100 + ' ' + (object.suffixe ? object.suffixe : '');
    }

    if (object.type === 'date') {
      return new Date(String(object.value)).toLocaleDateString();
    }

    if (object.type === 'boolean') {
      if (object.value === true || object.value === 'true') {
        return 'Oui';
      }
      if (object.value === false || object.value === 'false') {
        return 'Non';
      }
      return object.value;
    }

    if (object.type === 'string') {
      return object.value;
    }
  };

  const SortedEquipmentData = {};
  if (EquipmentData) {
    const arr2 = Object.entries(EquipmentDataValues);
    arr2.map(([key, value]) => {
      if (EquipmentData[key] !== null && EquipmentData[key] !== undefined && EquipmentData[key] !== '') {
        if (key === 'parking_floor_number' && EquipmentData['parking'] !== true) {
        } else if (key === 'parking_places_number' && EquipmentData['parking'] !== true) {
        } else if (key === 'logement_type' && !isBuilding && data.kind !== 1) {
        } else if (key === 'dependence_type' && !isBuilding && data.kind !== 5) {
        } else if (key === 'furnished' && isBuilding) {
        } else if (key === 'floor_number' && !isBuilding && data.kind !== 7 && data.kind !== 8) {
        } else if (key === 'rooms_number' && !isBuilding && data.kind !== 7 && data.kind !== 8) {
        } else if (key === 'usage' && isBuilding && data.kind !== 3) {
        } else if (value['type'] === 'number' && EquipmentData[key] === 0) {
        } else {
          SortedEquipmentData[value.name] = {
            type: value.type,
            value: EquipmentData[key],
            suffixe: value['suffixe'],
            reference: value['reference']
          };
        }
      }
    });
  }

  return (
    <>
      {data &&
      (!data.energy_class || data.energy_class === 0 || data.energy_class === '0' || data.energy_class === null) &&
      (!data.data?.model_brand || data.data?.model_brand === '') &&
      (!data.data?.model_id || data.data?.model_id === '') &&
      (!data.data?.model_serial_number || data.data?.model_serial_number === '') &&
      (!data.data?.meter_pmr || data.data?.meter_pmr === '') &&
      (!data.data?.tech_specs || data.data?.tech_specs === '') &&
      (!data.installed_at || data.installed_at === '') &&
      (!data.maintenance_periodicity || data.maintenance_periodicity === 0 || data.maintenance_periodicity === '0') &&
      (!data.warranty_end || data.warranty_end === '') &&
      (!data.maintenance_next_date || data.maintenance_next_date === '') &&
      (!data.data?.more_infos || data.data?.more_infos === '') ? (
        <div className="Product_infos">
          <div className="Product__card__moreInfo" style={{ height: '30px', textAlign: 'center', paddingTop: '10px' }}>
            Aucune information renseignée
          </div>
        </div>
      ) : (
        <div className="Product_infos">
          {/* {data?.energy_class && <EnergyClass energy={data && data.energy_result} co2={data && data.energy_result} />} */}
          <div className="Product__card__moreInfo">
            <Grid container spacing={0}>
              {data?.data && data.data.model_brand && data.data.model_brand !== '' && (
                <Grid item xs={6} sx={{ paddingBottom: '10px' }}>
                  <div>
                    <span style={{ fontSize: '12px', color: '#929292' }}>Marque </span>
                    <br />
                    <span>{`${data.data && data.data.model_brand ? data.data.model_brand : 'non renseigné'}`}</span>
                  </div>
                </Grid>
              )}
              {data?.data && data.data.model_id && data.data.model_id !== '' && (
                <Grid item xs={6} sx={{ paddingBottom: '10px' }}>
                  <div>
                    <span style={{ fontSize: '12px', color: '#929292' }}>Modèle </span>
                    <br />
                    <span>{`${data.data && data.data.model_id ? data.data.model_id : 'non renseigné'}`}</span>
                  </div>
                </Grid>
              )}
              {data?.data && data.data.model_serial_number && data.data.model_serial_number !== '' && (
                <Grid item xs={6} sx={{ paddingBottom: '10px' }}>
                  <div>
                    <span style={{ fontSize: '12px', color: '#929292' }}>Numéro de série </span>
                    <br />
                    <span>{`${data?.data && data.data.model_serial_number ? data.data.model_serial_number : 'non renseigné'}`}</span>
                  </div>
                </Grid>
              )}
              {data && data?.energy_class !== null && data.energy_class !== 0 && data.energy_class !== '0' && (
                <Grid item xs={6} sx={{ paddingBottom: '10px' }}>
                  <div>
                    <span style={{ fontSize: '12px', color: '#929292' }}>Classe énergétique </span>
                    <br />
                    <span>{`${data && data.energy_class ? energyClassRef[data.energy_class] : 'non renseigné'}`}</span>
                  </div>
                </Grid>
              )}
              {data?.data && data.data.meter_pmr && data.data.meter_pmr !== '' && (
                <Grid item xs={6} sx={{ paddingBottom: '10px' }}>
                  <div>
                    <span style={{ fontSize: '12px', color: '#929292' }}>Point de livraison </span>
                    <br />
                    <span>{`${data?.data && data.data.meter_pmr ? data.data.meter_pmr : 'non renseigné'}`}</span>
                  </div>
                </Grid>
              )}
              {data?.data && data.data.tech_specs && data.data.tech_specs !== '' && (
                <Grid item xs={6} sx={{ paddingBottom: '10px' }}>
                  <div>
                    <span style={{ fontSize: '12px', color: '#929292' }}>Caractéristiques techniques </span>
                    <br />
                    <span>{`${data?.data && data.data.tech_specs ? data.data.tech_specs : 'non renseigné'}`}</span>
                  </div>
                </Grid>
              )}
              {data && data.installed_at && data.installed_at !== '' && (
                <Grid item xs={6} sx={{ paddingBottom: '10px' }}>
                  <div>
                    <span style={{ fontSize: '12px', color: '#929292' }}>Date d'installation </span>
                    <br />
                    <span>{`${dayjs(data.installed_at).format('DD/MM/YYYY')}`}</span>
                  </div>
                </Grid>
              )}
              {data?.maintenance_periodicity !== 0 && data?.maintenance_periodicity !== '0' && (
                <Grid item xs={6} sx={{ paddingBottom: '10px' }}>
                  <div>
                    <span style={{ fontSize: '12px', color: '#929292' }}>Périodicité de maintenance </span>
                    <br />
                    <span>{`${data && data.maintenance_periodicity ? periodicityRef[data.maintenance_periodicity] : 'non renseigné'}`}</span>
                  </div>
                </Grid>
              )}

              {data && data.warranty_end && data.warranty_end !== '' && (
                <Grid item xs={6} sx={{ paddingBottom: '10px' }}>
                  <div>
                    <span style={{ fontSize: '12px', color: '#929292' }}>Fin de garantie </span>
                    <br />
                    <span>{`${dayjs(data.warranty_end).format('DD/MM/YYYY')}`}</span>
                  </div>
                </Grid>
              )}

              {data && data.maintenance_next_date && data.maintenance_next_date !== '' && (
                <Grid item xs={6} sx={{ paddingBottom: '10px' }}>
                  <div>
                    <span style={{ fontSize: '12px', color: '#929292' }}>Date de prochaine intervention </span>
                    <br />
                    <span>{`${dayjs(data.maintenance_next_date).format('DD/MM/YYYY')}`}</span>
                  </div>
                </Grid>
              )}
              {data?.data && data.data.more_infos && data.data.more_infos !== '' && (
                <Grid item xs={6} sx={{ paddingBottom: '10px' }}>
                  <div>
                    <span style={{ fontSize: '12px', color: '#929292' }}>Informations complémentaires </span>
                    <br />
                    <span>{`${data?.data.more_infos}`}</span>
                  </div>
                </Grid>
              )}
            </Grid>
          </div>
        </div>
      )}
    </>
  );
};

export default EquipmentInfos;
