import QrCode2Icon from '@mui/icons-material/QrCode2';
import LinkIcon from '@mui/icons-material/Link';
import { Grid, IconButton, Snackbar } from '@mui/material';
import { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { IProduct, ModalContentType } from '~/interfaces';
import EnergyClass from '~/app/components/EnergyClass/EnergyClass';
import { ForceDownload } from '~/utils/ForceDownload';
import { appTheme } from '~/utils/theme';
import { AppContext } from '~/utils/context/AppContext';
import { type_references } from '~/utils/references';
import _ from 'lodash';
import { IBuilding } from '~/api/building.models';
import '~/app/pages/Product/Product.scss';
import dayjs from 'dayjs';
import normalizedString from '~/utils/normalizedString';

interface PropertyInfoProps {
  onOpen: (open: boolean) => void;
  onModalType: (type: ModalContentType) => void;
  data: IBuilding | IProduct;
  buildingData: any;
  isBuilding?: boolean;
}

const PropertyInfo = ({ onOpen, onModalType, data, buildingData, isBuilding }: PropertyInfoProps) => {
  type TuseParams = {
    id: string;
  };
  const { id } = useParams<TuseParams>();
  const [qrCodeSrc, setQrCodeSrc] = useState(null);
  const [qrCodeViewerSrc, setQrCodeViewerSrc] = useState(null);
  const [declarationLink, setDeclarationLink] = useState('');
  const [viewer3DLink, setViewer3DLink] = useState('');
  const { REACT_APP_BASEURL_AVRT } = process.env;
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const { referencial } = useContext(AppContext);
  const [propertyKindName, setPropertyKindName] = useState('');
  const [constructionPeriode, setConstructionPeriode] = useState(null);

  const onCloseHandler = (e, reason) => {
    if (reason === 'timeout') {
      setSnackBarOpen(false);
    }
  };

  useEffect(() => {
    if (data.links) {
      const qrcodeBaseUrl = process.env.REACT_APP_API_URL + '/links';
      data.links.forEach((link) => {
        if (link.kind === 'viewer') {
          setViewer3DLink(REACT_APP_BASEURL_AVRT + '/' + link.uuid);
          setQrCodeViewerSrc(`${qrcodeBaseUrl}/${link.uuid}/qrcode`);
        } else if (link.kind === 'declaration') {
          setDeclarationLink(REACT_APP_BASEURL_AVRT + '/' + link.uuid);
          setQrCodeSrc(`${qrcodeBaseUrl}/${link.uuid}/qrcode`);
        }
      });
    }
  }, []);

  const handleCopy = (e) => {
    if (e.currentTarget.dataset.linktype === 'viewer') {
      navigator.clipboard.writeText(viewer3DLink);
      setSnackBarMessage('Le lien de la vue 3D a été copié dans le presse papier');
    } else if (e.currentTarget.dataset.linktype === 'declaration') {
      navigator.clipboard.writeText(declarationLink);
      setSnackBarMessage("Le lien de l'outil de ticketing a été copié dans le presse papier");
    }
    setSnackBarOpen(true);
  };

  useEffect(() => {
    if (referencial) {
      if (isBuilding) {
        const kind = _.find(referencial['building.kind'], { id: data.kind.toString() });
        setPropertyKindName(kind ? kind.name : 'non renseigné');
      } else {
        const kind = data.kind ? _.find(referencial['product.kind'], { id: data.kind.toString() }) : null;
        setPropertyKindName(kind ? kind.name : 'non renseigné');
      }
      setConstructionPeriode(_.sortBy(referencial['building.construction_period'], ['id']));
    }
  }, [referencial, data]);

  const ProductData = data.data;

  const ProductDataValues = {
    logement_type: { name: 'Type de logement', type: 'index', reference: 'logement_type' },
    dependence_type: { name: 'Type de dépendance', type: 'index', reference: 'dependence_type' },
    floor_number: { name: "Nombre d'étages", type: 'number' },
    rooms_number: { name: 'Nombre de pièces', type: 'number' },
    furnished: { name: 'Meublé', type: 'boolean' },
    usage: { name: 'Usage', type: 'index', reference: 'usage' },
    surface: { name: 'Surface habitable', type: 'number', suffixe: 'm²' },
    ceiling_height: { name: 'Hauteur sous plafond', type: 'number', suffixe: 'm' },
    access_code: { name: 'Code accès immeuble', type: 'string' },
    lot_number: { name: 'Numéro de lot', type: 'string' },
    lot_associated: { name: 'Lot(s) associé(s)', type: 'string' },
    mono_property: { name: 'Mono propriété', type: 'boolean' },
    co_property: { name: 'Co propriété', type: 'boolean' },
    immatriculattion_number: { name: "Numéro d'immatriculation", type: 'string' },
    cadastral_section: { name: 'Section cadastrale', type: 'string' },
    cadastral_parcel: { name: 'Parcelle cadastrale', type: 'string' },
    staircase_number: { name: "Nombre d'escaliers", type: 'number' },
    elevators_number: { name: "Nombre d'ascenseurs", type: 'number' },
    lots_number: { name: 'Nombre de lots', type: 'number' },
    flats_number: { name: "Nombre d'appartements", type: 'number' },
    cellar_number: { name: 'Nombre de caves', type: 'number' },
    technical_locals: { name: 'Locaux techniques', type: 'number' },
    various_locals: { name: 'Locaux divers', type: 'number' },
    cycle_local: { name: 'Local à vélo', type: 'boolean' },
    trash_local: { name: 'Local à poubelle', type: 'boolean' },
    letter_box: { name: 'Boîte aux lettres', type: 'boolean' },
    optical_fiber: { name: 'Fibre optique', type: 'boolean' },
    parking: { name: 'Parking', type: 'boolean' },
    parking_floor_number: { name: "Nombre d'étages de parking", type: 'number' },
    parking_places_number: { name: 'Nombre de places de parking', type: 'number' },
    heating_communal: { name: 'Type de chauffage', type: 'string' },
    heating_energy_type: { name: "Type d'énergie du chauffage", type: 'index', reference: 'heating_energy_type' },
    ecs_communal: { name: "Production d'eau chaude", type: 'string' },
    ecs_energy_type: { name: "Type d'énergie de l'ECS", type: 'index', reference: 'ecs_energy_type' },
    cooling_communal: { name: 'Refroidissement', type: 'string' },
    cooling_energy_type: { name: "Type d'énergie du refroidissement", type: 'index', reference: 'cooling_energy_type' },
    // DPE
    dpe_number: { name: 'Numéro du DPE', type: 'string' },
    // energy_result: { name: 'Consommation énergétique', type: 'number', suffixe: 'kWhEP/m2.an' },
    // energy_sticker: { name: 'Etiquette énergétique', type: 'string' },
    // ges_result: { name: 'GES', type: 'number', suffixe: 'kgCO2/m2.an' },
    // ges_sticker: { name: 'Etiquette GES', type: 'string' },
    done_at: { name: 'Date établissement DPE', type: 'date' },
    expire_at: { name: 'Date expiration DPE', type: 'date' }
  };

  const convertValue = (key, object) => {
    if (object === null) {
      return null;
    }
    if (object.type === 'index') {
      return type_references[object.reference][object.value];
    }

    if (object.type === 'number') {
      if (object.value === '' || object.value === null || object.value === undefined || object.value === 0) {
        return null;
      }
      return Math.floor(object.value * 100) / 100 + ' ' + (object.suffixe ? object.suffixe : '');
    }

    if (object.type === 'date') {
      return new Date(String(object.value)).toLocaleDateString();
    }

    if (object.type === 'boolean') {
      if (object.value === true || object.value === 'true') {
        return 'Oui';
      }
      if (object.value === false || object.value === 'false') {
        return 'Non';
      }
      return object.value;
    }

    if (object.type === 'string') {
      return object.value;
    }
  };

  const SortedProductData = {};
  if (ProductData) {
    const arr2 = Object.entries(ProductDataValues);
    arr2.map(([key, value]) => {
      if (ProductData[key] !== null && ProductData[key] !== undefined && ProductData[key] !== '') {
        if (key === 'parking_floor_number' && ProductData['parking'] !== true) {
        } else if (key === 'parking_places_number' && ProductData['parking'] !== true) {
        } else if (key === 'logement_type' && !isBuilding && data.kind !== 1) {
        } else if (key === 'dependence_type' && !isBuilding && data.kind !== 5) {
        } else if (key === 'furnished' && isBuilding) {
        } else if (key === 'floor_number' && !isBuilding && data.kind !== 7 && data.kind !== 8) {
        } else if (key === 'rooms_number' && !isBuilding && data.kind !== 7 && data.kind !== 8) {
        } else if (key === 'usage' && isBuilding && data.kind !== 3) {
        } else if (value['type'] === 'number' && ProductData[key] === 0) {
        } else {
          SortedProductData[value.name] = {
            type: value.type,
            value: ProductData[key],
            suffixe: value['suffixe'],
            reference: value['reference']
          };
        }
      }
    });
  }

  return (
    <>
      <div className="Product_infos">
        {data?.data &&
          data?.data?.energy_result !== 0 &&
          data?.data?.energy_result !== null &&
          data?.data?.energy_result !== undefined && (
            <EnergyClass energy={data.data && data.data.energy_result} co2={data.data && data.data.ges_result} />
          )}
        <div className="Product__card__moreInfo">
          <Grid container spacing={0}>
            {data.data && propertyKindName !== '' && (
              <Grid item xs={6} sx={{ paddingBottom: '10px' }}>
                <div>
                  <span style={{ fontSize: '12px', color: '#929292' }}>Type de bien </span>
                  <br />
                  <span>{`${data.data && data.kind ? propertyKindName : 'non renseigné'}`}</span>
                </div>
              </Grid>
            )}
            {data && data.id_internal !== '' && (
              <Grid item xs={6} sx={{ paddingBottom: '10px' }}>
                <div>
                  <span style={{ fontSize: '12px', color: '#929292' }}>Référence interne </span>
                  <br />
                  <span>{`${data.id_internal}`}</span>
                </div>
              </Grid>
            )}
            {data && data['construction_year'] && data['construction_year'] !== '' && (
              <Grid item xs={6} sx={{ paddingBottom: '10px' }}>
                <div>
                  <span style={{ fontSize: '12px', color: '#929292' }}>Année de construction </span>
                  <br />
                  <span>{`${dayjs(data['construction_year']).year()}`}</span>
                </div>
              </Grid>
            )}

            {data &&
              constructionPeriode &&
              data['construction_period'] !== null &&
              data['construction_period'] !== undefined &&
              data['construction_period'] !== 0 &&
              data['construction_period'] !== '0' && (
                <Grid item xs={6} sx={{ paddingBottom: '10px' }}>
                  <div>
                    <span style={{ fontSize: '12px', color: '#929292' }}>Période de construction </span>
                    <br />
                    <span>{`${constructionPeriode[data['construction_period']].name}`}</span>
                  </div>
                </Grid>
              )}
            {Object.entries(SortedProductData).map(([key, object]) => (
              <Grid key={key} item xs={6} sx={{ paddingBottom: '10px' }}>
                <div className={key}>
                  <span style={{ fontSize: '12px', color: '#929292' }}>{key}</span>
                  <br />
                  <span>{convertValue(key, object)}</span>
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
      {(qrCodeViewerSrc || qrCodeSrc) && (
        <div>
          <div className="Product__lineBlock">
            <span className="Product__lineBlock__title" style={{ position: 'relative', top: '10px' }}>
              Liens utiles
            </span>
          </div>
          {qrCodeViewerSrc && (
            <div className="Product__lineBlock" style={{ marginBottom: '0px', height: '23px' }}>
              <span style={{ position: 'relative', top: '10px', paddingLeft: '10px' }}>Vue 3D</span>
              <span>
                <IconButton
                  sx={{ '&:hover': { color: appTheme.palette.secondary.main }, paddingRight: '0px' }}
                  aria-label="link"
                  size="small"
                  onClick={handleCopy}
                  disableRipple
                  color={'primary'}
                  data-linktype="viewer"
                >
                  <LinkIcon></LinkIcon>
                </IconButton>

                <IconButton
                  sx={{ '&:hover': { color: appTheme.palette.secondary.main }, paddingRight: '0px' }}
                  aria-label="link"
                  size="small"
                  onClick={() => {
                    const fileName = data.address
                      ? `QRCode vue 3D ${data.address.street} ${data.address.zip_code} ${data.address.city}.png`
                      : 'QRCodeVue3D.png';
                    ForceDownload(qrCodeViewerSrc, normalizedString(fileName).replace(/ /g, '_').replace(/,/g, ''));
                    setSnackBarMessage('Le QR Code de la vue 3D a été téléchargé');
                    setSnackBarOpen(true);
                  }}
                  disableRipple
                  color={'primary'}
                >
                  <QrCode2Icon></QrCode2Icon>
                </IconButton>
              </span>
            </div>
          )}

          <div className="Product__lineBlock">
            <span style={{ position: 'relative', top: '10px', paddingLeft: '10px' }}>Ticketing</span>
            <span>
              <IconButton
                sx={{ '&:hover': { color: appTheme.palette.secondary.main }, paddingRight: '0px' }}
                aria-label="link"
                size="small"
                onClick={handleCopy}
                disableRipple
                color={'primary'}
                data-linktype="declaration"
              >
                <LinkIcon></LinkIcon>
              </IconButton>

              <IconButton
                sx={{ '&:hover': { color: appTheme.palette.secondary.main }, paddingRight: '0px' }}
                aria-label="link"
                size="small"
                onClick={() => {
                  const fileName = data.address
                    ? `QRCode Ticketing ${data.address.street} ${data.address.zip_code} ${data.address.city}.png`
                    : 'QRCodeTicketing.png';
                  ForceDownload(qrCodeSrc, normalizedString(fileName).replace(/ /g, '_').replace(/,/g, ''));
                  setSnackBarMessage("Le QR Code de l'outil de ticketing a été téléchargé");
                  setSnackBarOpen(true);
                }}
                disableRipple
                color={'primary'}
              >
                <QrCode2Icon></QrCode2Icon>
              </IconButton>
            </span>
          </div>
        </div>
      )}

      <Snackbar
        open={snackBarOpen}
        autoHideDuration={3000}
        disableWindowBlurListener={true}
        onClose={onCloseHandler}
        message={snackBarMessage}
        ContentProps={{
          sx: {
            background: appTheme.palette.primary.main
          }
        }}
      ></Snackbar>
    </>
  );
};

export default PropertyInfo;
