import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { Box, Button, Grid, Link, TextField, Typography, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  currentUser,
  getOrganization,
  getUser,
  patchAddress,
  patchOrganization,
  patchUser,
  updatePassword
} from '~/api';
import Breadcrumb from '~/app/components/Breadcrumb/Breadcrumb';
import './Profil.scss';
import { auth } from '~/app/components/Auth/AuthApi';
import { logOut } from '~/app/components/Auth/AuthApi';
import { api } from '~/api';
import { MuiTelInput } from 'mui-tel-input';
import { CountryCode } from 'libphonenumber-js';

interface IRoleUserProfil {
  first_name: string;
  last_name: string;
  email: string;
  phone1: string;
  phone2: string;
  kind: string;
}

interface IProfil {
  first_name: string;
  last_name: string;
  email: string;
  phone1: string;
  phone2: string;
  entreprise: string;
  legal_number: string;
  vat_number: string;
  street: string;
  city: string;
  zip_code: string;
  roleUser: IRoleUserProfil[];
}

export const Profil = ({ setSpinnerOpen, spinnerOpen }) => {
  // const editMode
  const [editMode, setEditMode] = useState(false);
  const [editNewPass, setEditNewPass] = useState(false);
  const [uuids, setUuids] = useState({
    user: '',
    oragnisation_object: {
      address: {
        uuid: ''
      },
      users: []
    },
    user_object: {
      uuid: '',
      kind: ''
    }
  });

  const initData: IProfil = {
    first_name: '',
    last_name: '',
    email: '',
    phone1: '',
    phone2: '',
    entreprise: '',
    legal_number: '',
    vat_number: '',
    street: '',
    zip_code: '',
    city: '',
    roleUser: [
      {
        first_name: '',
        last_name: '',
        email: '',
        phone1: '',
        phone2: '',
        kind: 'client'
      }
    ]
  };

  const initNewPassword = {
    oldPass: '',
    newPass: '',
    confirmNewPass: ''
  };

  const [countryCode, setCountryCode] = useState<CountryCode>('FR');
  const [countryCode2, setCountryCode2] = useState<CountryCode>('FR');

  const navigate = useNavigate();

  useLayoutEffect(() => {
    const claims = auth.getJWT();
    getOrganization(claims.uuid_organization).then((respOrga) => {
      getUser(claims.uuid_user).then((resp) => {
        setIsAdmin(auth.isAdmin());
        const newObjects = {
          oragnisation_object: JSON.parse(JSON.stringify(respOrga)),
          user_object: JSON.parse(JSON.stringify(resp)),
          user: claims.uuid_user
        };

        setUuids(newObjects);

        const newdataForm = { ...dataForm };

        newdataForm.first_name = resp.first_name;
        newdataForm.last_name = resp.last_name;
        newdataForm.email = resp.email;
        newdataForm.phone1 = resp.phone1;
        newdataForm.phone2 = resp.phone2 ? resp.phone2 : '';
        newdataForm.entreprise = respOrga.name;
        newdataForm.legal_number = respOrga.legal_number;
        newdataForm.vat_number = respOrga.vat_number;
        newdataForm.street = respOrga.address.street;
        newdataForm.city = respOrga.address.city;
        newdataForm.zip_code = respOrga.address.zip_code;

        setDataForm(newdataForm);
      });
    });
    setSpinnerOpen(false);
  }, []);

  const [dataForm, setDataForm] = useState<IProfil>(initData);
  const [newPassWordData, setNewPassWordData] = useState(initNewPassword);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [responseChangePassWord, setResponseChangePassWord] = useState<string>('');
  const [openDeleteProfil, setOpenDeleteProfil] = useState(false);

  const handleOpenDeleteProfil = () => {
    setOpenDeleteProfil(!openDeleteProfil);
  };

  const handleDeleteProfil = async () => {
    try {
      await api.users.delete(uuids.user);
      logOut();
      navigate('/');
    } catch (error) {
      console.log("Une erreur s'est produite lors de la suppression du profil :", error);
    }
  };

  useEffect(() => {
    console.log('dataForm', dataForm);
  }, [dataForm]);

  const handleEdition = () => {
    setEditMode(true);
  };

  const handleChangePassword = () => {
    setResponseChangePassWord('');
    setEditNewPass(true);
  };

  const handleCancelPasswordChange = () => {
    setNewPassWordData({ ...initNewPassword });
    setEditNewPass(false);
  };

  const handleValidateNewPassword = () => {
    updatePassword(currentUser.uuid_user, { old: newPassWordData.oldPass, new: newPassWordData.newPass })
      .then((resp) => {
        setEditNewPass(false);
        setResponseChangePassWord('Votre mot de passe a bien été modifié');
      })
      .catch((err) => {
        setResponseChangePassWord('Une erreur est survenue');
      });
  };

  const handleCancel = () => {
    setEditMode(false);
  };

  const handleValidate = () => {
    const newDatas = JSON.parse(JSON.stringify(uuids.oragnisation_object));
    newDatas.name = dataForm.entreprise;
    newDatas.legal_number = dataForm.legal_number;
    newDatas.vat_number = dataForm.vat_number;

    const newAddressData = JSON.parse(JSON.stringify(newDatas.address));

    newAddressData.street = dataForm.street;
    newAddressData.zip_code = dataForm.zip_code;
    newAddressData.city = dataForm.city;

    const newUserData = JSON.parse(JSON.stringify(uuids.user_object));

    newUserData.first_name = dataForm.first_name;
    newUserData.last_name = dataForm.last_name;
    newUserData.phone1 = dataForm.phone1;
    newUserData.phone2 = dataForm.phone2;
    newUserData.email = dataForm.email;
    delete newUserData.email;
    delete newUserData.password;
    delete newDatas.address;
    delete newDatas.users;
    if (isAdmin) {
      patchOrganization(auth.getOrganizationUUID(), newDatas).then((resp) => {
        patchAddress(uuids.oragnisation_object.address.uuid, newAddressData).then((repsAdress) => {
          patchUser(uuids.user_object.uuid, newUserData).then((repsUser) => {
            setEditMode(false);
          });
        });
      });
    } else {
      patchUser(uuids.user_object.uuid, newUserData).then((repsUser) => {
        setEditMode(false);
      });
    }
  };

  const textFieldColor = 'primary.main';
  const borderColor = 'rgba(0, 0, 0, 0.08)';
  const textFieldSX = {
    input: {
      WebkitTextFillColor: `${textFieldColor} !important`,
      color: `${textFieldColor} !important`
    },
    fieldset: {
      borderColor: `${borderColor} !important`
    }
  };

  return (
    <div className="Profil">
      <div className="">
        <Box
          sx={{
            my: 2,
            width: 1,
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="h1" sx={{ color: 'primary.main' }}>
              Votre profil {isAdmin ? ' administrateur' : ' utilisateur'}
            </Typography>
            <Breadcrumb data={[{ label: 'Accueil', href: '/' }]} last={`Profil`} setSpinnerOpen={setSpinnerOpen} />
          </Box>
        </Box>

        <Box
          sx={{
            mb: 4,
            width: 1,
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Link
              className="Product__link"
              onClick={() => {
                navigate(-1);
              }}
              color="primary"
            >
              <ArrowBackOutlinedIcon /> Retour
            </Link>
          </Box>
        </Box>

        <Typography variant="h5" sx={{ color: 'primary.main', mb: 2 }}>
          Vos informations
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TextField
              sx={!editMode ? textFieldSX : null}
              disabled={!editMode}
              label="Prénom"
              fullWidth
              value={dataForm.first_name}
              onChange={(e) => setDataForm({ ...dataForm, first_name: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              sx={!editMode ? textFieldSX : null}
              disabled={!editMode}
              label="Nom"
              fullWidth
              value={dataForm.last_name}
              onChange={(e) => setDataForm({ ...dataForm, last_name: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <MuiTelInput
              sx={!editMode ? textFieldSX : null}
              disabled={!editMode}
              label="Téléphone portable"
              fullWidth
              value={dataForm.phone1}
              onChange={(value, info) => setDataForm({ ...dataForm, phone1: info.numberValue })}
              defaultCountry={countryCode}
              forceCallingCode
              continents={['EU']}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <MuiTelInput
              sx={!editMode ? textFieldSX : null}
              disabled={!editMode}
              label="Téléphone fixe"
              fullWidth
              value={dataForm.phone2}
              onChange={(value, info) => setDataForm({ ...dataForm, phone2: info.numberValue })}
              defaultCountry={countryCode2}
              forceCallingCode
              continents={['EU']}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              sx={!editMode ? textFieldSX : null}
              disabled
              label="Adresse email"
              fullWidth
              value={dataForm.email}
              onChange={(e) => setDataForm({ ...dataForm, email: e.target.value })}
            />
          </Grid>
        </Grid>

        {isAdmin && <div className="Profil__title"></div>}

        <Grid container>
          {!editMode && (
            <Grid item xs={12} md={12}>
              <Button variant="contained" color={'primary'} onClick={handleEdition}>
                Edition
              </Button>
            </Grid>
          )}
          {editMode && (
            <Grid item xs={12} md={6}>
              <Button variant="outlined" color={'primary'} onClick={handleCancel}>
                Annuler
              </Button>
              <span>&nbsp;&nbsp;&nbsp;</span>
              <Button variant="contained" color={'primary'} onClick={handleValidate}>
                Valider
              </Button>
            </Grid>
          )}

          {!editNewPass && (
            <Grid item xs={12} md={12} sx={{ paddingTop: '20px' }}>
              <div className="Profil__title">Votre mot de passe</div>
              {responseChangePassWord === '' && (
                <Button variant="contained" color={'primary'} onClick={handleChangePassword}>
                  Changer mon mot de passe
                </Button>
              )}
            </Grid>
          )}
        </Grid>

        {editNewPass && (
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <div className="Profil__title">Changement de mot de passe</div>
            </Grid>

            {responseChangePassWord === '' && (
              <Grid item xs={12} md={4}>
                <TextField
                  sx={!editNewPass ? textFieldSX : null}
                  disabled={!editNewPass}
                  label="Ancien mot de passe"
                  value={newPassWordData.oldPass}
                  fullWidth
                  type="password"
                  autoComplete="off"
                  onChange={(e) => setNewPassWordData({ ...newPassWordData, oldPass: e.target.value })}
                ></TextField>
              </Grid>
            )}
            {responseChangePassWord === '' && (
              <Grid item xs={12} md={4}>
                <TextField
                  sx={!editNewPass ? textFieldSX : null}
                  disabled={!editNewPass}
                  label="Nouveau mot de passe"
                  error={newPassWordData.newPass !== newPassWordData.confirmNewPass}
                  value={newPassWordData.newPass}
                  fullWidth
                  type="password"
                  autoComplete="off"
                  onChange={(e) => setNewPassWordData({ ...newPassWordData, newPass: e.target.value })}
                ></TextField>
              </Grid>
            )}
            {responseChangePassWord === '' && (
              <Grid item xs={12} md={4}>
                <TextField
                  sx={!editNewPass ? textFieldSX : null}
                  disabled={!editNewPass}
                  error={newPassWordData.newPass !== newPassWordData.confirmNewPass}
                  label="Confirmer mot de passe"
                  value={newPassWordData.confirmNewPass}
                  fullWidth
                  type="password"
                  autoComplete="off"
                  onChange={(e) => setNewPassWordData({ ...newPassWordData, confirmNewPass: e.target.value })}
                ></TextField>
              </Grid>
            )}
            {responseChangePassWord === '' && (
              <Grid item xs={12} md={6} sx={{ paddingTop: '20px' }}>
                <Button variant="outlined" color={'primary'} onClick={handleCancelPasswordChange}>
                  Annuler
                </Button>
                <span>&nbsp;&nbsp;&nbsp;</span>
                <Button
                  variant="contained"
                  color={'primary'}
                  disabled={
                    newPassWordData.oldPass === '' ||
                    newPassWordData.newPass === '' ||
                    newPassWordData.newPass !== newPassWordData.confirmNewPass
                  }
                  onClick={handleValidateNewPassword}
                >
                  Valider
                </Button>
              </Grid>
            )}
          </Grid>
        )}
        <Grid item xs={12} md={6} sx={{ mt: '3vh' }}>
          <Button variant="outlined" color="error" sx={{ borderColor: '#EF5A41' }} onClick={handleOpenDeleteProfil}>
            Supprimer votre compte
          </Button>
        </Grid>
        {responseChangePassWord !== '' && <div className="Profil__response">{responseChangePassWord}</div>}
      </div>
      <Dialog open={openDeleteProfil} onClose={handleOpenDeleteProfil}>
        <DialogTitle> Suppression du compte </DialogTitle>
        <DialogContent>
          <Typography variant="h5" color="primary" sx={{ textAlign: 'center', mb: '4vh' }}>
            Etes-vous sur de vouloir supprimer votre compte ?
          </Typography>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
              marginTop: '2vh'
            }}
          >
            <Button variant="outlined" color={'primary'} onClick={handleOpenDeleteProfil}>
              Annuler
            </Button>
            <Button
              variant="contained"
              color={'primary'}
              onClick={() => {
                handleDeleteProfil();
                handleOpenDeleteProfil();
              }}
            >
              Valider
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Profil;
