import { axiosDelete, axiosGet, axiosPatch, axiosPost } from '.';

export const addMatterportScan = (data) => {
  return axiosPost(`/matterport`, data);
};

export const getMatterportScan = (idAddress: string) => {
  return axiosGet(`/matterport/${idAddress}`);
};

export const patchMatterportScan = (idAddress: string, data) => {
  return axiosPatch(`/matterport/${idAddress}`, data);
};

export const deleteMatterportScan = (idAddress: string) => {
  return axiosDelete(`/matterport/${idAddress}`);
};

export const getMatterportStats = () => {
  return axiosGet(`/matterport/stats`);
};
