import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Box, Button, Checkbox, DialogActions, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { api, createFile, deleteFile, getEquipment, getEvent } from '~/api';
import { UploadFile } from '~/app/components/CustomDropZoneArea/CustomDropZoneArea';
import '~/app/components/WizzardEquipment/WizzardEquipment';

export const DocumentUploader = ({
  nextStepCallBack,
  currentElementUUID,
  hasMatterportScan = false,
  showCheckBox = false,
  wizzardCreationMode = false,
  stepToReturn = '',
  elementType,
  filesType
}) => {
  const [files, setFiles] = useState([]);
  const [moreInfosCheck, setMoreInfosCheck] = useState(false);
  const [imagesAllreadyAssociated, setImagesAllreadyAssociated] = useState<any>([]);
  const [imagesUploadedSelected, setImagesUploadedSelected] = useState<any>({});
  const [hasOneImageSelected, setHasOneImageSelected] = useState<boolean>(false);
  const [documents, setDocuments] = useState<any>([]);
  const { REACT_APP_BASEURL_MEDIAS } = process.env;

  const getFiles = () => {
    if (elementType === 'equipment') {
      api.equipments.get(currentElementUUID).then((equipment) => {
        const images = [];
        const imgsStates = [];
        const imgsTags = [];
        if (equipment?.states && equipment?.states?.length > 0)
          equipment?.states?.map((state) => {
            if (state?.images && state?.images?.length > 0) imgsStates.push(...state.images);
            if (state?.tags && state?.tags?.length > 0) {
              state?.tags?.map((tag) => {
                if (tag?.images && tag?.images?.length > 0) imgsTags.push(...tag.images);
              });
            }
          });
        if (equipment?.images && equipment?.images?.length > 0) images.push(...equipment.images);
        setImagesAllreadyAssociated([...images, ...imgsStates, ...imgsTags]);
      });
    } else if (elementType === 'event') {
      getEvent(currentElementUUID).then((res) => {
        const imagesArr = [];
        const documentsArr = [];
        const imgsTags = [];

        res.files.forEach((file) => {
          if (file.kind === 'image') {
            imagesArr.push(file);
          } else if (file.kind === 'document') {
            documentsArr.push(file);
          }
        });
        res.tags.forEach((tag) => {
          if (tag?.images && tag?.images?.length > 0) imgsTags.push(...tag.images);
        });
        setImagesAllreadyAssociated([...imagesArr, ...imgsTags]);
        setDocuments(documentsArr);
      });
    }
  };

  const setSelectedFile = (selectedFiles) => {
    setFiles(selectedFiles);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMoreInfosCheck(event.target.checked);
  };

  const deleteImages = () => {
    const promises = [];
    for (const [uuid, selected] of Object.entries(imagesUploadedSelected)) {
      if (selected) {
        promises.push(deleteFile(uuid));
      }
    }

    Promise.all(promises)
      .then((res) => {
        getFiles();
      })
      .catch((reason) => {})
      .finally(() => {
        setHasOneImageSelected(false);
      });
  };

  const checkImage = (e) => {
    const selectedImages = JSON.parse(JSON.stringify(imagesUploadedSelected));
    selectedImages[e.target.id] = selectedImages[e.target.id] ? false : true;
    setImagesUploadedSelected(selectedImages);
  };

  const createFiles = () => {
    const promisesFiles = [];
    files.forEach((file) => {
      const imgdata = new FormData();
      imgdata.append('uuid_owner', currentElementUUID);
      imgdata.append('kind', filesType === 'images' ? 'image' : 'document');
      imgdata.append('file', file);
      promisesFiles.push(createFile(imgdata));
    });

    Promise.all(promisesFiles)
      .then((res) => {
        if (elementType === 'event' && filesType === 'images') {
          nextStepCallBack('images', moreInfosCheck);
        } else if (elementType === 'event' && filesType === 'documents') {
          nextStepCallBack('documents', true);
        } else if (elementType === 'equipment') {
          nextStepCallBack('images', moreInfosCheck);
        } else if (elementType === 'document') {
          nextStepCallBack(res);
        }
      })
      .catch((reason) => {});
  };

  useEffect(() => {
    getFiles();
  }, []);

  useEffect(() => {
    let hasOneImageSelected = false;
    for (const [uuid, selected] of Object.entries(imagesUploadedSelected)) {
      if (selected) {
        hasOneImageSelected = true;
      }
    }
    if (hasOneImageSelected) {
      setHasOneImageSelected(true);
    } else {
      setHasOneImageSelected(false);
    }
  }, [imagesUploadedSelected]);

  return (
    <Grid container spacing={0} direction="column">
      <Grid item xs={12}>
        <div className="Wizzards__contentTitle Wizzards__center">
          {elementType === 'document' && 'Importez vos documents'}
          {elementType === 'equipment' && 'Gestion des photos associées à l’équipement'}
          {elementType === 'event' && filesType === 'images' && 'Gestion des photos associées au ticket'}
          {elementType === 'event' && filesType === 'documents' && 'Gestion des documents associées au ticket'}
        </div>
      </Grid>
      <Grid item xs={12}>
        {filesType === 'images' && imagesAllreadyAssociated && (
          <Grid container spacing={0} justifyContent="center" sx={{ paddingBottom: '10px' }}>
            <Grid item xs={12}>
              <div style={{ width: '400px', margin: 'auto', textAlign: 'center' }}>
                {hasOneImageSelected && <DeleteForeverIcon style={{ cursor: 'pointer' }} onClick={deleteImages} />}
              </div>
            </Grid>
            {imagesAllreadyAssociated.map((item, index) => (
              <Grid item xs={1.3} key={index}>
                <div
                  style={{
                    display: 'inline-block',
                    width: '80px',
                    padding: '5px',
                    margin: '5px',
                    border: 'solid 1px grey',
                    borderRadius: '5px'
                  }}
                >
                  <span key={index}>
                    <img
                      width={'80px'}
                      src={`${REACT_APP_BASEURL_MEDIAS}/${item.uri}`}
                      style={{ cursor: 'pointer' }}
                      alt={item.name}
                      loading="lazy"
                      onClick={() => {
                        window.open(`${REACT_APP_BASEURL_MEDIAS}/${item.uri}`, '_blank').focus();
                      }}
                    />
                  </span>
                  <Checkbox id={item.uuid} onClick={checkImage} />
                </div>
              </Grid>
            ))}
          </Grid>
        )}
        {filesType === 'documents' && documents && (
          <Grid container spacing={0} justifyContent="center" sx={{ paddingBottom: '10px' }}>
            <Grid item xs={12}>
              <div style={{ width: '400px', margin: 'auto', textAlign: 'center' }}>
                {hasOneImageSelected && <DeleteForeverIcon style={{ cursor: 'pointer' }} onClick={deleteImages} />}
              </div>
            </Grid>
            {documents.map((item, index) => (
              <div
                key={index}
                style={{
                  display: 'inline-block',
                  width: 'auto',
                  padding: '5px',
                  margin: '5px',
                  border: 'solid 1px grey',
                  borderRadius: '5px'
                }}
              >
                <span
                  key={index}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    window.open(`${REACT_APP_BASEURL_MEDIAS}/${item.uri}`, '_blank').focus();
                  }}
                >
                  {item.name}
                </span>
                <Checkbox id={item.uuid} onClick={checkImage} />
              </div>
            ))}
          </Grid>
        )}
        <div className="Wizzards__form_item Wizzards__dropZoneArea">
          {elementType === 'equipment' && (
            <UploadFile
              filesType={filesType}
              setFile={setSelectedFile}
              text={'Faites glisser vos photos ici'}
            ></UploadFile>
          )}
          {elementType === 'event' && filesType === 'images' && (
            <UploadFile
              filesType={filesType}
              setFile={setSelectedFile}
              text={'Faites glisser vos photos ici'}
            ></UploadFile>
          )}
          {elementType === 'event' && filesType === 'documents' && (
            <UploadFile
              filesType={filesType}
              setFile={setSelectedFile}
              text={
                <pre>
                  'Faites glisser vos documents ici <br />
                  (10 megas maximum)'
                </pre>
              }
            ></UploadFile>
          )}
          {elementType === 'document' && filesType === 'document' && (
            <>
              <UploadFile
                filesType={filesType}
                setFile={setSelectedFile}
                text={
                  <pre>
                    'Faites glisser vos documents ici <br />
                    (10 megas maximum)'
                  </pre>
                }
              ></UploadFile>
            </>
          )}
        </div>
      </Grid>
      {showCheckBox && elementType === 'equipment' && (
        <Grid item xs={12}>
          <div className="Wizzards__center Wizzards__checkbox_text Wizzards__form_item">
            <Box>
              <Checkbox checked={moreInfosCheck} onChange={handleChange}></Checkbox>
              <span> Je souhaite ajouter dès maintenant d’autres caractéristiques</span>
            </Box>
          </div>
        </Grid>
      )}
      <Grid item xs={12}>
        <div className="Wizzards__center Wizzards__form_item">
          <Box sx={{ m: 2 }}>
            <DialogActions sx={{ justifyContent: 'center', gap: '10px' }}>
              {wizzardCreationMode && stepToReturn === '' && (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    nextStepCallBack('images', false, true);
                  }}
                >
                  Précédent
                </Button>
              )}
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  createFiles();
                }}
              >
                {wizzardCreationMode && (moreInfosCheck || hasMatterportScan || stepToReturn !== '')
                  ? 'Suivant'
                  : 'Enregistrer'}
              </Button>
            </DialogActions>
          </Box>
        </div>
      </Grid>
    </Grid>
  );
};

export default DocumentUploader;
