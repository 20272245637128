import { FormControl, Grid, Autocomplete, TextField, MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useState, useEffect } from 'react';
import { api, getProductContacts } from '~/api';

export const AssociateContact = ({
  allContacts,
  setSelectedContact,
  productId,
  isBuilding = false,
  isNewContact,
  contactsAlreadyAssociated
}) => {
  const [selectContact, setSelectContact] = useState(null);
  const [associateContacts, setAssociateContacts] = useState([]);
  const [filterContacts, setFilterContacts] = useState([]);

  const handleContactChange = (event, contact) => {
    const selectedUUID = contact?.uuid;
    const selectedContact = allContacts.find((contact) => contact.uuid === selectedUUID);
    setSelectedContact(selectedContact);
    setSelectContact(contact);
  };

  useEffect(() => {
    if (isBuilding) {
      api.building.contact
        .get(productId)
        .then((resp) => {
          setAssociateContacts(resp);
        })
        .catch((error) => {});
    } else if (productId) {
      setAssociateContacts(contactsAlreadyAssociated);
    }
    setFilterContacts(allContacts);
  }, []);

  useEffect(() => {
    if (associateContacts && associateContacts.length > 0) {
      const filterContacts = allContacts.filter((contact) => {
        return associateContacts.findIndex((associateContact) => associateContact.uuid === contact.uuid) === -1;
      });
      setFilterContacts(filterContacts);
    }
  }, [associateContacts]);

  const useStyles = makeStyles((theme) => ({
    groupLabel: {
      fontSize: '1.2rem',
      fontWeight: 'bold',
      color: '#000000'
    }
  }));

  const getGroupLabel = (option) => {
    const index = option.name.indexOf(' ');
    if (index !== -1 && index + 1 < option.name.length) {
      const firstLetter = option.name.charAt(index + 1).toUpperCase();
      return firstLetter;
    }
    return '';
  };

  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={12} md={12}>
        <Grid item xs={12} md={12}>
          <FormControl fullWidth>
            <Autocomplete
              options={filterContacts.sort((a, b) => {
                const firstLetterA = getGroupLabel(a).toUpperCase();
                const firstLetterB = getGroupLabel(b).toUpperCase();
                return firstLetterA.localeCompare(firstLetterB);
              })}
              groupBy={(option) => getGroupLabel(option)}
              value={selectContact}
              onChange={handleContactChange}
              getOptionLabel={(contact) =>
                contact && contact.keywords ? `${contact.name} (${contact.keywords})` : contact ? contact.name : ''
              }
              renderInput={(params) => <TextField {...params} label="Rechercher un contact existant" fullWidth />}
              renderOption={(props, contact) =>
                !selectContact || contact.uuid !== selectContact.uuid ? (
                  <MenuItem key={contact.uuid} onClick={() => handleContactChange(null, contact)} value={contact}>
                    {`${contact.name}`}
                  </MenuItem>
                ) : null
              }
              renderGroup={(params) =>
                !selectContact && (
                  <li key={params.key} className={classes.groupLabel}>
                    <ul>{params.group}</ul>
                    <ul>{params.children}</ul>
                  </li>
                )
              }
            />
          </FormControl>
        </Grid>
        <br />
        <br />
      </Grid>
    </Grid>
  );
};

export default AssociateContact;
