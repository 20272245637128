import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import '~/app/components/WizzardEquipment/WizzardEquipment';
import { addEquipment, patchEquipment } from '~/api/equipment';
import { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import { AppContext } from '~/utils/context/AppContext';

export const GeneralInfos = ({
  elementDatas,
  setElementDatas,
  nextStepCallBack,
  currentElementUUID,
  setCurrentElementUUID
}) => {
  const [equipmentFamily, setEquipmentFamily] = useState([]);
  const [equipmentKinds, setEquipmentKinds] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState(null);

  const { equipmentReferencial } = useContext(AppContext);

  useEffect(() => {
    setEquipmentFamily(equipmentReferencial?.equipmentCategoryKinds);
    setEquipmentKinds(equipmentReferencial?.equipments);
  }, []);

  useEffect(() => {
    if (selectedCategory) {
      setEquipmentKinds(_.find(equipmentFamily, { id: selectedCategory }).equipments);
    }
  }, [selectedCategory]);

  const creatEquipment = () => {
    if (currentElementUUID === '') {
      addEquipment(elementDatas)
        .then((res) => {
          setCurrentElementUUID(res.uuid);
          nextStepCallBack('generalInfos');
        })
        .catch((reason) => {});
    } else {
      patchEquipment(currentElementUUID, elementDatas)
        .then((res) => {
          nextStepCallBack('generalInfos');
        })
        .catch((reason) => {});
    }
  };

  return (
    <Grid container spacing={0} direction="column">
      <Grid item xs={12}>
        <div className="Wizzards__contentTitle Wizzards__center">Ajout d’un nouvel équipement</div>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={0} justifyContent="center">
          <Grid item xs={4}>
            <div className="Wizzards__form_item">
              <FormControl fullWidth>
                <InputLabel id="category">Catégorie</InputLabel>
                <Select
                  disabled={currentElementUUID === '' ? false : true}
                  labelId="category"
                  label="Catégorie"
                  value={selectedCategory ? _.find(equipmentFamily, { id: selectedCategory }).id : ''}
                  onChange={(e) => setSelectedCategory(e.target.value)}
                >
                  {equipmentFamily &&
                    equipmentFamily.map((EquipmentType, index) => (
                      <MenuItem key={index + 1} value={EquipmentType.id}>
                        {EquipmentType.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>

            <div className="Wizzards__form_item">
              <FormControl fullWidth>
                <InputLabel id="equipment">Equipement *</InputLabel>
                <Select
                  disabled={currentElementUUID === '' ? false : true}
                  labelId="equipment"
                  label="Equipement *"
                  value={elementDatas.kind ? elementDatas.kind : ''}
                  onChange={(e) =>
                    setElementDatas({ ...elementDatas, kind: e.target.value, category_kind: selectedCategory })
                  }
                >
                  {equipmentKinds &&
                    equipmentKinds.map((EquipmentType, index) => (
                      <MenuItem key={index + 1} value={EquipmentType.id}>
                        {EquipmentType.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
            <div className="Wizzards__form_item">
              <TextField
                label="Nom"
                fullWidth
                value={elementDatas.title ? elementDatas.title : ''}
                onChange={(e) => setElementDatas({ ...elementDatas, title: e.target.value })}
                autoComplete="off"
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <div className="Wizzards__center Wizzards__form_item">
          <Button
            disabled={
              elementDatas.kind !== null && elementDatas.kind !== undefined && elementDatas.kind !== '' ? false : true
            }
            variant="contained"
            color="primary"
            onClick={() => {
              creatEquipment();
            }}
          >
            Suivant
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};

export default GeneralInfos;
