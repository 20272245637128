import { IProduct } from '~/interfaces';
import { IAddress } from './address.models';
import { axiosPost, axiosDelete, axiosGet, axiosPatch } from './axiosClient';
import { IBuilding, IBuildingCreate, IBuildingUpdate } from './building.models';
import { IEquipment } from './equipment.models';
import { IEvent } from './event.models';
import { IFile } from './file.models';
import { ContactBase, ContactRelKind } from './contact.models';

export const createBuilding = (data: IBuildingCreate): Promise<IBuilding> => {
  return axiosPost('/buildings', data);
};

export const getBuilding = (idBuilding: string): Promise<IBuilding> => {
  return axiosGet(`/buildings/${idBuilding}`);
};

export const getAllBuilding = (): Promise<IBuilding[]> => {
  return axiosGet(`/buildings`);
};

export const updateBuilding = (idCBuilding: string, data): Promise<IBuilding> => {
  return axiosPatch(`/buildings/${idCBuilding}`, data);
};

export const deleteBuilding = (idCBuilding: string) => {
  return axiosDelete(`/buildings/${idCBuilding}`);
};

export const getBuildingEvents = (idBuilding: string): Promise<IEvent[]> => {
  return axiosGet(`/buildings/${idBuilding}/events`);
};

export const getBuildingImages = (idBuilding: string): Promise<IFile[]> => {
  return axiosGet(`/buildings/${idBuilding}/images`);
};

export const getBuildingDocuments = (idBuilding: string): Promise<IFile[]> => {
  return axiosGet(`/buildings/${idBuilding}/documents`);
};

export const getBuildingAddresse = (idBuilding: string): Promise<IAddress> => {
  return axiosGet(`/buildings/${idBuilding}/address`);
};

export const getBuildingEquipments = (idBuilding: string): Promise<IEquipment[]> => {
  return axiosGet(`/buildings/${idBuilding}/equipments`);
};

export const getBuildingProducts = (idBuilding: string): Promise<IProduct[]> => {
  return axiosGet(`/buildings/${idBuilding}/products`);
};

export const createBuildingContact = (idBuilding: string, data: any) => {
  return axiosPost(`/buildings/${idBuilding}/contacts`, data);
};

export const getBuildingContacts = (idBuilding: string): Promise<ContactBase[]> => {
  return axiosGet(`/buildings/${idBuilding}/contacts`);
};

export const createBuildingContactLink = (idBuilding: string, idContact: string, kind: ContactRelKind) => {
  return axiosPost(`/buildings/${idBuilding}/contacts/${idContact}/kind/${kind}`, {});
};

export const deleteBuildingContactLink = (idBuilding: string, idContact: string, kind: ContactRelKind) => {
  return axiosDelete(`/buildings/${idBuilding}/contacts/${idContact}/kind/${kind}`);
};

export const activateBuildingMatterportScan = (idBuilding: string) => {
  return axiosPost(`/buildings/${idBuilding}/activate`, {});
};

export const unactivateBuildingMatterportScan = (idBuilding: string) => {
  return axiosPost(`/buildings/${idBuilding}/unactivate`, {});
};

export const getBuildingMatterportImages = (idBuilding: string) => {
  return axiosGet(`/buildings/${idBuilding}/matterport-images`);
};
