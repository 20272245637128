import { Box, Grid, TextField } from '@mui/material';
import * as React from 'react';

export const ObjectForm = ({ documentData, setDocumentData, documentTypeLayout }) => {
  const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const data = { ...documentData.data };
    data.object = event.target.value;
    setDocumentData({ ...documentData, data: data });
  };

  return (
    <>
      {documentData && documentTypeLayout && documentTypeLayout.needObject && (
        <>
          <Grid item xs={12}>
            <Box sx={{ mb: 3 }}>
              <TextField
                label="Objet"
                fullWidth
                value={documentData.data.object ? documentData.data.object : ''}
                onChange={handleCommentChange}
                autoComplete="off"
              />
            </Box>
          </Grid>
        </>
      )}
    </>
  );
};
