import { Card, CardHeader, CardContent, Box, Typography, useTheme } from '@mui/material';
import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';

export const DoughnutChart = ({ value, title, text, info1, info2 }) => {
  const theme = useTheme();

  return (
    <Card sx={{ borderRadius: '10px' }}>
      <CardHeader title={title} sx={{ height: '2vh' }} />
      <CardContent sx={{ height: 208 }}>
        <Box display="flex" justifyContent="center">
          <Box display="flex" alignItems="center" mr={2}>
            <Box width={15} height={15} bgcolor={theme.palette.primary.main} mr={1} sx={{ borderRadius: '5px' }} />
            <Typography variant="body2">{info1}</Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <Box width={15} height={15} bgcolor={theme.palette.secondary.main} mr={1} sx={{ borderRadius: '5px' }} />
            <Typography variant="body2">{info2}</Typography>
          </Box>
        </Box>
        <Box position="relative" display="inline-flex" justifyContent="center" alignItems="center" sx={{ mt: '1vh' }}>
          <Gauge
            width={200}
            height={150}
            value={value}
            valueMin={0}
            valueMax={100}
            sx={{
              [`& .${gaugeClasses.valueText}`]: {
                fontSize: 40,
                textAlign: 'center',
                lineHeight: 1.2
              },
              [`& .${gaugeClasses.referenceArc}`]: {
                fill: theme.palette.secondary.main
              }
            }}
            text={() => ``}
          />
          <Box
            position="absolute"
            top="50%"
            left="50%"
            textAlign={'center'}
            style={{ transform: 'translate(-50%, -50%)' }}
          >
            <Typography variant="h3" color={'primary'}>{`${value}%`}</Typography>
            <Typography variant="h6" color={'gray'}>
              {text}
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};
