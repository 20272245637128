import { AddCircleRounded } from '@mui/icons-material';
import { useEffect, useRef, useState } from 'react';
import { getEvent } from '~/api';
import { ShowcaseBundleWindow, Sweep } from '~/../public/matterport/sdk';
import './MatterportViewer.scss';
import _ from 'lodash';

let tmpSid = null;
let sidCorrespondance = {};
let sidCorrespondance2 = {};
let tagsSidToUUID = {};
let tagsUUIDToSid = {};
let oldCameraPosition = { x: 0, y: 0, z: 0 };
let longClickStart = false;
//
let sweeps = {};
let selectedAdminSID = {};

const { REACT_APP_MATTERPORT_KEY } = process.env;

export declare interface MatterportViewerProps {
  productId: string;
  modelId: string;
  uuid_event: string;
  displayTags?: boolean;
  mode?: 'viewer' | 'event-viewer' | 'pin' | 'localize' | 'administrate' | 'equipment-location';
  tags?: any;
  mpHelp: number;
  mpDh: number;
  mpHl: number;
  mpGt: number;
  mpHr: number;
  mpMls: number;
  mpF: number;
  mpNozoom: number;
  mpWh: number;
  mpLang: string;
  mpSearch: number;
  mattertagsData?: any;
  tagIndexSelected?: any;
  removeTag?: any;
  setTagIndex?: any;
  customColorTag?: any;
  context?: string;
  status?: number;
  typeEquipment?: number;
  setSelectedTagSid?: any;
  cancelLocalization?: any;
  hasBeenValidated?: any;
  modifyLocalization?: any;
  placedTagCallback?: any;
  setModifyLocalization?: any;
  localizationActivated?: boolean;
  wizzardCreationMode?: boolean;
  currentElementUUID?: string;
  reinitialize?: boolean;
  matterport_scan_areas?: [];
  setCurrentSweepArea?: any;
  currentSweepArea?: any;
  restrictive?: boolean;
  equipmentTab?: string;
  setZoomImageOpened?: any;
  setZoomImgUrl?: any;
  mainOffset?: number;
}

interface cameraPos {
  x: number;
  y: number;
  z: number;
  time: number;
}

const pannels = [
  { name: 'informations', hasSub: false, idPanel: 0, label: 'Informations', hasLocalisations: false },
  { name: 'annexes', hasSub: false, idPanel: 1, label: 'Annexes', hasLocalisations: false },
  {
    name: 'externalEquipments',
    hasSub: false,
    idPanel: 2,
    label: 'Equipements extérieurs',
    hasLocalisations: true,
    icon: 'public_characters_circle-e',
    tagColor: { r: 0 / 255, g: 153 / 255, b: 51 / 255 }
  },
  {
    name: 'generalEquipments',
    hasSub: true,
    idPanel: 3,
    label: 'Equipements généraux',
    hasLocalisations: true,
    icon: 'public_characters_circle-e',
    tagColor: { r: 0 / 255, g: 153 / 255, b: 51 / 255 }
  },
  {
    name: 'meters',
    hasSub: false,
    idPanel: 4,
    label: 'Relevé des compteurs',
    hasLocalisations: true,
    icon: 'public_characters_circle-e',
    tagColor: { r: 0 / 255, g: 153 / 255, b: 51 / 255 }
  },
  {
    name: 'degradations',
    hasSub: true,
    idPanel: 5,
    label: 'Dégradations',
    hasLocalisations: true,
    icon: 'public_symbols_exclamation-square',
    tagColor: { r: 255 / 255, g: 204 / 255, b: 0 / 255 }
  },
  {
    name: 'inventoryEquipments',
    hasSub: true,
    idPanel: 6,
    label: 'Inventaire',
    hasLocalisations: true,
    icon: 'public_characters_circle-e',
    tagColor: { r: 0 / 255, g: 153 / 255, b: 51 / 255 }
  },
  { name: 'generalState', hasSub: false, idPanel: 7, label: 'Etat général du bien', hasLocalisations: false },
  { name: 'keys', hasSub: false, idPanel: 8, label: 'Remise des clés', hasLocalisations: false }
];

export const MatterportViewer = ({
  productId,
  modelId,
  uuid_event = null,
  displayTags = false,
  mode = 'pin',
  tags = [],
  mpDh,
  mpLang,
  mpSearch,
  mattertagsData: data,
  tagIndexSelected = null,
  placedTagCallback,
  removeTag,
  setSelectedTagSid = null,
  cancelLocalization = null,
  hasBeenValidated = null,
  modifyLocalization = null,
  setModifyLocalization = null,
  localizationActivated = null,
  wizzardCreationMode = null,
  currentElementUUID = null,
  reinitialize = null,
  matterport_scan_areas = null,
  setCurrentSweepArea = null,
  currentSweepArea = null,
  restrictive = false,
  equipmentTab = '',
  setZoomImageOpened = null,
  setZoomImgUrl = null,
  mainOffset = 0
}: MatterportViewerProps) => {
  const matterPortFrame = useRef<HTMLIFrameElement>(null);
  const [mpSdk, setMpSDK] = useState(null);

  const [sweepCollection, setSweepCollection] = useState<any>(null);

  const tagIdRef = useRef(null); //sid of mattertag
  const [sid, setSid] = useState(null);
  const tagDataRef = useRef(null); //placement of mattertag
  const circleIndicator = useRef(null); //placement of circleIndicator
  const circleIndicatorBackGround = useRef(null); //placement of circleIndicatorBackGround
  const circleIndicatorFill = useRef(null); //placement of circleIndicatorFill
  const [matterTagsAdded, setMatterTags] = useState(false); //bool if finished to place point
  const [initEditEffects, setInitEditEffects] = useState(false); //initialization of listeners to place points
  const [pinMode, setPinMode] = useState(false); //button switch to place point vs move inside the model
  const pinModeRef = useRef(false); //copy of pinMode
  const [eventData, setEventData] = useState<any>(null);
  const [tagSidToDelete, setTagSidToDelete] = useState(null);
  const [addMatterTagMode, setAddMatterTagMode] = useState(false);
  const [sceneReady, setSceneReady] = useState(false);
  const [relocalization, setRelocalization] = useState(false);
  const [sidsTags, setSidsTags] = useState([]);
  const [imgsBySIds, _setImgsBySIds] = useState({});
  const equipmentIdRef = useRef(null);

  type MatterportParams = {
    modelId: string; // Identifiant du modèle Matterport
    applicationKey: string; // Clé d'application pour l'authentification
    help?: 0 | 1 | 2; // Affichage de l'aide au lancement
    nt?: 0 | 1; // Comportement d'ouverture sur mobile
    play?: 0 | 1; // Lecture automatique du modèle
    qs?: 0 | 1; // Activation du Quickstart
    brand?: 0 | 1; // Affichage des détails de la marque
    dh?: 0 | 1; // Affichage de la vue Dollhouse
    tour?: 0 | 1 | 2 | 3; // Mode de visite guidée
    gt?: 0 | 1; // Affichage des boutons de visite guidée
    views?: 0 | 1; // Affichage des vues enregistrées
    hr?: 0 | 1; // Affichage du Highlight Reel
    mls?: 0 | 1 | 2; // Mode MLS (cacher les informations de marque)
    mt?: 0 | 1; // Affichage des Mattertags
    tagNav?: 0 | 1; // Navigation avec les tags
    pin?: 0 | 1; // Affichage des pins de vues 360°
    portal?: 0 | 1; // Affichage des portails de connexion 360°
    f?: 0 | 1; // Activation du changement d'étage
    fp?: 0 | 1; // Affichage du plan d'étage
    lang?: string; // Langue de l'interface utilisateur
    nozoom?: 0 | 1; // Désactivation du zoom
    search?: 0 | 1; // Affichage de l'icône de recherche
    wh?: 0 | 1; // Activation de la molette de la souris
    kb?: 0 | 1; // Activation du panoramique en mode visite guidée
    lp?: 0 | 1; // Boucle de la visite guidée
    st?: number; // Temps d'attente à chaque point de visite en ms
    title?: 0 | 1; // Affichage du titre de l'espace
    tourcta?: 0 | 1 | 2; // Affichage de l'appel à l'action en fin de visite
    wts?: number; // Vitesse de transition entre les points de visite
    ts?: number; // Temps avant le démarrage automatique de la visite guidée
    hl?: 0 | 1 | 2; // Mode d'affichage du Highlight Reel au lancement
    vr?: 0 | 1; // Activation du bouton VR
  };

  const generateMatterportUrl = (params: MatterportParams): string => {
    const baseUrl = '/matterport/showcase.html';
    const queryParams = new URLSearchParams({
      m: params.modelId,
      applicationKey: params.applicationKey,
      ...Object.fromEntries(
        Object.entries(params)
          .filter(([key, value]) => value !== undefined && key !== 'modelId' && key !== 'applicationKey')
          .map(([key, value]) => [key, String(value)])
      )
    });
    return `${baseUrl}?${queryParams.toString()}`;
  };

  // const frameUri = `/matterport/showcase.html?m=${modelId}&applicationKey=${REACT_APP_MATTERPORT_KEY}&help=0&play=1&qs=0&gt=0&hr=0&dh=${mpDh}&lang=${mpLang}&search=${mpSearch}&mls=2&f=1&wh=1&tagNav=0&brand=0`;
  const frameUri = generateMatterportUrl({
    modelId: modelId,
    applicationKey: REACT_APP_MATTERPORT_KEY,
    help: 0,
    play: 1,
    qs: 0,
    gt: 0,
    hr: 0,
    dh: mpDh as 0 | 1,
    lang: mpLang,
    search: mpSearch as 0 | 1,
    mls: 2,
    f: 1,
    wh: 1,
    tagNav: 0,
    brand: 0
  });

  const imgsBySIdsRef = useRef({});

  const setImgsBySIds = (data) => {
    imgsBySIdsRef.current = data;
    _setImgsBySIds(data);
  };

  const delayBeforeAddMatterTag = 1000;
  const cameraPosition: cameraPos = { x: 0, y: 0, z: 0, time: 0 };
  const cameraPositionOnInputStart: cameraPos = { x: 0, y: 0, z: 0, time: 0 };
  const iDown = (e) => {
    if (pinMode === true) {
      cameraPositionOnInputStart.x = cameraPosition.x;
      cameraPositionOnInputStart.y = cameraPosition.y;
      cameraPositionOnInputStart.z = cameraPosition.z;
      cameraPositionOnInputStart.time = new Date().getTime();

      longClickStart = true;

      const pathLength = circleIndicatorFill.current.getTotalLength();

      circleIndicatorFill.current.setAttribute('stroke-dashoffset', pathLength);
      circleIndicatorFill.current.setAttribute('stroke-dasharray', pathLength);
      circleIndicatorBackGround.current.setAttribute('opacity', 0);
      circleIndicator.current.setAttribute('visibility', 'visible');

      if (e.targetTouches && e.targetTouches.length > 0) {
        circleIndicator.current.style.left = `${e.targetTouches[0].clientX - 100}px`;
        circleIndicator.current.style.top = `${e.targetTouches[0].clientY + mainOffset / 2}px`;
      } else {
        circleIndicator.current.style.left = `${e.clientX - 100}px`;
        circleIndicator.current.style.top = `${e.clientY + mainOffset / 2}px`;
      }
    }
  };

  const createCurrentTags = (tags) => {
    sidCorrespondance2 = {};
    if (mpSdk) {
      sidsTags.forEach((key) => {
        mpSdk.Tag.remove(key);
      });

      let color = { r: 241 / 255, g: 118 / 255, b: 51 / 255 };
      if (equipmentTab !== '') {
        color = _.find(pannels, { name: equipmentTab }).tagColor;
      }

      const imgsBySIds = {};
      const promises = [];
      tags.forEach((tag) => {
        const rbg = tag.color !== null && tag.color !== undefined ? hexToRgb(tag.color) : color;

        promises.push(
          mpSdk.Tag.add({
            enabled: false,
            anchorPosition: {
              x: tag.position.x,
              y: tag.position.y,
              z: tag.position.z
            },
            stemVector: {
              x: tag.position.steam_x,
              y: tag.position.steam_y,
              z: tag.position.steam_z
            },
            color: rbg
            // label: ''
          })
        );
      });

      Promise.all(promises).then((resp) => {
        resp.forEach((sidArr, index) => {
          mpSdk.Tag.allowAction(sidArr[0], {
            navigating: true,
            opening: false
          });

          mpSdk.Tag.editStem(sidArr[0], { stemHeight: 0.3 });

          if (tags[index].images && tags[index].images.length > 0)
            imgsBySIds[sidArr[0]] = `${process.env.REACT_APP_BASEURL_MEDIAS}/${tags[index].images[0].uri}`;

          equipmentTab !== ''
            ? mpSdk.Tag.editIcon(sidArr[0], _.find(pannels, { name: equipmentTab }).icon)
            : mpSdk.Tag.editIcon(sidArr[0], pannels[2].icon);

          mpSdk.Tag.editStem(sidArr[0], { stemHeight: 0.3 });

          sidCorrespondance[sidArr[0]] = tags[index].sid;
          sidCorrespondance2[tags[index].sid] = sidArr[0];

          tagsSidToUUID[sidArr[0]] = tags[index].uuid;
          tagsUUIDToSid[tags[index].uuid] = sidArr[0];
          if (mode === 'equipment-location') {
            setTimeout(() => {
              mpSdk.Mattertag.navigateToTag(sidArr[0]);
            }, 1000);
          }
        });

        if (!wizzardCreationMode) {
          let noTagPrincipal = true;
          tags.forEach((tag) => {
            if (tag.is_principal === true) {
              mpSdk.Mattertag.navigateToTag(tagsUUIDToSid[tag.uuid]);
              noTagPrincipal = false;
            }
          });
          if (tags.length === 1 && noTagPrincipal) {
            mpSdk.Mattertag.navigateToTag(tagsUUIDToSid[tags[0].uuid]);
          }
        }

        setImgsBySIds(imgsBySIds);
      });
    }
  };

  const onClickTag = (sid, e) => {
    if (tmpSid === sid) {
      tmpSid = null;
      setTagSidToDelete(null);
      if (setSelectedTagSid) setSelectedTagSid(null);
    } else {
      tmpSid = sid;
      setTagSidToDelete(sid);

      let storedSidTag = null;

      for (const [key, value] of Object.entries(tagsSidToUUID)) {
        if (value === sid) {
          storedSidTag = key;
        }

        if (key === sid) {
          storedSidTag = key;
        }
      }
      if (setSelectedTagSid) setSelectedTagSid([storedSidTag, sid]);
    }

    if (mode === 'administrate') {
      if (selectedAdminSID[sid] === null || selectedAdminSID[sid] === undefined || selectedAdminSID[sid] === false) {
        mpSdk.Tag.editColor(sid, {
          r: 241 / 255,
          g: 118 / 255,
          b: 51 / 255
        });
        selectedAdminSID[sid] = true;
        for (const [key, value] of Object.entries(sweeps)) {
          const obj: any = value;
          if (sid === obj.tagSid) {
            mpSdk.Sweep.enable(obj.sweepSid);
            sweeps[key].enabled = true;
            setCurrentSweepArea((currentSweepArea) => [...currentSweepArea, obj.sweepSid]);
          }
        }
      } else {
        selectedAdminSID[sid] = false;
        for (const [key, value] of Object.entries(sweeps)) {
          const obj: any = value;
          if (sid === obj.tagSid) {
            mpSdk.Sweep.disable(obj.sweepSid);
            sweeps[key].enabled = true;
            setCurrentSweepArea((currentSweepArea) => {
              return currentSweepArea.filter((sweepSid) => sweepSid !== obj.sweepSid);
            });
          }
        }
      }
    }
  };

  const stopLongClick = () => {
    longClickStart = false;
    if (circleIndicator && circleIndicator.current) circleIndicator.current.setAttribute('visibility', 'hidden');
  };

  const addMattertagAdministrate = (data) => {
    const arrSids = [];

    eventData.tags.forEach((tagData, index) => {
      mpSdk.Tag.add([
        {
          label: eventData.title,
          anchorPosition: {
            x: tagData.position.x,
            y: tagData.position.y,
            z: tagData.position.z
          },
          stemVector: {
            x: tagData.position.steam_x,
            y: tagData.position.steam_y,
            z: tagData.position.steam_z
          },
          color: { r: 241 / 255, g: 118 / 255, b: 51 / 255 }
        }
      ]).then((tag) => {
        tagsSidToUUID[tag[0]] = tag[0];
        arrSids.push(tag[0]);
        if (index === Number(tagIndexSelected) && mode !== 'equipment-location') {
          mpSdk.Mattertag.navigateToTag(tag[0]);
        }
      });
    });
  };

  const changePinMode = () => {
    if (pinMode) {
      tagIdRef.current = null;
      tagDataRef.current = null;
    }
    pinModeRef.current = true;
    setPinMode(true);
    setAddMatterTagMode(true);
  };

  const changeTagPosition = () => {
    if (tagSidToDelete) mpSdk.Tag.remove(...tagSidToDelete);
    sidCorrespondance = {};

    for (const [key, value] of Object.entries(tagsSidToUUID)) {
      mpSdk.Tag.remove(key);
      removeTag(value);
    }
    sidCorrespondance2 = {};
    tagsSidToUUID = {};
    changePinMode();
  };

  const cancelAddMaterTag = () => {
    mpSdk.Tag.remove([sid]);
    setPinMode(false);
    setAddMatterTagMode(false);
    setSid(null);
  };

  ////
  //Initial load

  useEffect(() => {
    selectedAdminSID = {};
    if (data === undefined) {
      if (uuid_event !== '' && uuid_event !== null && uuid_event !== undefined)
        getEvent(uuid_event).then((resp) => {
          setEventData(resp);
        });
    } else {
      setEventData(data);
    }
    return () => {
      selectedAdminSID = {};
      sweeps = {};
    };
  }, []);

  useEffect(() => {
    if (sweepCollection) {
      const defaultScanArea =
        matterport_scan_areas && matterport_scan_areas['default'] && matterport_scan_areas['default'].sweeps
          ? matterport_scan_areas['default'].sweeps
          : [];
      for (const [key, value] of Object.entries(sweepCollection)) {
        const sweepData = value as Sweep.ObservableSweepData;
        if (
          defaultScanArea.length > 0 &&
          matterport_scan_areas &&
          matterport_scan_areas['default'] &&
          matterport_scan_areas['default'].restrictive
        )
          mpSdk.Sweep.disable(sweepData.id);

        let currentSweepIsEnable = false;
        defaultScanArea.forEach((sweepSid) => {
          if (sweepSid === sweepData.id) {
            mpSdk.Sweep.enable(sweepData.id);
            currentSweepIsEnable = true;
          }
        });
        if (mode === 'administrate') {
          mpSdk.Tag.add({
            enabled: false,
            anchorPosition: {
              x: sweepData.position.x,
              y: sweepData.position.y - 2,
              z: sweepData.position.z
            },
            stemVector: {
              x: 0,
              y: 1,
              z: 0
            },
            color: currentSweepIsEnable
              ? {
                  r: 241 / 255,
                  g: 118 / 255,
                  b: 51 / 255
                }
              : {
                  r: 9 / 255,
                  g: 107 / 255,
                  b: 128 / 255
                }
          }).then((tag) => {
            selectedAdminSID[tag[0]] = currentSweepIsEnable;
            sweeps[sweepData.id].tagSid = tag[0];
            sweeps[sweepData.id].enabled = currentSweepIsEnable;
            mpSdk.Tag.allowAction(tag[0], {
              navigating: false,
              opening: false
            });
          });
        }
        if (mode === 'viewer') {
        }
      }
    }
  }, [sweepCollection]);

  useEffect(() => {
    if (mode === 'administrate') {
      for (const [key, value] of Object.entries(selectedAdminSID)) {
        selectedAdminSID[key] = false;
        if (key !== undefined) {
          mpSdk.Tag.editColor(key, {
            r: 9 / 255,
            g: 107 / 255,
            b: 128 / 255
          }).then((resp) => {
            mpSdk.Tag.close(key);
          });
        }
        currentSweepArea.forEach((sweepSid) => {
          if (sweeps[sweepSid].tagSid === key) {
            mpSdk.Tag.editColor(key, {
              r: 241 / 255,
              g: 118 / 255,
              b: 51 / 255
            }).then((resp) => {
              mpSdk.Tag.close(key);
            });
            selectedAdminSID[key] = true;
          }
        });
      }

      for (const [key, value] of Object.entries(sweeps)) {
        const obj: any = value;
        obj.enabled = false;
        currentSweepArea.forEach((sweepSid) => {
          if (sweepSid === key) {
            if (obj.tagSid !== undefined) {
              obj.enabled = true;
              mpSdk.Tag.editColor(obj.tagSid, {
                r: 241 / 255,
                g: 118 / 255,
                b: 51 / 255
              }).then((resp) => {
                mpSdk.Tag.close(obj.tagSid);
              });
            }
          }
        });
      }
    }
  }, [currentSweepArea]);

  useEffect(() => {
    if (mpSdk && sweepCollection) {
      if (mode === 'viewer' && sceneReady) {
        for (const [key, value] of Object.entries(sweepCollection)) {
          const sweepData = value as Sweep.ObservableSweepData;

          if (currentSweepArea.length > 0 && restrictive) {
            mpSdk.Sweep.disable(sweepData.id);
          }
          currentSweepArea.forEach((sweepSid) => {
            if (sweepSid === sweepData.id) {
              mpSdk.Sweep.enable(sweepData.id);
            }
          });

          if (currentSweepArea.length === 0) {
            mpSdk.Sweep.enable(sweepData.id);
          }
        }
        if (currentSweepArea && currentSweepArea.length > 0) {
          mpSdk.Sweep.moveTo(currentSweepArea[Math.floor(Math.random() * currentSweepArea.length)], {
            transition: mpSdk.Sweep.Transition.FLY
          }).then((resp) => {});
        }
      }
    }
  }, [mpSdk, currentSweepArea, sweepCollection, sceneReady]);

  useEffect(() => {
    if (mpSdk) {
      mpSdk.on(mpSdk.Mattertag.Event.CLICK, onClickTag);
      mpSdk.on(mpSdk.Camera.Event.MOVE, (pose) => {
        if (
          oldCameraPosition.x !== pose.position.x ||
          oldCameraPosition.y !== pose.position.y ||
          oldCameraPosition.z !== pose.position.z
        ) {
          oldCameraPosition = JSON.parse(JSON.stringify(pose.position));
        }
      });
      mpSdk.Tag.toggleDocking(false);
      mpSdk.Tag.toggleNavControls(false);

      mpSdk.App.state.subscribe((appState) => {
        if (appState.phase === 'appphase.playing') {
          if (mode === 'administrate') {
            const mode = mpSdk.Mode.Mode.FLOORPLAN;

            mpSdk.Mode.moveTo(mode);
          } else if (currentSweepArea && currentSweepArea.length > 0) {
            // const selectedSweep = currentSweepArea[Math.floor(Math.random() * currentSweepArea.length)];
            // mpSdk.Sweep.moveTo(selectedSweep, { transition: mpSdk.Sweep.Transition.FLY }).then((resp) => {});
          }
        }
      });

      mpSdk.Sweep.data.subscribe({
        onAdded: function (index, item, collection) {
          sweeps[item.id] = { sweepSid: item.id };
        },
        onRemoved: function (index, item, collection) {},
        onUpdated: function (index, item, collection) {},
        onCollectionUpdated: function (collection) {
          setSweepCollection(collection);
        }
      });

      mpSdk.Tag.data.subscribe({
        onAdded: function (index, item, collection) {
          const arrSids = [];
          for (const [key, value] of Object.entries(collection)) {
            arrSids.push(key);
          }

          setSidsTags(arrSids);
        },
        onRemoved: function (index, item, collection) {
          // console.log('removed', item, item, collection);
          const arrSids = [];

          for (const [key] of Object.entries(collection)) {
            arrSids.push(key);
          }

          setSidsTags(arrSids);
        },
        onUpdated: function (index, item, collection) {
          // console.log('updated', item, item, collection);
        }
      });

      mpSdk.Tag.openTags.subscribe({
        prevState: {
          hovered: null,
          docked: null,
          selected: null
        },
        onChanged(newState) {
          if (newState.hovered !== this.prevState.hovered) {
            if (newState.hovered) {
              // console.log(newState.hovered, 'was hovered');

              if (mode !== 'administrate')
                mpSdk.Tag.allowAction(newState.hovered, {
                  navigating: true
                });
            } else {
              // console.log(this.prevState.hovered, 'is no longer hovered');
            }
          }
          if (newState.docked !== this.prevState.docked) {
            if (newState.docked) {
              // console.log(newState.docked, 'was docked');
            } else {
              // console.log(this.prevState.docked, 'was undocked');
            }
          }

          // only compare the first 'selected' since only one tag is currently supported
          const [selected = null] = newState.selected; // destructure and coerce the first Set element to null
          if (selected !== this.prevState.selected) {
            if (selected) {
              // console.log(selected);
              // // console.log(selected, 'was selected');
              // console.log(imgsBySIdsRef.current);
              // console.log(imgsBySIdsRef.current[selected]);

              if (imgsBySIdsRef.current[selected] !== undefined) {
                setZoomImgUrl(imgsBySIdsRef.current[selected]);
                setZoomImageOpened(true);
              }
            } else {
              // console.log(this.prevState.selected);
              // // console.log(selected, 'was selected');
              // console.log(imgsBySIdsRef.current);
              // console.log(imgsBySIdsRef.current[this.prevState.selected]);
              if (imgsBySIdsRef.current[this.prevState.selected] !== undefined) {
                setZoomImgUrl(imgsBySIdsRef.current[this.prevState.selected]);
                setZoomImageOpened(true);
              }
              // console.log(this.prevState.selected, 'was deselected');
            }
          }

          // clone and store the new state
          this.prevState = {
            ...newState,
            selected
          };
        }
      });

      if (tags && tags.length > 0) {
        createCurrentTags(tags);
      }
    }
  }, [mpSdk]);

  useEffect(() => {
    if (!matterPortFrame.current) {
      return;
    }
    matterPortFrame.current.addEventListener('load', async () => {
      try {
        const frameCtx = matterPortFrame.current.contentWindow as ShowcaseBundleWindow;
        const sdk = await frameCtx.MP_SDK.connect(matterPortFrame.current.contentWindow as ShowcaseBundleWindow);
        setMpSDK(sdk);
      } catch (e) {}
    });
    matterPortFrame.current.src = frameUri;
  }, [matterPortFrame, frameUri]);

  useEffect(() => {
    if (modifyLocalization === true) {
      for (const [key, value] of Object.entries(sidCorrespondance2)) {
        if (value && value !== undefined) {
          mpSdk.Tag.allowAction(value, {})
            .catch((error) => {
              // console.error(error);
              return;
            })
            .then((idTag) => {
              mpSdk.Mattertag.navigateToTag(value).then((idTag) => {
                mpSdk.Tag.allowAction(idTag, {
                  navigating: true
                });
              });
            });
        }
      }

      setRelocalization(modifyLocalization);
    }
  }, [modifyLocalization]);

  //PlaceInitial tags passed throught props
  useEffect(() => {
    if (mpSdk && !matterTagsAdded && tags && tags.length > 0 && displayTags) {
      mpSdk.Tag.add(tags);
      setMatterTags(true);
    }
  }, [mpSdk, matterTagsAdded, tags, displayTags]);

  useEffect(() => {
    if (hasBeenValidated) {
      for (const [key, value] of Object.entries(sidCorrespondance2)) {
        mpSdk.Tag.remove(value);
        mpSdk.Tag.remove(key);
      }

      changePinMode();
    }
  }, [hasBeenValidated]);

  useEffect(() => {
    const bodyIframe = matterPortFrame.current.contentWindow.window.document.body;

    bodyIframe.style.borderRadius = '20px';

    bodyIframe.removeEventListener('mousedown', iDown);
    bodyIframe.removeEventListener('mousemove', stopLongClick);
    bodyIframe.removeEventListener('mouseup', stopLongClick);
    bodyIframe.removeEventListener('touchstart', iDown);
    bodyIframe.removeEventListener('touchmove', stopLongClick);
    bodyIframe.removeEventListener('touchend', stopLongClick);

    bodyIframe.addEventListener('mousedown', iDown);
    bodyIframe.addEventListener('mousemove', stopLongClick);
    bodyIframe.addEventListener('mouseup', stopLongClick);
    bodyIframe.addEventListener('touchstart', iDown);
    bodyIframe.addEventListener('touchmove', stopLongClick);
    bodyIframe.addEventListener('touchend', stopLongClick);

    return () => {
      bodyIframe.removeEventListener('mousedown', iDown);
      bodyIframe.removeEventListener('mousemove', stopLongClick);
      bodyIframe.removeEventListener('mouseup', stopLongClick);
      bodyIframe.removeEventListener('touchstart', iDown);
      bodyIframe.removeEventListener('touchmove', stopLongClick);
      bodyIframe.removeEventListener('touchend', stopLongClick);
    };
  }, [mpSdk, pinMode]);

  useEffect(() => {
    if (localizationActivated !== false) {
      setAddMatterTagMode(false);
      setPinMode(false);
      setRelocalization(false);
      if (setModifyLocalization) setModifyLocalization(false);
    }
  }, [localizationActivated]);

  useEffect(() => {
    if (pinMode) {
      const interval = setInterval(() => {
        if (
          cameraPositionOnInputStart.x === cameraPosition.x &&
          cameraPositionOnInputStart.y === cameraPosition.y &&
          cameraPositionOnInputStart.z === cameraPosition.z &&
          longClickStart
        ) {
          const nextShow = cameraPositionOnInputStart.time + delayBeforeAddMatterTag;
          const pcent = (delayBeforeAddMatterTag - (nextShow - new Date().getTime())) / delayBeforeAddMatterTag;
          const pathLength = circleIndicatorFill.current.getTotalLength();
          circleIndicatorFill.current.setAttribute('stroke-dashoffset', pathLength - pathLength * pcent);

          if (new Date().getTime() > nextShow) {
            if (tagIdRef.current) {
              placedTagCallback(tagDataRef.current, tagIdRef.current, equipmentIdRef.current);
              tagIdRef.current = null;
              tagDataRef.current = null;
              pinModeRef.current = false;
              circleIndicator.current.setAttribute('visibility', 'hidden');
              setAddMatterTagMode(false);
              setPinMode(false);
              setRelocalization(false);
              setModifyLocalization(false);
            }
          }
        }
      }, 16);
      return () => clearInterval(interval);
    }
  }, [pinMode]);

  useEffect(() => {
    if (sceneReady === false) {
      return;
    }
    if (mode === 'localize' && sceneReady === true && localizationActivated) {
      changePinMode();
    }
  }, [sceneReady]);

  //Custom placement tag
  useEffect(() => {
    const addTag = async () => {
      tagDataRef.current = {
        anchorPosition: {
          x: -1000000000000000000000000000,
          y: -1000000000000000000000000000,
          z: -1000000000000000000000000000
        },
        stemVector: { x: -100000000000000000000000, y: -100000000000000000000000, z: -100000000000000000000000 }
      };

      const [sid] = await mpSdk.Tag.add({
        label: '',
        description: '',
        ...tagDataRef.current,
        color: { r: 241 / 255, g: 118 / 255, b: 51 / 255 }
      });
      tagIdRef.current = sid;

      mpSdk.Tag.allowAction(sid, {
        navigating: true
      });

      setSid(sid);
    };

    const updateTagPosition = (newPos, newNorm = undefined, scale = undefined) => {
      if (!newPos) return;
      if (!scale) scale = 0.33;
      if (!newNorm) newNorm = { x: 0, y: 1, z: 0 };

      tagDataRef.current = {
        anchorPosition: newPos,
        stemVector: {
          x: scale * newNorm.x,
          y: scale * newNorm.y,
          z: scale * newNorm.z
        }
      };

      mpSdk.Tag.editPosition(tagIdRef.current, tagDataRef.current).catch((e) => {
        tagIdRef.current = null;
        tagDataRef.current = null;
      });
    };

    async function asynChronous() {
      mpSdk.Camera.pose.subscribe((pose) => {
        cameraPosition.x = pose.position.x;
        cameraPosition.y = pose.position.y;
        cameraPosition.z = pose.position.z;
      });
      if (!initEditEffects) {
        //callback to pointer events if on model
        mpSdk.Pointer.intersection.subscribe((intersectionData) => {
          if (pinModeRef.current) {
            updateTagPosition(intersectionData.position, intersectionData.normal);
          }
        });
        setInitEditEffects(true);
      }

      if (pinMode && !tagIdRef.current) {
        mpSdk.Mode.moveTo(mpSdk.Mode.Mode.INSIDE);
        addTag();
      }
    }

    if (mpSdk) {
      asynChronous();
      mpSdk.App.state.subscribe((appState) => {
        if (appState.phase === 'appphase.playing') {
          const iframe = document.getElementById('matterport-frame') as HTMLIFrameElement;
          const iframeCanvas = iframe.contentWindow.document.getElementsByClassName(
            'webgl-canvas'
          )[0] as HTMLCanvasElement;
          iframeCanvas.focus();
          setSceneReady(true);
        }
      });
    }
  }, [mpSdk, mode, initEditEffects, pinMode, placedTagCallback]);

  useEffect(() => {
    if (mode === 'event-viewer' && mpSdk && eventData && eventData.tags.length > 0) {
      addMattertagAdministrate({});
    }
  }, [mpSdk, eventData]);

  useEffect(() => {
    if (reinitialize) changePinMode();
  }, [reinitialize]);

  useEffect(() => {
    for (const [key, value] of Object.entries(tagsSidToUUID)) {
      if (mpSdk) {
        mpSdk.Tag.remove(key);
      }
    }
    if (tags && tags.length > 0) {
      createCurrentTags(tags);
    }
  }, [tags]);

  function hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16) / 255,
          g: parseInt(result[2], 16) / 255,
          b: parseInt(result[3], 16) / 255
        }
      : null;
  }

  useEffect(() => {
    equipmentIdRef.current = currentElementUUID;
  }, [currentElementUUID]);

  // RENDER
  if (!REACT_APP_MATTERPORT_KEY) {
    return (
      <>
        <h2>Could not load frame</h2>
      </>
    );
  }

  return (
    <>
      <div className="matterportViewer__container">
        <iframe
          id="matterport-frame"
          frameBorder="0"
          allowFullScreen
          allow="xr-spatial-tracking"
          title="matterport-frame"
          ref={matterPortFrame}
          style={{ width: '100%' }}
        ></iframe>
      </div>
      {mpSdk && (
        <>
          {(mode === 'pin' || mode === 'localize') && sceneReady && (
            <div
              id="tagMenuContainer"
              style={{
                position: 'relative',
                color: 'white',
                display: 'flex',
                justifyContent: 'center',
                bottom: '50px',
                marginLeft: '180px',
                marginRight: '180px'
              }}
            >
              {mode === 'localize' && !addMatterTagMode && tags.length === 0 && relocalization && (
                <button
                  className="tag-button-action"
                  style={{
                    backgroundColor: 'Transparent',
                    backgroundRepeat: 'no-repeat',
                    border: 'none',
                    cursor: 'pointer',
                    overflow: 'hidden'
                  }}
                  onClick={changePinMode}
                >
                  <AddCircleRounded sx={{ fontSize: 32, color: '#F17633' }} />
                </button>
              )}

              {mode === 'localize' && !addMatterTagMode && tags.length > 0 && relocalization && (
                <button
                  style={{ backgroundColor: 'transparent', border: '0px', cursor: 'pointer' }}
                  className="tag-button-action"
                  onClick={changeTagPosition}
                >
                  <svg width="30" height="30" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="60" height="60" rx="30" fill="#F17633" />
                    <path
                      d="M36.3341 12.2676C36.3341 11.5681 36.9012 11.001 37.6008 11.001L47.7341 11.001C48.4337 11.001 49.0008 11.5681 49.0008 12.2676V22.401C49.0008 23.1005 48.4337 23.6676 47.7341 23.6676C47.0346 23.6676 46.4674 23.1005 46.4674 22.401V15.3256L35.9631 25.83C35.4685 26.3246 34.6664 26.3246 34.1718 25.83C33.6771 25.3353 33.6771 24.5333 34.1718 24.0386L44.6761 13.5343L37.6008 13.5343C36.9012 13.5343 36.3341 12.9672 36.3341 12.2676Z"
                      fill="white"
                    />
                    <path
                      d="M34.1718 34.1703C34.6664 33.6757 35.4685 33.6757 35.9631 34.1703L46.4674 44.6746L46.4674 37.5993C46.4674 36.8998 47.0346 36.3327 47.7341 36.3327C48.4337 36.3327 49.0008 36.8998 49.0008 37.5993L49.0008 47.7327C49.0008 48.4322 48.4337 48.9993 47.7341 48.9993H37.6008C36.9012 48.9993 36.3341 48.4322 36.3341 47.7327C36.3341 47.0331 36.9012 46.466 37.6008 46.466H44.6761L34.1718 35.9617C33.6771 35.467 33.6771 34.665 34.1718 34.1703Z"
                      fill="white"
                    />
                    <path
                      d="M25.829 34.1703C26.3237 34.665 26.3237 35.467 25.829 35.9617L15.3247 46.466L22.4 46.466C23.0996 46.466 23.6667 47.0331 23.6667 47.7327C23.6667 48.4322 23.0996 48.9993 22.4 48.9993L12.2667 48.9993C11.5671 48.9993 11 48.4322 11 47.7326L11 37.5993C11 36.8998 11.5671 36.3326 12.2667 36.3326C12.9662 36.3326 13.5333 36.8998 13.5333 37.5993L13.5333 44.6746L24.0377 34.1703C24.5323 33.6757 25.3343 33.6757 25.829 34.1703Z"
                      fill="white"
                    />
                    <path
                      d="M13.5333 15.3256L13.5333 22.401C13.5333 23.1005 12.9662 23.6676 12.2667 23.6676C11.5671 23.6676 11 23.1005 11 22.401L11 12.2676C11 11.5681 11.5671 11.001 12.2667 11.001L22.4 11.001C23.0996 11.001 23.6667 11.5681 23.6667 12.2676C23.6667 12.9672 23.0996 13.5343 22.4 13.5343L15.3247 13.5343L25.829 24.0386C26.3237 24.5333 26.3237 25.3353 25.829 25.83C25.3343 26.3246 24.5323 26.3246 24.0377 25.83L13.5333 15.3256Z"
                      fill="white"
                    />
                  </svg>
                </button>
              )}

              {mode === 'pin' && (
                <button className="tag-button-action" onClick={changePinMode}>
                  <AddCircleRounded sx={{ fontSize: 32 }} />
                </button>
              )}

              {mode === 'pin' && addMatterTagMode && (
                <button
                  style={{ backgroundColor: 'transparent', border: '0px', cursor: 'pointer' }}
                  onClick={cancelAddMaterTag}
                >
                  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="30" height="30" rx="30" fill="#F17633" />
                    <path
                      d="M16.2114 16.2114C17.1633 15.2595 18.7066 15.2595 19.6585 16.2114L30 26.5529L40.3414 16.2114C41.2933 15.2595 42.8367 15.2595 43.7886 16.2114C44.7405 17.1633 44.7405 18.7067 43.7886 19.6586L33.4471 30L43.7886 40.3414C44.7405 41.2933 44.7405 42.8367 43.7886 43.7886C42.8367 44.7405 41.2933 44.7405 40.3414 43.7886L30 33.4472L19.6586 43.7886C18.7067 44.7405 17.1633 44.7405 16.2114 43.7886C15.2595 42.8367 15.2595 41.2933 16.2114 40.3414L26.5528 30L16.2114 19.6586C15.2595 18.7067 15.2595 17.1633 16.2114 16.2114Z"
                      fill="white"
                    />
                  </svg>
                </button>
              )}
              {mode === 'localize' && addMatterTagMode && (
                <button
                  style={{ backgroundColor: 'transparent', border: '0px', cursor: 'pointer' }}
                  onClick={() => {
                    cancelLocalization();
                  }}
                >
                  <svg width="30" height="30" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="60" height="60" rx="30" fill="#F17633" />
                    <path
                      d="M16.2114 16.2114C17.1633 15.2595 18.7066 15.2595 19.6585 16.2114L30 26.5529L40.3414 16.2114C41.2933 15.2595 42.8367 15.2595 43.7886 16.2114C44.7405 17.1633 44.7405 18.7067 43.7886 19.6586L33.4471 30L43.7886 40.3414C44.7405 41.2933 44.7405 42.8367 43.7886 43.7886C42.8367 44.7405 41.2933 44.7405 40.3414 43.7886L30 33.4472L19.6586 43.7886C18.7067 44.7405 17.1633 44.7405 16.2114 43.7886C15.2595 42.8367 15.2595 41.2933 16.2114 40.3414L26.5528 30L16.2114 19.6586C15.2595 18.7067 15.2595 17.1633 16.2114 16.2114Z"
                      fill="white"
                    />
                  </svg>
                </button>
              )}
            </div>
          )}

          <div
            id="AU_helper_container"
            style={{
              position: 'relative',
              color: 'white',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              bottom: '120px'
            }}
          >
            {(mode === 'pin' || mode === 'localize') && pinMode === true && sceneReady && (
              <div
                id="AU_Helper"
                style={{
                  position: 'relative',
                  backgroundColor: 'rgba(0, 0, 0, 0.4)',
                  margin: '0 auto',
                  padding: '5px 15px',
                  borderRadius: '10px',
                  textAlign: 'center',
                  pointerEvents: 'none',
                  fontFamily: 'Roboto',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  fontSize: '13px',
                  color: '#FFFFFF'
                }}
              >
                {mode === 'localize' && !addMatterTagMode && tags.length > 0 && relocalization && (
                  <span>Cliquer sur le bouton pour repositionner la localisation</span>
                )}
                {mode !== 'localize' && <span>Cliquer sur le bouton + pour entrer en mode ajout de tag</span>}
                {pinMode === true && (
                  <span>Appuyer pendant une seconde à l'endroit ou vous voulez positionner le tag</span>
                )}
              </div>
            )}

            {mode === 'localize' && pinMode === false && relocalization && sceneReady && (
              <div
                id="AU_Helper"
                style={{
                  position: 'relative',
                  backgroundColor: 'rgba(0, 0, 0, 0.4)',
                  margin: '0 auto',
                  padding: '5px 15px',
                  borderRadius: '10px',
                  textAlign: 'center',
                  pointerEvents: 'none',
                  fontFamily: 'Roboto',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  fontSize: '13px',
                  color: '#FFFFFF'
                }}
              >
                {mode === 'localize' && !addMatterTagMode && tags.length > 0 && relocalization && (
                  <span>Cliquer sur le bouton pour repositionner la localisation</span>
                )}
                {mode === 'localize' && !addMatterTagMode && tags.length === 0 && relocalization && (
                  <span>Cliquer sur le bouton + pour entrer en mode ajout de tag</span>
                )}
              </div>
            )}
          </div>
        </>
      )}
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          ref={circleIndicator}
          version="1.1"
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            pointerEvents: 'none',
            transform: 'rotate(-90deg)'
          }}
          width="200"
          height={200}
          visibility="hidden"
        >
          <circle cx="100" cy="100" r="98" stroke="grey" strokeWidth="5" fill="none" />
          <circle ref={circleIndicatorBackGround} cx="100" cy="100" r="98" strokeWidth="0" fill="white" opacity={0.1} />
          <circle
            ref={circleIndicatorFill}
            cx="100"
            cy="100"
            r="98"
            stroke="#F17633"
            strokeWidth="5"
            fill="none"
            strokeDasharray={450}
            strokeDashoffset={450}
          />
        </svg>
      </div>
    </>
  );
};

export default MatterportViewer;
