import { Grid, TextField, FormControl, InputLabel, Select, MenuItem, Box } from '@mui/material';
import { AppContext } from '~/utils/context/AppContext';
import React from 'react';

export const TypeForm = ({ documentData, setDocumentData }) => {
  const { referencial } = React.useContext(AppContext);
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        style={{
          paddingBottom: '15px'
        }}
      >
        Type de document
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ mb: 3 }}>
          <TextField
            label="Nom du fichier"
            fullWidth
            value={documentData.name ? documentData.name : ''}
            onChange={(e) => setDocumentData({ ...documentData, name: e.target.value })}
            autoComplete="off"
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ mb: 3 }}>
          <FormControl fullWidth>
            <InputLabel id="category">Catégorie</InputLabel>
            <Select
              labelId="category"
              label="Catégorie"
              value={documentData.category ? documentData.category : ''}
              onChange={(e) => setDocumentData({ ...documentData, category: e.target.value })}
            >
              {referencial['document.category'] &&
                referencial['document.category']
                  .filter((DocumentType) => DocumentType.name !== '')
                  .map((DocumentType, index) => (
                    <MenuItem key={index + 1} value={DocumentType.name}>
                      {DocumentType.name}
                    </MenuItem>
                  ))}
            </Select>
          </FormControl>
        </Box>
      </Grid>
    </Grid>
  );
};
