export interface IUser {
  uuid?: string;
  kind?: string;
  first_name?: string;
  last_name?: string;
  email?: string;
  password?: string;
  mobile_phone?: string;
  land_line?: string;
  activated?: boolean;
  activated_at?: string;
  created_at?: string;
  update_at?: string;
  delete_at?: string;
  avatar_image_uri?: string;
}

export interface IRoleUserProfil {
  first_name: string;
  last_name: string;
  email: string;
  phone1: string;
  phone2: string;
  kind: string;
}

export enum UserKind {
  ADMIN = 'admin',
  CLIENT_ADMIN = 'client-admin',
  CLIENT = 'client',
  SUPER_ADMIN = 'super-admin',
  Unknown = 'unknown' // not logged in.
}
