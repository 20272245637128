import { jwtDecode } from 'jwt-decode';
import { api, axiosClient } from '~/api';
import { OrganizationKind } from '~/api/organization.models';
import { UserKind } from '~/interfaces';

export const JWTSessionStorageKey = 'av_jwt';
export const RFRSHJWTSessionStorage = 'av_rfrsh_jwt';
export const RedirectSessionStorageKey = 'av_redirect';

export interface JWTValues {
  kind: string; //"cession",
  expires_at: number; // 1681834183,
  issuer: string; //"test",
  website: string; // "",
  organization_kind: OrganizationKind;
  uuid_organization: string; // "org-XXXXXXXXXXX",
  uuid_user: string; // "usr-XXXXXXXXXXX",
  user_kind: UserKind; // "client-admin"
  user_name: string; // "Michel POTORO"
}

export interface RefreshTokenValues {
  uuid: string;
  kind: string;
  issuer: string;
  app_name: string;
  uuid_user: string;
  issued_at: number;
  expires_at: number;
}

export function getJWT(): JWTValues | null {
  const token = sessionStorage.getItem(JWTSessionStorageKey);
  if (token !== null && token !== '') {
    return jwtDecode<JWTValues>(token);
  }
  return null;
}

export function getRefreshJWT(): RefreshTokenValues | null {
  const token = sessionStorage.getItem(RFRSHJWTSessionStorage);
  if (token !== null && token !== '') {
    return jwtDecode<RefreshTokenValues>(token);
  }
  return null;
}

/**
 * @returns it gets the token from the session storage and check if it is still valid.
 * If the token is not valid, it will log out the user.
 */
export function hasAuthenticated(): boolean {
  const token = getJWT();
  if (token === null || !isValidToken(token)) {
    logOut();
    return false;
  }
  return true;
}

/**
 * @returns it gets the token from the session storage and check if it is still valid.
 */
export function isAuthenticated(): boolean {
  const token = getJWT();
  if (token === null || !isValidToken(token)) {
    return false;
  }
  return true;
}

// setSession is setting the session datas from the given token.
export function setSession(payload): void {
  const decoded_jwt = jwtDecode<JWTValues>(payload.jwt);
  if (!isValidToken(decoded_jwt)) {
    logOut();
    throw new Error('Invalid token');
  }
  sessionStorage.setItem(JWTSessionStorageKey, payload.jwt);
  sessionStorage.setItem(RFRSHJWTSessionStorage, payload.refresh);
  axiosClient.defaults.headers.common['Authorization'] = 'Bearer ' + payload.jwt;
}

export function getUserKind(): UserKind | null {
  const claims = getJWT();
  if (claims) {
    return claims.user_kind;
  }
  return null;
}

export function isAdmin(): boolean {
  const claims = getJWT();
  if (claims) {
    if (
      claims.user_kind === UserKind.SUPER_ADMIN ||
      claims.user_kind === UserKind.ADMIN ||
      claims.user_kind === UserKind.CLIENT_ADMIN
    ) {
      return true;
    }
  }
  return false;
}

export function isSuperAdmin(): boolean {
  const claims = getJWT();
  if (claims) {
    if (claims.user_kind === UserKind.SUPER_ADMIN) {
      return true;
    }
  }
  return false;
}

export function getUUIDUser(): string | null {
  const claims = getJWT();
  if (claims) {
    return claims.uuid_user;
  }
  return null;
}

export function getOrganizationUUID(): string | null {
  const claims = getJWT();
  if (claims) {
    return claims.uuid_organization;
  }
  return null;
}

export function getOrganizationName(): string | null {
  const orgaName = localStorage.getItem('OrganizationName');
  if (orgaName && orgaName.length > 0) {
    return orgaName;
  }

  const uuidOrga = getOrganizationUUID();
  if (!uuidOrga) {
    return null;
  }

  api.organizations.get(uuidOrga).then((res) => {
    localStorage.setItem('OrganizationName', res.name);
    return res.name;
  });

  return null;
}

// getRedirectURIAfterLogin is returning the redirect path after login.
export function getRedirectURIAfterLogin(): string {
  // if super admin, redirect to /organizations.
  if (isSuperAdmin()) {
    return '/organizations';
  }

  const redirectTo = sessionStorage.getItem(RedirectSessionStorageKey);
  // return redirect path.
  if (redirectTo !== null && redirectTo !== '') {
    return redirectTo;
  }
  // return default path.
  return '/';
}

export function logOut(): void {
  sessionStorage.removeItem(JWTSessionStorageKey);
  sessionStorage.removeItem(RFRSHJWTSessionStorage);
  window.location.pathname = 'login';
}

export function isValidToken(token: JWTValues | RefreshTokenValues): boolean {
  if (token === null) {
    return false;
  }
  return token.expires_at > Math.floor(Date.now() / 1000);
}

export function getToken(): string | null {
  return sessionStorage.getItem(JWTSessionStorageKey);
}

export function getRefreshToken(): string | null {
  return sessionStorage.getItem(RFRSHJWTSessionStorage);
}

export const auth = {
  getUserKind: getUserKind,
  getUUIDUser: getUUIDUser,
  getOrganizationUUID: getOrganizationUUID,
  getOrganizationName: getOrganizationName,
  getJWT: getJWT,
  isAdmin: isAdmin,
  isSuperAdmin: isSuperAdmin,
  authenticated: isAuthenticated,
  isValidToken: isValidToken,
  getToken: getToken
};

export default auth;
