import { Box, Button, DialogActions, Grid } from '@mui/material';
import '~/app/components/WizzardEquipment/WizzardEquipment';

export const Validate = ({ setCurrentStep, setModalOpen, elementType }) => {
  return (
    <Grid container spacing={0} direction="column">
      <Grid item xs={12}>
        <div className="Wizzards__contentTitle Wizzards__center">
          <svg width="90" height="90" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M45 90C20.1472 90 0 69.8528 0 45C0 20.1472 20.1472 0 45 0C69.8528 0 90 20.1472 90 45C89.9727 69.8415 69.8415 89.9727 45 90ZM44.928 81H45C64.8752 80.9801 80.9739 64.8572 80.964 44.982C80.9541 25.1068 64.8392 9.00002 44.964 9.00002C25.0888 9.00002 8.97394 25.1068 8.964 44.982C8.95407 64.8572 25.0528 80.9801 44.928 81ZM36 67.5L18 49.5L24.345 43.155L36 54.765L65.655 25.11L72 31.5L36 67.5Z"
              fill="#508D00"
            />
          </svg>
          <br />
          <br />
          {elementType === 'equipment' ? 'L’équipement à bien été enregistré' : 'Le ticket à bien été enregistré'}
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className="Wizzards__center Wizzards__form_item">
          <div className="Wizzards__contentTitle">
            {elementType === 'equipment'
              ? 'Voulez-vous ajouter un nouvel équipement ?'
              : 'Voulez-vous créer un nouveau ticket ? '}
          </div>

          <Box textAlign="center">
            <DialogActions sx={{ justifyContent: 'center' }}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  setModalOpen(false);
                  setCurrentStep('generalInfos');
                }}
              >
                Non
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setCurrentStep('generalInfos');
                }}
              >
                Oui
              </Button>
            </DialogActions>
          </Box>
        </div>
      </Grid>
    </Grid>
  );
};

export default Validate;
