// verify if the given password has at least 5 caracters.
export const checkPassword = (password: string) => {
  return password.length > 5;
};

export const checkEmail = (email: string) => {
  const regex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  return regex.test(email);
};

export const isNumber = (n) => {
  return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
};
