import { ForgotPassword } from '~/app/components/Auth/ForgotPassword/ForgotPassword';
import { Login } from '~/app/components/Auth/Login/Login';
import { Register } from '~/app/components/Auth/Register/Register';
import { Share } from '~/app/components/Auth/Share/Share';
import { DeleteAccount } from '~/app/components/Auth/DeleteAccount/DeleteAccount';
import './AuthHandler.scss';

interface AuthHandlerProps {
  type: 'login' | 'register' | 'forgot-password' | 'delete-account' | 'share' | 'event';
  setSpinnerOpen: any;
  spinnerOpen: boolean;
}

export const AuthHandler = ({ type, setSpinnerOpen, spinnerOpen }: AuthHandlerProps) => {
  const ComponentsHandler = () => {
    switch (type) {
      case 'register':
        return <Register setSpinnerOpen={setSpinnerOpen} spinnerOpen={spinnerOpen} />;
      case 'login':
        return <Login setSpinnerOpen={setSpinnerOpen} spinnerOpen={spinnerOpen} />;
      case 'forgot-password':
        return <ForgotPassword setSpinnerOpen={setSpinnerOpen} spinnerOpen={spinnerOpen} />;
      case 'delete-account':
        return <DeleteAccount setSpinnerOpen={setSpinnerOpen} spinnerOpen={spinnerOpen} />;
      case 'share':
        return <Share setSpinnerOpen={setSpinnerOpen} spinnerOpen={spinnerOpen} />;
      case 'event':
        return <></>;
    }
  };

  return <div className="AuthHandle">{ComponentsHandler()}</div>;
};

export default AuthHandler;
