import { axiosPost, axiosGet, axiosPatch, axiosDelete } from '.';

export const createPaymentMethod = (paymentMethodId: string) => {
  return axiosPost('/accounting/payments/methodes', { payment_method_id: paymentMethodId });
};

export const subscribePlan = (planKind: number, paymentMethodId: string) => {
  return axiosPost('/accounting/plans/subscribe', { planKind, paymentMethodId });
};

export const getBillings = () => {
  return axiosGet('/accounting/billings');
};

export const getPaymentsMethodes = () => {
  return axiosGet('/accounting/payments/methodes');
};

export const deletePaymentsMethodes = (id: string) => {
  return axiosDelete(`/accounting/payments/methodes/${id}`);
};

export const setPaymentMethodeDefault = (id: string) => {
  return axiosPatch(`/accounting/payments/methodes/${id}/default`, {});
};

export const getPlan = () => {
  return axiosGet('/accounting/plans');
};
