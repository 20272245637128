import { ILoginForm } from './LoginFormModel';
import { useReducer } from 'react';

// EventsLoginType is an enum that contains all the possible events
// that can be triggered by the login form.
export enum EventLoginType {
  SetEmail = 'SET_EMAIL', // change email.
  SetPassword = 'SET_PASSWORD', // change password.
  Submit = 'SUBMIT', // submit to the api.
  SetError = 'SET_ERROR' // error local validation or response.
}

// Action is a type that contains all the possible events
type Action =
  | { type: EventLoginType.SetEmail; email: string }
  | { type: EventLoginType.SetPassword; password: string }
  | { type: EventLoginType.Submit }
  | { type: EventLoginType.SetError; errorMessage: string };

// loginFormReducer is a reducer that contains all the possible events
const loginFormReducer = (state: ILoginForm, action: Action): ILoginForm => {
  // disable the submit button by default.
  let buttonSubmitDisabled: boolean = true;
  // switch on the action type.
  switch (action.type) {
    // change email.
    case EventLoginType.SetEmail: {
      if (action.email !== '' && state.Values.password !== '') {
        buttonSubmitDisabled = false;
      }
      return {
        ...state,
        Values: {
          email: action.email,
          password: state.Values.password
        },
        ButtonSubmitDisabled: buttonSubmitDisabled
      };
    }
    // change password.
    case EventLoginType.SetPassword: {
      if (action.password !== '' && state.Values.email !== '') {
        buttonSubmitDisabled = false;
      }
      return {
        ...state,
        Values: {
          email: state.Values.email,
          password: action.password
        },
        ButtonSubmitDisabled: buttonSubmitDisabled
      };
    }
    // submit to the API.
    case EventLoginType.Submit: {
      return {
        ...state,
        IsSubmitting: true,
        ButtonSubmitDisabled: true
      };
    }
    // error local validation or API response.
    case EventLoginType.SetError: {
      state.Spinner.setSpinnerOpen(false);
      return {
        ...state,
        Error: {
          AsError: true,
          Message: action.errorMessage
        },
        IsSubmitting: false,
        ButtonSubmitDisabled: true
      };
    }
    default: {
      console.log('Login: Unknown action: ' + action);
      return state;
    }
  }
};

// initialState is the initial state of the login form.
const initialState: ILoginForm = {
  Values: { email: '', password: '' },
  ButtonSubmitDisabled: true,
  IsSubmitting: false,
  Spinner: {
    setSpinnerOpen: null,
    spinnerOpen: true
  },
  Error: {
    AsError: false,
    Message: ''
  }
};

// useLoginFormReducer is a custom hook that returns the login form reducer.
export const useLoginFormReducer = () => {
  return useReducer(loginFormReducer, initialState);
};
