import {
  Button,
  Checkbox,
  FormControl,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Paper
} from '@mui/material';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SearchIcon from '@mui/icons-material/Search';
import { useEffect, useState, useContext, useMemo, useRef } from 'react';
import { addMatterportScan, patchMatterportScan, api } from '~/api';
import { isSuperAdmin } from '~/app/components/Auth/AuthApi';
import { correspondanceBuildingKinds, correspondanceProductKinds, type_references } from '~/utils/references';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers';
import { DateValidationError } from '@mui/x-date-pickers/models';
import { AppContext } from '~/utils/context/AppContext';
import _ from 'lodash';
import './EditPropertyInfos.scss';
import { ProductTitle } from '../ProductTitle/ProductTitle';
interface IPropertyData {
  logement_type: number; // Type de logement
  dependence_type: number; // Type de dépendance
  floor_number: number; // Nombre d'étages
  rooms_number: number; //Nombre de pièces
  furnished: boolean; // Meublé
  usage: number; // Usage
  surface: number; // Surface habitable
  ceiling_height: number; // Hauteur sous plafond
  access_code: string; // Code accès immeuble
  lot_number: number; // Numéro de lot
  lot_associated: number; // Lot(s) associé(s)
  mono_property: boolean; // Mono propriété
  co_property: boolean; // Co propriété
  immatriculattion_number: string; // Numéro d'immatriculation
  cadastral_parcel: string; // Parcelle cadastrale
  cadastral_section: string; // Section cadastrale
  staircase_number: number; // Nombre d'escaliers
  elevators_number: number; // Nombre d'ascenseurs
  lots_number: number; // Nombre de lots
  flats_number: number; // Nombre d'appartements
  cellar_number: number; // Nombre de caves
  technical_locals: string; // Locaux techniques
  various_locals: string; // Locaux divers
  cycle_local: boolean; // Local à vélo
  trash_local: boolean; // Local à poubelle
  letter_box: boolean; // Boîte aux lettres
  optical_fiber: boolean; // Fibre optique
  parking: boolean; // Parking
  parking_floor_number: number; // Nombre d'étages de parking
  parking_places_number: number; // Nombre de places de parking
  heating_communal: boolean; // Chauffage collectif
  heating_energy_type: number; // Type d'énergie du chauffage
  ecs_communal: boolean; // ECS collectif
  ecs_energy_type: number; // Type d'énergie de l'ECS
  cooling_communal: boolean; // Refroidissement collectif
  cooling_energy_type: number; // Type d'énergie du refroidissement

  dpe_number: number; // Numéro du DPE
  energy_result: number; // Consommation énergétique
  energy_sticker: string; // Etiquette énergétique
  ges_result: number; // GES
  ges_sticker: string; // Etiquette GES
  done_at: string; // Date établissement DPE
  expire_at: string; // Date expiration DPE
}

export const EditPropertyInfos = ({
  setSpinnerOpen,
  spinnerOpen,
  setModalOpen,
  refreshData,
  isBuilding = false,
  propertyData,
  setPropertyData,
  propertyKindName = ''
}) => {
  type TuseParams = {
    id: string;
  };

  const [kindIndex, setKindIndex] = useState<string>('0');
  const [dpeNumber, setDpeNumber] = useState('');
  const [dpeNumberSearched, setDpeNumberSearched] = useState('');
  const [dpeSearchStatus, setDpeSearchStatus] = useState('');
  const [dpeDatas, setDpeDatas] = useState(null);
  const [editDpe, setEditDpe] = useState(false);
  const [matterportID, setMatterportID] = useState('');
  const { referencial } = useContext(AppContext);
  const [yearToPeriodeArr, setYearToPeriodeArr] = useState(null);
  const [constructionPeriode, setConstructionPeriode] = useState(null);
  const [periode, setPeriode] = useState(0);
  const [minDate, setMinDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);
  const [tmpPropertyData, setTmpPropertyData] = useState(null);
  const [annee, setAnnee] = useState<any>(null);
  const [error, setError] = useState<DateValidationError | null>(null);
  const [contentHeight, setContentHeight] = useState(0);

  const [buildingData, setBuildingData] = useState<any>();

  const disableKeyboardEntry = (e: any) => {
    if (e?.keyCode === 40 || e?.keyCode === 38) {
      if (e?.keyCode === 38 && Number(e.target.value) >= dayjs().year()) {
        e?.preventDefault();
        e?.stopPropagation();
      }
    }
  };

  const headerRef = useRef(null);
  const footerRef = useRef(null);

  const errorMessage = useMemo(() => {
    switch (error) {
      case 'maxDate': {
        return 'Date supérieure à la date maximale';
      }
      case 'minDate': {
        return 'Date inférieure à la date minimale';
      }

      case 'invalidDate': {
        return "La date saisie n'est pas valide";
      }

      default: {
        return '';
      }
    }
  }, [error]);

  const getPropertyKindIndex = () => {
    let id = null;
    if (isBuilding) {
      correspondanceBuildingKinds.map((kind, index) => {
        if (kind.id === tmpPropertyData.kind) {
          setKindIndex(index.toString());
          return index.toString();
        }
      });
    } else {
      correspondanceProductKinds.map((kind, index) => {
        if (kind.id === tmpPropertyData.kind) {
          setKindIndex(index.toString());
          return index.toString();
        }
      });
    }
  };

  const keyPress = (e) => {
    if (e.keyCode == 13) {
      handleSearchDPE();
    }
  };
  const setPeriodFromYear = (year) => {
    yearToPeriodeArr.forEach((periode, index) => {
      if (year <= periode[1] && year >= periode[0]) {
        setPeriode(index);
      }
      if (periode[0] === 'Après' && year >= periode[1]) {
        setPeriode(index);
      }
      if (periode[0] === 'Avant' && year < periode[1]) {
        setPeriode(index);
      }
    });
  };

  useEffect(() => {
    if (propertyData && propertyData.uuid.includes('bui')) {
      setBuildingData(propertyData);
    } else {
      api.building.get(propertyData.uuid_building).then((res) => {
        setBuildingData(res);
      });
    }
  }, [propertyData]);

  useEffect(() => {
    if (headerRef.current && footerRef.current) {
      setContentHeight(window.innerHeight - headerRef.current.offsetHeight - footerRef.current.offsetHeight - 60);
    }
  });

  const resetDpeSearch = () => {
    setDpeDatas(null);
    setDpeNumber('');
    setDpeSearchStatus('');
    setEditDpe(true);
    setTmpPropertyData({
      ...tmpPropertyData,
      data: {
        ...tmpPropertyData.data,
        energy_result: null,
        energy_sticker: null,
        dpe_number: null,
        ges_result: null,
        ges_sticker: null,
        done_at: null,
        expire_at: null
      }
    });
  };

  const handleSearchDPE = () => {
    setDpeDatas(null);
    setDpeNumberSearched(dpeNumber);
    setDpeSearchStatus('searching');
    api.ademe
      .get(dpeNumber)
      .then((res) => {
        setTmpPropertyData({
          ...tmpPropertyData,
          data: {
            ...tmpPropertyData.data,
            energy_result: Number(res.body.energy_result),
            energy_sticker: res.body.energy_sticker,
            dpe_number: res.body.dpe_number,
            ges_result: Number(res.body.ges_result),
            ges_sticker: res.body.ges_sticker,
            done_at: res.body.done_at,
            expire_at: res.body.expire_at
          }
        });
      })
      .catch((e) => {
        setDpeDatas(null);
        setDpeSearchStatus('searched');
      })
      .finally(() => {});
  };

  useEffect(() => {
    if (propertyData.matterport_scan) {
      setMatterportID(propertyData.matterport_scan.model_id);
    }
    setPeriode(propertyData.construction_period);
  }, []);

  useEffect(() => {
    setConstructionPeriode(_.sortBy(referencial['building.construction_period'], ['id']));
  }, [referencial]);

  useEffect(() => {
    const tmpDatas = {
      id_internal: propertyData.id_internal,
      kind: propertyData.kind,
      data: { ...propertyData.data },
      address_complement: propertyData.address_complement,
      invariant_tax: propertyData.invariant_tax
    };
    if (isBuilding) {
      tmpDatas['name'] = propertyData.name;
      tmpDatas['construction_year'] = propertyData.construction_year;
      tmpDatas['construction_period'] = propertyData.construction_period;
    }
    setTmpPropertyData(tmpDatas);
  }, [propertyData]);

  useEffect(() => {
    if (constructionPeriode) {
      setYearToPeriodeArr(
        constructionPeriode.map((periode, index) => {
          if (periode.name.indexOf('Après') !== -1) {
            return [periode.name.split(' ')[0], Number(periode.name.split(' ')[1])];
          } else if (periode.name.indexOf('Avant') !== -1) {
            return [periode.name.split(' ')[0], Number(periode.name.split(' ')[1])];
          } else {
            return periode.name.split('-').map((x) => {
              return Number(x);
            });
          }
        })
      );
    }
  }, [constructionPeriode]);

  useEffect(() => {
    if (constructionPeriode) {
      if (constructionPeriode[Number(tmpPropertyData.construction_period)])
        setPeriode(constructionPeriode[Number(tmpPropertyData.construction_period)]['id']);
    }
  }, [constructionPeriode]);

  useEffect(() => {
    if (tmpPropertyData) {
      if (tmpPropertyData.data?.dpe_number && tmpPropertyData.data?.dpe_number.length > 0) {
        setEditDpe(false);
      } else {
        setEditDpe(true);
      }

      getPropertyKindIndex();
    }
  }, [tmpPropertyData]);

  if (!tmpPropertyData) {
    return null;
  }

  return (
    <Grid
      container
      spacing={1}
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      alignContent="stretch"
      wrap="wrap"
      // style={{ backgroundColor: '#f5f5f5' }}
    >
      {/* HEADER */}

      <Grid item xs={12} ref={headerRef}>
        <ProductTitle data={propertyData} buildingData={buildingData} />
        <Divider sx={{ mb: 5 }} />
      </Grid>

      <Grid
        item
        xs={12}
        style={{
          maxHeight: contentHeight + 'px',
          overflow: 'auto',
          paddingBottom: '10px',
          paddingRight: '10px'
        }}
      >
        <Grid
          container
          spacing={5}
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          alignContent="stretch"
          wrap="wrap"
        >
          {/* GENERAL */}

          <Grid item xs={12} md={6} lg={4} xl={3}>
            <Paper elevation={2} sx={{ p: 2 }}>
              <Grid
                container
                spacing={3}
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                alignContent="stretch"
                wrap="wrap"
              >
                <Grid item xs={12}>
                  <h3 style={{ paddingBottom: '0px', marginBottom: '0px' }}>Général</h3>
                </Grid>
                {isBuilding && (
                  <Grid item xs={12}>
                    <TextField
                      label="Nom du bâtiment"
                      size="small"
                      type={'text'}
                      fullWidth
                      value={tmpPropertyData.name}
                      onChange={(e) => {
                        setTmpPropertyData({ ...tmpPropertyData, name: e.target.value });
                      }}
                      autoComplete="off"
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <TextField
                    label="Complément d'adresse"
                    size="small"
                    type={'text'}
                    fullWidth
                    placeholder='Ex: "étage: 2, porte: 3"'
                    value={tmpPropertyData.address_complement}
                    onChange={(e) => {
                      setTmpPropertyData({ ...tmpPropertyData, address_complement: e.target.value });
                    }}
                    autoComplete="off"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Référence interne"
                    size="small"
                    type={'text'}
                    fullWidth
                    value={tmpPropertyData.id_internal}
                    autoComplete="off"
                    onChange={(e) => {
                      setTmpPropertyData({ ...tmpPropertyData, id_internal: e.target.value });
                    }}
                  />
                </Grid>
                {isSuperAdmin() && (
                  <Grid item xs={12}>
                    <TextField
                      label="UID modèle MTP"
                      size="small"
                      type={'text'}
                      fullWidth
                      value={matterportID}
                      autoComplete="off"
                      onChange={(e) => {
                        setMatterportID(e.target.value);
                      }}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel size="small" id="demo-simple-select-label">
                      Nature du bien
                    </InputLabel>
                    <Select
                      defaultValue=""
                      fullWidth
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={kindIndex}
                      label="Nature du bien"
                      onChange={(e) => {
                        let id = null;
                        if (isBuilding) {
                          id = correspondanceBuildingKinds[Number(e.target.value)].id;
                        } else {
                          id = correspondanceProductKinds[Number(e.target.value)].id;
                        }
                        setTmpPropertyData({ ...tmpPropertyData, kind: id });
                      }}
                      size="small"
                    >
                      {isBuilding
                        ? correspondanceBuildingKinds.map((kind, index) => {
                            return (
                              <MenuItem key={index} value={index}>
                                {kind.name}
                              </MenuItem>
                            );
                          })
                        : correspondanceProductKinds.map((kind, index) => {
                            return (
                              <MenuItem key={index} value={index}>
                                {kind.name}
                              </MenuItem>
                            );
                          })}
                    </Select>
                  </FormControl>
                </Grid>

                {/* SI LOGEMENT APPARTEMENT */}
                {!isBuilding && correspondanceProductKinds[kindIndex].id === 1 && (
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel size="small" id="demo-simple-select-label">
                        Type de logement
                      </InputLabel>
                      <Select
                        defaultValue=""
                        fullWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={tmpPropertyData.data?.logement_type}
                        label="Type de logement"
                        onChange={(e) => {
                          setTmpPropertyData({
                            ...tmpPropertyData,
                            data: { ...tmpPropertyData.data, logement_type: Number(e.target.value) }
                          });
                        }}
                        size="small"
                      >
                        {type_references['logement_type'].map((type, index) => {
                          return (
                            <MenuItem key={index} value={index.toString()}>
                              {type}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                )}

                {!isBuilding && correspondanceProductKinds[kindIndex].id === 5 && (
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel size="small" id="demo-simple-select-label">
                        Type de dépendance
                      </InputLabel>
                      <Select
                        defaultValue=""
                        fullWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={tmpPropertyData.data?.dependence_type}
                        label="Type de dépendance"
                        onChange={(e) => {
                          setTmpPropertyData({
                            ...tmpPropertyData,
                            data: { ...tmpPropertyData.data, dependence_type: Number(e.target.value) }
                          });
                        }}
                        size="small"
                      >
                        {type_references['dependence_type'].map((type, index) => {
                          return (
                            <MenuItem key={index} value={index.toString()}>
                              {type}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                {!isBuilding &&
                  (correspondanceProductKinds[kindIndex].id === 8 ||
                    correspondanceProductKinds[kindIndex].id === 7) && (
                    <>
                      <Grid item xs={6}>
                        <TextField
                          label="Nombre d'étages"
                          size="small"
                          type={'number'}
                          fullWidth
                          autoComplete="off"
                          value={tmpPropertyData.data?.floor_number !== 0 ? tmpPropertyData.data?.floor_number : ''}
                          onChange={(e) => {
                            setTmpPropertyData({
                              ...tmpPropertyData,
                              data: { ...tmpPropertyData.data, floor_number: Number(e.target.value) }
                            });
                          }}
                        ></TextField>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="Nombre de pièces"
                          size="small"
                          type={'number'}
                          fullWidth
                          autoComplete="off"
                          value={tmpPropertyData.data?.rooms_number !== 0 ? tmpPropertyData.data?.rooms_number : ''}
                          onChange={(e) => {
                            setTmpPropertyData({
                              ...tmpPropertyData,
                              data: { ...tmpPropertyData.data, rooms_number: Number(e.target.value) }
                            });
                          }}
                        ></TextField>
                      </Grid>
                    </>
                  )}
                {(!isBuilding || (isBuilding && correspondanceBuildingKinds[kindIndex].id === 2)) && (
                  <Grid item xs={12}>
                    <FormControl>
                      <FormLabel id="demo-row-radio-buttons-group-label">Meublé</FormLabel>
                      <RadioGroup
                        value={
                          tmpPropertyData.data?.furnished === true || tmpPropertyData.data?.furnished === 'true'
                            ? 'true'
                            : 'false'
                        }
                        onChange={(e) => {
                          console.log(e.target.value);
                          setTmpPropertyData({
                            ...tmpPropertyData,
                            data: { ...tmpPropertyData.data, furnished: e.target.value === 'true' ? true : false }
                          });
                        }}
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                      >
                        <FormControlLabel value={'true'} control={<Radio />} label="Oui" />
                        <FormControlLabel value={'false'} control={<Radio />} label="Non" />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                )}

                {isBuilding && correspondanceBuildingKinds[kindIndex].id === 3 && (
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel size="small" id="demo-simple-select-label">
                        Usage
                      </InputLabel>
                      <Select
                        defaultValue=""
                        fullWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={tmpPropertyData.data.usage}
                        label="Usage"
                        onChange={(e) => {
                          setTmpPropertyData({
                            ...tmpPropertyData,
                            data: { ...tmpPropertyData.data, usage: Number(e.target.value) }
                          });
                        }}
                        size="small"
                      >
                        {type_references['usage'].map((type, index) => {
                          return (
                            <MenuItem key={index} value={index.toString()}>
                              {type}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
              </Grid>
            </Paper>
          </Grid>

          {/* SURFACE */}

          <Grid item xs={12} md={6} lg={4} xl={3}>
            <Paper elevation={2} sx={{ p: 2 }}>
              <Grid
                container
                spacing={3}
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                alignContent="stretch"
                wrap="wrap"
              >
                <Grid item xs={12}>
                  <h3 style={{ paddingBottom: '0px', marginBottom: '0px' }}>Surface</h3>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    type="number"
                    label="Surface habitable"
                    size="small"
                    fullWidth
                    autoComplete="off"
                    value={tmpPropertyData?.data?.surface === 0 ? '' : tmpPropertyData?.data?.surface}
                    onChange={(e) => {
                      setTmpPropertyData({
                        ...tmpPropertyData,
                        data: { ...tmpPropertyData.data, surface: Number(e.target.value) }
                      });
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">m²</InputAdornment>
                    }}
                  ></TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    type="number"
                    label="Hauteur sous plafond"
                    size="small"
                    fullWidth
                    autoComplete="off"
                    value={tmpPropertyData?.data?.ceiling_height === 0 ? '' : tmpPropertyData?.data?.ceiling_height}
                    onChange={(e) => {
                      setTmpPropertyData({
                        ...tmpPropertyData,
                        data: { ...tmpPropertyData.data, ceiling_height: Number(e.target.value) }
                      });
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">m</InputAdornment>
                    }}
                  ></TextField>
                </Grid>
                {isBuilding && (
                  <>
                    <Grid item xs={12}>
                      <h3 style={{ paddingBottom: '0px', marginBottom: '0px', paddingTop: '0px', marginTop: '10px' }}>
                        Construction
                      </h3>
                    </Grid>

                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <DatePicker
                          disabled={dpeDatas?.body?.building_construction_year}
                          label="Année de construction"
                          views={['year']}
                          value={tmpPropertyData?.construction_year ? dayjs(tmpPropertyData.construction_year) : null}
                          onChange={(e) => {
                            if (dayjs(e).format() !== 'Invalid Date') {
                              setAnnee(dayjs(e).toDate());
                              setPeriodFromYear(dayjs(e).year());
                              setTmpPropertyData({
                                ...tmpPropertyData,
                                construction_year: dayjs(e).toISOString()
                              });
                            }
                          }}
                          onError={(newError) => setError(newError)}
                          minDate={minDate}
                          maxDate={maxDate}
                          slotProps={{
                            textField: {
                              size: 'small',
                              onKeyDownCapture: disableKeyboardEntry,
                              helperText: errorMessage
                            }
                          }}
                          disableFuture
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <InputLabel size="small" id="construction_period">
                          Periode de construction
                        </InputLabel>
                        <Select
                          defaultValue=""
                          // disabled={dpeDatas?.body?.building_construction_period_kind ? true : false}
                          fullWidth
                          labelId="construction_period"
                          id="demo-simple-select"
                          value={periode.toString()}
                          label="Période de construction"
                          onChange={(e) => {
                            setPeriode(Number(e.target.value));
                            setTmpPropertyData({ ...tmpPropertyData, construction_period: Number(e.target.value) });
                            const periodRange = yearToPeriodeArr[Number(e.target.value)];
                            if (periodRange[0] === 'Après') {
                              setMinDate(dayjs(periodRange[1].toString()));
                              setMaxDate(null);
                            } else if (periodRange[0] === 'Avant') {
                              setMinDate(null);
                              setMaxDate(dayjs(periodRange[1].toString()));
                            } else {
                              setMinDate(dayjs(periodRange[0].toString()));
                              setMaxDate(dayjs(periodRange[1].toString()));
                            }
                            // setAnnee(null);
                          }}
                          size="small"
                        >
                          {constructionPeriode.map((periode) => {
                            return (
                              <MenuItem key={periode.id} value={periode.id}>
                                {periode.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                      <h3 style={{ paddingBottom: '0px', marginBottom: '0px', paddingTop: '0px', marginTop: '10px' }}>
                        Accès
                      </h3>
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        label="Code accès immeuble"
                        size="small"
                        fullWidth
                        autoComplete="off"
                        value={tmpPropertyData?.data?.access_code}
                        onChange={(e) => {
                          setTmpPropertyData({
                            ...tmpPropertyData,
                            data: { ...tmpPropertyData.data, access_code: e.target.value }
                          });
                        }}
                      ></TextField>
                    </Grid>
                  </>
                )}
              </Grid>
            </Paper>
          </Grid>

          {/* PERFORMANCE ENERGETIQUE */}

          {(!isBuilding || propertyKindName === 'Habitation (Maison individuelle)') && (
            <Grid item xs={12} md={6} lg={4} xl={3}>
              <Paper elevation={2} sx={{ p: 2 }}>
                <Grid
                  container
                  spacing={3}
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  alignContent="stretch"
                  wrap="wrap"
                >
                  <Grid item xs={12}>
                    <h3 style={{ paddingBottom: '0px', marginBottom: '0px' }}>Performance énergétique</h3>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      sx={{ pr: '120px' }}
                      style={{ paddingRight: '0px' }}
                      label="N° du DPE"
                      disabled={tmpPropertyData.data?.dpe_number && !editDpe ? true : false}
                      id="search-input"
                      autoComplete="off"
                      placeholder="13 caractères"
                      size="small"
                      onKeyUp={keyPress}
                      value={
                        tmpPropertyData.data?.dpe_number && !editDpe ? tmpPropertyData.data?.dpe_number : dpeNumber
                      }
                      onChange={(e) => {
                        setDpeNumber(e.target.value);
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            {!editDpe && <HighlightOffIcon onClick={resetDpeSearch}></HighlightOffIcon>}
                            {editDpe && <SearchIcon onClick={handleSearchDPE} />}
                          </InputAdornment>
                        ),
                        style: {
                          paddingRight: 0
                        }
                      }}
                    ></TextField>
                  </Grid>
                  <Grid item xs={6}></Grid> {/* empty grid for alignement */}
                  <Grid item xs={6}>
                    <TextField
                      label="Consommation énergétique"
                      size="small"
                      fullWidth
                      autoComplete="off"
                      onChange={(e) => {
                        setTmpPropertyData({
                          ...tmpPropertyData,
                          data: { ...tmpPropertyData.data, energy_result: Number(e.target.value) }
                        });
                      }}
                      disabled
                      value={tmpPropertyData.data?.energy_result ? tmpPropertyData.data?.energy_result : ''}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">kWh/m²</InputAdornment>
                      }}
                    ></TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Etiquette énergétique"
                      size="small"
                      fullWidth
                      autoComplete="off"
                      onChange={(e) => {
                        setTmpPropertyData({
                          ...tmpPropertyData,
                          data: { ...tmpPropertyData.data, energy_sticker: e.target.value }
                        });
                      }}
                      disabled
                      value={tmpPropertyData.data?.energy_sticker ? tmpPropertyData.data?.energy_sticker : ''}
                    ></TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="GES"
                      size="small"
                      fullWidth
                      autoComplete="off"
                      onChange={(e) => {
                        setTmpPropertyData({
                          ...tmpPropertyData,
                          data: { ...tmpPropertyData.data, ges_result: Number(e.target.value) }
                        });
                      }}
                      disabled
                      value={tmpPropertyData.data?.ges_result ? tmpPropertyData.data?.ges_result : ''}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            kgCO
                            <sub>
                              <sub>2</sub>
                            </sub>
                            /m²
                          </InputAdornment>
                        )
                      }}
                    ></TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Etiquette GES"
                      size="small"
                      fullWidth
                      autoComplete="off"
                      onChange={(e) => {
                        setTmpPropertyData({
                          ...tmpPropertyData,
                          data: { ...tmpPropertyData.data, ges_sticker: e.target.value }
                        });
                      }}
                      disabled
                      value={tmpPropertyData.data?.ges_sticker ? tmpPropertyData.data?.ges_sticker : ''}
                    ></TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Date établissement DPE"
                      size="small"
                      fullWidth
                      autoComplete="off"
                      onChange={(e) => {
                        setTmpPropertyData({
                          ...tmpPropertyData,
                          data: { ...tmpPropertyData.data, done_at: e.target.value }
                        });
                      }}
                      disabled
                      value={
                        tmpPropertyData.data?.done_at ? dayjs(tmpPropertyData.data?.done_at).format('DD/MM/YYYY') : ''
                      }
                    ></TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Date expiration DPE"
                      size="small"
                      fullWidth
                      autoComplete="off"
                      onChange={(e) => {
                        setTmpPropertyData({
                          ...tmpPropertyData,
                          data: { ...tmpPropertyData.data, expire_at: e.target.value }
                        });
                      }}
                      disabled
                      value={
                        tmpPropertyData.data?.expire_at
                          ? dayjs(tmpPropertyData.data?.expire_at).format('DD/MM/YYYY')
                          : ''
                      }
                    ></TextField>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          )}

          {/* IDENTIFICATION BLOC */}

          <Grid item xs={12} md={6} lg={4} xl={3}>
            <Paper elevation={2} sx={{ p: 2 }}>
              <Grid
                container
                spacing={3}
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                alignContent="stretch"
                wrap="wrap"
              >
                <Grid item xs={12}>
                  <h3 style={{ paddingBottom: '0px', marginBottom: '0px' }}>Identification</h3>
                </Grid>
                {!isBuilding && (
                  <>
                    <Grid item xs={6}>
                      <TextField
                        label="Numéro de lot"
                        size="small"
                        autoComplete="off"
                        fullWidth
                        value={tmpPropertyData.data?.lot_number}
                        onChange={(e) => {
                          setTmpPropertyData({
                            ...tmpPropertyData,
                            data: { ...tmpPropertyData.data, lot_number: e.target.value }
                          });
                        }}
                      ></TextField>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label="Lot(s) associé(s)"
                        size="small"
                        fullWidth
                        autoComplete="off"
                        value={tmpPropertyData.data?.lot_associated}
                        onChange={(e) => {
                          setTmpPropertyData({
                            ...tmpPropertyData,
                            data: { ...tmpPropertyData.data, lot_associated: e.target.value }
                          });
                        }}
                      ></TextField>
                    </Grid>
                  </>
                )}
                {isBuilding && (
                  <>
                    <Grid item xs={6}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={tmpPropertyData.data?.mono_property}
                              onChange={(e) => {
                                setTmpPropertyData({
                                  ...tmpPropertyData,
                                  data: { ...tmpPropertyData.data, mono_property: e.target.checked }
                                });
                              }}
                            />
                          }
                          label="Mono-propiété"
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={6}></Grid> {/* empty grid for alignement */}
                    <Grid item xs={4}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={tmpPropertyData.data?.co_property}
                              onChange={(e) => {
                                setTmpPropertyData({
                                  ...tmpPropertyData,
                                  data: { ...tmpPropertyData.data, co_property: e.target.checked }
                                });
                              }}
                            />
                          }
                          label="Copropiété"
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        label="Numéro d'immatriculation"
                        size="small"
                        fullWidth
                        autoComplete="off"
                        value={
                          tmpPropertyData.data?.immatriculattion_number === 0
                            ? ''
                            : tmpPropertyData.data?.immatriculattion_number
                        }
                        onChange={(e) => {
                          setTmpPropertyData({
                            ...tmpPropertyData,
                            data: { ...tmpPropertyData.data, immatriculattion_number: Number(e.target.value) }
                          });
                        }}
                      ></TextField>
                    </Grid>
                  </>
                )}
                <Grid item xs={12}>
                  <h3 style={{ paddingBottom: '0px', marginBottom: '0px', paddingTop: '0px', marginTop: '10px' }}>
                    Référence cadastrale
                  </h3>
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    autoComplete="off"
                    label="Section"
                    size="small"
                    fullWidth
                    value={tmpPropertyData.data?.cadastral_section}
                    onChange={(e) => {
                      setTmpPropertyData({
                        ...tmpPropertyData,
                        data: { ...tmpPropertyData.data, cadastral_section: e.target.value }
                      });
                    }}
                  ></TextField>
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    autoComplete="off"
                    label="Parcelle"
                    size="small"
                    fullWidth
                    value={tmpPropertyData.data?.cadastral_parcel}
                    onChange={(e) => {
                      setTmpPropertyData({
                        ...tmpPropertyData,
                        data: { ...tmpPropertyData.data, cadastral_parcel: e.target.value }
                      });
                    }}
                  ></TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    autoComplete="off"
                    label="Invariant fiscal"
                    size="small"
                    fullWidth
                    value={tmpPropertyData.invariant_tax}
                    onChange={(e) => {
                      setTmpPropertyData({
                        ...tmpPropertyData,
                        invariant_tax: e.target.value
                      });
                    }}
                  ></TextField>
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          {/* COMPOSITION BLOC */}

          {isBuilding && (
            <Grid item xs={12} md={6} lg={4} xl={3}>
              <Paper elevation={2} sx={{ p: 2 }}>
                <Grid
                  container
                  spacing={3}
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  alignContent="stretch"
                  wrap="wrap"
                >
                  <Grid item xs={12}>
                    <h3 style={{ paddingBottom: '0px', marginBottom: '0px' }}>Composition</h3>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Nombre d'étages"
                      size="small"
                      type={'number'}
                      fullWidth
                      autoComplete="off"
                      value={tmpPropertyData.data?.floor_number === 0 ? '' : tmpPropertyData.data?.floor_number}
                      onChange={(e) => {
                        setTmpPropertyData({
                          ...tmpPropertyData,
                          data: { ...tmpPropertyData.data, floor_number: Number(e.target.value) }
                        });
                      }}
                    ></TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Nombre de cages d'esclalier"
                      size="small"
                      type={'number'}
                      autoComplete="off"
                      fullWidth
                      value={tmpPropertyData.data?.staircase_number === 0 ? '' : tmpPropertyData.data?.staircase_number}
                      onChange={(e) => {
                        setTmpPropertyData({
                          ...tmpPropertyData,
                          data: { ...tmpPropertyData.data, staircase_number: Number(e.target.value) }
                        });
                      }}
                    ></TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Nombre d'ascensseur"
                      size="small"
                      type={'number'}
                      autoComplete="off"
                      fullWidth
                      value={tmpPropertyData.data?.elevators_number === 0 ? '' : tmpPropertyData.data?.elevators_number}
                      onChange={(e) => {
                        setTmpPropertyData({
                          ...tmpPropertyData,
                          data: { ...tmpPropertyData.data, elevators_number: Number(e.target.value) }
                        });
                      }}
                    ></TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Nombre de lots"
                      size="small"
                      type={'number'}
                      autoComplete="off"
                      fullWidth
                      value={tmpPropertyData.data?.lots_number === 0 ? '' : tmpPropertyData.data?.lots_number}
                      onChange={(e) => {
                        setTmpPropertyData({
                          ...tmpPropertyData,
                          data: { ...tmpPropertyData.data, lots_number: Number(e.target.value) }
                        });
                      }}
                    ></TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Nombre d'appartements"
                      size="small"
                      type={'number'}
                      autoComplete="off"
                      fullWidth
                      value={tmpPropertyData.data?.flats_number === 0 ? '' : tmpPropertyData.data?.flats_number}
                      onChange={(e) => {
                        setTmpPropertyData({
                          ...tmpPropertyData,
                          data: { ...tmpPropertyData.data, flats_number: Number(e.target.value) }
                        });
                      }}
                    ></TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Nombre de caves"
                      size="small"
                      type={'number'}
                      autoComplete="off"
                      fullWidth
                      value={tmpPropertyData.data?.cellar_number === 0 ? '' : tmpPropertyData.data?.cellar_number}
                      onChange={(e) => {
                        setTmpPropertyData({
                          ...tmpPropertyData,
                          data: { ...tmpPropertyData.data, cellar_number: Number(e.target.value) }
                        });
                      }}
                    ></TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Locaux techniques"
                      size="small"
                      autoComplete="off"
                      fullWidth
                      value={tmpPropertyData.data?.technical_locals}
                      onChange={(e) => {
                        setTmpPropertyData({
                          ...tmpPropertyData,
                          data: { ...tmpPropertyData.data, technical_locals: Number(e.target.value) }
                        });
                      }}
                    ></TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Locaux divers"
                      size="small"
                      autoComplete="off"
                      fullWidth
                      value={tmpPropertyData.data?.various_locals}
                      onChange={(e) => {
                        setTmpPropertyData({
                          ...tmpPropertyData,
                          data: { ...tmpPropertyData.data, various_locals: Number(e.target.value) }
                        });
                      }}
                    ></TextField>
                  </Grid>
                  <Grid item xs={4}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={tmpPropertyData.data?.cycle_local}
                            onChange={(e) => {
                              setTmpPropertyData({
                                ...tmpPropertyData,
                                data: { ...tmpPropertyData.data, cycle_local: e.target.checked }
                              });
                            }}
                          />
                        }
                        label="Local vélo"
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={4}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={tmpPropertyData.data?.trash_local}
                            onChange={(e) => {
                              setTmpPropertyData({
                                ...tmpPropertyData,
                                data: { ...tmpPropertyData.data, trash_local: e.target.checked }
                              });
                            }}
                          />
                        }
                        label="Local poubelles"
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={4}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={tmpPropertyData.data?.letter_box}
                            onChange={(e) => {
                              setTmpPropertyData({
                                ...tmpPropertyData,
                                data: { ...tmpPropertyData.data, letter_box: e.target.checked }
                              });
                            }}
                          />
                        }
                        label="Boîtes aux lettres"
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={4}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={tmpPropertyData.data?.optical_fiber}
                            onChange={(e) => {
                              setTmpPropertyData({
                                ...tmpPropertyData,
                                data: { ...tmpPropertyData.data, optical_fiber: e.target.checked }
                              });
                            }}
                          />
                        }
                        label="Fibre optique"
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={4}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={tmpPropertyData.data?.parking}
                            onChange={(e) => {
                              setTmpPropertyData({
                                ...tmpPropertyData,
                                data: { ...tmpPropertyData.data, parking: e.target.checked }
                              });
                            }}
                          />
                        }
                        label="Parking"
                      />
                    </FormGroup>
                  </Grid>

                  {/* SI PARKING */}

                  {tmpPropertyData.data?.parking && (
                    <>
                      <Grid item xs={4}>
                        <TextField
                          label="Nombre d'étages"
                          size="small"
                          type={'number'}
                          autoComplete="off"
                          fullWidth
                          value={
                            tmpPropertyData.data?.parking_floor_number === 0
                              ? ''
                              : tmpPropertyData.data?.parking_floor_number
                          }
                          onChange={(e) => {
                            setTmpPropertyData({
                              ...tmpPropertyData,
                              data: { ...tmpPropertyData.data, parking_floor_number: Number(e.target.value) }
                            });
                          }}
                        ></TextField>
                      </Grid>
                      <Grid item xs={4}></Grid>
                      <Grid item xs={4}></Grid>
                      <Grid item xs={4}>
                        <TextField
                          label="Nombre de places"
                          size="small"
                          type={'number'}
                          autoComplete="off"
                          fullWidth
                          value={
                            tmpPropertyData.data?.parking_places_number === 0
                              ? ''
                              : tmpPropertyData.data?.parking_places_number
                          }
                          onChange={(e) => {
                            setTmpPropertyData({
                              ...tmpPropertyData,
                              data: { ...tmpPropertyData.data, parking_places_number: Number(e.target.value) }
                            });
                          }}
                        ></TextField>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Paper>
            </Grid>
          )}

          {/* CHAUFFAGE/ECS/REFROIDISSEMENT BLOC */}

          <Grid item xs={12} md={6} lg={4} xl={3}>
            <Paper elevation={2} sx={{ p: 2 }}>
              <Grid
                container
                spacing={3}
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                alignContent="stretch"
                wrap="wrap"
              >
                <Grid item xs={12}>
                  <h3 style={{ paddingBottom: '0px', marginBottom: '0px' }}>Chauffage / ecs / refroidissement</h3>
                </Grid>
                <Grid item xs={7}>
                  <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">Type de chauffage</FormLabel>
                    <RadioGroup
                      value={tmpPropertyData.data?.heating_communal}
                      onChange={(e) => {
                        setTmpPropertyData({
                          ...tmpPropertyData,
                          data: { ...tmpPropertyData.data, heating_communal: e.target.value }
                        });
                      }}
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                    >
                      <FormControlLabel value="Collectif" control={<Radio />} label="Collectif" />
                      <FormControlLabel value="Individuel" control={<Radio />} label="Individuel" />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={5} sx={{ mt: '20px' }}>
                  <FormControl fullWidth>
                    <InputLabel size="small" id="demo-simple-select-label">
                      Type d'énergie
                    </InputLabel>
                    <Select
                      defaultValue=""
                      fullWidth
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={tmpPropertyData.data?.heating_energy_type}
                      label="Type d'énergie'"
                      onChange={(e) => {
                        setTmpPropertyData({
                          ...tmpPropertyData,
                          data: { ...tmpPropertyData.data, heating_energy_type: Number(e.target.value) }
                        });
                      }}
                      size="small"
                    >
                      {type_references['heating_energy_type'].map((type, index) => {
                        return (
                          <MenuItem key={index} value={index.toString()}>
                            {type}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={7}>
                  <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">Production d'eau chaude</FormLabel>
                    <RadioGroup
                      value={tmpPropertyData.data?.ecs_communal}
                      onChange={(e) => {
                        setTmpPropertyData({
                          ...tmpPropertyData,
                          data: { ...tmpPropertyData.data, ecs_communal: e.target.value }
                        });
                      }}
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                    >
                      <FormControlLabel value="Collectif" control={<Radio />} label="Collectif" />
                      <FormControlLabel value="Individuel" control={<Radio />} label="Individuel" />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={5} sx={{ mt: '20px' }}>
                  <FormControl fullWidth>
                    <InputLabel size="small" id="demo-simple-select-label">
                      Type d'énergie
                    </InputLabel>
                    <Select
                      defaultValue=""
                      fullWidth
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={tmpPropertyData.data?.ecs_energy_type}
                      label="Type d'énergie'"
                      onChange={(e) => {
                        setTmpPropertyData({
                          ...tmpPropertyData,
                          data: { ...tmpPropertyData.data, ecs_energy_type: Number(e.target.value) }
                        });
                      }}
                      size="small"
                    >
                      {type_references['ecs_energy_type'].map((type, index) => {
                        return (
                          <MenuItem key={index} value={index.toString()}>
                            {type}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={7}>
                  <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">Refroidissement</FormLabel>
                    <RadioGroup
                      value={tmpPropertyData.data?.cooling_communal}
                      onChange={(e) => {
                        setTmpPropertyData({
                          ...tmpPropertyData,
                          data: { ...tmpPropertyData.data, cooling_communal: e.target.value }
                        });
                      }}
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                    >
                      <FormControlLabel value="Collectif" control={<Radio />} label="Collectif" />
                      <FormControlLabel value="Individuel" control={<Radio />} label="Individuel" />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={5} sx={{ mt: '20px' }}>
                  <FormControl fullWidth>
                    <InputLabel size="small" id="demo-simple-select-label">
                      Type d'énergie
                    </InputLabel>
                    <Select
                      defaultValue=""
                      fullWidth
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={tmpPropertyData.data?.cooling_energy_type}
                      label="Type d'énergie'"
                      onChange={(e) => {
                        setTmpPropertyData({
                          ...tmpPropertyData,
                          data: { ...tmpPropertyData.data, cooling_energy_type: Number(e.target.value) }
                        });
                      }}
                      size="small"
                    >
                      {type_references['cooling_energy_type'].map((type, index) => {
                        return (
                          <MenuItem key={index} value={index.toString()}>
                            {type}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>

      {/* FOOTER */}

      <Grid ref={footerRef} item xs={12} sx={{ mt: '15px' }}>
        <Grid container spacing={2} justifyContent={'center'} alignItems={'center'}>
          <Grid item>
            <Button
              variant="outlined"
              onClick={() => {
                setModalOpen(false);
              }}
            >
              Annuler
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              onClick={() => {
                const promises = [];

                if (isBuilding) {
                  promises.push(api.building.update(propertyData.uuid, tmpPropertyData));
                } else {
                  promises.push(api.product.update(propertyData.uuid, tmpPropertyData));
                }

                const matterportData = {
                  uuid_owner: propertyData.uuid,
                  model_id: matterportID
                };

                if (matterportID) {
                  if (propertyData.matterport_scan === undefined) {
                    promises.push(addMatterportScan(matterportData));
                  } else {
                    console.log(matterportData);
                    if (matterportData.model_id !== propertyData.matterport_scan.model_id)
                      promises.push(patchMatterportScan(propertyData.matterport_scan['uuid'], matterportData));
                  }
                }

                Promise.all(promises)
                  .then((resp) => {
                    setModalOpen(false);
                    refreshData();
                    propertyData.matterport_scan = matterportData;
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }}
            >
              Valider
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EditPropertyInfos;
