import {
  FormControl,
  Grid2,
  InputAdornment,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Rating,
  Select,
  TextField
} from '@mui/material';

import { useContext, useEffect, useState } from 'react';
import { api } from '~/api';
import { AppContext } from '~/utils/context/AppContext';
import { MediaQueryContext } from '~/utils/context/MediaQueryProvider';
import normalizedString from '~/utils/normalizedString';

import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import _ from 'lodash';
import {
  getTagForEquipment,
  getTagPinPoint,
  iconFilters,
  iconNoSorting,
  iconSortingDown,
  iconSortingUp
} from '../View3d/utilsView3D';
import { EquipmentDetails } from './EquipmentDetails';

export const EquipmentsList2 = ({
  productDatas,
  navigateToTag,
  setUuidEquipmentSelected,
  uuidEquipmentSelected,
  uuidTagSelected,
  setUuidTagSelected,
  hideTags,
  showTags,
  cameraMoving
}) => {
  const { referencial, equipmentReferencial } = useContext(AppContext);
  const [equipments, setEquipments] = useState([]);
  const [filterdedEquipmentsList, setFilteredEquipmentsList] = useState([]);
  const [sortedEquipmentsList, setSortedEquipmentsList] = useState([]);
  const [sortOrder, setSortOrder] = useState(1);
  const mediaQueries = useContext(MediaQueryContext);
  const [hoverFilterButton, setHoverFilterButton] = useState(false);
  const [showFilters, setShowFilters] = useState(false);

  const [searchInputValue, setSearchInputValue] = useState('');

  const [categories, setCategories] = useState([]);

  const [hasMinOneRating, setHasMinOneRating] = useState(false);

  const [rooms, setRooms] = useState([]);

  const [selectedRoom, setSelectedRoom] = useState(null);

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [fonctionalStates, setFonctionalStates] = useState([]);

  const [selectedFonctionalState, setSelectedFonctionalState] = useState(null);

  const [equipmentSelected, setEquipmentSelected] = useState(null);

  useEffect(() => {
    api.product.equipments.get(productDatas.uuid).then((res) => {
      setEquipments(res);
      setFilteredEquipmentsList(res);
      setSortedEquipmentsList(res);
      const rooms = [];
      const categories = [];
      const fonctionalStates = [];
      res.forEach((equipment, index) => {
        if (equipment.states && equipment.states.length > 0) {
          // Récupérer le dernier etat
          const lastState = equipment.states.sort(
            (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
          )[0];

          // Récupérer les notes
          if (lastState.rating !== null) {
            setHasMinOneRating(true);
          }

          // Récupérer les états fonctionnels
          if (lastState.state) {
            if (!fonctionalStates.some((f) => f === lastState.state)) {
              fonctionalStates.push(lastState.state);
            }
          }
        }

        // Récupérer les pièces
        if (equipment.room) {
          if (!rooms.some((r) => r.uuid === equipment.room.uuid)) {
            rooms.push(equipment.room);
          }
        }

        // Récupérer les catégories
        if (equipment.category_kind) {
          if (!categories.some((c) => c === equipment.category_kind)) {
            categories.push(equipment.category_kind);
          }
        }
      });
      setRooms(rooms);
      setCategories(categories);
      setFonctionalStates(fonctionalStates);
    });
  }, []);

  useEffect(() => {
    if (showFilters === false) {
      setSelectedCategory(null);
      setSelectedRoom(null);
      setSelectedFonctionalState(null);
      setFilteredEquipmentsList(equipments);
      setSortedEquipmentsList(equipments);
    }
  }, [showFilters]);

  useEffect(() => {
    if (uuidTagSelected) {
      navigateToTag(uuidTagSelected);
    }
  }, [uuidTagSelected]);

  useEffect(() => {
    if (
      sortedEquipmentsList &&
      sortedEquipmentsList.length > 0 &&
      uuidEquipmentSelected &&
      uuidEquipmentSelected.uuid &&
      document.getElementById(uuidEquipmentSelected?.uuid)
    ) {
      document.getElementById(uuidEquipmentSelected?.uuid).scrollIntoView({ behavior: 'smooth' });
    }
  }, [uuidEquipmentSelected, sortedEquipmentsList]);

  useEffect(() => {
    // Filtrer les équipements en fonction de la recherche

    const filteredEquipmentsTmp = equipments.filter(
      (equipment) =>
        normalizedString(equipment.title).includes(normalizedString(searchInputValue)) ||
        normalizedString(
          equipmentReferencial.equipments.find((category) => category.id === equipment.kind)?.name
            ? equipmentReferencial.equipments.find((category) => category.id === equipment.kind)?.name
            : ''
        ).includes(normalizedString(searchInputValue)) ||
        normalizedString(
          equipmentReferencial.equipmentCategoryKinds.find((category) => category.id === equipment.category_kind)?.name
            ? equipmentReferencial.equipmentCategoryKinds.find((category) => category.id === equipment.category_kind)
                ?.name
            : ''
        ).includes(normalizedString(searchInputValue))
    );

    setFilteredEquipmentsList(filteredEquipmentsTmp);
    setSortedEquipmentsList(filteredEquipmentsTmp);

    filteredEquipmentsTmp.forEach((equipment, index) => {
      if (
        filteredEquipmentsTmp[index] &&
        filteredEquipmentsTmp[index]?.states &&
        equipment.states?.length - 1 >= 0 &&
        filteredEquipmentsTmp[index]?.states[equipment.states?.length - 1].rating !== null
      ) {
        setHasMinOneRating(true);
      }
    });
  }, [searchInputValue]);

  useEffect(() => {
    // Filtrer les équipements en fonction des filtres

    if (selectedRoom === null || selectedRoom === undefined) {
    } else {
      setFilteredEquipmentsList(equipments.filter((equipment) => equipment.uuid_room === selectedRoom.uuid));
      setSortedEquipmentsList(equipments.filter((equipment) => equipment.uuid_room === selectedRoom.uuid));
    }

    if (
      selectedCategory === null ||
      selectedCategory === undefined ||
      selectedCategory === '' ||
      selectedCategory === 0
    ) {
    } else {
      setFilteredEquipmentsList(equipments.filter((equipment) => equipment.category_kind === Number(selectedCategory)));
      setSortedEquipmentsList(equipments.filter((equipment) => equipment.category_kind === Number(selectedCategory)));
    }

    if (selectedFonctionalState === null || selectedFonctionalState === undefined) {
    } else {
      setFilteredEquipmentsList(
        equipments.filter((equipment) => equipment.states[0].state === selectedFonctionalState)
      );
      setSortedEquipmentsList(equipments.filter((equipment) => equipment.states[0].state === selectedFonctionalState));
    }

    if (
      (selectedCategory === null || selectedCategory === 0 || selectedCategory === '0') &&
      (selectedRoom === undefined || selectedRoom === null) &&
      selectedFonctionalState === null
    ) {
      setFilteredEquipmentsList(equipments);
      setSortedEquipmentsList(equipments);
    }
  }, [selectedRoom, selectedCategory, selectedFonctionalState]);

  useEffect(() => {
    const tagsToShow = [];

    sortedEquipmentsList.forEach((equipment, index) => {
      if (equipment.tags && equipment.tags.length > 0) {
        tagsToShow.push(equipment.tags[0].uuid);
      }

      let lastState = null;

      if (equipment.states && equipment.states.length > 0) {
        lastState = _.maxBy(equipment.states, 'created_at');
      }

      if (lastState && lastState.tags && lastState.tags.length > 0) {
        tagsToShow.push(lastState.tags[0].uuid);
      }
    });

    showTags(tagsToShow);
  }, [sortedEquipmentsList]);

  const getEquipmentName = (equipment) => {
    const title = equipment.title && equipment.title !== '' ? equipment.title : '';
    const categoryName = equipment.kind
      ? equipmentReferencial.equipments.find((category) => category.id === equipment.kind)?.name
      : '';
    const categoryKindName = equipment.category_kind
      ? 'Catégorie : ' +
        equipmentReferencial.equipmentCategoryKinds.find((category) => category.id === equipment.category_kind)?.name
      : equipment.tab !== ''
        ? 'Onglet : ' + equipment.tab
        : '';

    // Retourner le titre de l'équipement ou la catégorie
    return `${title !== '' ? title : categoryName}\n${categoryKindName}`;
  };

  if (uuidEquipmentSelected) {
    return (
      <EquipmentDetails
        setUuidEquipmentSelected={setUuidEquipmentSelected}
        equipment={uuidEquipmentSelected}
        productDatas={productDatas}
      ></EquipmentDetails>
    );
  }

  return (
    <Grid2 container spacing={0}>
      <>
        <Grid2 size={12}>
          <Grid2
            container
            spacing={0}
            alignItems={'flex-start'}
            sx={{ borderBottom: '0px solid #e0e0e0', padding: '10px 0' }}
          >
            <Grid2 size={'grow'}>
              {showFilters && equipments.length > 1 && (
                <Grid2 container spacing={1}>
                  <Grid2 size={{ xs: 6 }}>
                    <TextField
                      size="small"
                      fullWidth
                      autoComplete="off"
                      label="Recherche"
                      onChange={(e) => {
                        setSearchInputValue(e.target.value);
                        setSelectedRoom(null);
                        setSelectedCategory(null);
                        setSelectedFonctionalState(null);
                      }}
                      value={searchInputValue}
                      InputProps={
                        searchInputValue !== ''
                          ? {
                              endAdornment: (
                                <InputAdornment position="start">
                                  <HighlightOffIcon
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      setSearchInputValue('');
                                    }}
                                  ></HighlightOffIcon>
                                </InputAdornment>
                              ),
                              style: {
                                paddingRight: 0
                              }
                            }
                          : {}
                      }
                    ></TextField>
                  </Grid2>
                  {rooms.length > 1 && (
                    <Grid2 size={{ xs: 6 }}>
                      <FormControl fullWidth>
                        <InputLabel size="small" id="demo-simple-select-label">
                          Par pièce
                        </InputLabel>
                        <Select
                          defaultValue=""
                          fullWidth
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={selectedRoom ? selectedRoom.uuid : ''}
                          label="Par pièce"
                          onChange={(e) => {
                            setSearchInputValue('');
                            setSelectedCategory(null);
                            setSelectedFonctionalState(null);
                            setSelectedRoom(rooms.find((room) => room.uuid === e.target.value));
                          }}
                          size="small"
                        >
                          {rooms.map((room, index) => {
                            return (
                              <MenuItem key={index} value={room.uuid}>
                                {room.name}
                              </MenuItem>
                            );
                          })}
                          {selectedRoom && (
                            <MenuItem value="">
                              <ListItemIcon>
                                <HighlightOffIcon></HighlightOffIcon>
                              </ListItemIcon>
                              <ListItemText primary="Réinitialiser" />
                            </MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </Grid2>
                  )}

                  <Grid2 size={{ xs: 6 }}>
                    <FormControl fullWidth>
                      <InputLabel size="small" id="demo-simple-select-label">
                        Par catégories
                      </InputLabel>
                      <Select
                        defaultValue=""
                        fullWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedCategory ? selectedCategory : ''}
                        label="Par catégories"
                        onChange={(e) => {
                          setSearchInputValue('');
                          setSelectedRoom(null);
                          setSelectedFonctionalState(null);
                          setSelectedCategory(Number(e.target.value));
                        }}
                        size="small"
                      >
                        {categories.map((category, index) => {
                          return (
                            <MenuItem key={index} value={category}>
                              {_.find(equipmentReferencial['equipmentCategoryKinds'], { id: Number(category) }).name}
                            </MenuItem>
                          );
                        })}
                        {selectedCategory && (
                          <MenuItem value={null}>
                            <ListItemIcon>
                              <HighlightOffIcon></HighlightOffIcon>
                            </ListItemIcon>
                            <ListItemText primary="Réinitialiser" />
                          </MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </Grid2>

                  {fonctionalStates.length > 1 && (
                    <Grid2 size={{ xs: 6 }}>
                      <FormControl fullWidth>
                        <InputLabel size="small" id="demo-simple-select-label">
                          Fonctionnement
                        </InputLabel>
                        <Select
                          defaultValue=""
                          fullWidth
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={selectedFonctionalState ? selectedFonctionalState : ''}
                          label="Fonctionnement"
                          onChange={(e) => {
                            setSearchInputValue('');
                            setSelectedRoom(null);
                            setSelectedCategory(null);
                            setSelectedFonctionalState(Number(e.target.value));
                          }}
                          size="small"
                        >
                          {fonctionalStates.map((category, index) => {
                            return (
                              <MenuItem key={index} value={category}>
                                {_.find(referencial['equipment_state.status'], { id: Number(category) }).name}
                              </MenuItem>
                            );
                          })}
                          {selectedFonctionalState && (
                            <MenuItem value={null}>
                              <ListItemIcon>
                                <HighlightOffIcon></HighlightOffIcon>
                              </ListItemIcon>
                              <ListItemText primary="Réinitialiser" />
                            </MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </Grid2>
                  )}
                </Grid2>
              )}
            </Grid2>
            {equipments.length > 1 && (
              <Grid2
                size={'auto'}
                sx={{ width: '32px', cursor: 'pointer', pl: '10px' }}
                onMouseEnter={() => setHoverFilterButton(true)}
                onMouseLeave={() => setHoverFilterButton(false)}
                onClick={() => setShowFilters(!showFilters)}
              >
                {iconFilters(hoverFilterButton || showFilters ? 'rgb(241, 118, 51)' : '#000')}
              </Grid2>
            )}
          </Grid2>
        </Grid2>
        <Grid2 size={12}>
          {sortedEquipmentsList && sortedEquipmentsList.length === 0 && (
            <span style={{ fontSize: '12px', color: '#9e9e9e' }}>Aucun équipement trouvé</span>
          )}
          {hasMinOneRating && sortedEquipmentsList?.length > 1 && (
            <Grid2
              container
              spacing={0}
              alignItems={'center'}
              sx={{ borderBottom: '1px solid #e0e0e0', padding: '10px 0' }}
            >
              <Grid2 size={'grow'}></Grid2>
              <Grid2
                size={'auto'}
                onClick={() => {
                  const sortEquipments = () => {
                    if (sortOrder === 0) {
                      setSortedEquipmentsList([...filterdedEquipmentsList]);
                      setSortOrder(1);
                    } else if (sortOrder === 1) {
                      const sortedEquipments = [...filterdedEquipmentsList].sort((a, b) => {
                        const aRating = a.states?.length ? a.states[a.states.length - 1].rating : 0;
                        const bRating = b.states?.length ? b.states[b.states.length - 1].rating : 0;
                        return bRating - aRating;
                      });
                      setSortedEquipmentsList(sortedEquipments);
                      setSortOrder(2);
                    } else {
                      const sortedEquipments = [...filterdedEquipmentsList].sort((a, b) => {
                        const aRating = a.states?.length ? a.states[a.states.length - 1].rating : 0;
                        const bRating = b.states?.length ? b.states[b.states.length - 1].rating : 0;
                        return aRating - bRating;
                      });
                      setSortedEquipmentsList(sortedEquipments);
                      setSortOrder(0);
                    }
                  };

                  sortEquipments();
                }}
              >
                <span style={{ fontSize: mediaQueries.matchesSm ? '16px' : '12px', cursor: 'pointer' }}>
                  Trier par statut
                </span>
                <div style={{ display: 'inline-block', cursor: 'pointer', position: 'relative', top: '7px' }}>
                  {sortOrder === 1 && iconNoSorting()}
                  {sortOrder === 2 && iconSortingDown()}
                  {sortOrder === 0 && iconSortingUp()}
                </div>
              </Grid2>
              <Grid2 size={'auto'} sx={{ width: '15px' }}></Grid2>
            </Grid2>
          )}
        </Grid2>

        {sortedEquipmentsList &&
          sortedEquipmentsList.map((equipment, index) => (
            <Grid2 size={12} key={index}>
              <Grid2
                id={equipment.uuid}
                container
                spacing={0}
                alignItems={'center'}
                sx={{
                  borderBottom: '1px solid #e0e0e0',
                  padding: '10px 0',
                  cursor: 'pointer',
                  pl: '10px',
                  pr: '10px',
                  backgroundColor:
                    uuidEquipmentSelected?.uuid === equipment?.uuid || equipmentSelected === equipment?.uuid
                      ? '#EEEEEE'
                      : '#fff',
                  fontWeight:
                    uuidEquipmentSelected?.uuid === equipment?.uuid || equipmentSelected === equipment?.uuid
                      ? 'bold'
                      : 'normal'
                }}
                onClick={() => {
                  if (cameraMoving === false) {
                    if (getTagForEquipment(equipment)) setUuidTagSelected(getTagForEquipment(equipment).uuid);

                    setUuidEquipmentSelected(equipment);
                    // setEquipmentSelected(equipment.uuid);
                  }
                }}
              >
                <Grid2 size={'grow'}>{getEquipmentName(equipment)}</Grid2>
                <Grid2 size={'auto'}>
                  {sortedEquipmentsList &&
                    sortedEquipmentsList[index] &&
                    sortedEquipmentsList[index]?.states &&
                    equipment.states?.length - 1 >= 0 &&
                    sortedEquipmentsList[index]?.states[equipment.states?.length - 1].rating !== null && (
                      <>
                        <Rating
                          size={mediaQueries.matchesSm ? 'medium' : 'small'}
                          sx={{ paddingRight: '15px' }}
                          value={sortedEquipmentsList[index]?.states[equipment.states?.length - 1].rating}
                          precision={0.5}
                          readOnly
                        ></Rating>
                      </>
                    )}
                </Grid2>
                <Grid2 size={'auto'}>
                  {getTagPinPoint(equipment) ? (
                    getTagPinPoint(equipment)
                  ) : (
                    <svg
                      width="15"
                      height="25"
                      viewBox="0 0 20 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    ></svg>
                  )}
                </Grid2>
              </Grid2>
            </Grid2>
          ))}
      </>
    </Grid2>
  );
};

export default EquipmentsList2;
