import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { addTag, api, deleteEquipment, patchEquipment, patchTag } from '~/api';
import { useDialog } from '~/utils/useDialog';
import BasicModal from '~/app/components/BasicModal/BasicModal';
import { ConfirmationDialog } from '~/app/components/ConfirmationDialog/ConfirmationDialog';
import MatterportViewer from '~/app/components/MatterportViewer/MatterportViewer';
// Steps
import GeneralInfos from '~/app/components/WizzardBlocks/GeneralInfos';
import DocumentUploader from '~/app/components/WizzardBlocks/DocumentUploader';
import LocalizationChoice from '~/app/components/WizzardBlocks/LocalizationChoice';
import MoreInfos from '~/app/components/WizzardBlocks/MoreInfos';
import RecapInfos from '~/app/components/WizzardBlocks/RecapInfos';
import TitleBar from '~/app/components/WizzardBlocks/TitleBar';
import Validate from '~/app/components/WizzardBlocks/Validate';
import '~/app/components/WizzardBlocks/Wizzards.scss';
import { ProductTitle } from '../ProductTitle/ProductTitle';
import { EquipmentSubTitle } from '../EquipmentSubTitle/EquipmentSubTitle';
import React from 'react';
import { ca } from 'date-fns/locale';

let interval = null;

export const WizzardEquipment = ({
  setWizzardEquipmentOpen,
  productDatas,
  refreshEquipments = null,
  wizzardEquipmentCreationMode,
  selectedUUIDEquipment = null,
  equipmentFamily = null,
  setRefreshRequired = null,
  buildingData = null,
  generateSubtitleEquipment = null,
  equipmentData = null,
  equipmentReferential = null
}) => {
  const { id, idEquipment } = useParams();
  const [modalOpen, setModalOpen] = useState<boolean>(true);
  const [deleteDialogOpen, handleOpenDeleteDialog, handleCloseDeleteDialog] = useDialog();
  const [hasMatterportScan, setHasMatterportScan] = useState(false);
  const [hasDatasFilled, setHasDatasFilled] = useState(false);
  const [currentStep, setCurrentStep] = useState<string>('generalInfos');
  const [currentEquipmentUUID, setCurrentEquipmentUUID] = useState<string>('');
  const [equipmentDatas, setEquipmentDatas] = useState<any>({
    uuid_owner: productDatas.uuid,
    kind: equipmentFamily !== null ? equipmentFamily + 1 : null,
    is_draft: false
  });
  const [moreInfosDatas, setMoreInfosDatas] = useState<any>({ data: {} });
  const [tags, setTags] = useState<any>([]);
  const [localizationTagUUID, setLocalizationTagUUID] = useState<string>('');
  const [stepToReturn, setStepToReturn] = useState<string>('');
  const [hasBeenValidated, setHasBeenValidated] = useState<boolean>(false);
  const [relocalization, setRelocalization] = useState<boolean>(false);
  const [localizationActivated, setLocalizationActivated] = useState<boolean>(true);
  const [deleteEquipmentWarningMode, setDeleteEquipmentWarningMode] = useState<boolean>(false);
  const [backUpTags, setBackUpTags] = useState<any>([]);
  const [reinitialize, setReinitialize] = useState<boolean>(false);
  const [pathContext, setPathContext] = useState('');
  const [mainOffset, setMainOffset] = useState(0);

  const [readyToRender, setReadyToRender] = useState(false);

  const headerRef = React.useRef(null);
  const headerEquiRef = React.useRef(null);

  const navigate = useNavigate();

  useEffect(() => {
    let offset = 0;
    if (headerRef.current) {
      offset = headerRef.current.clientHeight;
    }
    if (headerEquiRef.current) {
      offset += headerEquiRef.current.clientHeight;
    }

    offset += 16;

    setMainOffset(offset);
  });

  function localizationTagCallback(tag, sid, uuidEquipment) {
    if (tag.anchorPosition.x !== 0 && tag.anchorPosition.y !== 0 && tag.anchorPosition.z !== 0) {
      const tagPayload = {
        uuid_owner: uuidEquipment,
        owner_kind: 'equipment',
        position: {
          x: tag.anchorPosition.x,
          y: tag.anchorPosition.y,
          z: tag.anchorPosition.z,
          steam_x: tag.stemVector.x,
          steam_y: tag.stemVector.y,
          steam_z: tag.stemVector.z
        },
        sid: sid,
        icone_id: tag.icone_id,
        description: tag.description,
        is_principal: true
      };

      if (localizationTagUUID === null || localizationTagUUID === undefined || localizationTagUUID === '') {
        api.tags
          .create([tagPayload])
          .then((resp) => {
            setLocalizationTagUUID(resp[0].uuid);
          })
          .catch((reason) => {});
      } else {
        api.tags
          .update(localizationTagUUID, { position: tagPayload.position })
          .then((resp) => {})
          .catch((reason) => {});
      }
      setTags((oldArray) => [...oldArray, JSON.parse(JSON.stringify(tagPayload))]);
    }
  }

  const modifyInfos = () => {
    setCurrentStep('moreInfos');
    setStepToReturn('localization');
  };

  const modifyPhoto = () => {
    setCurrentStep('images');
    setStepToReturn('localization');
  };

  const modifyLocalization = () => {
    setStepToReturn('');
    setRelocalization(true);
  };

  const suppressEquipment = () => {
    setDeleteEquipmentWarningMode(true);

    handleOpenDeleteDialog();
  };

  const removeTag = (sid) => {
    const backUpTags = JSON.parse(JSON.stringify(tags));
    setBackUpTags(backUpTags);
    const newTags = tags.filter((tag) => tag.uuid !== sid);
    //

    setTags(JSON.parse(JSON.stringify(newTags)));
  };

  const cancelLocalization = () => {
    if (!wizzardEquipmentCreationMode) {
      setTags(backUpTags);
      setLocalizationActivated(false);
      setRelocalization(false);
      setStepToReturn('');
      setBackUpTags([]);
    } else {
      setCurrentStep('localizationChoice');
    }
  };

  const deleteDraftEquipment = (): void => {
    handleCloseDeleteDialog();
    setModalOpen(false);

    if (deleteEquipmentWarningMode) {
      deleteEquipment(currentEquipmentUUID).then((resp) => {});
      if (refreshEquipments) refreshEquipments();
      setModalOpen(false);
      setWizzardEquipmentOpen(false);
      navigate(`/product/${id}/equipments`);
      return;
    }
    if (currentEquipmentUUID !== '') {
      deleteEquipment(currentEquipmentUUID)
        .then((resp) => {})
        .catch((reason) => {});
    }
  };

  const modalClosed = () => {
    setWizzardEquipmentOpen(false);
  };

  const reinitialization = (callback = null) => {
    setEquipmentDatas({ uuid_owner: productDatas.uuid });
    setHasDatasFilled(false);
    setMoreInfosDatas({ data: {} });
    setLocalizationTagUUID('');
    setCurrentEquipmentUUID('');
    setHasBeenValidated(true);
    const newTags = [];
    setTags(newTags);
    if (refreshEquipments) refreshEquipments();
    setStepToReturn('');
    if (callback) {
      callback();
    }
    setReinitialize(false);
    setReinitialize(true);
  };

  const validateNewEquipment = (callback = null) => {
    console.log('validateNewEquipment');
    console.log(moreInfosDatas);
    console.log(callback);
    const tmpMoreInfosDatas = JSON.parse(JSON.stringify(moreInfosDatas));
    delete tmpMoreInfosDatas.sub_tab;
    delete tmpMoreInfosDatas.tab;
    patchEquipment(currentEquipmentUUID, { is_draft: false, ...tmpMoreInfosDatas }).then((resp) => {
      if (setRefreshRequired) setRefreshRequired(true);
      // if (refreshEquipments) refreshEquipments(currentEquipmentUUID);
      if (callback) {
        callback();
      }
    });
  };

  const handleConfirm = () => {
    if (hasDatasFilled && wizzardEquipmentCreationMode) {
      handleOpenDeleteDialog();
    } else {
      setModalOpen(false);
    }
  };

  const handleNextStep = (step, choice = false, goBack = false) => {
    // EDITION MODE
    if (!wizzardEquipmentCreationMode) {
      if (step === 'localization') {
        refreshEquipments();
        setModalOpen(false);

        setWizzardEquipmentOpen(false);
      } else {
        setCurrentStep('localization');
      }
      // if (refreshEquipments) refreshEquipments();
      return;
    }

    // RETURN TO STEP
    if (stepToReturn !== '') {
      setCurrentStep(stepToReturn);
      setStepToReturn('');
      return;
    }

    // HAS MATTERSCAN
    if (hasMatterportScan) {
      // General infos
      if (step === 'generalInfos') {
        setCurrentStep('images');
      }

      // Images
      else if (step === 'images' && goBack) {
        setCurrentStep('generalInfos');
        return;
      } else if (step === 'images' && !choice) {
        setCurrentStep('localizationChoice');
      } else if (step === 'images' && choice) {
        setCurrentStep('moreInfos');
      }

      // More infos
      else if (step === 'moreInfos' && goBack) {
        setCurrentStep('images');
        return;
      } else if (step === 'moreInfos') {
        setCurrentStep('localizationChoice');
      }

      // Localization choice
      else if (step === 'localizationChoice' && !choice) {
        validateNewEquipment(() => {
          setCurrentStep('validate');
          reinitialization();
        });
        // setCurrentStep('validate');
      } else if (step === 'localizationChoice' && choice) {
        setCurrentStep('localization');
      }

      // Localization
      else if (step === 'localization') {
        validateNewEquipment(() => {
          setCurrentStep('validate');
          reinitialization();
        });
      }
    }

    // NO MATTERSCAN
    else {
      // General infos
      if (step === 'generalInfos') {
        setCurrentStep('images');
      }

      // Images
      else if (step === 'images' && !choice) {
        validateNewEquipment(() => {
          reinitialization(() => {
            setCurrentStep('validate');
          });
        });
      } else if (step === 'images' && choice) {
        setCurrentStep('moreInfos');
      }

      // more infos
      else if (step === 'moreInfos') {
        validateNewEquipment(() => {
          setCurrentStep('validate');
          reinitialization();
        });
      }
    }
  };

  useEffect(() => {
    const pathname = window.location.pathname.split('/');
    if (pathname[1] === 'product' || (pathname[1] === 'events' && productDatas.uuid.split('-')[0] === 'pro')) {
      setPathContext('product');
    } else if (pathname[1] === 'building' || (pathname[1] === 'events' && productDatas.uuid.split('-')[0] === 'bui')) {
      setPathContext('building');
    }
    if (
      productDatas &&
      productDatas.matterport_scan !== null &&
      productDatas.matterport_scan !== undefined &&
      productDatas.matterport_scan !== '' &&
      productDatas.matterport_scan.model_id !== null &&
      productDatas.matterport_scan.model_id !== undefined &&
      productDatas.matterport_scan.model_id !== ''
    ) {
      setHasMatterportScan(true);
    } else {
      setHasMatterportScan(false);
    }
  }, []);

  useEffect(() => {
    if (!wizzardEquipmentCreationMode) {
      if (!equipmentData && !equipmentData.uuid) {
        console.log('equipmentData', equipmentData);
        const uuidEquipment = selectedUUIDEquipment ? selectedUUIDEquipment : idEquipment;
        if (uuidEquipment !== '') {
          api.equipments.get(uuidEquipment).then((resp) => {
            console.log(resp);

            const tags = [];
            if (resp.states && resp.states.length > 0) {
              resp.states.map((state) => {
                if (state.tags && state.tags.length > 0) {
                  // setLocalizationTagUUID(state.tags[0].uuid);
                  // setTags(state.tags);
                  tags.push(...state.tags);
                }
              });
            }

            if (resp.tags.length > 0) {
              tags.push(...resp.tags);
            }

            console.log(tags);
            setTags(tags);
            setLocalizationTagUUID(tags && tags.length > 0 ? tags[tags.length - 1].uuid : null);
            setCurrentEquipmentUUID(uuidEquipment);
            const infosDatas = JSON.parse(JSON.stringify(resp));
            delete infosDatas.desc;
            delete infosDatas.documents;
            delete infosDatas.events;
            delete infosDatas.image_thumbnail;
            delete infosDatas.images;
            delete infosDatas.tags;
            delete infosDatas.states;
            delete infosDatas.room;
            setMoreInfosDatas(infosDatas);
            setEquipmentDatas(infosDatas);
            setCurrentStep('localization');
            setLocalizationActivated(false);
            setRelocalization(false);
            setReadyToRender(true);
          });
        }
      } else {
        const uuidEquipment = selectedUUIDEquipment ? selectedUUIDEquipment : idEquipment;
        const tags = [];

        if (equipmentData.states && equipmentData.states.length > 0) {
          equipmentData.states.map((state) => {
            if (state.tags && state.tags.length > 0) {
              // setLocalizationTagUUID(state.tags[0].uuid);
              // setTags(state.tags);
              tags.push(...state.tags);
            }
          });
        }

        if (equipmentData.tags.length > 0) {
          tags.push(...equipmentData.tags);
        }

        console.log(tags);
        setTags(tags);
        setLocalizationTagUUID(tags && tags.length > 0 ? tags[tags.length - 1].uuid : null);
        setCurrentEquipmentUUID(uuidEquipment);
        const infosDatas = JSON.parse(JSON.stringify(equipmentData));
        delete infosDatas.desc;
        delete infosDatas.documents;
        delete infosDatas.events;
        delete infosDatas.image_thumbnail;
        delete infosDatas.images;
        delete infosDatas.tags;
        delete infosDatas.states;
        delete infosDatas.room;
        setMoreInfosDatas(infosDatas);
        setEquipmentDatas(infosDatas);
        setCurrentStep('localization');
        setLocalizationActivated(false);
        setRelocalization(false);
        setReadyToRender(true);
      }
    } else {
      setReadyToRender(true);
    }
  }, [idEquipment]);

  useEffect(() => {
    if (
      (equipmentDatas.kind !== null && equipmentDatas.kind !== undefined && equipmentDatas.kind !== '') ||
      (equipmentDatas.title !== null && equipmentDatas.title !== undefined && equipmentDatas.title !== '')
    ) {
      setHasDatasFilled(true);
    }
  }, [equipmentDatas]);

  return (
    <div className="Wizzards__container">
      <div>
        <BasicModal
          modalOpen={modalOpen}
          onModalOpen={(open: boolean) => setModalOpen(open)}
          onModalClose={modalClosed}
          confirmClose={true}
          handleConfirm={handleConfirm}
        >
          <Grid container spacing={0} style={{ height: '100%' }}>
            <div style={{ backgroundColor: '#fff7f3', width: '100vw' }}>
              <Grid item xs={12} ref={headerRef}>
                <ProductTitle data={productDatas} buildingData={buildingData} />
              </Grid>
            </div>
            {generateSubtitleEquipment && equipmentData && equipmentReferential && (
              <Grid item xs={12} ref={headerEquiRef}>
                <EquipmentSubTitle
                  generateSubtitleEquipment={generateSubtitleEquipment}
                  equipmentData={equipmentData}
                  equipmentReferencial={equipmentReferential}
                />
              </Grid>
            )}
            {!readyToRender ? (
              <div></div>
            ) : (
              <>
                <Grid item xs={12} sx={{ height: `calc(100vh - ${mainOffset}px)` }}>
                  <div className="Wizzards__content">
                    {currentStep === 'generalInfos' && (
                      <GeneralInfos
                        elementDatas={equipmentDatas}
                        setElementDatas={setEquipmentDatas}
                        nextStepCallBack={handleNextStep}
                        currentElementUUID={currentEquipmentUUID}
                        setCurrentElementUUID={setCurrentEquipmentUUID}
                      ></GeneralInfos>
                    )}{' '}
                    {currentStep === 'images' && (
                      <DocumentUploader
                        nextStepCallBack={handleNextStep}
                        currentElementUUID={currentEquipmentUUID}
                        hasMatterportScan={hasMatterportScan}
                        showCheckBox={stepToReturn === '' ? true : false}
                        wizzardCreationMode={wizzardEquipmentCreationMode}
                        stepToReturn={stepToReturn}
                        elementType="equipment"
                        filesType="images"
                      ></DocumentUploader>
                    )}
                    {currentStep === 'moreInfos' && (
                      <MoreInfos
                        elementDatas={equipmentDatas}
                        nextStepCallBack={handleNextStep}
                        hasNextStep={hasMatterportScan && stepToReturn === '' ? true : false}
                        validateNewElement={validateNewEquipment}
                        moreInfosDatas={moreInfosDatas}
                        setMoreInfosDatas={setMoreInfosDatas}
                        wizzardCreationMode={wizzardEquipmentCreationMode}
                        stepToReturn={stepToReturn}
                      ></MoreInfos>
                    )}
                    {currentStep === 'localizationChoice' && (
                      <LocalizationChoice nextStepCallBack={handleNextStep}></LocalizationChoice>
                    )}
                    {currentStep === 'localization' && <div></div>}
                    {currentStep === 'validate' && (
                      <Validate
                        setCurrentStep={setCurrentStep}
                        setModalOpen={setModalOpen}
                        elementType={'equipment'}
                      ></Validate>
                    )}
                    <div
                      style={{
                        height: `calc(100vh - ${mainOffset}px)`,
                        display: currentStep === 'localization' ? 'block' : 'none'
                      }}
                    >
                      <Grid container spacing={0} sx={{ height: '100%', overflow: 'hidden' }}>
                        <Grid
                          item
                          xs={tags.length > 0 || !wizzardEquipmentCreationMode ? 8 : 12}
                          style={{ backgroundColor: '#222' }}
                        >
                          {hasMatterportScan && mainOffset > 0 && (
                            <MatterportViewer
                              productId={productDatas.uuid}
                              modelId={productDatas.matterport_scan.model_id}
                              mode={'localize'}
                              uuid_event={null}
                              placedTagCallback={localizationTagCallback}
                              mpHelp={1}
                              mpDh={0}
                              mpHl={0}
                              mpGt={0}
                              mpHr={0}
                              mpMls={0}
                              mpF={1}
                              mpNozoom={0}
                              mpWh={0}
                              mpLang="fr"
                              mpSearch={0}
                              tags={tags}
                              removeTag={removeTag}
                              typeEquipment={equipmentDatas?.kind}
                              cancelLocalization={cancelLocalization}
                              hasBeenValidated={hasBeenValidated}
                              modifyLocalization={relocalization}
                              setModifyLocalization={setRelocalization}
                              localizationActivated={localizationActivated}
                              wizzardCreationMode={wizzardEquipmentCreationMode}
                              currentElementUUID={currentEquipmentUUID}
                              reinitialize={reinitialize}
                              mainOffset={mainOffset}
                            ></MatterportViewer>
                          )}
                          {!hasMatterportScan && (
                            <div
                              style={{
                                textAlign: 'center',
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                              }}
                            >
                              <div
                                style={{
                                  border: '1px solid grey',
                                  margin: 'auto',
                                  borderRadius: '10px',
                                  width: '90%',
                                  height: '90%',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center'
                                }}
                              >
                                <div
                                  style={{
                                    fontFamily: 'Roboto',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    fontSize: '36px',
                                    lineHeight: '42px',
                                    textAlign: 'center',

                                    color: '#929292'
                                  }}
                                >
                                  Vous ne disposez pas d’un jumeau numérique pour ce bien
                                </div>
                              </div>
                            </div>
                          )}
                        </Grid>
                        {(tags.length > 0 || !wizzardEquipmentCreationMode) && (
                          <Grid item xs={4}>
                            <RecapInfos
                              currentElementUUID={currentEquipmentUUID}
                              moreInfosDatas={moreInfosDatas}
                              validateNewElement={validateNewEquipment}
                              modifyInfos={modifyInfos}
                              modifyPhoto={modifyPhoto}
                              currentStep={currentStep}
                              modifyLocalization={modifyLocalization}
                              wizzardCreationMode={wizzardEquipmentCreationMode}
                              suppressElement={suppressEquipment}
                              hasMatterportScan={hasMatterportScan}
                              nextStepCallBack={handleNextStep}
                              elementType="equipment"
                              elementDatas={equipmentDatas}
                              mainOffset={mainOffset}
                              setWizzardEquipmentOpen={setWizzardEquipmentOpen}
                            ></RecapInfos>
                          </Grid>
                        )}
                      </Grid>
                    </div>
                  </div>
                </Grid>
              </>
            )}
          </Grid>
        </BasicModal>

        <ConfirmationDialog
          message={
            deleteEquipmentWarningMode
              ? 'Etes vous certain de vouloir supprimer définitivement cet équipement'
              : "Voulez-vous abandonner la création d'un équipement?"
          }
          onCancel={handleCloseDeleteDialog}
          onConfirm={deleteDraftEquipment}
          open={deleteDialogOpen}
          title={deleteEquipmentWarningMode ? 'Suppression' : 'Abandon'}
          variant={deleteEquipmentWarningMode ? 'error' : 'warning'}
          cancelText="Non"
          confirmText="Oui"
        />
      </div>
    </div>
  );
};

export default WizzardEquipment;
