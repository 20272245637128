import { Grid2 } from '@mui/material';
import dayjs from 'dayjs';

export const DocumentItem = ({ document, setWizzardDocumentOpen, setSelectedDocument }) => {
  return (
    <div>
      <Grid2 container>
        {document.created_at && document.created_at !== '' && (
          <Grid2 container>
            <Grid2 size={12} sx={{ pb: '10px' }}>
              <span style={{ color: '#929292' }}>
                {dayjs(document.created_at).format('DD/MM/YYYY') + ' - ' + dayjs(document.created_at).format('HH:mm')}{' '}
              </span>{' '}
              <br />
              <span
                onClick={() => {
                  setSelectedDocument(document);
                  setWizzardDocumentOpen(true);
                }}
              >
                {document.name}
              </span>{' '}
            </Grid2>
          </Grid2>
        )}
      </Grid2>
      <hr style={{ borderTop: '1px solid #EEEEEE' }} />
    </div>
  );
};

export default DocumentItem;
