import { Box, Typography } from '@mui/material';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import ApartmentIcon from '@mui/icons-material/Apartment';
import { useEffect, useState } from 'react';
import { appTheme } from '~/utils/theme';

export const ProductTitle = ({ data, buildingData }) => {
  const [isBuilding, setIsBuilding] = useState(false);

  useEffect(() => {
    if (data.isBuilding || data.uuid.includes('bui')) {
      setIsBuilding(true);
    }
  }, [data, buildingData, isBuilding]);
  return (
    <Box
      sx={{
        mt: 2,
        width: 1,
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <Box sx={{ mr: 2 }}>
        {!isBuilding ? (
          <HomeOutlinedIcon sx={{ color: appTheme.palette.primary.main, fontSize: '52px' }} />
        ) : (
          <ApartmentIcon sx={{ color: appTheme.palette.primary.main, fontSize: '52px' }}></ApartmentIcon>
        )}
      </Box>
      <Box sx={{ flexGrow: 1, mb: 1 }}>
        {isBuilding && data.address && data.address.street && (
          <Typography variant="h1" sx={{ color: appTheme.palette.primary.main, lineHeight: 1 }}>
            {data.name} - {data.address.street}, {data.address.zip_code} {data.address.city}
          </Typography>
        )}
        {!isBuilding && data.address && data.address.street && (
          <Typography variant="h1" sx={{ color: appTheme.palette.primary.main, lineHeight: 1 }}>
            {data.address.street}, {data.address.zip_code} {data.address.city}
          </Typography>
        )}

        {isBuilding ? (
          <>
            {data.address_complement && (
              <Typography variant="h5" sx={{ color: appTheme.palette.primary.main, fontWeight: 500 }}>
                {`${data.address_complement}`}
              </Typography>
            )}
          </>
        ) : (
          <>
            {buildingData && buildingData.name ? (
              <>
                {data.address_complement ? (
                  <Typography variant="h5" sx={{ color: appTheme.palette.primary.main, fontWeight: 500 }}>
                    {`${buildingData.name} - ${data.address_complement}`}
                  </Typography>
                ) : (
                  <Typography variant="h5" sx={{ color: appTheme.palette.primary.main, fontWeight: 500 }}>
                    {`${buildingData.name}`}
                  </Typography>
                )}
              </>
            ) : (
              <>
                {data.address_complement ? (
                  <Typography variant="h5" sx={{ color: appTheme.palette.primary.main, fontWeight: 500 }}>
                    {`${data.address_complement}`}
                  </Typography>
                ) : (
                  <Typography variant="h5" sx={{ color: appTheme.palette.primary.main, fontWeight: 500 }}>
                    {``}
                  </Typography>
                )}
              </>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};
