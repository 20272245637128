import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import IosShareOutlinedIcon from '@mui/icons-material/IosShareOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import { Chip, Grid, Pagination, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Box } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  frFR,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
  useGridApiRef
} from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './EventsList.scss';

const EventsList = ({
  handleModal,
  loading,
  onRowsSelectionHandler,
  rows,
  onData,
  eventIndexInList = null,
  setOpen,
  uuid_owner = null,
  onRowClick = null,
  unselect = null,
  setWizzardEventCreationMode = null,
  setWizzardEventOpen = null,
  setSelectedUUIDEvent = null,
  setSelectedProduct = null
}) => {
  const { id, idEvent } = useParams();
  const [indexInRows, setIndexInRows] = useState(null);
  const [clickedRowId, setClickedRowId] = useState(null);
  const [isLoading, setIsloading] = useState(true);

  const [selectionModel, setSelectionModel] = useState([]);

  const apiRef = useGridApiRef();

  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.up('xs'));
  const matchesSm = useMediaQuery(theme.breakpoints.up('sm'));
  const matchesMd = useMediaQuery(theme.breakpoints.up('md'));
  const matchesLg = useMediaQuery(theme.breakpoints.up('lg'));
  const matchesXl = useMediaQuery(theme.breakpoints.up('xl'));

  const CustomPagination = () => {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    if (pageCount === 1) return <div></div>;
    return (
      <Pagination
        color="primary"
        count={pageCount}
        page={page + 1}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />
    );
  };

  const columns: GridColDef[] = [
    {
      field: 'desc',
      headerName: 'Détail',
      width: 340,
      flex: 0.5,
      headerClassName: 'cibel-app-theme--header',
      renderCell: (params) => {
        return (
          <Box sx={{ pl: 2, py: 3 }}>
            <Grid style={{ display: 'flex' }}>
              <Typography
                variant="body1"
                color={params.row.urgent ? 'secondary.main' : 'primary.main'}
                sx={{ mb: 2, fontWeight: '500' }}
              >
                {params.row.diagnosticType}
              </Typography>
              {params.row.urgent && (
                <ReportProblemOutlinedIcon fontSize="small" sx={{ ml: 1, color: 'secondary.main' }} />
              )}
            </Grid>
            {!id && (
              <Typography variant="body1" sx={{ fontWeight: '500' }}>
                {params.row.address}
              </Typography>
            )}
            <Typography variant="body1">{params.row.situation}</Typography>
            <Typography variant="body1">{params.row.desc}</Typography>
          </Box>
        );
      }
    },
    {
      field: 'date',
      headerName: 'Date',
      width: 120,
      headerClassName: 'cibel-app-theme--header',
      renderCell: (params) => {
        const date = new Date(params.row.date);
        const today = new Date();
        const theDate =
          date.toLocaleDateString('fr') === today.toLocaleDateString('fr')
            ? date.toLocaleTimeString(['fr'], { timeStyle: 'short' })
            : date.toLocaleDateString('fr');
        return (
          <Box sx={{ py: 3 }}>
            <Typography variant="body1">{theDate}</Typography>
          </Box>
        );
      }
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 110,
      headerClassName: 'cibel-app-theme--header',
      renderCell: (params) => {
        const getVariant = (type: any): 'error' | 'success' | 'primary' | 'secondary' | 'warning' => {
          switch (type) {
            case 1:
              return 'success';
            case 2:
              return 'error';
            case 3:
              return 'warning';
            case 4:
              return 'success';
            case 5:
              return 'secondary';
            default:
              return 'success';
          }
        };

        const getIcon = (type: any): JSX.Element => {
          switch (type) {
            case 5:
              return <LockOutlinedIcon />;
            case 4:
              return <CheckOutlinedIcon />;
            case 3:
              return <LockOpenOutlinedIcon />;
            default:
              return <ClearOutlinedIcon />;
          }
        };

        const getStatusWording = (type: any) => {
          switch (type) {
            case 1:
              return 'A qualifier';
            case 2:
              return 'A qualifier';
            case 3:
              return 'En cours';
            case 4:
              return 'Terminé';
            case 5:
              return 'Archivé';
          }
        };

        return (
          <Box sx={{ pr: 2, py: 3 }}>
            <Chip
              size="small"
              icon={getIcon(params.row.status)}
              label={getStatusWording(params.row.status)}
              color={getVariant(params.row.status)}
              sx={{
                color: getVariant(params.row.status) + '.main',
                backgroundColor: getVariant(params.row.status) + '.light'
              }}
            />
            <Box
              justifyContent="flex-end"
              sx={{
                mt: 4,
                width: 1,
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <CreateOutlinedIcon
                onClick={(e) => {
                  e.preventDefault();

                  setSelectedProduct(params.row);
                }}
                sx={{ fontSize: '16px', color: 'primary.main', cursor: 'pointer', mr: 1 }}
              />
              <IosShareOutlinedIcon sx={{ fontSize: '16px', color: 'primary.main', cursor: 'pointer' }} />
            </Box>
          </Box>
        );
      }
    }
  ];

  useEffect(() => {
    if (idEvent === undefined) {
      setIsloading(false);
    }
    if (eventIndexInList) {
      setSelectionModel([eventIndexInList]);
    }
  }, []);

  useEffect(() => {
    if (eventIndexInList !== null && eventIndexInList !== undefined) {
      setSelectionModel([eventIndexInList]);
      const indexInRows = rows.findIndex((row) => row.id === eventIndexInList);
      setIndexInRows(indexInRows);
    }
  }, [eventIndexInList]);

  useEffect(() => {
    if (apiRef && indexInRows !== null && indexInRows !== undefined && apiRef.current) {
      apiRef.current.setPaginationModel({ page: Math.floor(indexInRows / 5), pageSize: 5 });
      apiRef.current.scrollToIndexes({ rowIndex: indexInRows, colIndex: 0 });
      setIsloading(false);
    }
  }, [indexInRows, apiRef]);

  useEffect(() => {
    if (eventIndexInList === 0) {
      setIsloading(false);
    }
    if (apiRef && eventIndexInList) {
      const indexInRows = rows.findIndex((row) => row.id === eventIndexInList);
      setIndexInRows(indexInRows);
    }
  }, [apiRef, eventIndexInList]);

  useEffect(() => {
    if (unselect && unselect[uuid_owner] === false) {
      setSelectionModel([]);
    }
  }, [unselect]);

  const testIfFooter = rows.length < 6 ? true : false;

  return (
    <Grid container sx={{ height: 'calc(100%)' }}>
      <Grid item xs={12} md={12} className="hidescrollbar">
        {rows.length > 0 ? (
          <DataGrid
            apiRef={apiRef}
            className="EventList"
            sx={{ maxHeight: `${window.innerHeight - 380}px` }}
            loading={isLoading}
            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
            hideFooterSelectedRowCount
            hideFooter={testIfFooter}
            getRowHeight={() => 'auto'}
            rows={rows}
            columns={columns}
            pageSizeOptions={[5]}
            columnVisibilityModel={{
              uuid: false,
              uuid_owner: false
            }}
            initialState={{
              sorting: {
                sortModel: [{ field: 'date', sort: 'desc' }]
              },
              pagination: { paginationModel: { page: 0, pageSize: 5 } }
            }}
            sortingOrder={['desc', 'asc']}
            checkboxSelection={false}
            disableColumnMenu={true}
            onRowSelectionModelChange={(newSelectionModel) => {
              setSelectionModel(newSelectionModel);
            }}
            rowSelectionModel={selectionModel}
            components={{
              Pagination: CustomPagination
            }}
            onCellClick={(e) => {
              if (e.field === 'desc' || e.field === 'date') {
                if (onRowClick === null) {
                  if (setOpen) setOpen(false);
                } else {
                  setClickedRowId(e.row.id);
                  onRowClick(e.row);
                }
              }
            }}
          />
        ) : null}
      </Grid>
    </Grid>
  );
};

export default EventsList;
