import { Grid2 } from '@mui/material';
import { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { IDpe, IProduct, ModalContentType } from '~/interfaces';
import EnergyClass from '~/app/components/EnergyClass/EnergyClass';
import { AppContext } from '~/utils/context/AppContext';
import _ from 'lodash';
import '~/app/pages/Product/Product.scss';

interface PropertyInfoProps {
  onOpen: (open: boolean) => void;
  onModalType: (type: ModalContentType) => void;
  data: IProduct;
  lastDpe: IDpe;
}

const PropertyInfo = ({ onOpen, onModalType, data, lastDpe }: PropertyInfoProps) => {
  type TuseParams = {
    id: string;
  };
  const { id } = useParams<TuseParams>();
  const { referencial } = useContext(AppContext);
  const [propertyKindName, setPropertyKindName] = useState('');
  const [constructionPeriode, setConstructionPeriode] = useState(null);

  useEffect(() => {
    if (referencial) {
      const kind = data.kind ? _.find(referencial['product.kind'], { id: data.kind.toString() }) : null;
      setPropertyKindName(kind ? kind.name : 'Autre');

      setConstructionPeriode(_.sortBy(referencial['building.construction_period'], ['id']));
    }
  }, [referencial, data]);

  const ProductData = data.data;

  const SurfaceDataValues = {
    logement_type: { name: 'Type de logement', type: 'indexFromReferentialArray', reference: 'surface.kind' },
    living_space: { name: 'Surface habitable', type: 'number', suffixe: 'm²' }, /// anciennement surface est devenu living_space
    carrez: { name: 'Surface Carrez', type: 'number', suffixe: 'm²' },
    boutin: { name: 'Surface Boutin', type: 'number', suffixe: 'm²' },
    ground: { name: 'Surface au sol', type: 'number', suffixe: 'm²' },
    ceiling_height: { name: 'Hauteur sous plafond', type: 'number', suffixe: 'm' },
    nb_floors: { name: "Nombre d'étages", type: 'number' },
    nb_rooms: { name: 'Nombre de pièces', type: 'number' },
    nb_stairs: { name: "Nombre d'escaliers", type: 'number' }, // staircase_number => nb_stairs
    nb_elevators: { name: "Nombre d'ascenseurs", type: 'number' } // elevators_number => nb_elevators
  };

  const heatingIndividualValues = {
    heating_energy_type: {
      name: 'Energie du chauffage individuel',
      type: 'indexFromReferential',
      reference: 'energy_heat.type'
    },
    ecs_energy_type: {
      name: "Energie de l'ECS individuel",
      type: 'indexFromReferential',
      reference: 'energy_water.type'
    },
    cooling_energy_type: {
      name: 'Energie du refroidissement individuel',
      type: 'indexFromReferential',
      reference: 'energy_cooling.type'
    }
  };

  const heatingCommunalValues = {
    heating_energy_type: {
      name: 'Energie du chauffage collectif',
      type: 'indexFromReferential',
      reference: 'energy_heat.type'
    },
    ecs_energy_type: {
      name: "Energie de l'ECS collectif",
      type: 'indexFromReferential',
      reference: 'energy_water.type'
    },
    cooling_energy_type: {
      name: 'Energie du refroidissement collectif',
      type: 'indexFromReferential',
      reference: 'energy_cooling.type'
    }
  };

  const IdentificationValues = {
    lot_number: { name: 'Numéro de lot', type: 'string' },
    immatriculation_number: { name: "Numéro d'immatriculation", type: 'string' },
    id_internal: { name: 'Référence interne', type: 'string' },
    invariant_tax: { name: 'Invariant fiscal', type: 'string' }
  };

  const ProductDataValues = {
    // dependence_type: { name: 'Type de dépendance', type: 'index', reference: 'dependence_type' },
    // furnished: { name: 'Meublé', type: 'boolean' },
    // usage: { name: 'Usage', type: 'index', reference: 'usage' },
    access_code: { name: 'Code accès immeuble', type: 'string' },
    lot_associated: { name: 'Lot(s) associé(s)', type: 'string' },
    mono_property: { name: 'Mono propriété', type: 'boolean' },
    co_property: { name: 'Co propriété', type: 'boolean' }
    // lots_number: { name: 'Nombre de lots', type: 'number' },
    // flats_number: { name: "Nombre d'appartements", type: 'number' },
    // cellar_number: { name: 'Nombre de caves', type: 'number' },
    // technical_locals: { name: 'Locaux techniques', type: 'number' },
    // various_locals: { name: 'Locaux divers', type: 'number' },
    // cycle_local: { name: 'Local à vélo', type: 'boolean' },
    // trash_local: { name: 'Local à poubelle', type: 'boolean' },
    // letter_box: { name: 'Boîte aux lettres', type: 'boolean' },
    // optical_fiber: { name: 'Fibre optique', type: 'boolean' },
    // parking: { name: 'Parking', type: 'boolean' },
    // parking_floor_number: { name: "Nombre d'étages de parking", type: 'number' },
    // parking_places_number: { name: 'Nombre de places de parking', type: 'number' },
  };

  const dpeValues = {
    // DPE
    dpe_number: { name: 'Numéro du DPE', type: 'string' },
    // energy_result: { name: 'Consommation énergétique', type: 'number', suffixe: 'kWhEP/m2.an' },
    // energy_sticker: { name: 'Etiquette énergétique', type: 'string' },
    // ges_result: { name: 'GES', type: 'number', suffixe: 'kgCO2/m2.an' },
    // ges_sticker: { name: 'Etiquette GES', type: 'string' },
    done_at: { name: 'Date établissement DPE', type: 'date' },
    expire_at: { name: 'Date expiration DPE', type: 'date' }
  };

  const convertValue = (key, object) => {
    if (object === null) {
      return null;
    }
    if (object.type === 'indexFromReferential') {
      return referencial[object.reference].find((ref) => ref.id === object.value).name;
    }

    if (object.type === 'indexFromReferentialArray') {
      return referencial[object.reference][object.value];
    }

    if (object.type === 'number') {
      if (object.value === '' || object.value === null || object.value === undefined || object.value === 0) {
        return null;
      }
      return Math.floor(object.value * 100) / 100 + ' ' + (object.suffixe ? object.suffixe : '');
    }

    if (object.type === 'date') {
      return new Date(String(object.value)).toLocaleDateString();
    }

    if (object.type === 'boolean') {
      if (object.value === true || object.value === 'true') {
        return 'Oui';
      }
      if (object.value === false || object.value === 'false') {
        return 'Non';
      }
      return object.value;
    }

    if (object.type === 'string') {
      return object.value;
    }
  };

  const SortedProductData = {};
  if (ProductData) {
    const productDataEntries = Object.entries(ProductDataValues);
    productDataEntries.map(([key, value]) => {
      if (ProductData[key] !== null && ProductData[key] !== undefined && ProductData[key] !== '') {
        if (key === 'parking_floor_number' && ProductData['parking'] !== true) {
        } else if (key === 'parking_places_number' && ProductData['parking'] !== true) {
        } else if (key === 'logement_type' && data.kind !== 1) {
        } else if (key === 'dependence_type' && data.kind !== 5) {
        } else if (key === 'furnished') {
        } else if (key === 'floor_number' && data.kind !== 7 && data.kind !== 8) {
        } else if (key === 'rooms_number' && data.kind !== 7 && data.kind !== 8) {
        } else if (key === 'usage' && data.kind !== 3) {
        } else if (value['type'] === 'number' && ProductData[key] === 0) {
        } else {
          SortedProductData[value.name] = {
            type: value.type,
            value: ProductData[key],
            suffixe: value['suffixe'],
            reference: value['reference']
          };
        }
      }
    });

    const dpeEntries = Object.entries(dpeValues);
    dpeEntries.map(([key, value]) => {
      if (lastDpe && lastDpe[key] !== null && lastDpe[key] !== undefined && lastDpe[key] !== '') {
        SortedProductData[value.name] = {
          type: value.type,
          value: lastDpe[key],
          suffixe: value['suffixe'],
          reference: value['reference']
        };
      }
    });

    const surfaceEntries = Object.entries(SurfaceDataValues);
    surfaceEntries.map(([key, value]) => {
      if (data.surface && data.surface[key] !== null && data.surface[key] !== undefined && data.surface[key] !== '') {
        SortedProductData[value.name] = {
          type: value.type,
          value: data.surface[key],
          suffixe: value['suffixe'],
          reference: value['reference']
        };
      }
    });

    const heatingIndividualEntries = Object.entries(heatingIndividualValues);
    heatingIndividualEntries.map(([key, value]) => {
      if (data.heating && data.heating.individual !== null) {
        if (
          data.heating.individual &&
          data.heating.individual[key] !== null &&
          data.heating.individual[key] !== undefined &&
          data.heating.individual[key] !== ''
        ) {
          SortedProductData[value.name] = {
            type: value.type,
            value: data.heating.individual[key],
            suffixe: value['suffixe'],
            reference: value['reference']
          };
        }
      }
    });

    const heatingCommunalEntries = Object.entries(heatingCommunalValues);
    heatingCommunalEntries.map(([key, value]) => {
      if (data.heating && data.heating.communal !== null) {
        if (
          data.heating.communal &&
          data.heating.communal[key] !== null &&
          data.heating.communal[key] !== undefined &&
          data.heating.communal[key] !== ''
        ) {
          SortedProductData[value.name] = {
            type: value.type,
            value: data.heating.communal[key],
            suffixe: value['suffixe'],
            reference: value['reference']
          };
        }
      }
    });

    const identificationEntries = Object.entries(IdentificationValues);
    identificationEntries.map(([key, value]) => {
      if (
        data.identification &&
        data.identification[key] !== null &&
        data.identification[key] !== undefined &&
        data.identification[key] !== ''
      ) {
        SortedProductData[value.name] = {
          type: value.type,
          value: data.identification[key],
          suffixe: value['suffixe'],
          reference: value['reference']
        };
      }
    });
  }

  return (
    <>
      <div className="Product_infos">
        <div className="Product__card__moreInfo">
          <Grid2 container spacing={0}>
            {lastDpe &&
              lastDpe.energy_result !== 0 &&
              lastDpe.energy_result !== null &&
              lastDpe.energy_result !== undefined && (
                <Grid2 size={12}>
                  <EnergyClass
                    energy={lastDpe && lastDpe.energy_result}
                    co2={lastDpe && lastDpe.ges_result}
                    energySticker={lastDpe && lastDpe.energy_sticker}
                    co2Sticker={lastDpe && lastDpe.ges_sticker}
                  />
                </Grid2>
              )}

            {propertyKindName !== '' && referencial['product.kind'].find((kind) => kind.id === data.kind) && (
              <Grid2 size={6} sx={{ paddingBottom: '10px' }}>
                <div>
                  <span style={{ fontSize: '12px', color: '#929292' }}>Type de bien </span>
                  <br />
                  <span>{`${data.kind ? referencial['product.kind'].find((kind) => kind.id === data.kind).name : 'Autre'}`}</span>
                </div>
              </Grid2>
            )}

            {Object.entries(SortedProductData).map(([key, object]) => (
              <Grid2 key={key} size={6} sx={{ paddingBottom: '10px' }}>
                <div className={key}>
                  <span style={{ fontSize: '12px', color: '#929292' }}>{key}</span>
                  <br />
                  <span>{convertValue(key, object)}</span>
                </div>
              </Grid2>
            ))}
          </Grid2>
        </div>
      </div>
    </>
  );
};

export default PropertyInfo;
