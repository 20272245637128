import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import { Box, Button, DialogActions, ImageList, ImageListItem } from '@mui/material';
import { useEffect, useState } from 'react';
import { api, getEquipment, getEvent, getUser } from '~/api';
import { energyClassRef, periodicityRef, referentielEventStatus } from '~/utils/references';
import '~/app/components/WizzardEquipment/WizzardEquipment';

export const RecapInfos = ({
  moreInfosDatas,
  currentElementUUID,
  validateNewElement,
  modifyBasicInfos = null,
  modifyInfos,
  modifyPhoto,
  modifyDocument = null,
  modifyLocalization,
  currentStep,
  wizzardCreationMode,
  suppressElement,
  hasMatterportScan,
  nextStepCallBack,
  elementType,
  elementDatas = null,
  mainOffset = 0,
  setWizzardEquipmentOpen = null
}) => {
  const [images, setImages] = useState<any>([]);
  const [documents, setDocuments] = useState<any>([]);
  const [eventInfos, setEventInfos] = useState<any>({});
  const [userName, setUserName] = useState<any>('');
  const { REACT_APP_BASEURL_MEDIAS } = process.env;

  const getFiles = () => {
    if (elementType === 'equipment' && currentElementUUID !== '' && currentElementUUID !== undefined) {
      api.equipments.get(currentElementUUID).then((equipment) => {
        const images = [];
        const imgsStates = [];
        const imgsTags = [];
        if (equipment?.states && equipment?.states?.length > 0)
          equipment?.states?.map((state) => {
            if (state?.images && state?.images?.length > 0) imgsStates.push(...state.images);
            if (state?.tags && state?.tags?.length > 0) {
              state?.tags?.map((tag) => {
                if (tag?.images && tag?.images?.length > 0) imgsTags.push(...tag.images);
              });
            }
          });
        if (equipment?.images && equipment?.images?.length > 0) images.push(...equipment.images);
        setImages([...images, ...imgsStates, ...imgsTags]);
      });
    } else if (
      elementType === 'event' &&
      currentElementUUID !== '' &&
      currentElementUUID !== undefined &&
      currentElementUUID !== null
    ) {
      getEvent(currentElementUUID).then((res) => {
        const imagesArr = [];
        const documentsArr = [];
        const imgsTags = [];
        res.files.forEach((file) => {
          if (file.kind === 'image') {
            imagesArr.push(file);
          } else if (file.kind === 'document') {
            documentsArr.push(file);
          }
        });
        res.tags.forEach((tag) => {
          if (tag.images && tag.images.length > 0) imgsTags.push(...tag.images);
        });

        console.log(res);

        res.equipments?.map((equipment) => {
          api.equipments.get(equipment.uuid).then((equipment) => {
            if (equipment.images && equipment.images.length > 0) imagesArr.push(...equipment.images);
            if (equipment.states && equipment.states.length > 0) {
              equipment.states.map((state) => {
                if (state.images && state.images.length > 0) imagesArr.push(...state.images);
                if (state.tags && state.tags.length > 0) {
                  state.tags.map((tag) => {
                    if (tag.images && tag.images.length > 0) imgsTags.push(...tag.images);
                  });
                }
              });
            }

            setImages([...imagesArr, ...imgsTags]);
          });
        });

        setImages([...imagesArr, ...imgsTags]);
        setDocuments(documentsArr);
        setEventInfos(res);
      });
    }
  };

  const getUserName = () => {
    getUser(eventInfos.uuid_user_creator).then((res) => {
      setUserName(res.first_name + ' ' + res.last_name);
    });
  };

  useEffect(() => {
    getFiles();
    if (eventInfos && eventInfos.uuid_user_creator) getUserName();
  }, []);

  useEffect(() => {
    getFiles();
  }, [currentStep]);

  return (
    <div
      style={{
        paddingLeft: '10px',
        height: `calc(100vh - ${mainOffset}px)`,
        overflow: 'scroll',
        width: 'calc(100% - 10px)'
      }}
    >
      {elementType === 'event' && (
        <>
          <div className="Wizzards__panel_subtitle" style={{ paddingTop: '20px' }}>
            <span>Détails du Ticket :</span>
            <div
              style={{
                float: 'right',
                display: 'inline-block',
                position: 'relative',
                right: '0px',
                paddingRight: '10px'
              }}
            >
              <CreateOutlinedIcon
                onClick={modifyBasicInfos}
                sx={{ fontSize: '16px', color: 'primary.main', cursor: 'pointer', mr: 1 }}
              ></CreateOutlinedIcon>
            </div>
          </div>
          <div
            style={{
              paddingLeft: '30px',
              paddingBottom: '10px',
              paddingRight: '10px'
            }}
          >
            {elementDatas.created_at && (
              <>
                <div className="Wizzards__infoItemTitle">Date :</div>
                <div className="Wizzards__infoItemValue">
                  {new Date(elementDatas.created_at).toLocaleDateString('fr-FR')}
                </div>
              </>
            )}
            {elementDatas.status && (
              <>
                <div className="Wizzards__infoItemTitle">Statut :</div>
                <div className="Wizzards__infoItemValue">{referentielEventStatus[elementDatas.status]}</div>
              </>
            )}
            {elementDatas.desc && (
              <>
                <div className="Wizzards__infoItemTitle">Description :</div>
                <div className="Wizzards__infoItemValue">{elementDatas.desc}</div>
              </>
            )}
            {elementDatas && elementDatas.uuid_user_creator && (
              <>
                <div className="Wizzards__infoItemTitle">Déclarant :</div>
                <div className="Wizzards__infoItemValue">{userName}</div>
              </>
            )}
          </div>
        </>
      )}

      {/* {elementType === 'event' && (
        <div className="Wizzards__panel_subtitle">
          Photos :{' '}
          <span
            style={{
              float: 'right',
              display: 'inline-block',
              position: 'relative',
              right: '0px',
              paddingRight: '10px'
            }}
          >
            <CreateOutlinedIcon
              onClick={modifyPhoto}
              sx={{ fontSize: '16px', color: 'primary.main', cursor: 'pointer', mr: 1 }}
            ></CreateOutlinedIcon>
          </span>
          <div
            style={{
              paddingLeft: '30px',
              paddingBottom: '10px',
              paddingRight: '10px'
            }}
          >
            {images && (
              <ImageList>
                {images.map((item, index) => (
                  <ImageListItem key={index}>
                    <img
                      src={`${REACT_APP_BASEURL_MEDIAS}/${item.uri}`}
                      style={{ cursor: 'pointer' }}
                      alt={item.name}
                      loading="lazy"
                      onClick={() => {
                        window.open(`${REACT_APP_BASEURL_MEDIAS}/${item.uri}`, '_blank').focus();
                      }}
                    />
                  </ImageListItem>
                ))}
              </ImageList>
            )}
          </div>
        </div>
      )} */}

      {elementType === 'event' && (
        <div className="Wizzards__panel_subtitle">
          Documents :{' '}
          <span
            style={{
              float: 'right',
              display: 'inline-block',
              position: 'relative',
              right: '0px',
              paddingRight: '10px'
            }}
          >
            <CreateOutlinedIcon
              onClick={() => {
                modifyDocument();
              }}
              sx={{ fontSize: '16px', color: 'primary.main', cursor: 'pointer', mr: 1 }}
            ></CreateOutlinedIcon>
          </span>
          <div
            style={{
              paddingLeft: '30px',
              paddingBottom: '10px',
              paddingRight: '10px'
            }}
          >
            {/* {images && ( */}
            {/* // <ImageList> */}
            {documents.map((item, index) => (
              <div
                style={{
                  fontFamily: 'Roboto',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  fontSize: '16px',
                  lineHeight: '19px',
                  color: '#062746',
                  cursor: 'pointer',
                  border: '1px solid #ccc',
                  marginBottom: '10px',
                  width: 'auto',
                  display: 'inline-block',
                  padding: '5px 10px'
                }}
                key={index}
                onClick={() => {
                  window.open(`${REACT_APP_BASEURL_MEDIAS}/${item.uri}`, '_blank').focus();
                }}
              >
                {item.name}
              </div>
            ))}
            {/* // </ImageList> */}
            {/* )} */}
          </div>
        </div>
      )}

      {elementType === 'equipment' && wizzardCreationMode && (
        <>
          <div className="Wizzards__panel_subtitle" style={{ paddingTop: '20px' }}>
            <span>Informations :</span>
            <div
              style={{
                float: 'right',
                display: 'inline-block',
                position: 'relative',
                right: '0px',
                paddingRight: '10px'
              }}
            >
              <CreateOutlinedIcon
                onClick={modifyInfos}
                sx={{ fontSize: '16px', color: 'primary.main', cursor: 'pointer', mr: 1 }}
              ></CreateOutlinedIcon>
            </div>
          </div>
          <div
            style={{
              paddingLeft: '30px',
              paddingBottom: '10px',
              paddingRight: '10px'
            }}
          >
            {moreInfosDatas?.data?.model_brand && (
              <>
                <div className="Wizzards__infoItemTitle">Marque :</div>
                <div className="Wizzards__infoItemValue">{moreInfosDatas.data.model_brand}</div>
              </>
            )}
            {moreInfosDatas?.data?.model_id && (
              <>
                <div className="Wizzards__infoItemTitle">Modèle :</div>
                <div className="Wizzards__infoItemValue">{moreInfosDatas.data.model_id}</div>
              </>
            )}
            {moreInfosDatas?.data?.model_serial_number && (
              <>
                <div className="Wizzards__infoItemTitle">Numéro de série :</div>
                <div className="Wizzards__infoItemValue">{moreInfosDatas.data.model_serial_number}</div>
              </>
            )}
            {moreInfosDatas?.energy_class !== 0 &&
              moreInfosDatas?.energy_class !== null &&
              energyClassRef[moreInfosDatas?.energy_class] !== undefined && (
                <>
                  <div className="Wizzards__infoItemTitle">Classe énergétique :</div>
                  <div className="Wizzards__infoItemValue">{energyClassRef[moreInfosDatas.energy_class]}</div>
                </>
              )}
            {moreInfosDatas?.data?.meter_pmr && (
              <>
                <div className="Wizzards__infoItemTitle">Point de livraison :</div>
                <div className="Wizzards__infoItemValue">{moreInfosDatas.data.meter_pmr}</div>
              </>
            )}
            {moreInfosDatas?.data?.tech_specs && (
              <>
                <div className="Wizzards__infoItemTitle">Caractéristiques techniques :</div>
                <div className="Wizzards__infoItemValue">{moreInfosDatas.data.tech_specs}</div>
              </>
            )}
            {moreInfosDatas?.installed_at && (
              <>
                <div className="Wizzards__infoItemTitle">Date d'installation :</div>
                <div className="Wizzards__infoItemValue">
                  {new Date(moreInfosDatas.installed_at).toLocaleDateString('fr-FR')}
                </div>
              </>
            )}
            {moreInfosDatas?.warranty_end && (
              <>
                <div className="Wizzards__infoItemTitle">Date de fin de garantie :</div>
                <div className="Wizzards__infoItemValue">
                  {new Date(moreInfosDatas.warranty_end).toLocaleDateString('fr-FR')}
                </div>
              </>
            )}
            {moreInfosDatas?.maintenance_periodicity !== 0 &&
              moreInfosDatas?.maintenance_periodicity !== null &&
              periodicityRef[moreInfosDatas?.maintenance_periodicity] !== undefined && (
                <>
                  <div className="Wizzards__infoItemTitle">Périodicité de la maintenance :</div>
                  <div className="Wizzards__infoItemValue">
                    {periodicityRef[moreInfosDatas.maintenance_periodicity]}
                  </div>
                </>
              )}
            {moreInfosDatas?.maintenance_next_date && (
              <>
                <div className="Wizzards__infoItemTitle">Prochaine intervention :</div>
                <div className="Wizzards__infoItemValue">
                  {new Date(moreInfosDatas.maintenance_next_date).toLocaleDateString('fr-FR')}
                </div>
              </>
            )}
            {moreInfosDatas?.data?.more_infos && (
              <>
                <div className="Wizzards__infoItemTitle">Informations complémentaires :</div>
                <div className="Wizzards__infoItemValue">{moreInfosDatas.data.more_infos}</div>
              </>
            )}
          </div>
        </>
      )}

      {((elementType === 'equipment' && wizzardCreationMode) || elementType === 'event') && (
        <div className="Wizzards__panel_subtitle">
          Photos :{' '}
          <span
            style={{
              float: 'right',
              display: 'inline-block',
              position: 'relative',
              right: '0px',
              paddingRight: '10px'
            }}
          >
            <CreateOutlinedIcon
              onClick={modifyPhoto}
              sx={{ fontSize: '16px', color: 'primary.main', cursor: 'pointer', mr: 1 }}
            ></CreateOutlinedIcon>
          </span>
          <div
            style={{
              paddingLeft: '30px',
              paddingBottom: '10px',
              paddingRight: '10px'
            }}
          >
            {images && (
              <ImageList>
                {images.map((item, index) => (
                  <ImageListItem key={index}>
                    <img
                      src={`${REACT_APP_BASEURL_MEDIAS}/${item.uri}`}
                      style={{ cursor: 'pointer' }}
                      alt={item.name}
                      loading="lazy"
                      onClick={() => {
                        window.open(`${REACT_APP_BASEURL_MEDIAS}/${item.uri}`, '_blank').focus();
                      }}
                    />
                  </ImageListItem>
                ))}
              </ImageList>
            )}
          </div>
        </div>
      )}

      {elementType === 'equipment' && !wizzardCreationMode && (
        <>
          <br></br>
        </>
      )}

      {hasMatterportScan && (
        <div className="Wizzards__panel_subtitle">
          Localisation :
          <span
            style={{
              float: 'right',
              display: 'inline-block',
              position: 'relative',
              right: '0px',
              paddingRight: '10px'
            }}
          >
            <CreateOutlinedIcon
              onClick={modifyLocalization}
              sx={{ fontSize: '16px', color: 'primary.main', cursor: 'pointer', mr: 1 }}
            ></CreateOutlinedIcon>
          </span>
        </div>
      )}

      <Box textAlign="center">
        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              if (!wizzardCreationMode) {
                console.log('GRRRRRR');
                validateNewElement(() => {
                  console.log('dsqdgfdfsfdfdgsfgd');
                  nextStepCallBack('localization');
                });
                // validateNewElement(nextStepCallBack('localization'));
              } else {
                nextStepCallBack('localization');
              }
            }}
          >
            Enregistrer
          </Button>
        </DialogActions>
      </Box>
      {!wizzardCreationMode && elementType === 'suppressmode' && (
        <Box textAlign="center">
          <DialogActions sx={{ justifyContent: 'center' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                suppressElement();
              }}
            >
              Supprimer
            </Button>
          </DialogActions>
        </Box>
      )}
    </div>
  );
};

export default RecapInfos;
