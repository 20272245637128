import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import Cropper from 'react-easy-crop';
import Slider from '@mui/material/Slider';
import React, { useContext } from 'react';
import { getCroppedImg } from './canvasUtils';
import Resizer from 'react-image-file-resizer';

import {
  Box,
  Button,
  Dialog,
  FormControl,
  Grid2,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography
} from '@mui/material';

import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import Link from '@mui/material/Link';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { api, createUser, getOrganization, getUser, patchAddress, patchOrganization, patchUser } from '~/api';
import { checkEmail } from '~/utils/check';
import Breadcrumb from '~/app/components/Breadcrumb/Breadcrumb';
import UserBlock from '~/app/components/UserBlock/UserBlock';
import { auth } from '~/app/components/Auth/AuthApi';
import { UploadFile } from '~/app/components/CustomDropZoneArea/CustomDropZoneArea';
import './Settings.scss';
import { Attribution } from '~/app/pages/Attribution/Attribution';
import { MuiTelInput } from 'mui-tel-input';
import { CountryCode } from 'libphonenumber-js';
import { PaymentSettings } from '~/app/components/PaymentForm/PaymentSettings';
import { MediaQueryContext } from '~/utils/context/MediaQueryProvider';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface IRoleUserProfil {
  first_name: string;
  last_name: string;
  email: string;
  phone1: string;
  phone2: string;
  kind: string;
}

interface IProfil {
  first_name: string;
  last_name: string;
  email: string;
  phone1: string;
  phone2: string;
  entreprise: string;
  legal_number: string;
  vat_number: string;
  street: string;
  city: string;
  zip_code: string;
  roleUser: IRoleUserProfil[];
}
const initData: IProfil = {
  first_name: '',
  last_name: '',
  email: '',
  phone1: '',
  phone2: '',
  entreprise: '',
  legal_number: '',
  vat_number: '',
  street: '',
  zip_code: '',
  city: '',
  roleUser: [
    {
      first_name: '',
      last_name: '',
      email: '',
      phone1: '',
      phone2: '',
      kind: 'client'
    }
  ]
};

const initNewPassword = {
  oldPass: '',
  newPass: '',
  confirmNewPass: ''
};

const initNewUser: IRoleUserProfil = {
  first_name: '',
  last_name: '',
  email: '',
  phone1: '',
  phone2: '',
  kind: 'client'
};

export const Settings = ({ setSpinnerOpen, spinnerOpen }) => {
  const [dataForm, setDataForm] = useState<IProfil>(initData);
  const [newUserDataForm, setNewUserDataForm] = useState<IRoleUserProfil>(initNewUser);
  const [addUser, setAddUser] = useState<boolean>(false);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [emailAlreadyUsed, setEmailAlreadyUsed] = useState<boolean>(false);
  const [submit, setSubmit] = useState<boolean>(false);
  const [value, setValue] = useState(0);
  const [editMode, setEditMode] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [rotation, setRotation] = useState(0);
  const [dataOrganization, setDataOrganization] = useState(null);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedFile, setCroppedFile] = useState(null);
  const [logoUri, setLogoUri] = useState(null);
  const [openLogoUpload, setOpenLogoUpload] = useState(false);
  const [countryCode, setCountryCode] = useState<CountryCode>('FR');
  const [imageAspectRatio, setImageAspectRatio] = useState(1);
  const mediaQueries = useContext(MediaQueryContext);

  const [uuids, setUuids] = useState({
    user: '',
    oragnisation_object: {
      address: {
        uuid: ''
      },
      users: []
    },
    user_object: {
      uuid: '',
      kind: ''
    }
  });

  useEffect(() => {
    if (dataOrganization) {
      setLogoUri(`${process.env.REACT_APP_BASEURL_MEDIAS}/${dataOrganization.img_logo_uri}?${Date.now()}}`);
    }
  }, [dataOrganization]);

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const showCroppedImage = async () => {
    try {
      const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels, rotation);

      croppedImage.toBlob((file) => {
        setCroppedImage(URL.createObjectURL(file));
      }, 'image/jpeg');
      const dataUrl = croppedImage.toDataURL('image/jpeg');
      const bytes =
        dataUrl.split(',')[0].indexOf('base64') >= 0
          ? atob(dataUrl.split(',')[1])
          : window.unescape(dataUrl.split(',')[1]);
      const mime = dataUrl.split(',')[0].split(':')[1].split(';')[0];
      const max = bytes.length;
      const ia = new Uint8Array(max);
      for (let i = 0; i < max; i++) {
        ia[i] = bytes.charCodeAt(i);
      }
      const newImageFileFromCanvas = new File([ia], 'logo.jpg', { type: mime });
      setCroppedFile(newImageFileFromCanvas);
    } catch (e) {
      console.error(e);
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    const claims = auth.getJWT();
    getOrganization(claims.uuid_organization).then((respOrga) => {
      setDataOrganization(respOrga);
      getUser(claims.uuid_user).then((resp) => {
        if (resp.kind === 'admin' || resp.kind === 'client-admin' || resp.kind === 'super-admin') {
          setIsAdmin(true);
        }
        const newObjects = {
          oragnisation_object: JSON.parse(JSON.stringify(respOrga)),
          user_object: JSON.parse(JSON.stringify(resp)),
          user: claims.uuid_user
        };

        setUuids(newObjects);

        const newdataForm = { ...dataForm };

        newdataForm.first_name = resp.first_name;
        newdataForm.last_name = resp.last_name;
        newdataForm.email = resp.email;
        newdataForm.phone1 = resp.phone1;
        newdataForm.phone2 = resp.phone2 ? resp.phone2 : '';
        newdataForm.entreprise = respOrga.name;
        newdataForm.legal_number = respOrga.legal_number;
        newdataForm.vat_number = respOrga.vat_number;
        newdataForm.street = respOrga.address.street;
        newdataForm.city = respOrga.address.city;
        newdataForm.zip_code = respOrga.address.zip_code;

        setDataForm(newdataForm);
      });
    });
    setSpinnerOpen(false);
  }, []);

  const handleEdition = () => {
    setEditMode(true);
  };

  const readFile = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener('load', () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  };

  const handleCancel = () => {
    setEditMode(false);
  };

  const callBackEditUser = (data) => {
    const oragnisation_object = JSON.parse(JSON.stringify(uuids.oragnisation_object));
    oragnisation_object.users.forEach((user, index) => {
      if (user.uuid === data.uuid) {
        user = data;
        oragnisation_object.users[index].first_name = data.first_name;
        oragnisation_object.users[index].last_name = data.last_name;
        oragnisation_object.users[index].email = data.email;
        oragnisation_object.users[index].phone1 = data.phone1;
        oragnisation_object.users[index].kind = data.kind;
      }
    });
    setUuids({ ...uuids, oragnisation_object: oragnisation_object });
  };

  const callBackSuppressUser = (data) => {
    const oragnisation_object = JSON.parse(JSON.stringify(uuids.oragnisation_object));
    const newUsers = [];
    oragnisation_object.users.forEach((user, index) => {
      if (user.uuid !== data.uuid) {
        newUsers.push(user);
      }
    });
    oragnisation_object.users = newUsers;
    setUuids({ ...uuids, oragnisation_object: oragnisation_object });
  };

  const handleImageUploaded = (imageDataUrl) => {
    const img = new Image();
    img.src = imageDataUrl;
    img.onload = () => {
      const aspect = img.width / img.height;
      const cropSize = aspect > 1 ? { width: img.height, height: img.height } : { width: img.width, height: img.width };
      setCrop({ x: 0, y: 0 });
      setZoom(1);
      setRotation(0);
      setImageAspectRatio(aspect);
      // setInitialCrop(cropSize);
    };
  };

  const callBackEditMode = (id) => {};

  const handleValidate = () => {
    const newDatas = JSON.parse(JSON.stringify(uuids.oragnisation_object));
    newDatas.name = dataForm.entreprise;
    newDatas.legal_number = dataForm.legal_number;
    newDatas.vat_number = dataForm.vat_number;

    const newAddressData = JSON.parse(JSON.stringify(newDatas.address));

    newAddressData.street = dataForm.street;
    newAddressData.zip_code = dataForm.zip_code;
    newAddressData.city = dataForm.city;

    const newUserData = JSON.parse(JSON.stringify(uuids.user_object));

    newUserData.first_name = dataForm.first_name;
    newUserData.last_name = dataForm.last_name;
    newUserData.phone1 = dataForm.phone1;
    newUserData.phone2 = dataForm.phone2;
    newUserData.email = dataForm.email;
    delete newUserData.email;
    delete newUserData.password;
    delete newDatas.address;
    delete newDatas.users;
    delete newDatas.products;

    if (isAdmin) {
      patchOrganization(auth.getOrganizationUUID(), newDatas).then((resp) => {
        patchAddress(uuids.oragnisation_object.address.uuid, newAddressData).then((repsAdress) => {
          patchUser(uuids.user_object.uuid, newUserData).then((repsUser) => {
            setEditMode(false);
          });
        });
      });
    } else {
      patchUser(uuids.user_object.uuid, newUserData).then((repsUser) => {
        setEditMode(false);
      });
    }
  };

  const handleNewUser = () => {
    setSpinnerOpen(true);
    setSubmit(true);
    setEmailAlreadyUsed(false);

    createUser(newUserDataForm)
      .then((resp) => {
        const oragnisation_object = JSON.parse(JSON.stringify(uuids.oragnisation_object));
        oragnisation_object.users.push(resp);
        setUuids({ ...uuids, oragnisation_object: oragnisation_object });
        setAddUser(false);
        setNewUserDataForm(initNewUser);
        setSubmit(false);
        setSpinnerOpen(false);
      })
      .catch((error) => {
        console.error('Erreur détectée :', error);

        if (error?.status === 406) {
          setEmailAlreadyUsed(true);
        } else {
          console.error('Erreur inconnue ou non gérée :', error);
        }

        setSubmit(false);
        setSpinnerOpen(false);
      });
  };

  const handleUploadLogo = () => {
    setOpenLogoUpload(true);
  };

  const suppressLogo = () => {
    api.organizations.update(auth.getOrganizationUUID(), { img_logo_uri: '' }).then((resp) => {
      setLogoUri('');
    });
  };

  const handleCancelNewUser = () => {
    setAddUser(false);
  };

  const borderColor = 'rgba(0, 0, 0, 0.08)';
  const textFieldSX = {
    input: {
      WebkitTextFillColor: 'primary.main !important',
      color: 'primary.main'
    },
    fieldset: {
      borderColor: `${borderColor} !important`
    }
  };

  const renderUsers = () => {
    const usersElements = [];

    if (uuids.oragnisation_object.users) {
      uuids.oragnisation_object.users.forEach((user, index) => {
        usersElements.push(
          <div key={index}>
            <UserBlock
              data={user}
              key={index}
              currentUser={uuids.user}
              callback={callBackEditUser}
              callBackSuppressUser={callBackSuppressUser}
              callBackEditMode={callBackEditMode}
            ></UserBlock>
          </div>
        );
      });

      return <div>{usersElements}</div>;
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    };
  }
  const handleCloseUploadDialog = () => {
    setImageSrc(null);
    setZoom(1);
    setCroppedImage(null);
    setOpenLogoUpload(false);
  };

  const validateLogoUpload = () => {
    const imgdata = new FormData();
    const resizeFile = (file) =>
      new Promise((resolve) => {
        Resizer.imageFileResizer(
          file,
          300,
          300,
          'jpeg',
          80,
          0,
          (uri) => {
            resolve(uri);
          },
          'file'
        );
      });

    resizeFile(croppedFile).then((resp: File) => {
      imgdata.append('uuid_owner', auth.getOrganizationUUID());
      imgdata.append('category', 'image');
      imgdata.append('kind', 'images');
      imgdata.append('file', resp);
      api.organizations.addLogo(auth.getOrganizationUUID(), imgdata).then((resp) => {
        setImageSrc(null);
        setZoom(1);
        setCroppedImage(null);
        setOpenLogoUpload(false);

        getOrganization(auth.getOrganizationUUID()).then((respOrga) => {
          setDataOrganization(respOrga);
        });
      });
    });
  };

  return (
    <div className="Profil">
      <div className="">
        <Box
          sx={{
            my: 2,
            width: 1,
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Typography
              variant={
                mediaQueries.matchesXl ? 'h1' : mediaQueries.matchesLg ? 'h2' : mediaQueries.matchesMd ? 'h3' : 'h4'
              }
              sx={{ color: 'primary.main' }}
            >
              Paramètres
            </Typography>
            <Breadcrumb data={[{ label: 'Accueil', href: '/' }]} last={`Paramètres`} setSpinnerOpen={setSpinnerOpen} />
          </Box>
        </Box>
        <Box
          sx={{
            mb: 2,
            width: 1,
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Link
              className="Product__link"
              onClick={() => {
                navigate(-1);
              }}
              color="primary"
            >
              <ArrowBackOutlinedIcon /> Retour
            </Link>
          </Box>
        </Box>
        <Box sx={{ paddingTop: '20px', borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Société" {...a11yProps(0)} />
            <Tab label="Utilisateurs" {...a11yProps(1)} />
            <Tab label="Attribution des biens" {...a11yProps(2)} />
            <Tab label="Facturation" {...a11yProps(3)} />
          </Tabs>
        </Box>

        {value === 0 && (
          <div>
            {isAdmin && <div className="Settings__title">Votre société</div>}
            {!isAdmin && <div className="Settings__title"></div>}
            {isAdmin && (
              <FormControl>
                <Grid2 container spacing={3}>
                  <Grid2 size={{ xs: 12, md: 6 }}>
                    <TextField
                      sx={!editMode ? textFieldSX : null}
                      disabled={!editMode}
                      label="Nom commercial"
                      fullWidth
                      value={dataForm.entreprise}
                      onChange={(e) => setDataForm({ ...dataForm, entreprise: e.target.value })}
                    />
                  </Grid2>
                  <Grid2 size={{ xs: 12, md: 3 }}>
                    <TextField
                      sx={!editMode ? textFieldSX : null}
                      disabled={!editMode}
                      label="Numéro de SIREN"
                      fullWidth
                      value={dataForm.legal_number}
                      onChange={(e) => setDataForm({ ...dataForm, legal_number: e.target.value })}
                    />
                  </Grid2>
                  <Grid2 size={{ xs: 12, md: 3 }}>
                    <TextField
                      sx={!editMode ? textFieldSX : null}
                      disabled={!editMode}
                      label="Numéro de TVA"
                      fullWidth
                      value={dataForm.vat_number}
                      onChange={(e) => setDataForm({ ...dataForm, vat_number: e.target.value })}
                    />
                  </Grid2>
                  <Grid2 size={{ xs: 12, md: 6 }}>
                    <TextField
                      sx={!editMode ? textFieldSX : null}
                      disabled={!editMode}
                      label="Adresse postale"
                      fullWidth
                      value={dataForm.street}
                      onChange={(e) => setDataForm({ ...dataForm, street: e.target.value })}
                    />
                  </Grid2>
                  <Grid2 size={{ xs: 12, md: 3 }}>
                    <TextField
                      sx={!editMode ? textFieldSX : null}
                      disabled={!editMode}
                      label="Code postal"
                      fullWidth
                      value={dataForm.zip_code}
                      onChange={(e) => setDataForm({ ...dataForm, zip_code: e.target.value })}
                    />
                  </Grid2>
                  <Grid2 size={{ xs: 12, md: 3 }}>
                    <TextField
                      sx={!editMode ? textFieldSX : null}
                      disabled={!editMode}
                      label="Ville"
                      fullWidth
                      value={dataForm.city}
                      onChange={(e) => setDataForm({ ...dataForm, city: e.target.value })}
                    />
                  </Grid2>
                  <Grid2 size={{ xs: 12, md: 3 }}>
                    {dataOrganization.img_logo_uri && dataOrganization.img_logo_uri !== '' && logoUri !== '' && (
                      <div>
                        <img src={logoUri} alt={'no-logo'} style={{ maxWidth: '400px', maxHeight: '400px' }}></img>
                      </div>
                    )}
                    {editMode &&
                      dataOrganization.img_logo_uri &&
                      dataOrganization.img_logo_uri !== '' &&
                      logoUri !== '' && (
                        <Button
                          size="small"
                          variant="text"
                          disabled={!editMode}
                          color={'primary'}
                          onClick={suppressLogo}
                        >
                          <span>Supprimer le logo</span>
                        </Button>
                      )}
                    {editMode && (
                      <Button
                        size="small"
                        variant="text"
                        disabled={!editMode}
                        color={'primary'}
                        onClick={handleUploadLogo}
                      >
                        {dataOrganization.img_logo_uri && dataOrganization.img_logo_uri !== '' && logoUri !== '' && (
                          <span>Modifier le logo</span>
                        )}
                        {(!dataOrganization.img_logo_uri || dataOrganization.img_logo_uri === '' || logoUri === '') && (
                          <span>Ajouter un logo</span>
                        )}
                      </Button>
                    )}
                  </Grid2>

                  {isAdmin && openLogoUpload && (
                    <Dialog open={openLogoUpload} onClose={handleCloseUploadDialog}>
                      {!imageSrc && (
                        <Grid2 size={12} sx={{ margin: '15px' }}>
                          <UploadFile
                            setFile={async (file) => {
                              if (file.length > 0) {
                                let imageDataUrl = await readFile(file[0]);
                                handleImageUploaded(imageDataUrl);
                                setImageSrc(imageDataUrl);
                              }
                            }}
                            text="Logo de l'entreprise"
                            filesType="logo"
                          />
                        </Grid2>
                      )}
                      {imageSrc && !croppedImage && (
                        <Grid2 container sx={{ padding: '15px' }}>
                          <Grid2 size={12}>
                            <div style={{ width: '205px', height: '300px' }}>
                              <div>
                                <Cropper
                                  image={imageSrc}
                                  crop={crop}
                                  zoom={zoom}
                                  aspect={imageAspectRatio}
                                  onCropChange={setCrop}
                                  onCropComplete={onCropComplete}
                                  onZoomChange={setZoom}
                                  style={{
                                    containerStyle: { top: '15px', left: '15px', width: '300px', height: '300px' }
                                  }}
                                />
                              </div>
                            </div>
                          </Grid2>
                          <Grid2 size={12}>
                            <div>
                              <Typography variant="overline">Zoom</Typography>
                              <Slider
                                value={zoom}
                                min={1}
                                max={3}
                                step={0.1}
                                aria-labelledby="Zoom"
                                onChange={(e, zoom) => setZoom(Number(zoom))}
                              />
                            </div>
                            <div>
                              <Button onClick={showCroppedImage} variant="contained" color="primary">
                                Visualiser
                              </Button>
                            </div>
                          </Grid2>
                        </Grid2>
                      )}
                      {croppedImage && (
                        <Grid2 container direction={'column'}>
                          <Grid2 size={{ xs: 'grow' }}>
                            <img style={{ width: '400px', padding: '15px' }} src={croppedImage} alt="" />
                          </Grid2>
                          <Grid2 container justifyContent="center" alignItems="center">
                            <Grid2 sx={{ pb: '15px', pr: '7px' }}>
                              <Button
                                onClick={() => {
                                  setCroppedImage(null);
                                }}
                                variant="outlined"
                                color="primary"
                              >
                                Annuler
                              </Button>
                            </Grid2>
                            <Grid2 sx={{ pb: '15px', pl: '7px' }}>
                              <Button
                                onClick={() => {
                                  validateLogoUpload();
                                }}
                                variant="contained"
                                color="primary"
                              >
                                Valider
                              </Button>
                            </Grid2>
                          </Grid2>
                        </Grid2>
                      )}
                    </Dialog>
                  )}
                  <Grid2 size={12}>
                    <Grid2 container spacing={3}>
                      {!editMode && (
                        <>
                          <Grid2 size={{ xs: 'grow' }}>
                            <Button variant="contained" color={'primary'} onClick={handleEdition}>
                              Edition
                            </Button>
                          </Grid2>

                          <Grid2 size={{ xs: 'grow' }}></Grid2>
                        </>
                      )}
                      {editMode && (
                        <Grid2 size={{ xs: 12, md: 6 }}>
                          <Button variant="outlined" color={'primary'} onClick={handleCancel}>
                            Annuler
                          </Button>
                          <span>&nbsp;&nbsp;&nbsp;</span>
                          <Button variant="contained" color={'primary'} onClick={handleValidate}>
                            Valider
                          </Button>
                        </Grid2>
                      )}
                    </Grid2>
                  </Grid2>
                </Grid2>
              </FormControl>
            )}
          </div>
        )}

        {value === 1 && (
          <div>
            {isAdmin && (
              <div className="Settings__row">
                <div className="Settings__title">{"Utilisateurs de l'organisation"}</div>
                <div className="Contacts__lineBlock" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    color="secondary"
                    variant="contained"
                    sx={{ color: 'white', borderRadius: '20px', paddingTop: '8px' }}
                    onClick={() => {
                      setAddUser(!addUser);
                    }}
                  >
                    <AddOutlinedIcon fontSize="small" />
                    <span style={{ fontWeight: 'bold', fontSize: '15px' }}>&nbsp; Ajouter un utilisateur</span>
                  </Button>
                </div>
              </div>
            )}
            {addUser && (
              <Grid2 container spacing={3}>
                <Grid2 size={{ xs: 12, md: 6 }}>
                  <TextField
                    label="Prénom"
                    fullWidth
                    value={newUserDataForm.first_name}
                    onChange={(e) => setNewUserDataForm({ ...newUserDataForm, first_name: e.target.value })}
                  />
                </Grid2>
                <Grid2 size={{ xs: 12, md: 6 }}>
                  <TextField
                    label="Nom"
                    fullWidth
                    value={newUserDataForm.last_name}
                    onChange={(e) => setNewUserDataForm({ ...newUserDataForm, last_name: e.target.value })}
                  />
                </Grid2>
                <Grid2 size={{ xs: 12, md: 4 }}>
                  <MuiTelInput
                    label="Numéro de téléphone"
                    fullWidth
                    value={newUserDataForm.phone1}
                    onChange={(value, info) => setNewUserDataForm({ ...newUserDataForm, phone1: info.numberValue })}
                    defaultCountry={countryCode}
                    forceCallingCode
                    continents={['EU']}
                  />
                </Grid2>
                <Grid2 size={{ xs: 12, md: 4 }}>
                  <TextField
                    label="Adresse email"
                    fullWidth
                    error={(submit && !checkEmail(dataForm.email)) || emailAlreadyUsed}
                    value={newUserDataForm.email}
                    type={'email'}
                    onChange={(e) => setNewUserDataForm({ ...newUserDataForm, email: e.target.value })}
                    helperText={
                      emailAlreadyUsed
                        ? 'Email déjà utilisé'
                        : submit
                          ? checkEmail(newUserDataForm.email)
                            ? ''
                            : 'Adresse email non valide'
                          : ''
                    }
                  />
                </Grid2>
                <Grid2 size={{ xs: 12, md: 4 }}>
                  <FormControl fullWidth>
                    <InputLabel id="userType">Type d&apos;utilisateur</InputLabel>
                    <Select
                      labelId="userType"
                      label="Type d'utilisateur"
                      value={newUserDataForm.kind}
                      onChange={(e) => setNewUserDataForm({ ...newUserDataForm, kind: e.target.value })}
                    >
                      <MenuItem value={'client-admin'}>Administrateur</MenuItem>
                      <MenuItem value={'client'}>Utilisateur</MenuItem>
                    </Select>
                  </FormControl>
                </Grid2>
                <Grid2 size={{ xs: 12, md: 6 }}>
                  <Button variant="outlined" color={'primary'} onClick={handleCancelNewUser}>
                    Annuler
                  </Button>
                  <span>&nbsp;&nbsp;&nbsp;</span>
                  <Button variant="contained" color={'primary'} onClick={handleNewUser}>
                    Valider
                  </Button>
                </Grid2>
              </Grid2>
            )}
            {isAdmin && renderUsers()}
          </div>
        )}

        {value === 2 && (
          <div>
            <Attribution setSpinnerOpen={setSpinnerOpen} spinnerOpen={spinnerOpen} />
          </div>
        )}

        {value === 3 && (
          <div>
            <PaymentSettings setSpinnerOpen={setSpinnerOpen} spinnerOpen={spinnerOpen} />
          </div>
        )}

        {isAdmin && <div className="Settings__title"></div>}
      </div>
    </div>
  );
};

export default Settings;
