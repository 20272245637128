import { Button, Grid2, Paper, Typography, Box, IconButton, TextField } from '@mui/material';
import Divider from '@mui/material/Divider';
import { useEffect, useState, useRef } from 'react';
import { api } from '~/api';
import '../EditBuildingInfos/EditPropertyInfos.scss';
import { EditOutlined } from '@mui/icons-material';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { ProductTitle } from '../ProductTitle/ProductTitle';

export const EditModels = ({ propertyData }) => {
  const headerRef = useRef(null);
  const footerRef = useRef(null);

  useEffect(() => {
    if (headerRef.current && footerRef.current) {
      setContentHeight(window.innerHeight - headerRef.current.offsetHeight - footerRef.current.offsetHeight - 60);
    }
  }, []);

  const [contentHeight, setContentHeight] = useState(0);
  const [modelsList, setModelsList] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);
  const [newModelNames, setNewModelNames] = useState({});
  const [addNewModel, setAddNewModel] = useState(false);

  const [idModel, setIdModel] = useState('');
  const [nameModel, setNameModel] = useState('');
  const [inCreation, setInCreation] = useState(false);

  const handleIsEditing = (index, name) => {
    setEditingIndex(index);
    setNewModelNames((prev) => ({ ...prev, [index]: name }));
  };

  const handleNewNameModel = (index, value) => {
    setNewModelNames((prev) => ({ ...prev, [index]: value }));
  };

  const handleSave = (index, uuid) => {
    setEditingIndex(null);
    patchModelName(uuid, newModelNames[index], index);
  };

  const handleAddNewModel = () => {
    setAddNewModel(!addNewModel);
    setIdModel('');
    setNameModel('');
    setEditingIndex(null);
  };

  const handleIdModel = (e) => {
    setIdModel(e.target.value);
  };

  const handleNameModel = (e) => {
    setNameModel(e.target.value);
  };

  const patchModelName = async (uuid, name, index) => {
    try {
      const response = await api.matterport.update(uuid, { name: name });
      setModelsList((prev) => {
        const newList = [...prev];
        newList[index] = response;
        return newList;
      });
    } catch (error) {
      console.error('error', error);
      return error;
    }
  };

  const createNewModel = async (idOwner, idModel, nameModel) => {
    setInCreation(true);
    try {
      const response = await api.matterport.create({ uuid_owner: idOwner, model_id: idModel, name: nameModel });
      setModelsList((prev) => [...prev, response]);
      setAddNewModel(false);
      setIdModel('');
      setNameModel('');
      setInCreation(false);
    } catch (error) {
      console.error('error', error);
      setInCreation(false);
    }
  };

  const getModels = async (idOwner) => {
    try {
      const response = await api.product.matterport.getProductMatterportScans(idOwner);
      setModelsList(response);
    } catch (error) {
      console.error('error', error);
    }
  };

  useEffect(() => {
    getModels(propertyData?.uuid);
  }, [propertyData]);

  const deleteModel = async (uuid) => {
    try {
      await api.matterport.delete(uuid);
      setModelsList((prev) => prev.filter((item) => item.uuid !== uuid));
    } catch (error) {
      console.error('error', error);
      return error;
    }
  };

  return (
    <Grid2
      container
      spacing={1}
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      alignContent="stretch"
      wrap="wrap"
    >
      {/* HEADER */}

      <Grid2 size={12} ref={headerRef}>
        <ProductTitle data={propertyData} />
        <Divider sx={{ mb: 5 }} />
      </Grid2>

      <Grid2
        size={12}
        style={{
          maxHeight: contentHeight + 'px',
          overflow: 'auto',
          paddingBottom: '10px',
          paddingTop: '10px',
          paddingRight: '10px'
        }}
      >
        <Grid2 container spacing={5} justifyContent="flex-start">
          <Grid2 size={12}>
            <Paper elevation={2} sx={{ p: 2, position: 'relative' }}>
              <Typography variant="h5" sx={{ pb: 2 }}>
                Gestion des Modèles 3D du bien
              </Typography>
              {addNewModel ? (
                <Grid2
                  container
                  alignItems="center"
                  sx={{
                    p: 1,
                    borderBottom: '1px solid #ddd',
                    backgroundColor: 'transparent',
                    position: 'relative'
                  }}
                >
                  <Grid2 size={{ xs: 12, sm: 5 }}>
                    <TextField
                      label="Nom du modèle"
                      variant="outlined"
                      fullWidth
                      size="small"
                      value={nameModel}
                      onChange={handleNameModel}
                    />
                  </Grid2>
                  <Grid2 size={{ xs: 12, sm: 12 }} sx={{ mb: 2 }} />
                  <Grid2 size={{ xs: 12, sm: 5 }}>
                    <TextField
                      label="ID Modèle Matterport"
                      variant="outlined"
                      fullWidth
                      size="small"
                      value={idModel}
                      onChange={handleIdModel}
                    />
                  </Grid2>
                </Grid2>
              ) : (
                <>
                  {modelsList.map((item, index) => (
                    <Grid2
                      key={index}
                      container
                      alignItems="center"
                      sx={{
                        p: 1,
                        borderBottom: '1px solid #ddd',
                        backgroundColor: 'transparent',
                        position: 'relative'
                      }}
                    >
                      <Grid2 size={{ xs: 12, sm: 10 }}>
                        {editingIndex === index ? (
                          <TextField
                            label="Nom du modèle"
                            variant="outlined"
                            fullWidth
                            value={newModelNames[index] || ''}
                            onChange={(e) => handleNewNameModel(index, e.target.value)}
                            size="small"
                            sx={{ mb: 1 }}
                          />
                        ) : (
                          <Typography variant="h6" color="primary">
                            {item?.name !== '' ? item.name : 'Modèle 3D'}
                          </Typography>
                        )}
                        <Typography variant="body2" color="primary">
                          ID Modèle : {item?.model_id}
                        </Typography>

                        <Typography variant="body2" color="primary">
                          Création le{' '}
                          {new Date(item?.scanned_at).toLocaleDateString('fr-FR', {
                            day: 'numeric',
                            month: 'long',
                            year: 'numeric'
                          })}
                        </Typography>
                      </Grid2>

                      <Grid2 size={{ xs: 12, sm: 2 }}>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                          {editingIndex === index ? (
                            <>
                              <Button size="small" color="primary" onClick={() => handleSave(index, item?.uuid)}>
                                ✔
                              </Button>
                              <Button size="small" color="secondary" onClick={() => setEditingIndex(null)}>
                                ✖
                              </Button>
                            </>
                          ) : (
                            <IconButton size="small" color="primary" onClick={() => handleIsEditing(index, item.name)}>
                              <EditOutlined />
                            </IconButton>
                          )}
                        </Box>
                      </Grid2>
                    </Grid2>
                  ))}
                </>
              )}
            </Paper>
          </Grid2>
        </Grid2>
      </Grid2>

      {/* FOOTER */}

      <Grid2 ref={footerRef} size={12} sx={{ mt: '15px' }}>
        <Grid2 container spacing={2} justifyContent={'center'} alignItems={'center'}>
          {addNewModel ? (
            <>
              <Grid2>
                <Button variant="outlined" onClick={handleAddNewModel}>
                  Annuler
                </Button>
              </Grid2>
              <Grid2>
                <Button
                  variant="contained"
                  onClick={() => {
                    createNewModel(propertyData?.uuid, idModel, nameModel);
                  }}
                  disabled={!idModel || !nameModel || inCreation}
                >
                  Enregistrer
                </Button>
              </Grid2>
            </>
          ) : (
            <Button
              sx={{ color: 'primary', borderRadius: '20px', borderColor: 'primary.main', minWidth: 0 }}
              variant="outlined"
              onClick={handleAddNewModel}
            >
              <AddOutlinedIcon fontSize="small" /> Ajouter un nouveau modèle 3D
            </Button>
          )}
        </Grid2>
      </Grid2>
    </Grid2>
  );
};

export default EditModels;
