import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import LockIcon from '@mui/icons-material/Lock';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { CircularProgress } from '@mui/material';
import { grey } from '@mui/material/colors';
import { GridColDef } from '@mui/x-data-grid';
import { ReactNode, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getProductImages, deleteEvent, getProductContacts, getProduct } from '~/api';
import { IProduct, ModalContentType } from '~/interfaces';
import BasicModal from '~/app/components/BasicModal/BasicModal';
import Breadcrumb from '~/app/components/Breadcrumb/Breadcrumb';
import Tag from '~/app/components/Tag/Tag';
import { View3d } from '~/app/components/View3d/View3d';
import './Share.scss';

export const Share = ({ setSpinnerOpen, spinnerOpen }) => {
  type TuseParams = {
    id: string;
  };
  const { id } = useParams<TuseParams>();
  const [data, setData] = useState<null | IProduct>(null);
  const [eventId, setEventId] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState({ isError: false, error: {} });
  const [modalType, setModalType] = useState<ModalContentType>();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [rows, setRows] = useState([]);
  const [images, setImages] = useState([]);
  const [headerImage, setHeaderImage] = useState([]);

  const handleModal = (type: ModalContentType) => {
    setModalOpen(!modalOpen);
    setModalType(type);
  };

  const handleCancelSuppressEvent = () => {
    setModalOpen(false);
  };

  const editEvent = (e) => {
    setEventId(e.currentTarget.parentNode.dataset.uuid_event);
    handleModal('Events');
  };

  const supressEvent = (e) => {
    setEventId(e.currentTarget.parentNode.dataset.uuid_event);
    handleModal('Suppress');
  };

  const columns: GridColDef[] = [
    {
      field: 'status',
      headerName: 'Status',
      width: 115,
      renderCell: (params) => {
        const getVariant = (type: any): 'danger' | 'success' | 'primary' | 'warning' => {
          switch (type) {
            case 1:
              return 'success';
            case 2:
              return 'success';
            case 3:
              return 'primary';
            default:
              return 'warning';
          }
        };

        const getIcon = (type: any): ReactNode => {
          switch (type) {
            case 'À qualifier':
              return <CloseIcon />;
            case 'Terminé':
              return <CheckIcon />;

            default:
              return <LockIcon />;
          }
        };

        const getStatusWording = (type: any) => {
          switch (type) {
            case 1:
              return 'A qualifier';
            case 2:
              return 'A qualifier';
            case 3:
              return 'En cours';
            case 4:
              return 'Terminé';
          }
        };

        return (
          <Tag variant={getVariant(params.row.status)} icon={getIcon(params.row.status)}>
            {getStatusWording(params.value)}
          </Tag>
        );
      }
    },
    { field: 'title', headerName: 'Type', width: 170 },
    { field: 'date', headerName: 'Date', width: 170 },
    { field: 'desc', headerName: 'Description', width: 340, flex: 0.5 },
    {
      field: 'action',
      headerName: ' ',
      sortable: false,
      width: 50,
      renderCell: (params) => {
        return (
          <div className="Events__action" data-uuid_event={params.row.uuid}>
            <a onClick={editEvent}>
              <EditIcon />
            </a>
            <a onClick={supressEvent}>
              <DeleteIcon></DeleteIcon>
            </a>
          </div>
        );
      }
    }
  ];

  const setRowsData = (events) => {
    setRows([]);
    events.reverse().forEach((event, index: number) => {
      const date = new Date(event.created_at);
      setRows((rows) => [
        ...rows,
        {
          id: index,
          diagnosticType: event.kind,
          status: event.status,
          title: event.title,
          date: `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`,
          desc: event.desc,
          action: 'action',
          uuid: event.uuid
        }
      ]);
    });
  };

  useEffect(() => {
    getProduct(id)
      .then((res) => {
        if (res.events && res.events.length > 0) {
          setRowsData(res.events.reverse());
          // suppress event
          res.events.forEach((ev, index) => {});
        }

        setData(res);

        getProductContacts(res.uuid)
          .then((contacts) => {})
          .catch((err) => {});
        setIsLoading(true);
      })
      .catch((error) => {
        setError({ isError: true, error });
        setIsLoading(false);
      });
  }, [id]);

  useEffect(() => {
    if (data !== null && data.events && data.events.length > 0) {
      setRowsData(data.events);
    }
  }, [data]);

  // initial images
  useEffect(() => {
    getProductImages(id)
      .then((images) => {
        setImages(images);
        const dollviews = images.filter((image) => {
          return image.name === 'Dollhouse View';
        });
        setHeaderImage(dollviews);
      })
      .catch((err) => {});
  }, [id]);

  const handleSuppressEvent = () => {
    deleteEvent(eventId).then(() => {
      const newEventsArr = JSON.parse(JSON.stringify(data.events));
      data.events.forEach((event, index) => {
        if (event.uuid === eventId) {
          newEventsArr.splice(index, 1);
        }
      });

      const newDatas = JSON.parse(JSON.stringify(data));
      newDatas.events = newEventsArr.reverse();

      setData(newDatas);
      setModalOpen(!modalOpen);
    });
  };

  if (isLoading) return <CircularProgress />;

  if (error.isError) return <p>Une erreur est survenue</p>;

  const handleContentModal = (type: ModalContentType): ReactNode => {
    switch (type) {
      case 'View3d':
        return (
          <div>
            <View3d mode="viewer" productDatas={data} />
          </div>
        );
    }
  };

  const modalClosed = (str: string) => {};

  return (
    <div className="Product">
      <div>
        <BasicModal
          modalOpen={modalOpen}
          onModalOpen={(open: boolean) => setModalOpen(open)}
          onModalClose={modalClosed}
        >
          {handleContentModal(modalType)}
        </BasicModal>
      </div>
      <div>
        <h1>{data.name}</h1>
        <div className="Product__lineBlock">
          {data.address && data.address.street && (
            <Breadcrumb data={[]} last={`${data.address.street}, ${data.address.city}, ${data.address.country}`} />
          )}
        </div>
        <div className="Product__sheet">
          <div className="Product__grid">
            <div
              className="left"
              onClick={() => handleModal('View3d')}
              {...(headerImage[0] && {
                style: { backgroundImage: `url(${process.env.REACT_APP_BASEURL_MEDIAS}/${headerImage[0].uri})` }
              })}
            >
              <PlayCircleIcon fontSize="large" sx={{ color: grey[50] }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Share;
