import { axiosDelete, axiosGet, axiosPatch, axiosPost, axiosPostMultipart } from '~/api/axiosClient';
import { IFile } from './file.models';

export const getAllFiles = () => {
  return axiosGet(`/files`);
};

export const createFile = (data) => {
  return axiosPostMultipart('/files', data);
};

export const getFile = (idFile: string) => {
  return axiosGet(`/files/${idFile}`);
};

export const updateFile = (idFile: string, data: any): Promise<IFile> => {
  return axiosPatch(`/files/${idFile}`, data);
};

export const deleteFile = (idFile: string) => {
  return axiosDelete(`/files/${idFile}`);
};

export const zipFiles = (idsOfFiles: string[]) => {
  return axiosPost('/files-to-zip', idsOfFiles);
};

export const createFileContactLink = (idFile: string, idContact: string) => {
  return axiosPost(`/files/${idFile}/contacts/${idContact}`, null);
};

export const deleteFileContactLink = (idFile: string, idContact: string) => {
  return axiosDelete(`/files/${idFile}/contacts/${idContact}`);
};

export const getFileContacts = (idFile: string) => {
  return axiosGet(`/files/${idFile}/contacts`);
};

export const createFileEquipmentLink = (idFile: string, idEquipment: string) => {
  return axiosPost(`/files/${idFile}/equipments/${idEquipment}`, null);
};

export const deleteFileEquipmentLink = (idFile: string, idEquipment: string) => {
  return axiosDelete(`/files/${idFile}/equipments/${idEquipment}`);
};

export const getFileEquipments = (idFile: string) => {
  return axiosGet(`/files/${idFile}/equipments`);
};
