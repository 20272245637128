import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { LoginForm } from './LoginForm';
import '../Auth.scss';

export const Login = ({ setSpinnerOpen, spinnerOpen }) => {
  // navigation.
  const navigate = useNavigate();

  return (
    <Box className="Auth" sx={{ mt: 10 }}>
      <div className="Auth__header">
        <Box sx={{ mt: 4, mb: 4 }}>
          <img src="/logo3D.svg" height="50" alt="logo" />
        </Box>
        <Typography variant="h5" color={'primary'} sx={{ mb: 3 }}>
          Se connecter
        </Typography>
      </div>
      <LoginForm setSpinnerOpen={setSpinnerOpen} spinnerOpen={spinnerOpen} />
      <Stack className="Auth__hr">
        <Divider sx={{ my: 2 }}>ou</Divider>
        <Button
          variant="outlined"
          color={'primary'}
          sx={{ mb: 2, px: '32px', py: '12px' }}
          onClick={() => {
            setSpinnerOpen(true);
            navigate('/register');
          }}
        >
          Inscription
        </Button>
      </Stack>
    </Box>
  );
};

export default Login;
