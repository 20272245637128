import { Card, CardHeader, CardContent, Box, Typography } from '@mui/material';

export const ListStats = ({ title, stats }) => {
  return (
    <Card sx={{ height: 298, maxHeight: 298, borderRadius: '10px' }}>
      <CardHeader title={title} />
      <CardContent>
        <Box display="flex" flexWrap="wrap" flexDirection={'column'} justifyContent="space-around">
          {Object.entries(stats).map(([key, value], index) => (
            <Box key={index} display="flex" alignItems="center" sx={{ justifyContent: 'center', gap: '1vw' }}>
              <Typography variant="body2">
                {parseFloat(value as string)
                  .toFixed(0)
                  .replace(/^0+/, '')}
                {'%'}
              </Typography>
              <Typography variant="h6">{key}</Typography>
            </Box>
          ))}
        </Box>
      </CardContent>
    </Card>
  );
};
