import {
  CheckUser,
  createUser,
  patchUser,
  getUsers,
  getUser,
  deleteUser,
  loginUser,
  forgotPassword,
  registerAccount,
  assignProductToUser,
  unAssignProductToUser,
  updatePassword,
  refreshToken,
  deleteAccount,
  unregister
} from './user';
import { getAllContacts, createContact, getContact, updateContact, deleteContact } from '~/api/contact';
import { getEquipment, patchEquipment, deleteEquipment, addEquipment, getEquipmentImages } from '~/api/equipment';
import {
  addEvent,
  getEvent,
  getEvents,
  patchEvent,
  deleteEvent,
  postMessage,
  deleteMessage,
  assignEquipmentToEvent,
  unassignEquipmentToEvent
} from '~/api/event';

import {
  createFile,
  updateFile,
  deleteFile,
  getFile,
  getAllFiles,
  zipFiles,
  createFileContactLink,
  deleteFileContactLink,
  getFileContacts,
  createFileEquipmentLink,
  deleteFileEquipmentLink,
  getFileEquipments
} from '~/api/file';

import {
  getOrganizations,
  getOrganization,
  deleteOrganization,
  patchOrganization,
  addOrganization,
  getContactsOrganization,
  addLogoOrganization
} from '~/api/organization';

import {
  getProducts,
  getProduct,
  getProductShareLink,
  deleteProduct,
  patchProduct,
  addProduct,
  getProductImages,
  getProductMatterportImages,
  getDocuments,
  getProductEquipments,
  getProductContacts,
  createProductContact,
  assignContact,
  unassignContact,
  setThumbnail,
  createProductContactLink,
  deleteProductContactLink,
  getProductMatterportScans
} from '~/api/product';

import { generateLink, generateQrCode } from '~/api/link';

import { getPageTickets, getPageTicketsDetail } from '~/api/pages';

import { addTag, getTag, patchTag, deleteTag } from '~/api/tag';

import {
  addAddress,
  getAddress,
  patchAddress,
  deleteAddress,
  getAllAddress,
  getBuildingsSameAdress
} from '~/api/address';

import { getDpe, getDpeList, assignDpe } from '~/api/ademe';

import {
  addMatterportScan,
  getMatterportScan,
  patchMatterportScan,
  deleteMatterportScan,
  getMatterportStats
} from '~/api/matterportScan';

import { referencial, equipmentreferencial } from '~/api/referencial';

import {
  createMissionOrder,
  searchMissionOrder,
  getTechnicians,
  getPhotographs,
  assignMissionOrder,
  updateMissionOrder,
  odmLink,
  scanAppointmentDate,
  deleteMissionOrder
} from '~/api/missionOrder';

import { sendMail } from './mails';

import {
  createPaymentMethod,
  subscribePlan,
  getBillings,
  getPaymentsMethodes,
  deletePaymentsMethodes,
  setPaymentMethodeDefault,
  getPlan
} from './payment';

import { productsNumber, productsDpe, productsKind, usersEvents, allStats } from './stats';

import { createLease, deleteLease, getLeases, updateLease } from './lease';

import { createLot, updateLot, deleteLot, getLot } from './lot';

export const api = {
  ademe: { get: getDpe, getList: getDpeList, assign: assignDpe },
  address: {
    create: addAddress,
    update: patchAddress,
    get: getAddress,
    delete: deleteAddress,
    search: getAllAddress,
    buildingsProducts: getBuildingsSameAdress
  },
  contacts: {
    // crud
    create: createContact,
    update: updateContact,
    get: getContact,
    delete: deleteContact,
    search: getAllContacts
  },
  equipments: {
    // crud
    create: addEquipment,
    update: patchEquipment,
    get: getEquipment,
    delete: deleteEquipment,
    getImages: getEquipmentImages
    //search: ?
  },
  events: {
    // crud
    create: addEvent,
    update: patchEvent,
    get: getEvent,
    delete: deleteEvent,
    search: getEvents,
    // message
    message: {
      create: postMessage,
      delete: deleteMessage
    },
    // equipment
    equipment: {
      assign: assignEquipmentToEvent,
      unassign: unassignEquipmentToEvent
    }
  },
  files: {
    // crud
    create: createFile,
    update: updateFile,
    get: getFile,
    delete: deleteFile,
    search: getAllFiles,
    // other
    zip: zipFiles,
    contactLink: {
      create: createFileContactLink,
      delete: deleteFileContactLink,
      get: getFileContacts
    },
    equipmentLink: {
      create: createFileEquipmentLink,
      delete: deleteFileEquipmentLink,
      get: getFileEquipments
    }
  },
  lot: {
    create: createLot,
    update: updateLot,
    get: getLot,
    delete: deleteLot
  },
  link: {
    // crud
    create: generateLink,
    createQrCode: generateQrCode
  },
  matterport: {
    create: addMatterportScan,
    update: patchMatterportScan,
    get: getMatterportScan,
    delete: deleteMatterportScan,
    stats: getMatterportStats
  },
  organizations: {
    // crud
    create: addOrganization,
    update: patchOrganization,
    get: getOrganization,
    delete: deleteOrganization,
    search: getOrganizations,
    addLogo: addLogoOrganization,
    contact: {
      get: getContactsOrganization
    }
  },
  pages: {
    ticket: {
      get: getPageTickets,
      getDetail: getPageTicketsDetail
    }
  },
  product: {
    create: addProduct,
    update: patchProduct,
    get: getProduct,
    delete: deleteProduct,
    search: getProducts,
    getImages: getProductImages,
    getDocuments: getDocuments,
    getEquipments: getProductEquipments,
    getContacts: createProductContact,
    contact: {
      create: createProductContact,
      get: getProductContacts,
      assign: assignContact, // deprecated
      unassign: unassignContact, // deprecated
      linkTo: createProductContactLink,
      unlinkTo: deleteProductContactLink
    },
    equipments: {
      get: getProductEquipments
    },
    // files
    files: {
      getImages: getProductImages,
      getDocuments: getDocuments,
      setThumbnail: setThumbnail
    },
    // matterport
    matterport: {
      getImages: getProductMatterportImages,
      getProductMatterportScans: getProductMatterportScans
    },
    link: {
      // crud
      getShareLink: getProductShareLink
    }
  },
  referencial: {
    get: referencial,
    equipments: {
      get: equipmentreferencial
    }
  },
  tags: {
    create: addTag,
    get: getTag,
    update: patchTag,
    delete: deleteTag
  },
  users: {
    // crud
    create: createUser,
    update: patchUser,
    get: getUser,
    delete: deleteUser,
    search: getUsers,
    deleteAccount: deleteAccount,
    unregister: unregister,
    // product
    product: {
      assign: assignProductToUser,
      unAssign: unAssignProductToUser
    },
    //other
    login: loginUser,
    refresh: refreshToken,
    updatePassword: updatePassword,
    forgotPassword: forgotPassword,
    registerAccount: registerAccount,
    check: CheckUser
  },
  missionOrders: {
    create: createMissionOrder,
    update: updateMissionOrder,
    search: searchMissionOrder,
    getTechnicians: getTechnicians,
    getPhotographs: getPhotographs,
    assign: assignMissionOrder,
    odmLink: odmLink,
    scanAppointmentDate: scanAppointmentDate,
    delete: deleteMissionOrder
  },
  mails: {
    send: sendMail
  },

  payment: {
    createPaymentMethod: createPaymentMethod,
    subscribe: subscribePlan,
    getBillings: getBillings,
    getPaymentsMethodes: getPaymentsMethodes,
    deletePaymentsMethodes: deletePaymentsMethodes,
    setDefault: setPaymentMethodeDefault,
    getPlan: getPlan
  },

  stats: {
    productsNumber: productsNumber,
    productsDpe: productsDpe,
    productsKind: productsKind,
    usersEvents: usersEvents,
    allStats: allStats
  },

  lease: {
    create: createLease,
    update: updateLease,
    get: getLeases,
    delete: deleteLease
  }
};
