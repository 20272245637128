import { Grid } from '@mui/material';
import './StepsViewer.scss';
import { Step } from './Step';

export const StepsViewer = ({ steps, currentStep }) => {
  return (
    <Grid container>
      {steps.map((step, index) => {
        return (
          <Step
            key={index}
            title={step}
            index={index}
            status={index === currentStep ? 'current' : index < currentStep ? 'done' : 'todo'}
          />
        );
      })}
    </Grid>
  );
};
