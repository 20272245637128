// import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import { Box, Button, Typography } from '@mui/material';
import Resizer from 'react-image-file-resizer';
import { DropzoneArea } from 'react-mui-dropzone';
import { DropZoneAreaIcon, NoIcon } from './CustomDropZoneAreaIcons';

import './CustomDropZoneArea.scss';

export const UploadFile = ({ setFile, text = null, filesType = 'images' }) => {
  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        3000,
        3000,
        'JPEG',
        80,
        0,
        (uri) => {
          resolve(uri);
        },
        'file'
      );
    });

  const handleChangeStatus = async (files) => {
    if (filesType === 'images') {
      if (files.length === 0) return;
      const imagesResized = [];
      files.forEach(async (f) => {
        imagesResized.push(resizeFile(f));
      });
      Promise.all(imagesResized).then((values) => {
        setFile(values);
      });
    } else {
      setFile(files);
    }
  };

  const getText = () => {
    if (filesType === 'images') {
      return 'Sélectionner les photos à importer';
    } else if (filesType === 'documents') {
      return 'Sélectionner les documents à importer';
    } else if (filesType === 'logo') {
      return 'Sélectionner le logo à importer';
    }
  };

  return (
    <DropzoneArea
      maxFileSize={20000000}
      onChange={handleChangeStatus}
      acceptedFiles={filesType === 'images' || filesType === 'logo' ? ['image/*'] : ['image/*', 'application/pdf']}
      dropzoneText={
        <div style={{ width: '100%', padding: '0px' }}>
          <Typography className={'textDropZoneArea'}>{text}</Typography>
          <DropZoneAreaIcon></DropZoneAreaIcon>
          <Box mt={1} sx={{ width: '100%', padding: '0px' }}>
            <Button color="primary" variant="contained">
              {getText()}
            </Button>
          </Box>
        </div>
      }
      Icon={NoIcon}
      getFileLimitExceedMessage={(fileName) => {
        return `Vous ne pouvez ajouter que ${fileName} fichiers.`;
      }}
      getDropRejectMessage={(file, acceptedFiles) => {
        return `Le fichier ${file.name} est trop volumineux.`;
      }}
      getFileRemovedMessage={(file) => {
        return `Le fichier ${file} a été retiré.`;
      }}
      getFileAddedMessage={(file) => {
        return `Le fichier ${file} a été ajouté.`;
      }}
      showPreviewsInDropzone={true}
      filesLimit={filesType === 'logo' ? 1 : 3}
    ></DropzoneArea>
  );
};
