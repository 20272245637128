import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
export const CreateProductValidation = ({ productDatas, currentStep, productBuildingUuid = null }) => {
  const navigate = useNavigate();
  return (
    <Grid container spacing={1} direction="column" justifyContent="center" alignItems="center" wrap="wrap">
      <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
        <div className="Wizzards__contentTitle Wizzards__center" style={{ width: '100%' }}>
          <svg width="90" height="90" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M45 90C20.1472 90 0 69.8528 0 45C0 20.1472 20.1472 0 45 0C69.8528 0 90 20.1472 90 45C89.9727 69.8415 69.8415 89.9727 45 90ZM44.928 81H45C64.8752 80.9801 80.9739 64.8572 80.964 44.982C80.9541 25.1068 64.8392 9.00002 44.964 9.00002C25.0888 9.00002 8.97394 25.1068 8.964 44.982C8.95407 64.8572 25.0528 80.9801 44.928 81ZM36 67.5L18 49.5L24.345 43.155L36 54.765L65.655 25.11L72 31.5L36 67.5Z"
              fill="#508D00"
            />
          </svg>
        </div>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
        <h3>{'Le bien à été créé avec succès'}</h3>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
        <h3>{'Retrouvez-le dans votre Portefeuille'}</h3>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            if (!productDatas.kind && productBuildingUuid) {
              navigate(`/building/${productBuildingUuid}`);
            } else {
              navigate(`/product/${productDatas.uuid}`);
            }
          }}
        >
          {'Accéder à la fiche'}
        </Button>
      </Grid>
    </Grid>
  );
};
