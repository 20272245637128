import { Grid, useTheme, useMediaQuery } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

import './StepsViewer.scss';

export const Step = ({ title, index, status }) => {
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.up('xs'));
  const matchesSm = useMediaQuery(theme.breakpoints.up('sm'));
  const matchesMd = useMediaQuery(theme.breakpoints.up('md'));
  const matchesLg = useMediaQuery(theme.breakpoints.up('lg'));
  const matchesXl = useMediaQuery(theme.breakpoints.up('xl'));
  return (
    <>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs>
            <div></div>
          </Grid>
          <Grid item sx={{ minWidth: '70px' }}>
            <div className="line"></div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Grid item xs textAlign={'center'}>
            <div className="step-title">{title}</div>
          </Grid>
          <Grid item sx={{ minWidth: !matchesMd ? '30px' : '70px', ml: !matchesMd ? '1.5vw' : 0 }}>
            <div className={status === 'current' || status === 'done' ? 'current-circle' : 'todo-circle'}>
              {status === 'done' && <CheckIcon sx={{ color: 'white' }} />}
              {status !== 'done' && index + 1}
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
