import {
  Grid,
  InputAdornment,
  TextField,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputLabel,
  Select,
  MenuItem,
  Button
} from '@mui/material';
import { appTheme } from '~/utils/theme';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SearchIcon from '@mui/icons-material/Search';
import { useState, useEffect, useContext, useMemo } from 'react';
import { api } from '~/api';
import DeleteIcon from '@mui/icons-material/Delete';
import auth from '../Auth/AuthApi';
import _ from 'lodash';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { AppContext } from '~/utils/context/AppContext';
import { DateValidationError } from '@mui/x-date-pickers/models';
import { correspondanceBuildingKinds, correspondanceKinds, correspondanceProductKinds } from '~/utils/references';

export const CreateGeneralInfos = ({
  dpeDatas,
  setDpeDatas,
  setNextStepPossible,
  productKind,
  setProductKind,
  buildingKind,
  setBuildingKind,
  setBuildingDatas,
  buildingDatas,
  setProductBuildingUuid,
  setPropertyDatas,
  currentStep,
  propertyNature,
  setPropertyNature,
  propertyIdSelected,
  setPropertyIdSelected,
  initialBuildingDatas,
  setProductDatas,
  initialProductDatas
}) => {
  const [dpeNumber, setDpeNumber] = useState('');
  const [dpeNumberSearched, setDpeNumberSearched] = useState('');
  const [dpeSearchStatus, setDpeSearchStatus] = useState('');

  const [adressValue, setAdressValue] = useState('');

  const [buildingsSameAdresseSearched, setBuildingsSameAdresseSearched] = useState(false);
  const [buildingsSameAddressFounded, setBuildingsSameAddressFounded] = useState(null);

  const [buildingsSameAddress, setBuildingsSameAddress] = useState(null);

  const [attachedBuildingIndex, setAttachedBuildingIndex] = useState(null);
  const [attachedBuildingDatas, setAttachedBuildingDatas] = useState(null);

  const [complementAdresse, setComplementAdresse] = useState(null);

  const [showBuildingName, setShowBuildingName] = useState(false);
  const [showOtherInformations, setShowOtherInformations] = useState(false);

  const [placeSelected, setPlaceSelected] = useState(null);
  const [buildingName, setBuildingName] = useState('Bâtiment unique');
  const [buildingKindName, setBuildingKindName] = useState(null);
  const [periode, setPeriode] = useState(0);
  const [annee, setAnnee] = useState<any>(null);
  const [constructionPeriode, setConstructionPeriode] = useState(null);
  const [yearToPeriodeArr, setYearToPeriodeArr] = useState(null);
  const [minDate, setMinDate] = useState(new Date(1700, 0, 1));
  const currentYear = new Date().getFullYear();
  const [maxDate, setMaxDate] = useState(new Date(currentYear, 0, 1));
  const [error, setError] = useState<DateValidationError | null>(null);
  const [buildingKinds, setBuildingKinds] = useState(null);

  const [currentBuildingKind, setCurrentBuildingKind] = useState(null);

  const [productNatureDisabled, setProductNatureDisabled] = useState(false);

  const [firstStepChoice, setFirstStepChoice] = useState('');
  const [secondStepChoice, setSecondStepChoice] = useState('');

  const { referencial } = useContext(AppContext);

  const handleChangeDPESearch = (e) => {
    // resetDpeSearch();
    setMinDate(null);
    setMaxDate(null);
    setPeriode(0);
    setAnnee(null);
    setDpeDatas(null);
    setShowOtherInformations(false);
    setShowBuildingName(false);
    setDpeSearchStatus('');
    setDpeNumberSearched('');
    setDpeNumber(e.target.value);
  };

  const setPropertyKind = (e) => {
    if (firstStepChoice === 'DPE') {
      if (correspondanceKinds[e.target.value].type === 'building') {
        setBuildingKind(correspondanceKinds[e.target.value].id);
      } else if (correspondanceKinds[e.target.value].type === 'product') {
        setProductKind(correspondanceKinds[e.target.value].id);
      }
      setPropertyNature(correspondanceKinds[e.target.value].type);
    } else if (firstStepChoice === 'NoDPE' && secondStepChoice === 'BUILDING') {
      setBuildingKind(correspondanceBuildingKinds[e.target.value].id);
      setPropertyNature('building');
    } else if (firstStepChoice === 'NoDPE' && secondStepChoice === 'PRODUCT') {
      setProductKind(correspondanceProductKinds[e.target.value].id);
      setPropertyNature('product');
    }
    setPropertyIdSelected(e.target.value);
  };

  useEffect(() => {
    setConstructionPeriode(_.sortBy(referencial['building.construction_period'], ['id']));
    setBuildingKinds(_.sortBy(referencial['building.kind'], ['id']));
  }, [referencial]);

  useEffect(() => {
    if (constructionPeriode) {
      setYearToPeriodeArr(
        constructionPeriode.map((periode, index) => {
          if (periode.name.indexOf('Après') !== -1) {
            return [periode.name.split(' ')[0], Number(periode.name.split(' ')[1])];
          } else if (periode.name.indexOf('Avant') !== -1) {
            return [periode.name.split(' ')[0], Number(periode.name.split(' ')[1])];
          } else {
            return periode.name.split('-').map((x) => {
              return Number(x);
            });
          }
        })
      );
    }
  }, [constructionPeriode]);

  useEffect(() => {
    if (dpeSearchStatus === 'searched') {
      setAdressValue('');
    }
  }, [dpeSearchStatus]);

  useEffect(() => {
    if (productKind !== '') {
      enablePlacesSearch();
    }
  }, [productKind]);
  useEffect(() => {
    enablePlacesSearch();
  }, []);

  useEffect(() => {
    enablePlacesSearch();
  }, [secondStepChoice]);

  const enablePlacesSearch = () => {
    const input = document.getElementById('autocomplete-input');
    if (input === null) {
      return;
    }
    const autocomplete = new window.google.maps.places.Autocomplete(input as HTMLInputElement, {
      types: ['geocode']
    });
    window.google.maps.event.addListener(autocomplete, 'place_changed', function () {
      const place = autocomplete.getPlace();
      if (place !== undefined && place.address_components) {
        setPlaceSelected(place);
        setAdressValue(place.formatted_address);

        let streetNumber = null;
        let name = null;
        let zip_code = null;
        let city = null;
        let country = null;
        for (var i = 0; i < place.address_components.length; i++) {
          for (var j = 0; j < place.address_components[i].types.length; j++) {
            if (place.address_components[i].types[j] == 'postal_code') {
              zip_code = place.address_components[i].long_name;
            }
            if (place.address_components[i].types[j] == 'locality') {
              city = place.address_components[i].long_name;
            }
            if (place.address_components[i].types[j] == 'country') {
              country = place.address_components[i].long_name;
            }
            if (place.address_components[i].types[j] == 'route') {
              name = place.address_components[i].long_name;
            }
            if (place.address_components[i].types[j] == 'street_number') {
              streetNumber = place.address_components[i].long_name;
            }
          }
        }

        var latitude = place.geometry.location.lat();
        var longitude = place.geometry.location.lng();

        setBuildingDatas((prev) => ({
          ...prev,
          address: {
            street: streetNumber + ' ' + name,
            zip_code: zip_code,
            city: city,
            country: country
          }
        }));

        checkIfElementSameAddress(streetNumber + ' ' + name, city, zip_code, country, latitude, longitude);
      }
    });
  };

  const checkIfElementSameAddress = (street, city, zip_code, country, lat = null, long = null) => {
    api.address
      .buildingsProducts({
        street: street,
        city: city,
        zip_code: zip_code,
        country: country
      })
      .then((res) => {
        setBuildingsSameAdresseSearched(true);
        setBuildingsSameAddressFounded(res.buildings);
      });
  };

  const handleChangeAdressSearch = (e) => {
    setAttachedBuildingIndex(null);
    setAdressValue(e.target.value);
  };

  const keyPress = (e) => {
    if (e.keyCode == 13) {
      handleSearchDPE();
    }
  };

  const keyPressAdress = (e) => {
    if (e.keyCode == 13) {
    }
  };

  const handleSearchDPE = () => {
    setDpeDatas(null);
    setDpeNumberSearched(dpeNumber);
    setDpeSearchStatus('searching');
    api.ademe
      .get(dpeNumber)
      .then((res) => {
        const currentBuildingKind = _.find(buildingKinds, { id: res.body.building_kind.toString() });

        if (currentBuildingKind !== null && currentBuildingKind !== undefined) {
          setCurrentBuildingKind(_.find(buildingKinds, { id: res.body.building_kind.toString() }));
        }

        setDpeDatas(res);
        setDpeSearchStatus('searched');

        if (res?.body?.building_construction_period_kind) {
          setPeriode(res.body.building_construction_period_kind);
        }

        if (res?.body?.building_construction_year) {
          setAnnee(dayjs(res.body.building_construction_year));
        }

        if (!res?.body === null) {
          setDpeDatas(null);
          setShowBuildingName(false);
          setShowOtherInformations(false);
          setShowBuildingName(false);
        }
      })
      .catch((e) => {
        setDpeDatas(null);
        setDpeSearchStatus('searched');
      })
      .finally(() => {});
  };

  const setPeriodFromYear = (year) => {
    yearToPeriodeArr.forEach((periode, index) => {
      if (year <= periode[1] && year >= periode[0]) {
        setPeriode(index);
      }
      if (periode[0] === 'Après' && year >= periode[1]) {
        setPeriode(index);
      }
      if (periode[0] === 'Avant' && year < periode[1]) {
        setPeriode(index);
      }
    });
  };

  useEffect(() => {
    if (currentBuildingKind) {
      setBuildingKindName(currentBuildingKind.name);
    }
  }, [currentBuildingKind]);

  useEffect(() => {
    if (dpeDatas?.body?.address) {
      const street = dpeDatas.body.address.Street;
      const zip_code = dpeDatas.body.address.ZipCode;
      const city = dpeDatas.body.address.City;

      setAdressValue(`${street} ${zip_code} ${city}`);

      if (dpeDatas.body.building_kind) {
        const buildingKind = buildingKinds[dpeDatas.body.building_kind];

        if (dpeDatas.body.building_kind === 2) {
          setBuildingKind(2); // maison individuelle
          setPropertyIdSelected(0);
          setPropertyNature('building');
        } else if (dpeDatas.body.building_kind === 3 && dpeDatas.body.is_building === true) {
          setBuildingKind(3);
          setPropertyIdSelected(3); // immeuble
          setPropertyNature('building');
        } else if (dpeDatas.body.building_kind === 3 && dpeDatas.body.is_building === false) {
          setBuildingKind(3);
          setPropertyIdSelected(1); // appartement
          setPropertyNature('product');
          setProductKind(1);
        }
      }

      setBuildingDatas((prev) => ({
        ...prev,
        address: {
          street: street,
          city: city,
          country: 'France',
          zip_code: zip_code
        },
        kind: dpeDatas.body.building_kind
      }));
      checkIfElementSameAddress(street, city, zip_code, 'France');
    }
    if (!dpeDatas?.body?.address && productKind !== '') {
      enablePlacesSearch();
    }
  }, [dpeDatas]);

  useEffect(() => {
    setBuildingDatas((prev) => ({
      ...prev,
      name: buildingName
    }));
  }, [buildingName]);

  useEffect(() => {
    setPropertyDatas((prev) => ({
      ...prev,
      address_complement: complementAdresse
    }));
  }, [complementAdresse]);

  useEffect(() => {
    if (annee) {
      if (!dpeDatas?.body?.building_construction_period_kind) {
        // setPeriodFromYear(new Date(annee).getFullYear());
      }
      setBuildingDatas((prev) => ({
        ...prev,
        construction_year: annee.toISOString()
      }));
    } else {
      setBuildingDatas((prev) => ({
        ...prev,
        construction_year: null
      }));
    }
  }, [annee]);

  useEffect(() => {
    console.log('annee', annee);
  }, [annee]);

  useEffect(() => {
    setBuildingDatas((prev) => ({
      ...prev,
      construction_period: Number(periode)
    }));

    // setAnnee(null);

    // if (yearToPeriodeArr) {
    //   const periodRange = yearToPeriodeArr[Number(periode)];
    //   if (periodRange[0] === 'Après') {
    //     setMinDate(dayjs(periodRange[1].toString()));
    //     setMaxDate(null);
    //   } else if (periodRange[0] === 'Avant') {
    //     setMinDate(dayjs('1000'));
    //     setMaxDate(dayjs(periodRange[1].toString()));
    //   } else {
    //     if (periodRange[0]) setMinDate(dayjs(periodRange[0].toString()));
    //     if (periodRange[1]) setMaxDate(dayjs(periodRange[1].toString()));
    //   }
    // }
  }, [periode, setBuildingDatas]);

  useEffect(() => {
    if (!dpeDatas && dpeNumberSearched !== '' && dpeSearchStatus === 'searched') {
      enablePlacesSearch();
    }
  }, [dpeDatas, dpeNumberSearched, dpeSearchStatus]);

  const selectBuildingToAttach = (event: React.ChangeEvent<HTMLInputElement>) => {
    const split = (event.target as HTMLInputElement).value.split('-');
    if (split[0] === 'building') {
      setAttachedBuildingDatas(buildingsSameAddressFounded[split[1]]);
      setProductBuildingUuid(buildingsSameAddressFounded[split[1]].uuid);
      setPropertyDatas((prev) => ({
        ...prev,
        uuid_building: buildingsSameAddressFounded[split[1]].uuid,
        uuid_address: buildingsSameAddressFounded[split[1]].address.uuid
      }));
    } else {
      setAttachedBuildingDatas(null);
      setProductBuildingUuid(null);
      setPropertyDatas((prev) => ({
        ...prev,
        uuid_building: null,
        uuid_address: null
      }));
    }
    setAttachedBuildingIndex((event.target as HTMLInputElement).value);
  };

  const resetDpeSearch = () => {
    setPropertyIdSelected('');
    setBuildingsSameAddressFounded(null);
    setFirstStepChoice('');
    setSecondStepChoice('');
    setProductKind('');
    setPropertyNature(null);
    setBuildingKind('');
    setAdressValue('');
    setDpeDatas(null);
    setDpeNumber('');
    setDpeSearchStatus('');
    setPeriode(0);
    setAnnee(null);
    setCurrentBuildingKind(null);
    setBuildingName('Bâtiment unique');
    setPlaceSelected(null);
    setAttachedBuildingIndex(null);
    setBuildingsSameAdresseSearched(false);
    setBuildingsSameAddress(null);
    setShowBuildingName(false);
    setAttachedBuildingDatas(null);
    setShowOtherInformations(false);
    setBuildingDatas((prev) => ({ ...prev, ...initialBuildingDatas }));
    setProductDatas((prev) => ({ ...prev, ...initialProductDatas }));
  };

  const errorMessage = useMemo(() => {
    switch (error) {
      case 'maxDate': {
        return 'Date supérieure à la date maximale';
      }
      case 'minDate': {
        return 'Date inférieure à la date minimale';
      }

      case 'invalidDate': {
        return "La date saisie n'est pas valide";
      }

      default: {
        return '';
      }
    }
  }, [error]);

  const disableKeyboardEntry = (e: any) => {
    if (e?.keyCode === 40 || e?.keyCode === 38) {
      if (e?.keyCode === 38 && Number(e.target.value) >= dayjs().year()) {
        e?.preventDefault();
        e?.stopPropagation();
      }
    }
  };

  const resetPlaceSearch = () => {
    setPropertyIdSelected('');
    setBuildingsSameAddressFounded(null);
    setFirstStepChoice('');
    setSecondStepChoice('');
    setProductKind('');
    setPropertyNature(null);
    setBuildingKind('');
    setDpeDatas(null);
    setMinDate(null);
    setMaxDate(null);
    setDpeDatas(null);
    setPlaceSelected(null);
    setBuildingsSameAddressFounded(null);
    setBuildingName('Bâtiment unique');
    setAdressValue('');
    setPeriode(0);
    setAnnee(null);
    setAttachedBuildingDatas(null);
    setAttachedBuildingIndex(null);
    setBuildingsSameAdresseSearched(false);
    setBuildingsSameAddress(null);
    setShowBuildingName(false);
    setShowOtherInformations(false);
    setBuildingDatas((prev) => ({ ...prev, ...initialBuildingDatas }));
    setProductDatas((prev) => ({ ...prev, ...initialProductDatas }));
  };

  useEffect(() => {
    if (currentStep === 0) {
      if (dpeDatas || placeSelected) {
        if (
          (!buildingsSameAddressFounded || buildingsSameAddressFounded.length === 0) &&
          buildingsSameAdresseSearched === true &&
          error === null
        ) {
          setShowBuildingName(true);
          setShowOtherInformations(true);
          setNextStepPossible(true);
        } else if (buildingsSameAddressFounded && buildingsSameAddressFounded.length > 0) {
          if (
            (attachedBuildingDatas && attachedBuildingDatas.name && error === null) ||
            attachedBuildingIndex === 'none'
          ) {
            setShowBuildingName(true);
            setShowOtherInformations(true);
            setNextStepPossible(true);
          }
        }
      }
    }
  }, [
    placeSelected,
    buildingsSameAddressFounded,
    attachedBuildingIndex,
    dpeDatas,
    setNextStepPossible,
    buildingsSameAdresseSearched,
    attachedBuildingDatas,
    buildingsSameAddress,
    buildingName,
    currentStep,
    error
  ]);

  if (firstStepChoice === '') {
    setNextStepPossible(false);
    return (
      <Grid
        container
        spacing={5}
        direction="column"
        justifyContent={'center'}
        wrap="wrap"
        sx={{ padding: '50px 100px' }}
      >
        <Grid item xs={12}>
          <Button
            fullWidth
            variant="outlined"
            onClick={() => {
              setFirstStepChoice('DPE');
            }}
            sx={{ height: '5vh', width: '40%' }}
          >
            <Typography variant="h6" gutterBottom component="div" sx={{ pt: '5px', pb: '5px' }}>
              J'ai un numéro de DPE
            </Typography>
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button
            fullWidth
            variant="outlined"
            onClick={() => {
              setFirstStepChoice('NoDPE');
            }}
            sx={{ height: '5vh', width: '40%' }}
          >
            <Typography variant="h6" gutterBottom component="div" sx={{ pt: '5px', pb: '5px' }}>
              Je n'ai pas de numéro de DPE
            </Typography>
          </Button>
        </Grid>
      </Grid>
    );
  } else if (firstStepChoice === 'DPE') {
    return (
      <Grid
        container
        spacing={2}
        sx={{
          paddingLeft: '30px',
          msOverflowStyle: 'none' /* Pour IE et Edge */,
          scrollbarWidth: 'none' /* Pour Firefox */,
          '&::-webkit-scrollbar': {
            display: 'none'
          }
        }}
        style={{
          maxHeight: 'calc(100vh - 190px)',
          overflow: 'scroll'
        }}
      >
        <Grid item xs={12}>
          <h3 style={{ color: appTheme.palette.primary.main, marginTop: '0px' }}>Adresse</h3>
        </Grid>
        <Grid item xs={8}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                sx={{ pr: '120px' }}
                style={{ paddingRight: '0px' }}
                label="N° du DPE"
                // required
                id="search-input"
                autoComplete="off"
                placeholder="13 caractères"
                size="small"
                onKeyUp={keyPress}
                value={dpeNumber}
                onChange={handleChangeDPESearch}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      {(dpeDatas || dpeSearchStatus === 'searched') && (
                        <HighlightOffIcon onClick={resetDpeSearch}></HighlightOffIcon>
                      )}
                      {!dpeDatas && dpeSearchStatus !== 'searched' && <SearchIcon onClick={handleSearchDPE} />}
                    </InputAdornment>
                  ),
                  style: {
                    paddingRight: 0
                  }
                }}
              ></TextField>

              {dpeDatas && (
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography gutterBottom component="div" sx={{ pt: '15px' }}>
                        Nous avons trouvé le bien correspondant à ce DPE, il est siitué à cette adresse :
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )}

              {dpeSearchStatus === 'searching' && (
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography gutterBottom component="div" sx={{ pt: '15px' }}>
                        Recherche en cours...
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {dpeNumber === dpeNumberSearched && !dpeDatas && dpeSearchStatus === 'searched' && (
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography gutterBottom component="div" sx={{ pt: '15px' }}>
                        Les informations du DPE ne sont pas disponibles
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {dpeDatas && dpeDatas !== null && (
                <TextField
                  disabled={true}
                  fullWidth
                  label="Adresse du bien"
                  // required
                  id="autocomplete-input"
                  autoComplete="off"
                  size="small"
                  onKeyUp={keyPressAdress}
                  value={adressValue}
                  onChange={handleChangeAdressSearch}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        {!placeSelected && <SearchIcon />}
                        {placeSelected && <HighlightOffIcon onClick={resetPlaceSearch}></HighlightOffIcon>}
                      </InputAdornment>
                    ),
                    style: {
                      paddingRight: 0
                    }
                  }}
                ></TextField>
              )}
              {buildingsSameAddressFounded && buildingsSameAddressFounded.length > 0 && (
                <FormControl>
                  <Typography sx={{ pt: '15px' }}>
                    {`Nous avons trouvé ce${buildingsSameAddressFounded.length > 1 ? 's' : ''} bâtiment${
                      buildingsSameAddressFounded.length > 1 ? 's' : ''
                    } à cette adresse, ${
                      buildingsSameAddressFounded.length > 1
                        ? "souhaitez vous vous rattacher à l'un d'entre eux ?"
                        : 'souhaitez vous vous y rattacher ?'
                    } `}
                  </Typography>
                  {auth.isSuperAdmin() && attachedBuildingDatas && (
                    <span>
                      <DeleteIcon
                        onClick={() => {
                          api.building.delete(attachedBuildingDatas.uuid).then((res) => {});
                        }}
                      />{' '}
                      Supprimer le bâtiment selectionné
                    </span>
                  )}
                  <RadioGroup
                    value={attachedBuildingIndex}
                    onChange={selectBuildingToAttach}
                    aria-labelledby="attachment-proposition"
                    name="radio-buttons-group"
                  >
                    {buildingsSameAddressFounded &&
                      buildingsSameAddressFounded.map((building, index) => {
                        return (
                          <FormControlLabel
                            key={`building-${index}`}
                            value={`building-${index}`}
                            control={<Radio size="small"></Radio>}
                            label={`${building.name} - ${building.address.street} ${building.address.zip_code} ${building.address.city}`}
                            data-obj={building}
                            data-obj-type="building"
                          />
                        );
                      })}
                    <FormControlLabel
                      key={`New`}
                      value={`none`}
                      control={<Radio size="small" />}
                      label={`Non, je souhaite créer un nouveau bâtiment`}
                    />
                  </RadioGroup>
                </FormControl>
              )}

              {(showBuildingName || buildingsSameAddress === 'true') && (
                <Grid container spacing={2} sx={{ pt: '15px' }}>
                  <Grid item xs={6}>
                    <TextField
                      size="small"
                      autoComplete="off"
                      fullWidth
                      // required
                      id="batimentxyz"
                      value={
                        attachedBuildingDatas && attachedBuildingDatas.name ? attachedBuildingDatas.name : buildingName
                      }
                      disabled={attachedBuildingDatas && attachedBuildingDatas.name ? true : false}
                      onChange={(e) => setBuildingName(e.target.value)}
                      label="Nom du bâtiment"
                      inputProps={{
                        autoComplete: 'off'
                      }}
                    ></TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      size="small"
                      autoComplete="off"
                      fullWidth
                      // required
                      id="more-info"
                      value={complementAdresse}
                      // disabled={attachedBuildingDatas && attachedBuildingDatas.name ? true : false}
                      onChange={(e) => setComplementAdresse(e.target.value)}
                      label="Complément d'adresse"
                      placeholder='Ex: "Etage 5, porte 502"'
                      inputProps={{
                        autoComplete: 'off'
                      }}
                    ></TextField>
                  </Grid>
                </Grid>
              )}
              {showOtherInformations && (
                <>
                  <Grid container spacing={2} sx={{ pt: '15px' }}>
                    <Grid item xs>
                      <FormControl fullWidth>
                        <InputLabel size="small" id="construction_period">
                          Periode de construction
                        </InputLabel>
                        <Select
                          disabled={dpeDatas?.body?.building_construction_period_kind ? true : false}
                          fullWidth
                          labelId="construction_period"
                          id="demo-simple-select"
                          value={periode}
                          label="Période de construction"
                          onChange={(e) => {
                            setPeriode(Number(e.target.value));
                            const periodRange = yearToPeriodeArr[Number(e.target.value)];
                            // if (periodRange[0] === 'Après') {
                            //   setMinDate(dayjs(periodRange[1].toString()));
                            //   setMaxDate(null);
                            // } else if (periodRange[0] === 'Avant') {
                            //   setMinDate(null);
                            //   setMaxDate(dayjs(periodRange[1].toString()));
                            // } else {
                            //   setMinDate(dayjs(periodRange[0].toString()));
                            //   setMaxDate(dayjs(periodRange[1].toString()));
                            // }
                            setAnnee(null);
                          }}
                          size="small"
                        >
                          {constructionPeriode.map((periode) => {
                            return (
                              <MenuItem key={periode.id} value={periode.id}>
                                {periode.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs>
                      <FormControl fullWidth>
                        <DatePicker
                          disabled={dpeDatas?.body?.building_construction_year}
                          label="Année de construction"
                          views={['year']}
                          value={annee}
                          onChange={(e) => {
                            if (dayjs(e).format() !== 'Invalid Date') {
                              setAnnee(dayjs(e).toDate());
                            }
                          }}
                          onError={(newError) => setError(newError)}
                          minDate={minDate}
                          maxDate={maxDate}
                          slotProps={{
                            textField: {
                              size: 'small',
                              onKeyDownCapture: disableKeyboardEntry,
                              helperText: errorMessage
                            }
                          }}
                          disableFuture
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel size="small" id="demo-simple-select-label">
                          Nature du bien
                        </InputLabel>
                        <Select
                          fullWidth
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={propertyIdSelected}
                          label="Nature du bien"
                          disabled={true}
                          onChange={(e) => setPropertyKind(e)}
                          size="small"
                        >
                          {correspondanceKinds.map((kind, index) => {
                            return (
                              <MenuItem key={index} value={index}>
                                {kind.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
          <br />
          <br />
          <br />
        </Grid>
      </Grid>
    );
  } else if (firstStepChoice === 'NoDPE' && secondStepChoice === '') {
    return (
      <Grid container spacing={5} direction="column" justifyContent="center" wrap="wrap" sx={{ padding: '50px 100px' }}>
        <Grid item xs={12}>
          <Button
            fullWidth
            variant="outlined"
            onClick={() => {
              setPropertyNature('product');
              setSecondStepChoice('PRODUCT');
            }}
            sx={{ height: '5vh', width: '50%' }}
          >
            <Typography variant="h6" gutterBottom component="div" sx={{ pt: '5px', pb: '5px' }}>
              Je crée un LOT (appartement, bureau, commerce etc )
            </Typography>
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button
            fullWidth
            variant="outlined"
            onClick={() => {
              setPropertyNature('building');
              setSecondStepChoice('BUILDING');
            }}
            sx={{ height: '5vh', width: '50%' }}
          >
            <Typography variant="h6" gutterBottom component="div" sx={{ pt: '5px', pb: '5px' }}>
              Je crée un BATIMENT (maison, immeuble, partie commune etc)
            </Typography>
          </Button>
        </Grid>
      </Grid>
    );
  } else if (firstStepChoice === 'NoDPE' && secondStepChoice !== '') {
    return (
      <Grid
        container
        spacing={2}
        sx={{
          paddingLeft: '30px',
          msOverflowStyle: 'none' /* Pour IE et Edge */,
          scrollbarWidth: 'none' /* Pour Firefox */,
          '&::-webkit-scrollbar': {
            display: 'none' /* Pour WebKit (Chrome, Safari) */
          }
        }}
        style={{
          maxHeight: 'calc(100vh - 190px)',
          overflow: 'scroll'
        }}
      >
        <Grid item xs={12}>
          <h3 style={{ color: appTheme.palette.primary.main, marginTop: '0px' }}>Adresse</h3>
        </Grid>
        <Grid item xs={8}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {
                <TextField
                  // disabled={placeSelected !== null}
                  fullWidth
                  label="Adresse du bien"
                  // required
                  id="autocomplete-input"
                  autoComplete="off"
                  size="small"
                  onKeyUp={keyPressAdress}
                  value={adressValue}
                  onChange={handleChangeAdressSearch}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        {!placeSelected && <SearchIcon />}
                        {placeSelected && <HighlightOffIcon onClick={resetPlaceSearch}></HighlightOffIcon>}
                      </InputAdornment>
                    ),
                    style: {
                      paddingRight: 0
                    }
                  }}
                ></TextField>
              }
              {buildingsSameAddressFounded && buildingsSameAddressFounded.length > 0 && (
                <FormControl>
                  <Typography sx={{ pt: '15px' }}>
                    {`Nous avons trouvé ce${buildingsSameAddressFounded.length > 1 ? 's' : ''} bâtiment${
                      buildingsSameAddressFounded.length > 1 ? 's' : ''
                    } à cette adresse, ${
                      buildingsSameAddressFounded.length > 1
                        ? "souhaitez vous vous rattacher à l'un d'entre eux ?"
                        : 'souhaitez vous vous y rattacher ?'
                    } `}
                  </Typography>
                  {auth.isSuperAdmin() && attachedBuildingDatas && (
                    <span>
                      <DeleteIcon
                        onClick={() => {
                          api.building.delete(attachedBuildingDatas.uuid).then((res) => {});
                        }}
                      />{' '}
                      Supprimer le bâtiment selectionné
                    </span>
                  )}
                  <RadioGroup
                    value={attachedBuildingIndex}
                    onChange={selectBuildingToAttach}
                    aria-labelledby="attachment-proposition"
                    name="radio-buttons-group"
                  >
                    {buildingsSameAddressFounded &&
                      buildingsSameAddressFounded.map((building, index) => {
                        return (
                          <FormControlLabel
                            key={`building-${index}`}
                            value={`building-${index}`}
                            control={<Radio size="small"></Radio>}
                            label={`${building.name} - ${building.address.street} ${building.address.zip_code} ${building.address.city}`}
                            data-obj={building}
                            data-obj-type="building"
                          />
                        );
                      })}
                    <FormControlLabel
                      key={`New`}
                      value={`none`}
                      control={<Radio size="small" />}
                      label={`Non, je souhaite créer un nouveau bâtiment`}
                    />
                  </RadioGroup>
                </FormControl>
              )}

              {(showBuildingName || buildingsSameAddress === 'true') && (
                <Grid container spacing={2} sx={{ pt: '15px' }}>
                  <Grid item xs={6}>
                    <TextField
                      size="small"
                      autoComplete="off"
                      fullWidth
                      // required
                      id="batimentxyz"
                      value={
                        attachedBuildingDatas && attachedBuildingDatas.name ? attachedBuildingDatas.name : buildingName
                      }
                      disabled={attachedBuildingDatas && attachedBuildingDatas.name ? true : false}
                      onChange={(e) => setBuildingName(e.target.value)}
                      label="Nom du bâtiment"
                      inputProps={{
                        autoComplete: 'off'
                      }}
                    ></TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      size="small"
                      autoComplete="off"
                      fullWidth
                      // required
                      id="more-info"
                      value={complementAdresse}
                      // disabled={attachedBuildingDatas && attachedBuildingDatas.name ? true : false}
                      onChange={(e) => setComplementAdresse(e.target.value)}
                      label="Complément d'adresse"
                      placeholder='Ex: "Etage 5, porte 502"'
                      inputProps={{
                        autoComplete: 'off'
                      }}
                    ></TextField>
                  </Grid>
                </Grid>
              )}
              {showOtherInformations && (
                <>
                  <Grid container spacing={2} sx={{ pt: '15px' }}>
                    <Grid item xs>
                      <FormControl fullWidth>
                        <InputLabel size="small" id="construction_period">
                          Periode de construction
                        </InputLabel>
                        <Select
                          // disabled={dpeDatas?.body?.building_construction_period_kind ? true : false}
                          fullWidth
                          labelId="construction_period"
                          id="demo-simple-select"
                          value={periode}
                          label="Période de construction"
                          onChange={(e) => {
                            setPeriode(Number(e.target.value));
                            // const periodRange = yearToPeriodeArr[Number(e.target.value)];
                            // if (periodRange[0] === 'Après') {
                            //   setMinDate(dayjs(periodRange[1].toString()));
                            //   setMaxDate(null);
                            // } else if (periodRange[0] === 'Avant') {
                            //   setMinDate(null);
                            //   setMaxDate(dayjs(periodRange[1].toString()));
                            // } else {
                            //   setMinDate(dayjs(periodRange[0].toString()));
                            //   setMaxDate(dayjs(periodRange[1].toString()));
                            // }
                            setAnnee(null);
                          }}
                          size="small"
                        >
                          {constructionPeriode.map((periode) => {
                            return (
                              <MenuItem key={periode.id} value={periode.id}>
                                {periode.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item sx={{ width: '30px' }}>
                      <div style={{ paddingTop: '8px' }}>ou</div>
                    </Grid>

                    <Grid item xs>
                      <FormControl fullWidth>
                        <DatePicker
                          // disabled={dpeDatas?.body?.building_construction_year}
                          label="Année de construction"
                          views={['year']}
                          value={annee}
                          onChange={(e) => {
                            if (dayjs(e).format() !== 'Invalid Date') {
                              setAnnee(dayjs(e).toDate());
                            }
                          }}
                          // minDate={minDate}
                          // maxDate={maxDate}
                          onError={(newError) => setError(newError)}
                          slotProps={{
                            textField: {
                              size: 'small',
                              onKeyDownCapture: disableKeyboardEntry,
                              helperText: errorMessage
                            }
                          }}
                          disableFuture
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel size="small" id="demo-simple-select-label">
                          Nature du bien
                        </InputLabel>
                        <Select
                          fullWidth
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={propertyIdSelected}
                          label="Nature du bien"
                          disabled={productNatureDisabled}
                          onChange={(e) => setPropertyKind(e)}
                          size="small"
                        >
                          {secondStepChoice === 'BUILDING'
                            ? correspondanceBuildingKinds.map((kind, index) => {
                                return (
                                  <MenuItem key={index} value={index}>
                                    {kind.name}
                                  </MenuItem>
                                );
                              })
                            : correspondanceProductKinds.map((kind, index) => {
                                return (
                                  <MenuItem key={index} value={index}>
                                    {kind.name}
                                  </MenuItem>
                                );
                              })}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
          <br />
          <br />
          <br />
        </Grid>
      </Grid>
    );
  }
};
