import { axiosDelete, axiosGet, axiosPatch, axiosPost } from '~/api/axiosClient';

export const addEquipment = (data) => {
  return axiosPost('/equipments', data);
};

export const getEquipment = (idEquipment: string) => {
  return axiosGet(`/equipments/${idEquipment}`);
};

export const patchEquipment = (idEquipment: string, data: any) => {
  return axiosPatch(`/equipments/${idEquipment}`, data);
};

export const deleteEquipment = (idEquipment: string) => {
  return axiosDelete(`/equipments/${idEquipment}`);
};

export const getEquipmentImages = (idEquipment: string) => {
  return axiosGet(`/equipments/${idEquipment}/images`);
};
