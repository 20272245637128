import React, { useState, useEffect } from 'react';
import { Button, Avatar, CircularProgress } from '@mui/material';
import Resizer from 'react-image-file-resizer';

const AvatarUploader = ({ avatar_image_uri, setAvatar, editMode }) => {
  const [isUploading, setIsUploading] = useState(false); // Indique si une image est en cours de traitement
  const [preview, setPreview] = useState<string | null>(null); // Aperçu de l'image sélectionnée

  // Initialiser l'aperçu avec l'avatar existant
  useEffect(() => {
    if (avatar_image_uri) {
      console.log(avatar_image_uri);
      setPreview(process.env.REACT_APP_BASEURL_MEDIAS + '/' + avatar_image_uri);
    }
  }, [avatar_image_uri]);

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]; // Récupère le premier fichier
    if (!file) return;

    try {
      setIsUploading(true);
      const resizedImage = await resizeFile(file); // Redimensionner l'image
      const previewUrl = URL.createObjectURL(resizedImage);
      setPreview(previewUrl); // Mettre à jour l'aperçu
      setAvatar(resizedImage); // Enregistrer l'image redimensionnée
    } catch (error) {
      console.error("Erreur lors du redimensionnement de l'image :", error);
      alert("Impossible de télécharger l'avatar. Veuillez réessayer.");
    } finally {
      setIsUploading(false);
    }
  };

  const resizeFile = (file: File) =>
    new Promise<File>((resolve, reject) => {
      Resizer.imageFileResizer(
        file,
        300, // Taille max (largeur ou hauteur)
        300,
        'JPEG',
        80, // Qualité
        0, // Rotation
        (uri) => resolve(uri as File), // Retourne le fichier redimensionné
        'file'
      );
    });

  const removeAvatar = () => {
    setPreview(null);
    setAvatar(null);
  };

  return (
    <div style={styles.container}>
      <div style={styles.avatarContainer}>
        {preview ? (
          <Avatar src={preview} alt="Avatar" style={styles.avatar} />
        ) : (
          <Avatar style={{ ...styles.avatar, backgroundColor: '#f0f0f0' }}>?</Avatar>
        )}
      </div>
      {editMode && (
        <div style={styles.buttons}>
          <Button variant="contained" component="label" disabled={isUploading} style={styles.uploadButton}>
            {isUploading ? <CircularProgress size={24} /> : "Changer d'avatar"}
            <input type="file" accept="image/*" style={{ display: 'none' }} onChange={handleFileChange} />
          </Button>
          {preview && (
            <Button variant="outlined" color="error" onClick={removeAvatar} disabled={isUploading}>
              Supprimer
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

// Styles CSS en JS
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    gap: '15px'
  },
  avatarContainer: {
    position: 'relative' as 'relative',
    width: '100px',
    height: '100px',
    borderRadius: '50%',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '2px solid #ccc'
  },
  avatar: {
    width: '100%',
    height: '100%',
    objectFit: 'cover' as 'cover'
  },
  buttons: {
    display: 'flex',
    gap: '10px',
    justifyContent: 'center'
  },
  uploadButton: {
    backgroundColor: '#F17633',
    color: '#fff'
  }
};

export default AvatarUploader;
