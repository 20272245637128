import {
  Grid,
  Checkbox,
  Switch,
  FormControlLabel,
  TextField,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Accordion,
  ListItem
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useEffect } from 'react';
import * as React from 'react';
import { DiagnosticTypes } from '~/utils/references';

export const DiagnosticForm = ({ documentData, setDocumentData }) => {
  const [commentsBlocks, setCommentsBlocks] = React.useState([]);
  const [conclusionBlocks, setConclusionBlocks] = React.useState([]);
  const [diagnosticsSelected, setDiagnosticsSelected] = React.useState([]);
  useEffect(() => {
    if (documentData.data.diagnostics === null || documentData.data.diagnostics === undefined) {
      const data = { ...documentData.data };
      data.diagnostics = [];
      setDocumentData({ ...documentData, data: data });
    }
  }, []);

  useEffect(() => {
    if (documentData?.data?.diagnostics !== null && documentData?.data?.diagnostics !== undefined) {
      const commentBlocks = [];
      const needCommentTypes = DiagnosticTypes.filter((DiagnosticType) => DiagnosticType.needComment);
      const conclusionBlocks = [];
      const needConclusionTypes = DiagnosticTypes.filter((DiagnosticType) => DiagnosticType.needConclusion);

      needCommentTypes.forEach((type, index) => {
        if (documentData.data.diagnostics.find((diagnostic) => diagnostic.type === type.name)) {
          const diagnostic = documentData.data.diagnostics.find((diagnostic) => diagnostic.type === type.name);
          commentBlocks.push(
            <Grid item xs={12} style={{ paddingBottom: '15px' }} key={index}>
              <TextField
                autoComplete="off"
                fullWidth
                label={type.name}
                id={type.name}
                value={diagnostic.comment}
                onChange={handleCommentChange}
              ></TextField>
            </Grid>
          );
        }
      });

      needConclusionTypes.forEach((type, index) => {
        if (documentData.data.diagnostics.find((diagnostic) => diagnostic.type === type.name)) {
          const diagnostic = documentData.data.diagnostics.find((diagnostic) => diagnostic.type === type.name);
          conclusionBlocks.push(
            <div key={index}>
              <span
                className="MuiTypography-root MuiTypography-body1 MuiFormControlLabel-label css-1n58w6-MuiTypography-root"
                style={{ paddingRight: '10px' }}
              >
                {type.name} : Absence
              </span>
              <FormControlLabel
                control={<Switch checked={diagnostic.presence} onChange={handleSwitchChange} id={type.name} />}
                label="Présence"
              />
            </div>
          );
        }
      });

      setCommentsBlocks(commentBlocks);
      setConclusionBlocks(conclusionBlocks);

      const diagnosticTypes = [];
      documentData.data.diagnostics.forEach((diagnostic) => {
        diagnosticTypes.push(diagnostic.type);
      });

      setDiagnosticsSelected(diagnosticTypes);
    }
  }, [documentData]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const indexSelectedDiagnostic = documentData.data.diagnostics.findIndex(
      (diagnostic) => diagnostic.type === event.target.id
    );
    const currentDiagnosticType = documentData.data.diagnostics.find(
      (diagnostic) => diagnostic.type === event.target.id
    );
    const data = { ...documentData.data };
    if (indexSelectedDiagnostic === -1) {
      if (data.diagnostics && data.diagnostics.length > 0) {
        if (!currentDiagnosticType) data.diagnostics.push({ type: event.target.id });
      } else {
        data.diagnostics = [];
        data.diagnostics.push({ type: event.target.id });
      }
    } else {
      data.diagnostics.splice(indexSelectedDiagnostic, 1);
    }
    setDocumentData({ ...documentData, data: data });
  };

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const indexSelectedDiagnostic = documentData.data.diagnostics.findIndex(
      (diagnostic) => diagnostic.type === event.target.id
    );
    const data = { ...documentData.data };
    data.diagnostics[indexSelectedDiagnostic].presence = event.target.checked;
    setDocumentData({ ...documentData, data: data });
  };

  const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const indexSelectedDiagnostic = documentData.data.diagnostics.findIndex(
      (diagnostic) => diagnostic.type === event.target.id
    );
    const data = { ...documentData.data };
    data.diagnostics[indexSelectedDiagnostic].comment = event.target.value;
    setDocumentData({ ...documentData, data: data });
  };

  return (
    <>
      {documentData &&
        documentData.data &&
        documentData.data.diagnostics &&
        documentData.category === 'Diagnostics' && (
          <Grid container spacing={0}>
            <Grid item xs={12} style={{ paddingBottom: '15px' }}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  <Typography>Type de diagnostic : {diagnosticsSelected.toString()}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {DiagnosticTypes &&
                    DiagnosticTypes.filter((DiagnosticType) => DiagnosticType.name !== '').map(
                      (DiagnosticType, index) => (
                        <ListItem
                          dense
                          key={index}
                          value={DiagnosticType.name}
                          style={{ padding: '0', fontSize: '12px' }}
                        >
                          <Checkbox
                            checked={
                              documentData.data.diagnostics
                                ? documentData.data.diagnostics.find(
                                    (diagnostic) => diagnostic.type === DiagnosticType.name
                                  )
                                  ? true
                                  : false
                                : false
                            }
                            color={'primary'}
                            onChange={handleChange}
                            id={DiagnosticType.name}
                          />
                          {DiagnosticType.name}
                        </ListItem>
                      )
                    )}
                </AccordionDetails>
              </Accordion>
            </Grid>

            {commentsBlocks.length + conclusionBlocks.length > 0 && (
              <div style={{ width: '100%', paddingBottom: '15px' }}>
                {commentsBlocks.length + conclusionBlocks.length > 1 && <div>Conclusions</div>}
                {commentsBlocks.length + conclusionBlocks.length === 1 && <div>Conclusion</div>}
              </div>
            )}

            {conclusionBlocks.length > 0 && (
              <div style={{ paddingBottom: '15px', width: '100%' }}>{conclusionBlocks}</div>
            )}
            {commentsBlocks.length > 0 && <div style={{ paddingBottom: '15px', width: '100%' }}>{commentsBlocks}</div>}
          </Grid>
        )}
    </>
  );
};
