import { useEffect, useState } from 'react';

export function useWindowSize() {
  const isSSR = typeof window === 'undefined';

  const minWidthDesktop = 1024;

  const [windowSize, setWindowSize] = useState({
    width: isSSR ? minWidthDesktop : window.innerWidth
  });

  function changeWindowSize() {
    setWindowSize({ width: window.innerWidth });
  }

  useEffect(() => {
    window.addEventListener('resize', changeWindowSize);

    return () => {
      window.removeEventListener('resize', changeWindowSize);
    };
  }, []);

  const [isMobile, setMobile] = useState(false);

  useEffect(() => {
    const mobile = Boolean(windowSize.width < minWidthDesktop);

    setMobile(mobile);
  }, [windowSize]);

  return { isMobile };
}
