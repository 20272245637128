import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { Button } from '@mui/material';
import { ReactNode, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ListFilter from '~/app/components/ListFilter/ListFilter';
import '~/app/pages/Product/Product.scss';

interface ProductCardProps {
  redirect?: string;
  redirect2?: string;
  children?: ReactNode;
  title: string;
  action?: string;
  id?: string;
  onAction?: () => void;
  handleChangeSearchFilter?: (search: string) => void;
  search?: string;
}

const ProductCard = ({
  redirect,
  redirect2,
  children,
  title,
  action,
  id,
  onAction,
  handleChangeSearchFilter = null,
  search = null
}: ProductCardProps) => {
  return (
    <div className="Product__card">
      <div className="Product__lineBlock" style={!children ? { paddingBottom: '0px', marginBottom: '0px' } : {}}>
        <div className="Product__lineBlock__title" style={{ cursor: 'pointer' }}>
          {redirect ? <Link to={redirect}> {title} </Link> : <div>{title}</div>}
        </div>
        {handleChangeSearchFilter && (
          <div className="Product__lineBlock__title" style={{ cursor: 'pointer' }}>
            <ListFilter handleChangeSearchFilter={handleChangeSearchFilter} search={search}></ListFilter>
          </div>
        )}
        {action && (
          <div className="Product__linkBlock__title" onClick={() => onAction && onAction()}>
            {redirect2 ? (
              <Button
                color="secondary"
                variant="outlined"
                sx={{ color: 'primary', borderRadius: '20px', borderColor: 'primary.main' }}
              >
                <AddOutlinedIcon fontSize="small" /> {action}
              </Button>
            ) : (
              <Button
                color="secondary"
                variant="outlined"
                size="small"
                sx={{ color: 'primary', borderRadius: '20px', borderColor: 'secondary.main' }}
              >
                <AddOutlinedIcon fontSize="small" />
                {action}
              </Button>
            )}
          </div>
        )}
      </div>
      {children && <div className="Product__card__content">{children}</div>}
    </div>
  );
};

export default ProductCard;
