import { EquipmentsList2 } from '../EquipmentsList/EquipmentsList2';
import { ImagePicker } from '../ImagePicker/ImagePicker';
import EventsList2 from '../EventsList/EventsList2';

export const ViewerContentContainer = ({
  productDatas,
  context,
  takeScreenShot,
  refreshProductData = null,
  contentId = null,
  uuidEquipmentSelected = null,
  setUuidEquipmentSelected = null,
  uuidTagSelected = null,
  setUuidTagSelected = null,
  navigateToTag = null,
  hideTags = null,
  showTags = null,
  cameraMoving = null
}) => {
  const refreshProductImages = () => {
    refreshProductData();
  };

  return (
    <>
      {contentId === 2 && (
        <div style={{ padding: '10px' }}>
          <EventsList2 events={productDatas.events}></EventsList2>
        </div>
      )}
      {contentId === 3 && (
        <div style={{ padding: '10px' }}>
          <EquipmentsList2
            productDatas={productDatas}
            navigateToTag={navigateToTag}
            // context="View3D"
            uuidEquipmentSelected={uuidEquipmentSelected}
            setUuidEquipmentSelected={setUuidEquipmentSelected}
            uuidTagSelected={uuidTagSelected}
            setUuidTagSelected={setUuidTagSelected}
            hideTags={hideTags}
            showTags={showTags}
            cameraMoving={cameraMoving}
          ></EquipmentsList2>
        </div>
      )}
      {contentId === 5 && (
        <ImagePicker
          matterport_model_id={productDatas?.matterport_scan?.model_id}
          refreshProductImages={refreshProductImages}
          productData={productDatas}
          context={context}
          takeScreenShot={takeScreenShot}
          refreshProductData={refreshProductData}
        />
      )}
    </>
  );
};

export default ViewerContentContainer;
