import { IosShareOutlined } from '@mui/icons-material';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import LinkIcon from '@mui/icons-material/Link';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {
  Box,
  Chip,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Typography,
  Grid,
  Dialog,
  DialogTitle,
  List,
  Autocomplete,
  TextField,
  FormControl
} from '@mui/material';
import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { appTheme } from '~/utils/theme';
import { AppContext } from '~/utils/context/AppContext';
import _ from 'lodash';
import './PortfolioCard.scss';
import normalizedString from '~/utils/normalizedString';
import { isAdmin } from '../Auth/AuthApi';

interface ICard {
  title: string;
  subtitle: string;
  description: string;
  tag?: string;
  links?: any;
  image?: string;
  linkId?: string;
  ref?: string;
  isSuperAdmin?: boolean;
  handleSuppressProduct?: any;
  onMouseEnter?: any;
  onMouseLeave?: any;
  obj?: any;
  geocoords?: any;
  openSnackBar?: any;
  openSnackBarMessage?: any;
  usersInCharge?: any;
  setSpinnerOpen?: any;
  reference?: string;
  index?: number;
  isInMyWallet?: boolean;
  nb_events_to_qualify?: number;
  isBuilding?: boolean;
  name?: string;
  toggleShowProducts?: any;
  hasProducts?: any;
  isFolded?: boolean;
  setAttributionDialogOpen?: any;
  setUuidProductToAttribute?: any;
}

interface CardProps {
  data: ICard;
}

export const PortfolioCard = ({ data }: CardProps) => {
  const {
    title,
    subtitle,
    description,
    tag,
    links,
    image,
    linkId,
    obj,
    ref,
    isSuperAdmin,
    handleSuppressProduct,
    onMouseEnter,
    onMouseLeave,
    geocoords,
    openSnackBar,
    openSnackBarMessage,
    setSpinnerOpen,
    reference,
    index,
    usersInCharge,
    isInMyWallet,
    nb_events_to_qualify,
    isBuilding,
    name,
    toggleShowProducts,
    hasProducts,
    isFolded,
    setAttributionDialogOpen,
    setUuidProductToAttribute
  } = data;

  const navigate = useNavigate();
  const { referencial } = useContext(AppContext);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [shortLinkKind, setShortLinkKind] = React.useState('');
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (referencial) {
      setShortLinkKind(_.sortBy(referencial['short_link.kind'], ['id']));
    }
  }, [referencial]);

  const setContactText = (contact) => {
    let contactText = '';
    if (contact.name !== '') {
      contactText = contact.name;
    } else if (contact.first_name !== '' || contact.last_name !== '') {
      contactText = contact.first_name + ' ' + contact.last_name;
    } else if (contact.company_name !== '') {
      contactText = contact.company_name;
    }
    return contactText;
  };

  const generateLinksElements = () => {
    const elements: Array<React.ReactElement> = [];
    const elementsDeclare: Array<React.ReactElement> = [];
    const elementsView3D: Array<React.ReactElement> = [];

    if (shortLinkKind && links !== null && links !== undefined && links.length > 0) {
      links.forEach((link, index) => {
        if (link.kind === Number(shortLinkKind[3]['id'])) {
          elementsView3D.push(
            <MenuItem key={`view3D-${link.uuid}`}>
              <span style={{ width: '145px' }}>Vue 3D </span>
              <IconButton
                sx={{ '&:hover': { color: appTheme.palette.secondary.main }, paddingRight: '0px' }}
                aria-label="link"
                size="small"
                onClick={handleCloseUserMenu}
                disableRipple
                color={'primary'}
                data-type="edl3D"
                data-index={index}
              >
                <LinkIcon></LinkIcon>
              </IconButton>
              <IconButton
                sx={{ '&:hover': { color: appTheme.palette.secondary.main }, paddingRight: '0px' }}
                aria-label="link"
                size="small"
                onClick={handleCloseUserMenu}
                disableRipple
                color={'primary'}
                data-index={index}
                data-type="edl3DQRCode"
              >
                <QrCode2Icon></QrCode2Icon>
              </IconButton>
            </MenuItem>
          );
        }

        if (link.kind === Number(shortLinkKind[2]['id'])) {
          elementsView3D.push(
            <MenuItem key={`view3D-${link.uuid}`}>
              <span style={{ width: '145px' }}>Vue 3D </span>
              <IconButton
                sx={{ '&:hover': { color: appTheme.palette.secondary.main }, paddingRight: '0px' }}
                aria-label="link"
                size="small"
                onClick={handleCloseUserMenu}
                disableRipple
                color={'primary'}
                data-type="view3D"
                data-index={index}
              >
                <LinkIcon></LinkIcon>
              </IconButton>
              <IconButton
                sx={{ '&:hover': { color: appTheme.palette.secondary.main }, paddingRight: '0px' }}
                aria-label="link"
                size="small"
                onClick={handleCloseUserMenu}
                disableRipple
                color={'primary'}
                data-index={index}
                data-type="view3DQRCode"
              >
                <QrCode2Icon></QrCode2Icon>
              </IconButton>
            </MenuItem>
          );
        }
        if (link.kind === Number(shortLinkKind[1]['id'])) {
          elementsDeclare.push(
            <MenuItem key={`declareEvent-${link.uuid}`} onClick={handleCloseUserMenu}>
              <span style={{ width: '145px' }}>Ticketing</span>
              <IconButton
                sx={{ '&:hover': { color: appTheme.palette.secondary.main }, paddingRight: '0px' }}
                aria-label="link"
                size="small"
                onClick={handleCloseUserMenu}
                disableRipple
                color={'primary'}
                data-index={index}
                data-type="declareEvent"
              >
                <LinkIcon></LinkIcon>
              </IconButton>
              <IconButton
                sx={{ '&:hover': { color: appTheme.palette.secondary.main }, paddingRight: '0px' }}
                aria-label="link"
                size="small"
                onClick={handleCloseUserMenu}
                disableRipple
                color={'primary'}
                data-index={index}
                data-type="QRCode"
              >
                <QrCode2Icon></QrCode2Icon>
              </IconButton>
            </MenuItem>
          );
        }
      });
      elements.push(...elementsView3D, ...elementsDeclare);
      return elements;
    }
  };

  const handleCloseUserMenu = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(null);

    if (e.currentTarget.getAttribute('data-type') !== null) {
      let linkEdl3D = [];
      let linkView3D = [];
      let linkDeclareEvent = [];

      links.forEach((link, index) => {
        if (link.kind === Number(shortLinkKind[2]['id'])) {
          linkView3D[index] = link;
        } else if (link.kind === Number(shortLinkKind[1]['id'])) {
          linkDeclareEvent[index] = link;
        } else if (link.kind === Number(shortLinkKind[3]['id'])) {
          linkEdl3D[index] = link;
        }
      });

      if (
        e.currentTarget.getAttribute('data-type') === 'QRCode' ||
        e.currentTarget.getAttribute('data-type') === 'view3DQRCode' ||
        e.currentTarget.getAttribute('data-type') === 'edl3DQRCode'
      ) {
        const snackBarMessage =
          e.currentTarget.getAttribute('data-type') === 'QRCode'
            ? "Le QR Code de l'outil de ticketing a été téléchargé"
            : e.currentTarget.getAttribute('data-type') === 'view3DQRCode'
              ? 'Le QR Code de la vue 3D a été téléchargé'
              : "Le QR Code de l'EDL 3D a été téléchargé";
        const qrcodeBaseUrl = process.env.REACT_APP_API_URL + '/links';

        function forceDownload(url, fileName) {
          const xhr = new XMLHttpRequest();
          xhr.open('GET', url, true);
          xhr.responseType = 'blob';
          xhr.onload = function () {
            const urlCreator = window.URL || window.webkitURL;
            const imageUrl = urlCreator.createObjectURL(this.response);
            const tag = document.createElement('a');
            tag.href = imageUrl;
            tag.download = fileName;
            document.body.appendChild(tag);
            tag.click();
            document.body.removeChild(tag);
          };

          xhr.onreadystatechange = () => {
            if (xhr.readyState === 4 && xhr.status === 200) {
              openSnackBar(true);
              openSnackBarMessage(snackBarMessage);
            }
          };
          xhr.send();
        }

        if (e.currentTarget.getAttribute('data-type') === 'view3DQRCode') {
          const fileName = data.obj.address
            ? `QRCode vue 3D ${data.obj.address.street} ${data.obj.address.zip_code} ${data.obj.address.city}.png`
            : 'QRCodeVue3D.png';

          forceDownload(
            `${qrcodeBaseUrl}/${linkView3D[Number(e.currentTarget.getAttribute('data-index'))].uuid}/qrcode`,
            normalizedString(fileName).replace(/ /g, '_').replace(/,/g, '')
          );
        }

        if (e.currentTarget.getAttribute('data-type') === 'edl3DQRCode') {
          const fileName = data.obj.address
            ? `QRCode EDL 3D ${data.obj.address.street} ${data.obj.address.zip_code} ${data.obj.address.city}.png`
            : 'QRCodeEDL3D.png';

          forceDownload(
            `${qrcodeBaseUrl}/${linkEdl3D[Number(e.currentTarget.getAttribute('data-index'))].uuid}/qrcode`,
            normalizedString(fileName).replace(/ /g, '_').replace(/,/g, '')
          );
        }

        if (e.currentTarget.getAttribute('data-type') === 'QRCode') {
          const fileName = data.obj.address
            ? `QRCode Ticketing ${data.obj.address.street} ${data.obj.address.zip_code} ${data.obj.address.city}.png`
            : 'QRCodeTicketing.png';

          forceDownload(
            `${qrcodeBaseUrl}/${linkDeclareEvent[Number(e.currentTarget.getAttribute('data-index'))].uuid}/qrcode`,
            normalizedString(fileName).replace(/ /g, '_').replace(/,/g, '')
          );
        }

        // });
      } else {
        const avrtBaseUrl = process.env.REACT_APP_BASEURL_AVRT;
        if (e.currentTarget.getAttribute('data-type') === 'view3D') {
          openSnackBarMessage('Le lien de la vue 3D a été copié dans le presse papier');
          navigator.clipboard.writeText(
            `${avrtBaseUrl}/${linkView3D[Number(e.currentTarget.getAttribute('data-index'))].uuid}`
          );
        } else if (e.currentTarget.getAttribute('data-type') === 'declareEvent') {
          openSnackBarMessage("Le lien de l'outil de ticketing a été copié dans le presse papier");
          navigator.clipboard.writeText(
            `${avrtBaseUrl}/${linkDeclareEvent[Number(e.currentTarget.getAttribute('data-index'))].uuid}`
          );
        } else if (e.currentTarget.getAttribute('data-type') === 'edl3D') {
          openSnackBarMessage("Le lien de l'EDL 3D a été copié dans le presse papier");
          navigator.clipboard.writeText(
            `${avrtBaseUrl}/${linkEdl3D[Number(e.currentTarget.getAttribute('data-index'))].uuid}`
          );
        }
        openSnackBar(true);
      }
    }
  };

  const handleSuppress = () => {
    handleSuppressProduct(linkId, data.isBuilding);
  };

  let bgColor = '#ededed';

  if (usersInCharge) {
    usersInCharge.forEach((user) => {
      if (isInMyWallet) {
        bgColor = '#FFF';
      } else {
        bgColor = '#E9E9E9';
      }
    });
  }

  return (
    <Box
      className="PCard"
      data-linkid={linkId}
      data-lat={geocoords[0]}
      data-lgt={geocoords[1]}
      data-index={index}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={{}}
      sx={[
        {
          minWidth: 256,
          height: 408,
          borderRadius: '20px',
          background: bgColor,
          border: '1px solid',
          outline: data.isBuilding ? '5px solid' : '0px',
          outlineColor: nb_events_to_qualify !== 0 ? appTheme.palette.secondary.main : '#dadada',
          borderColor: nb_events_to_qualify !== 0 ? 'secondary.main' : '#dadada',
          boxShadow: '0px 25px 22px -24px rgba(0, 0, 0, 0.1)',
          position: 'relative'
        },
        {
          '&:hover': {
            borderColor: 'primary.main',
            outlineColor: appTheme.palette.primary.main
          }
        },
        {
          '&:not(hover)': {
            borderColor: 'secondary.main',
            outlineColor: appTheme.palette.secondary.main
          }
        }
      ]}
    >
      <Link
        sx={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          display: 'block',
          zIndex: 1000,
          cursor: 'pointer'
        }}
        href={!obj.isBuilding ? `/product/${linkId}` : `/building/${linkId}`}
      ></Link>
      <Box
        sx={{
          position: 'absolute',
          p: '16px',
          top: 0,
          bottom: 0
        }}
      >
        <Box
          sx={{
            p: '10px',
            mb: '16px',
            width: 204,
            height: 140,
            borderRadius: '16px',
            background:
              image !== '' && image !== null
                ? `url(${image}) no-repeat center center #CCC`
                : 'repeating-linear-gradient(-45deg,#0627461d,#0627461d 20px,#06274614 20px,#06274614 40px)',
            backgroundSize: 'cover'
          }}
        >
          {Number(nb_events_to_qualify) > 0 && (
            <Chip
              component="a"
              href={!obj.isBuilding ? `/product/${linkId}/events` : `/building/${linkId}/events`}
              sx={{
                zIndex: 1000,
                position: 'absolute',
                fontWeight: '500',
                color: '#FFF'
              }}
              size="small"
              label={`${nb_events_to_qualify < 99 ? nb_events_to_qualify : '99+'} évènements en attente`}
              color="secondary"
              clickable
            />
          )}
        </Box>

        <Box>
          {data.isBuilding && data.name && (
            <>
              <Typography sx={{ fontSize: 12, mb: 0 }} color="text.secondary" gutterBottom>
                Bâtiment :
              </Typography>
              <Typography sx={{ fontSize: 14, mb: 0 }} color="text.primary" gutterBottom>
                {data.name}
              </Typography>
            </>
          )}
          <Typography sx={{ fontSize: 12, mb: 0 }} color="text.secondary" gutterBottom>
            Adresse :
          </Typography>
          <Typography sx={{ fontSize: 14 }} color="text.primary" gutterBottom>
            {title + ' ' + subtitle + ' ' + description}
          </Typography>
          {obj.situation && (
            <Typography sx={{ fontSize: 12, mb: 0 }} color="text.secondary" gutterBottom>
              Situation :
            </Typography>
          )}
          {obj.owner && (
            <>
              <Typography sx={{ fontSize: 12, mb: 0 }} color="text.secondary" gutterBottom>
                Propriétaire :
              </Typography>
              <Typography sx={{ fontSize: 14 }} color="secondary.main" gutterBottom>
                {setContactText(obj.owner)}
              </Typography>
            </>
          )}
          {obj.tenant && (
            <>
              <Typography sx={{ fontSize: 12, mb: 0 }} color="text.secondary" gutterBottom>
                Locataire :
              </Typography>
              <Typography sx={{ fontSize: 14 }} color="secondary.main" gutterBottom>
                {setContactText(obj.tenant)}
              </Typography>
            </>
          )}
          {obj.id_internal && (
            <>
              <Typography sx={{ fontSize: 12, mb: 0 }} color="text.secondary" gutterBottom>
                Réf :
              </Typography>
              <Typography sx={{ fontSize: 14 }} color="text.primary" gutterBottom>
                {obj.id_internal}
              </Typography>
            </>
          )}

          <Box
            sx={{
              position: 'absolute',
              bottom: '12px',
              left: '16px',
              right: '10px'
            }}
          >
            <Typography sx={{ fontSize: 11, fontWeight: 500 }} color="text.primary" gutterBottom>
              {isInMyWallet ? 'Mon portefeuille' : usersInCharge ? 'Attribué à ' + usersInCharge[0].full_name : ''}
            </Typography>
            {usersInCharge === null && (
              <Grid
                container
                direction="row-reverse"
                justifyContent="flex-start"
                alignItems="center"
                spacing={0}
                sx={{ position: 'absolute', zIndex: 1000, width: '100px', left: '-10px', bottom: '10px' }}
              >
                <Chip
                  component="a"
                  onClick={(e) => {
                    console.log('click on chip', obj);
                    setUuidProductToAttribute(obj.uuid);
                    setAttributionDialogOpen(true);
                  }}
                  // href={!obj.isBuilding ? `/product/${linkId}/events` : `/building/${linkId}/events`}
                  sx={{
                    zIndex: 1000,
                    position: 'absolute',
                    fontWeight: '500',
                    color: '#FFF'
                  }}
                  size="small"
                  label={`A attribuer +`}
                  color="secondary"
                  clickable
                />
                {/* <Chip>
                  <Typography
                    sx={{
                      pointer: 'cursor',
                      fontSize: 11,p
                      fontWeight: 500,
                      '&:hover': {
                        color: 'purple'
                      }
                    }}
                    color="secondary.main"
                    gutterBottom
                    onClick={(e) => {
                      // toggleShowProducts();
                    }}
                  >
                    A attribuer +
                  </Typography> */}
                {/* </Chip> */}
              </Grid>
            )}
            <Grid
              container
              direction="row-reverse"
              justifyContent="flex-start"
              alignItems="center"
              spacing={0}
              sx={{ position: 'absolute', zIndex: 1000, width: '100px', right: '0px', bottom: '0px' }}
            >
              <Grid item>
                {links !== null && (
                  <>
                    <IconButton
                      id="basic-button"
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      onClick={handleClick}
                      size="small"
                    >
                      <IosShareOutlined sx={{ color: 'text.primary' }} fontSize="small" />
                    </IconButton>

                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button'
                      }}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                      }}
                    >
                      {generateLinksElements()}
                    </Menu>
                  </>
                )}
              </Grid>
              <Grid item>
                {(isSuperAdmin || isAdmin) && (
                  <IconButton onClick={handleSuppress} size="small">
                    <DeleteOutlineOutlinedIcon sx={{ color: 'text.primary' }} fontSize="small" />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PortfolioCard;
