import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const LocationListener = ({ ReactGA }) => {
  const location = useLocation(); // Récupère l'URL actuelle

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname }); // Envoie une pageview à Google Analytics
  }, [location]); // Se déclenche à chaque changement de l'URL

  return null; // Ce composant n'affiche rien
};

export default LocationListener;
