import React from 'react';
import { Box, Grid, Typography, useTheme, useMediaQuery } from '@mui/material';
import { StepsViewer } from '~/app/components/StepsViewer/StepsViewer';

import { WizzardMissionOrder } from '~/app/components/WizzardMissionOrder/WizzardMissionOrder';

import { api } from '~/api';

export const WizzardMissionOrderForm = ({ spinnerOpen, setSpinnerOpen, handleClose, productData, scanOrder }) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [data, setData] = React.useState({});
  const [scanData, setScanData] = React.useState({});

  const handleAssignation = async (idOdm: string, idUser: string) => {
    api.missionOrders
      .assign(idOdm, idUser)
      .then((response) => setTimeout(handleClose(), 1000))
      .catch((error) => {});
  };

  const handleCreateMissionOrder = async (
    isScan: boolean,
    isEdl: boolean,
    contactPhotograph,
    contactTechEdl,
    contactUserEdl
  ) => {
    let uuidOdmEdl = '';
    if (isEdl) {
      if (contactUserEdl) {
        await api.missionOrders
          .create(data)
          .then((response) => {
            handleAssignation(response.uuid, contactUserEdl.uuid);
            uuidOdmEdl = response.uuid;
          })
          .catch((error) => {});
      } else {
        await api.missionOrders
          .create(data)
          .then((response) => {
            handleAssignation(response.uuid, contactTechEdl.uuid);
            uuidOdmEdl = response.uuid;
          })
          .catch((error) => {});
      }
    }
    if (isScan) {
      await api.missionOrders
        .create(scanData)
        .then((response) => {
          if (uuidOdmEdl) {
            api.missionOrders.odmLink(response.uuid, uuidOdmEdl);
          }
          handleAssignation(response.uuid, contactPhotograph.uuid);
        })
        .catch((error) => {});
    }
    if (!contactPhotograph && !contactTechEdl && !contactUserEdl) {
      handleClose();
    }
  };

  const steps = ['Choix des missions', 'Informations nécessaires', 'Informations pratiques'];

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h1" sx={{ color: 'primary.main' }}>
          Nouvel ordre de mission
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Box sx={{ maxWidth: 600, ml: '5vw' }}>
          <StepsViewer steps={steps} currentStep={activeStep} />
        </Box>
      </Grid>
      <Grid item xs={7}>
        <WizzardMissionOrder
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          product={productData ? productData : null}
          setData={setData}
          data={data}
          scanData={scanData}
          setScanData={setScanData}
          handleCreateMissionOrder={handleCreateMissionOrder}
          closeModal={handleClose}
          scanOrder={scanOrder}
        />
      </Grid>
    </Grid>
  );
};
