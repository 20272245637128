export type ContactBase = {
  uuid: string;
  name: string;
  // phones and mails
  phone1: string;
  phone2: string;
  email: string;
  // pro
  company_name: string;
  job_title: string;
  // keywords
  keywords: string[] | null;
  // extra data...
  data: object | null;
  // dates
  created_at: Date;
  update_at: Date;
  delete_at: Date | null;
}

export const contactBaseFieldsAllowed = [
  'uuid',
  'name',
  'phone1',
  'phone2',
  'email',
  'company_name',
  'job_title',
  'keywords',
  'data',
  'created_at',
  'update_at',
  'delete_at'
];


export interface IContactCreate {
  job_title?: string;
  phone?: string;
  email?: string;
  name?: string;
  company_name?: string;
  avatar_url?: string;
  // extra data...
  data?: object | null;
}


export type ContactResponse = {} & ContactBase;

/**
 * @description ContactCreate is used for creating a new contact from scrach.
 */
export type ContactCreate = {
  name?: string | undefined;
  // phones and mails
  phone1?: string | undefined;
  phone2?: string | undefined;
  email?: string | undefined;
  // pro
  company_name?: string | undefined;
  job_title?: string | undefined;
  // keywords
  keywords?: string[] | undefined;
  // extra data...
  //  data?: object | undefined;
  data?: Record<string, any> | undefined;

};

/**
 * @description ContactCreate is used for updating a contact.
 */
export type ContactUpdate = {
  name?: string | undefined;
  // phones and mails
  phone1?: string | undefined;
  phone2?: string | undefined;
  email?: string | undefined;
  // pro
  company_name?: string | undefined;
  job_title?: string | undefined;
  // keywords
  keywords?: string[] | null | undefined;
  // extra data...
  data?: object | null | undefined;
};

export const ContactRelKind = {
  Other: 0,
  Owner: 1,
  Tenant: 2,
  Worker: 3,
  Guardian: 4,
  Syndic: 5,
  Council: 6,
  SeviceProvider: 7,
  Ocuppant :8
} as const;

export type ContactRelKind = (typeof ContactRelKind)[keyof typeof ContactRelKind];

export const ContactRelKindToString = (ck: ContactRelKind): string => {
  switch (ck) {
    case ContactRelKind.Owner:
      return 'Propriétaire';
    case ContactRelKind.Tenant:
      return 'Locataire';
    case ContactRelKind.Worker:
      return 'Employé';
      case ContactRelKind.Ocuppant:
        return 'Occupant';
    case ContactRelKind.Guardian:
      return 'Gardien';
    case ContactRelKind.Syndic:
      return 'Syndic';
    case ContactRelKind.Council:
      return 'Conseil';
      case ContactRelKind.SeviceProvider:
        return 'Prestataire';
    default:
      return 'Autre';
  }
};
