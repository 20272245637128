import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  Radio,
  RadioGroup,
  Autocomplete,
  Dialog,
  DialogContent,
  IconButton
} from '@mui/material';
import { api } from '~/api';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { Dayjs } from 'dayjs';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import { WizzardContact } from '~/app/components/WizzardContact/WizzardContact';

export const WizzardMissionOrderScan = ({
  productSelected,
  nextActiveStepViewer,
  previousActiveStepViewer,
  previousStep,
  isFurnished,
  buildingCode,
  allContactsOrga,
  closeModal,
  isBuilding
}) => {
  const [stepFormScan, setStepFormScan] = useState(0);
  const [refFolderScan, setRefFolderScan] = useState('');
  const [isFurnishedForm, setIsFurnishedForm] = useState(isFurnished);
  const [buildingCodeForm, setBuildingCodeForm] = useState(buildingCode);
  const [scanExpectedDate, setScanExpectedDate] = useState<Dayjs | null>(null);
  const [contact, setContact] = useState(null);
  const [allContactsOrgaForm, setAllContactsOrgaForm] = useState(allContactsOrga);
  const [addContact, setAddContact] = useState(false);
  const [commentary, setCommentary] = useState('');
  const [allContacts, setAllContacts] = useState([]);
  const [openScanCreated, setOpenScanCreated] = useState(false);
  const [openHelperTextRefFolder, setOpenHelperTextRefFolder] = useState(false);

  const handleNextStep = () => {
    setStepFormScan(stepFormScan + 1);
  };

  const handlePrevStep = () => {
    setStepFormScan(stepFormScan - 1);
  };

  const handleRefFolder = (event) => {
    setRefFolderScan(event.target.value);
  };

  const handleIsFurnished = (event) => {
    if (event.target.value === 'true') {
      setIsFurnishedForm(true);
    } else {
      setIsFurnishedForm(false);
    }
  };

  const handleBuildingCode = (event) => {
    setBuildingCodeForm(event.target.value);
  };

  const handleDateScan = (date) => {
    setScanExpectedDate(date);
  };

  const handleContact = (event, value) => {
    setContact(value);
  };

  const handleCommentary = (event) => {
    setCommentary(event.target.value);
  };

  const handleOpenHelperTextRefFolder = () => {
    setOpenHelperTextRefFolder(!openHelperTextRefFolder);
  };

  useEffect(() => {
    nextActiveStepViewer();

    api.product.contact.get(productSelected.uuid).then((res) => {
      setAllContacts(res);
    });
    generateRefFolder(productSelected);
  }, [productSelected]);

  const generateRefFolder = (producSelected) => {
    if (isBuilding) {
      const refFolder = `${producSelected?.address?.street}-${producSelected?.name}-scan`;
      setRefFolderScan(refFolder);
    } else {
      const refFolder = `${producSelected?.id_internal}-${producSelected?.address?.street}-scan`;
      setRefFolderScan(refFolder);
    }
    return null;
  };

  const handleCreateScan = async () => {
    const payload = {
      expected_date: scanExpectedDate.format(),
      kind: 2,
      ref_internal: refFolderScan,
      uuid_property: productSelected.uuid,
      data: {
        is_furnished: isBuilding ? isFurnishedForm : null,
        building_code: buildingCodeForm,
        contact: contact,
        commentary: commentary
      }
    };
    await api.missionOrders
      .create(payload)
      .then((response) => {
        setOpenScanCreated(true);
      })
      .catch((error) => {});
  };

  const handleCloseScanCreated = () => {
    setOpenScanCreated(false);
    closeModal();
  };

  return (
    <Grid container spacing={2} sx={{ ml: '0.1vw' }}>
      <Grid item xs={12}>
        <Typography variant="h4" sx={{ color: 'primary.main' }}>
          Informations nécessaires pour la modélisation 3D :
        </Typography>
      </Grid>
      {stepFormScan === 0 && (
        <>
          <Grid item xs={12} md={5.3} display={'flex'} alignItems={'center'}>
            <TextField
              label="Référence du dossier *"
              variant="outlined"
              size="small"
              fullWidth
              value={refFolderScan}
              onChange={handleRefFolder}
            />
            <IconButton size="small" color="primary" onClick={handleOpenHelperTextRefFolder} sx={{ ml: '1.5vw' }}>
              <HelpOutlineIcon fontSize="large" />
            </IconButton>
          </Grid>

          {!isBuilding && (
            <>
              <Grid item xs={12} sx={{ mt: '2vh' }}>
                <Typography variant="h6">Le bien est-il meublé ?</Typography>
              </Grid>
              <Grid item xs={6}>
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    aria-label="position"
                    name="position"
                    value={isFurnishedForm}
                    onChange={handleIsFurnished}
                  >
                    <FormControlLabel value={true} control={<Radio color="primary" />} label="Oui" />
                    <FormControlLabel value={false} control={<Radio color="primary" />} label="Non" />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </>
          )}
        </>
      )}
      {stepFormScan === 1 && (
        <React.Fragment>
          <Grid item xs={12}>
            <Typography variant="h5">DATE SOUHAITÉE POUR LA RÉALISATION DE L’ÉTAT DES LIEUX :</Typography>
          </Grid>

          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
            <Grid item xs={10} md={4}>
              <DesktopDatePicker
                format="DD/MM/YYYY"
                value={scanExpectedDate}
                onChange={(date) => handleDateScan(date)}
                label="Date *"
                sx={{ width: '9vw', height: '3vh' }}
              />
            </Grid>
          </LocalizationProvider>
          <Grid item xs={12} sx={{ mt: '3vh' }}>
            <Typography variant="h5">Personne à contacter pour la prise de rendez-vous :</Typography>
          </Grid>
          <Grid item xs={12} md={3.5}>
            <Autocomplete
              options={allContactsOrgaForm}
              getOptionLabel={(option) => option?.name || ''}
              onChange={handleContact}
              renderInput={(params) => <TextField {...params} label="Nom du contact *" variant="outlined" />}
              size="small"
              noOptionsText="Aucun contact trouvé"
              isOptionEqualToValue={(option, value) => option?.name === value}
              value={contact}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Button
              variant="contained"
              sx={{ borderRadius: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              onClick={() => setAddContact(!addContact)}
            >
              <AddOutlinedIcon /> Créer un nouveau contact
            </Button>
          </Grid>
          <Grid item xs={12} md={3.5}>
            <TextField
              label="Code accès immeuble"
              variant="outlined"
              size="small"
              fullWidth
              value={buildingCodeForm}
              onChange={handleBuildingCode}
            />
          </Grid>
          <Grid item xs={12}></Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Commentaires"
              multiline
              rows={2}
              placeholder="Où se trouvent les clés, Où se trouvent les compteurs"
              variant="outlined"
              size="small"
              fullWidth
              value={commentary}
              onChange={handleCommentary}
            />
          </Grid>
        </React.Fragment>
      )}

      <Grid item xs={12} sx={{ mt: '3vh' }}>
        <Box sx={{ my: 2 }}>
          <Button
            sx={{ marginRight: 2 }}
            onClick={() => {
              if (stepFormScan === 0) {
                previousStep();
                previousActiveStepViewer();
              } else if (stepFormScan === 1) {
                previousActiveStepViewer();
                handlePrevStep();
              } else {
                handlePrevStep();
              }
            }}
          >
            Retour
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              if (stepFormScan === 0) {
                nextActiveStepViewer();
              }
              if (stepFormScan === 1) {
                handleCreateScan();
              } else {
                handleNextStep();
              }
            }}
            disabled={
              (stepFormScan === 0 && isBuilding && refFolderScan === '') ||
              (stepFormScan === 0 && !isBuilding && (refFolderScan === '' || isFurnishedForm === null)) ||
              (stepFormScan === 1 && (scanExpectedDate === null || contact === null))
            }
          >
            {stepFormScan === 1 ? 'Soumettre' : 'Suivant'}
          </Button>
        </Box>
      </Grid>
      {addContact && (
        <Dialog open={addContact} onClose={() => setAddContact(false)}>
          <DialogContent>
            <WizzardContact
              open={addContact}
              isProductPage={true}
              onClose={() => setAddContact(false)}
              isNewContact={true}
              productId={productSelected.uuid}
              contacts={allContacts}
              setContacts={setAllContacts}
            />
          </DialogContent>
        </Dialog>
      )}

      {openScanCreated && (
        <Dialog open={openScanCreated} onClose={handleCloseScanCreated}>
          <DialogContent>
            <Typography variant="h5" textAlign={'center'} sx={{ mb: '1vh' }}>
              Votre commande de modélisation 3D a bien été prise en compte.
            </Typography>
            <Typography variant="h5" textAlign={'center'}>
              La visite virtuelle sera disponible dans la fiche du bien dès le lendemain de la mission.
            </Typography>
          </DialogContent>
        </Dialog>
      )}

      <Dialog open={openHelperTextRefFolder} onClose={handleOpenHelperTextRefFolder}>
        <Box sx={{ p: 3 }}>
          <Typography variant="h5" textAlign={'center'} color={'primary'}>
            La référence du dossier que vous allez renseigner nous servira de référence pour la facturation.
          </Typography>
        </Box>
      </Dialog>
    </Grid>
  );
};
