import { axiosPost, axiosGet, axiosPatch, axiosDelete } from '.';

export const createMissionOrder = (data: any) => {
  return axiosPost('/mission-orders', data);
};

export const searchMissionOrder = () => {
  return axiosGet(`/mission-orders`);
};

export const getTechnicians = () => {
  return axiosGet('/mission-orders/technicians');
};

export const getPhotographs = () => {
  return axiosGet('mission-orders/photographs');
};

export const assignMissionOrder = (idOdm: string, idUser: any) => {
  return axiosPost(`/mission-orders/${idOdm}/assign/${idUser}`, {});
};

export const updateMissionOrder = (idOdm: string, data: any) => {
  return axiosPatch(`/mission-orders/${idOdm}`, data);
};

export const odmLink = (idOdm: string, idOtherOdm: string) => {
  return axiosPatch(`/mission-orders/${idOdm}/associate-to-odm/${idOtherOdm}`, {});
};

export const scanAppointmentDate = (idOdm: string, data: any) => {
  return axiosPatch(`/mission-orders/${idOdm}/appointment-date`, data);
};

export const deleteMissionOrder = (idOdm: string) => {
  return axiosDelete(`/mission-orders/${idOdm}`);
};
