import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { ReactNode, useContext, useEffect, useState } from 'react';
import { Slide } from '@mui/material';
import { MediaQueryContext } from '~/utils/context/MediaQueryProvider';
import './BasicModal.scss';

interface BasicModalProps {
  children: ReactNode;
  modalOpen: boolean;
  confirmClose?: boolean;
  handleConfirm?: any;
  onModalOpen: (open: boolean) => void;
  onModalClose: (str: string) => void;
  context?: null | string;
}

export const BasicModal = ({
  children,
  modalOpen,
  onModalOpen,
  onModalClose,
  confirmClose = null,
  handleConfirm = null,
  context = null
}: BasicModalProps) => {
  const mediaQueries = useContext(MediaQueryContext);
  const style = {
    position: 'absolute',
    width: '100%',
    height: '100%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    outline: 'none'
  };
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
    onModalOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    onModalOpen(false);
    onModalClose('update');
  };

  useEffect(() => {
    modalOpen ? handleOpen() : handleClose();
  }, [modalOpen]);

  return (
    <div>
      <Modal
        open={open}
        onClose={confirmClose ? handleConfirm : handleClose}
        className="BasicModal"
        sx={{
          backdropFilter: 'blur(5px)'
        }}
      >
        <Slide direction="up" in={open}>
          <Box sx={style as any} className={context !== 'View3d' ? 'BasicModal__content' : ''}>
            <div className="BasicModal__close" onClick={confirmClose ? handleConfirm : handleClose}>
              <CloseIcon fontSize={mediaQueries.matchesMd ? 'medium' : 'small'} />
            </div>
            {children}
          </Box>
        </Slide>
      </Modal>
    </div>
  );
};

export default BasicModal;
