import { Grid2 } from '@mui/material';
import { EventItem } from '../EquipmentsList/EventItem';

export const EventsList2 = ({ events }) => {
  return (
    <Grid2 container spacing={2}>
      {events.map((event) => (
        <Grid2 size={12} key={event.id}>
          <EventItem event={event} />
        </Grid2>
      ))}
    </Grid2>
  );
};

export default EventsList2;
