import React, { useEffect, useState } from 'react';
import { Autocomplete, Box, Button, Grid, Modal, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import dayjs, { Dayjs } from 'dayjs';
import { api } from '~/api';
import RemoveIcon from '@mui/icons-material/Remove';

export const WizzardMissionOrderRecap = ({ data, close }) => {
  const [state, setState] = useState('NC');
  const [edlType, setEdlType] = useState('');
  const [edlKind, setEdlKind] = useState('');
  const [productKind, setProductKind] = useState('');

  const [allTechs, setAllTechs] = useState([]);
  const [allPhotographs, setAllPhotographs] = useState([]);
  const [allUsersOrganization, setAllUsersOrganization] = useState([]);

  const [contactPhotographScan, setContactPhotographScan] = useState(null);
  const [contactTechEdl, setContactTechEdl] = useState(null);
  const [contactUserEdl, setContactUserEdl] = useState(null);
  const [userTech, setUserTech] = useState(null);

  const [isEdit, setIsEdit] = useState(false);
  const [appointmentDate, setAppointmentDate] = React.useState<Dayjs | null>();

  const [displayButton, setDisplayButton] = useState(false);

  const [openDeleteOdm, setOpenDeleteOdm] = useState(false);

  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.up('xs'));
  const matchesSm = useMediaQuery(theme.breakpoints.up('sm'));
  const matchesMd = useMediaQuery(theme.breakpoints.up('md'));
  const matchesLg = useMediaQuery(theme.breakpoints.up('lg'));
  const matchesXl = useMediaQuery(theme.breakpoints.up('xl'));

  useEffect(() => {
    handleState(data?.data?.general_state);
    handleEdlType(data?.data?.edl_kind);
    handleEdlKind(data?.data?.edl_kind);
    handleProductKind(data?.data?.property.kind);
  }, [data]);

  useEffect(() => {
    api.missionOrders.getTechnicians().then((response) => {
      setAllTechs(response.technicians);
    });
    api.missionOrders.getPhotographs().then((response) => {
      setAllPhotographs(response.photographs);
    });
  }, []);

  const address = `${data?.data?.address?.street} ${data?.data?.address?.city}, ${data?.data?.address?.zip_code}`;

  const handleState = (generalState) => {
    setState(generalState);
    switch (generalState) {
      case 1:
        setState('NC');
        break;
      case 2:
        setState('Neuf');
        break;
      case 3:
        setState('Rénové');
        break;
      case 4:
        setState('Bon état');
        break;
      case 5:
        setState("Etat d'usage");
        break;
      case 6:
        setState('Dégradé');
        break;
      default:
        setState('NC');
        break;
    }
  };

  const handleEdlType = (edlType) => {
    setEdlType(edlType);
    switch (edlType) {
      case 1:
        setEdlType('EDL');
        break;
      case 2:
        setEdlType('EDL');
        break;
      case 3:
        setEdlType('EDL3D');
        break;
      case 4:
        setEdlType('EDL3D');
        break;
      default:
        setEdlType('NC');
        break;
    }
  };

  const handleEdlKind = (edlKind) => {
    setEdlKind(edlKind);
    switch (edlKind) {
      case 1:
        setEdlKind('Entrée');
        break;
      case 2:
        setEdlKind('Sortie');
        break;
      case 3:
        setEdlKind('Entrée');
        break;
      case 4:
        setEdlKind('Sortie');
        break;
      default:
        setEdlKind('NC');
        break;
    }
  };

  const handleProductKind = (productKind) => {
    switch (productKind) {
      case 1:
        setProductKind('Appartement');
        break;
      case 2:
        setProductKind('Bureau');
        break;
      case 3:
        setProductKind('Partie Communes');
        break;
      case 5:
        setProductKind('Dépendance');
        break;
      case 7:
        setProductKind('Commerce');
        break;
      default:
        setProductKind('Inconnu');
        break;
    }
  };

  const formatDate = (dateString, withHour) => {
    const date = dayjs(dateString);
    if (withHour) return date.format('DD/MM/YYYY HH:mm');
    else return date.format('DD/MM/YYYY');
  };

  const handleEdit = () => {
    setIsEdit(!isEdit);
  };

  const handleContactPhotograph = (e, newValue) => {
    if (!newValue) return '';
    setContactPhotographScan(newValue);
  };

  const handleAssignTechContact = (e, newValue) => {
    if (!newValue) return '';
    setContactTechEdl(newValue);
  };

  const handleAssignTechUser = (e, newValue) => {
    if (!newValue) return '';
    setContactUserEdl(newValue);
  };

  const handleAssignation = async (idOdm: string, idUser: string) => {
    api.missionOrders
      .assign(idOdm, idUser)
      .then(() => {
        close();
      })
      .catch((error) => {});
  };

  const handleOpenDeleteOdm = () => {
    setOpenDeleteOdm(!openDeleteOdm);
  };

  const deleteOdm = () => {
    api.missionOrders
      .delete(data.uuid)
      .then(() => {
        handleOpenDeleteOdm();
        close();
      })
      .catch((error) => {});
  };

  useEffect(() => {
    api.organizations.get(data.uuid_organization).then((res) => {
      setAllUsersOrganization(res.users);
    });
  }, []);

  const handleValidate = () => {
    if (appointmentDate) {
      api.missionOrders
        .scanAppointmentDate(data.uuid, { appointment_date: appointmentDate })
        .then(() => {
          close();
        })
        .catch((error) => {});
    }
    if (!data?.uuid_user_in_charge) {
      if (data?.kind === 1) {
        if (contactUserEdl) {
          handleAssignation(data?.uuid, contactUserEdl?.uuid);
        } else {
          if (contactTechEdl) handleAssignation(data?.uuid, contactTechEdl.users[0].uuid);
        }
      } else if (data?.kind === 2) {
        if (contactPhotographScan) handleAssignation(data?.uuid, contactPhotographScan.users[0].uuid);
      }
    }
  };

  useEffect(() => {
    const date = '0001-01-01T00:00:21Z';
    if (data) {
      if (data?.uuid_user_in_charge === '' || !data?.appointment_date) {
        setDisplayButton(true);
      }
      if (data?.kind === 1) {
        if (data?.uuid_user_in_charge) {
          setDisplayButton(false);
        }
      }
    }
  }, [data]);

  return (
    <Grid container spacing={2} sx={{ overflowX: 'auto' }}>
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: !matchesMd ? 'column' : 'row' }}>
          <Grid item xs={12} md={5}>
            <Typography
              variant="h6"
              gutterBottom
              color="primary"
              sx={{
                marginBottom: '1vh',
                marginTop: '2vh',
                ml: '1vw'
              }}
            >
              INFORMATIONS GENERALES
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} display={'flex'} alignItems={'center'} justifyContent={'space-around'}>
            {displayButton && (
              <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={() => {
                  handleEdit();
                }}
              >
                Editer
              </Button>
            )}

            <Button
              color="primary"
              variant="outlined"
              sx={{ borderRadius: '20px', paddingTop: '8px', ml: '1vw' }}
              onClick={handleOpenDeleteOdm}
              size="small"
            >
              <RemoveIcon fontSize="small" />
              <span style={{ fontWeight: 'bold', fontSize: '15px' }}>&nbsp; Supprimer l'ordre de service</span>
            </Button>
          </Grid>
        </div>

        <br />
        <div
          style={{
            maxHeight: '68vh',
            overflowY: 'auto',
            overflowX: 'hidden'
          }}
        >
          {data?.kind === 1 && (
            <>
              <Grid container spacing={2} sx={{ ml: '2vw' }}>
                <Grid item xs={12}>
                  <Typography variant="h5">Périmètre de la mission :</Typography>
                </Grid>
                <Grid item xs={12} sm={5} sx={{ display: 'flex', ml: '1vw' }}>
                  <Typography variant="h6" sx={{ mr: '1vh' }} color={'#929292'}>
                    Type d'EDL:
                  </Typography>
                  <Typography variant="h6" color={'primary'}>
                    {edlType}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} sx={{ display: 'flex', ml: '1vw' }}>
                  <Typography variant="h6" sx={{ mr: '1vh' }} color={'#929292'}>
                    Etat des lieux à réaliser :
                  </Typography>
                  <Typography variant="h6" color={'primary'}>
                    {edlKind}
                  </Typography>
                </Grid>
                {data?.appointment_date !== null ? (
                  <Grid item xs={11} sx={{ display: 'flex', ml: '1vw' }}>
                    <Typography variant="h6" sx={{ mr: '1vh' }} color={'#929292'}>
                      Date et heure de la mission :
                    </Typography>
                    <Typography variant="h6" color={'primary'}>
                      {data?.appointment_date && formatDate(data?.appointment_date, true)}
                    </Typography>
                  </Grid>
                ) : (
                  <Grid item xs={11} sx={{ display: 'flex', ml: '1vw' }}>
                    <Typography variant="h6" sx={{ mr: '1vh' }} color={'#929292'}>
                      Date souhaitée de la mission :
                    </Typography>
                    <Typography variant="h6" color={'primary'}>
                      {data?.expected_date && formatDate(data?.expected_date, false)}
                    </Typography>
                  </Grid>
                )}

                <Grid item xs={12} sm={6} sx={{ display: 'flex', ml: '1vw' }}>
                  <Typography variant="h6" sx={{ mr: '1vh' }} color={'#929292'}>
                    Adresse:
                  </Typography>
                  <Typography variant="h6" color={'primary'}>
                    {address}
                  </Typography>
                </Grid>
                {data?.data?.property.address_complement && (
                  <Grid item xs={12} sm={5.5} sx={{ display: 'flex', ml: '1vw' }}>
                    <Typography variant="h6" sx={{ mr: '1vh' }} color={'#929292'}>
                      Bâtiment, Etage, Porte :
                    </Typography>
                    <Typography variant="h6" color={'primary'}>
                      {data?.data?.property.address_complement}
                    </Typography>
                  </Grid>
                )}
              </Grid>
              <Grid container spacing={2} sx={{ ml: '2vw', mt: '1vh' }}>
                <Grid item xs={12}>
                  <Typography variant="h5">Donneur d'ordre :</Typography>
                </Grid>
                <Grid item xs={12} sx={{ display: 'flex', ml: '1vw' }}>
                  <Typography variant="h6" sx={{ mr: '1vh' }}>
                    {`${data?.data?.organization?.name} ${data?.data?.organization?.address?.street} ${data?.data?.organization?.address?.city}, ${data?.data?.organization?.address?.zip_code}`}
                  </Typography>
                </Grid>
                {data?.user_contractor && (
                  <Grid item xs={12} sx={{ display: 'flex', ml: '1vw' }}>
                    <Typography variant="h6" sx={{ mr: '1vh' }} color={'#929292'}>
                      Contact :
                    </Typography>
                    <Typography variant="h6" color={'primary'}>
                      {`${data?.user_contractor?.first_name} ${data?.user_contractor?.last_name} - ${data?.user_contractor?.phone1}`}
                    </Typography>
                  </Grid>
                )}
              </Grid>
              <Grid container spacing={2} sx={{ ml: '2vw', mt: '1vh' }}>
                <Grid item xs={12}>
                  <Typography variant="h5">Locataire(s) entrant(s)/sortant(s) :</Typography>
                </Grid>
                {data?.data?.residents?.map((resident) => (
                  <>
                    <Grid item xs={12} md={5} sx={{ display: 'flex', ml: '1vw' }}>
                      <Typography variant="h6" sx={{ mr: '1vh' }} color={'#929292'}>
                        Locataire :
                      </Typography>
                      <Typography variant="h6" color={'primary'}>
                        {resident.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={11} md={6} sx={{ display: 'flex', ml: '1vw' }}>
                      <Typography variant="h6" sx={{ mr: '1vh' }} color={'#929292'}>
                        Email :
                      </Typography>
                      <Typography variant="h6" color={'primary'}>
                        {resident.email}
                      </Typography>
                    </Grid>
                    {resident.phone1 && (
                      <Grid item xs={12} sx={{ display: 'flex', ml: '1vw' }}>
                        <Typography variant="h6" sx={{ mr: '1vh' }} color={'#929292'}>
                          Tel :
                        </Typography>
                        <Typography variant="h6" color={'primary'}>
                          {resident.phone1}
                        </Typography>
                      </Grid>
                    )}
                  </>
                ))}
              </Grid>
              {data?.data?.contact && (
                <Grid container spacing={2} sx={{ ml: '2vw', mt: '1vh' }}>
                  <Grid item xs={12}>
                    <Typography variant="h5">Personne à contacter pour la prise de RDV :</Typography>
                  </Grid>
                  <>
                    <Grid item xs={12} md={5} sx={{ display: 'flex', ml: '1vw' }}>
                      <Typography variant="h6" sx={{ mr: '1vh' }} color={'#929292'}>
                        Contact :
                      </Typography>
                      <Typography variant="h6" color={'primary'}>
                        {data?.data?.contact.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ display: 'flex', ml: '1vw' }}>
                      <Typography variant="h6" sx={{ mr: '1vh' }} color={'#929292'}>
                        Email :
                      </Typography>
                      <Typography variant="h6" color={'primary'}>
                        {data?.data?.contact.email}
                      </Typography>
                    </Grid>
                    {data?.data?.contact.phone1 && (
                      <Grid item xs={12} sx={{ display: 'flex', ml: '1vw' }}>
                        <Typography variant="h6" sx={{ mr: '1vh' }} color={'#929292'}>
                          Tel :
                        </Typography>
                        <Typography variant="h6" color={'primary'}>
                          {data?.data?.contact.phone1}
                        </Typography>
                      </Grid>
                    )}
                  </>
                </Grid>
              )}

              <Grid container spacing={2} sx={{ ml: '2vw', mt: '1vh' }}>
                <Grid item xs={12}>
                  <Typography variant="h5">Informations complémentaires :</Typography>
                </Grid>
                <Grid item xs={12} md={5.5} sx={{ display: 'flex', ml: '1vw' }}>
                  <Typography variant="h6" sx={{ mr: '1vh' }} color={'#929292'}>
                    Date de début du bail :
                  </Typography>
                  <Typography variant="h6" color={'primary'}>
                    {data?.data?.end_lease && formatDate(data?.data?.end_lease, false)}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} sx={{ display: 'flex', ml: '1vw' }}>
                  <Typography variant="h6" sx={{ mr: '1vh' }} color={'#929292'}>
                    {edlKind === 'Entrée' ? 'Date de fin du bail précédent :' : 'Date de fin du bail :'}
                  </Typography>
                  <Typography variant="h6" color={'primary'}>
                    {data?.data?.start_lease && formatDate(data?.data?.start_lease, false)}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={5.5} sx={{ display: 'flex', ml: '1vw' }}>
                  <Typography variant="h6" sx={{ mr: '1vh' }} color={'#929292'}>
                    Meublé :
                  </Typography>
                  <Typography variant="h6" color={'primary'}>
                    {data?.data?.is_furnished ? 'Oui' : 'Non'}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={5.5} sx={{ display: 'flex', ml: '1vw' }}>
                  <Typography variant="h6" sx={{ mr: '1vh' }} color={'#929292'}>
                    Inventaire :
                  </Typography>
                  <Typography variant="h6" color={'primary'}>
                    {data?.data?.with_inventory ? 'Oui' : 'Non'}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={5} sx={{ display: 'flex', ml: '1vw' }}>
                  <Typography variant="h6" sx={{ mr: '1vh' }} color={'#929292'}>
                    Etat du bien :
                  </Typography>
                  <Typography variant="h6" color={'primary'}>
                    {state}
                  </Typography>
                </Grid>
                {data?.data?.building_code && (
                  <Grid item xs={12} md={5} sx={{ display: 'flex', ml: '1vw' }}>
                    <Typography variant="h6" sx={{ mr: '1vh' }} color={'#929292'}>
                      Code immeuble :
                    </Typography>
                    <Typography variant="h6" color={'primary'}>
                      {data?.data?.building_code}
                    </Typography>
                  </Grid>
                )}
                {data?.data?.works_commentary && (
                  <Grid item xs={12} sx={{ display: 'flex', ml: '1vw' }}>
                    <Typography variant="h6" sx={{ mr: '1vh' }} color={'#929292'}>
                      Des travaux ont-ils été effectués ? :
                    </Typography>
                    <Typography variant="h6" color={'primary'}>
                      {data?.data?.works_commentary}
                    </Typography>
                  </Grid>
                )}

                {data.data?.commentary && (
                  <Grid item xs={12} sx={{ display: 'flex', ml: '1vw' }}>
                    <Typography variant="h6" sx={{ mr: '1vh' }} color={'#929292'}>
                      Commentaires :
                    </Typography>
                    <Typography variant="h6" sx={{ mr: '1vh' }}>
                      {data?.data?.commentary}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </>
          )}
          {data?.kind === 2 && (
            <>
              <Grid container spacing={2} sx={{ ml: '2vw' }}>
                <Grid item xs={12}>
                  <Typography variant="h5">Périmètre de la mission :</Typography>
                </Grid>
                <Grid item xs={12} sx={{ display: 'flex', ml: '1vw' }}>
                  <Typography variant="h6" sx={{ mr: '1vh' }}>
                    Scan 3D
                  </Typography>
                </Grid>
                {data?.appointment_date !== null ? (
                  <Grid item xs={10} sx={{ display: 'flex', ml: '1vw' }}>
                    <Typography variant="h6" sx={{ mr: '1vh' }} color={'#929292'}>
                      Date et heure de la mission :
                    </Typography>
                    <Typography variant="h6" color={'primary'}>
                      {data?.appointment_date && formatDate(data?.appointment_date, true)}
                    </Typography>
                  </Grid>
                ) : (
                  <Grid item xs={10} sx={{ display: 'flex', ml: '1vw' }}>
                    <Typography variant="h6" sx={{ mr: '1vh' }} color={'#929292'}>
                      Date souhaitée de la mission :
                    </Typography>
                    <Typography variant="h6" color={'primary'}>
                      {data?.expected_date && formatDate(data?.expected_date, false)}
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={12} md={6} sx={{ display: 'flex', ml: '1vw' }}>
                  <Typography variant="h6" sx={{ mr: '1vh' }} color={'#929292'}>
                    Adresse:
                  </Typography>
                  <Typography variant="h6" color={'primary'}>
                    {address}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ ml: '2vw', mt: '1vh' }}>
                <Grid item xs={12}>
                  <Typography variant="h5">Donneur d'ordre :</Typography>
                </Grid>
                <Grid item xs={12} sx={{ display: 'flex', ml: '1vw' }}>
                  <Typography variant="h6" sx={{ mr: '1vh' }}>
                    {`${data?.data?.organization?.name} ${data?.data?.organization?.address?.street} ${data?.data?.organization?.address?.city}, ${data?.data?.organization?.address?.zip_code}`}
                  </Typography>
                </Grid>
                {data?.user_contractor && (
                  <Grid item xs={11} sx={{ display: 'flex', ml: '1vw' }}>
                    <Typography variant="h6" sx={{ mr: '1vh' }} color={'#929292'}>
                      Contact :
                    </Typography>
                    <Typography variant="h6" color={'primary'}>
                      {`${data?.user_contractor?.first_name} ${data?.user_contractor?.last_name} - ${data?.user_contractor?.phone1}`}
                    </Typography>
                  </Grid>
                )}
              </Grid>
              {data?.data?.contact && (
                <Grid container spacing={2} sx={{ ml: '2vw', mt: '1vh' }}>
                  <Grid item xs={12}>
                    <Typography variant="h5">Personne à contacter pour la prise de RDV :</Typography>
                  </Grid>
                  <>
                    <Grid item xs={12} md={5} sx={{ display: 'flex', ml: '1vw' }}>
                      <Typography variant="h6" sx={{ mr: '1vh' }} color={'#929292'}>
                        Contact :
                      </Typography>
                      <Typography variant="h6" color={'primary'}>
                        {data?.data?.contact.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ display: 'flex', ml: '1vw' }}>
                      <Typography variant="h6" sx={{ mr: '1vh' }} color={'#929292'}>
                        Email :
                      </Typography>
                      <Typography variant="h6" color={'primary'}>
                        {data?.data?.contact.email}
                      </Typography>
                    </Grid>
                    {data?.data?.contact.phone1 && (
                      <Grid item xs={12} sx={{ display: 'flex', ml: '1vw' }}>
                        <Typography variant="h6" sx={{ mr: '1vh' }} color={'#929292'}>
                          Tel :
                        </Typography>
                        <Typography variant="h6" color={'primary'}>
                          {data?.data?.contact.phone1}
                        </Typography>
                      </Grid>
                    )}
                  </>
                </Grid>
              )}

              <Grid container spacing={2} sx={{ ml: '2vw', mt: '1vh' }}>
                <Grid item xs={12}>
                  <Typography variant="h5">Informations complémentaires :</Typography>
                </Grid>
                <Grid item xs={12} md={5.5} sx={{ display: 'flex', ml: '1vw' }}>
                  <Typography variant="h6" sx={{ mr: '1vh' }} color={'#929292'}>
                    Meublé :
                  </Typography>
                  <Typography variant="h6" color={'primary'}>
                    {data?.data?.is_furnished ? 'Oui' : 'Non'}
                  </Typography>
                </Grid>
                {data?.data?.building_code && (
                  <Grid item xs={12} md={5} sx={{ display: 'flex', ml: '1vw' }}>
                    <Typography variant="h6" sx={{ mr: '1vh' }} color={'#929292'}>
                      Code immeuble :
                    </Typography>
                    <Typography variant="h6" color={'primary'}>
                      {data?.data?.building_code}
                    </Typography>
                  </Grid>
                )}
                {data?.data?.commentary && (
                  <Grid item xs={12} sx={{ display: 'flex', ml: '1vw' }}>
                    <Typography variant="h6" sx={{ mr: '1vh' }} color={'#929292'}>
                      Commentaires :
                    </Typography>
                    <Typography variant="h6" sx={{ mr: '1vh' }}>
                      {data?.data?.commentary}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </>
          )}
        </div>
      </div>
      <Modal open={isEdit} onClose={handleEdit}>
        <Box
          sx={{
            position: 'absolute',
            top: '20%',
            left: '35%',
            maxHeight: '45%',
            width: '27%',
            minHeight: '25%',
            backgroundColor: 'white',
            borderRadius: '20px',
            p: 3
          }}
        >
          <>
            {!data?.appointment_date && (
              <>
                {data?.kind === 2 && (
                  <>
                    <Grid item xs={12} sx={{ mt: '1vh' }}>
                      <Typography variant="h6">DATE ET HORAIRE SOUHAITÉES POUR LA RÉALISATION DU SCAN 3D :</Typography>
                    </Grid>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                      <Grid item xs={7} sx={{ mt: '1vh' }}>
                        <MobileDateTimePicker
                          format="DD/MM/YYYY HH:mm"
                          value={appointmentDate}
                          onChange={(date) => setAppointmentDate(date)}
                          label="Date"
                        />
                      </Grid>
                    </LocalizationProvider>
                  </>
                )}
              </>
            )}
          </>
          <Grid item xs={12} sx={{ mt: '3vh' }}>
            <Button variant="contained" color="primary" onClick={handleValidate}>
              Valider
            </Button>
          </Grid>
        </Box>
      </Modal>
      <Modal open={openDeleteOdm} onClose={handleOpenDeleteOdm}>
        <Box
          sx={{
            position: 'absolute',
            top: '35%',
            left: '35%',
            maxHeight: '35%',
            width: '27%',
            minHeight: '15%',
            backgroundColor: 'white',
            borderRadius: '20px',
            p: 3
          }}
        >
          <Typography variant="h5" textAlign={'center'} sx={{ mt: '2.5vh' }}>
            Voulez-vous vraiment supprimer cet ordre de service ?
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-around', mt: '3vh' }}>
            <Button variant="contained" color="primary" onClick={deleteOdm}>
              Valider
            </Button>
            <Button variant="outlined" color="primary" onClick={handleOpenDeleteOdm}>
              Retour
            </Button>
          </Box>
        </Box>
      </Modal>
    </Grid>
  );
};
