import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { deleteAccount } from '~/api';
import { checkEmail } from '~/utils/check';
import '../Auth.scss';

export const DeleteAccount = ({ setSpinnerOpen, spinnerOpen }) => {
  const [email, setEmail] = useState<string>('');
  const [submited, setSubmited] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [organisation, setOrganisation] = useState<string>('');
  const [canSubmit, setCanSubmit] = useState<boolean>(false);

  const handleSubmit = () => {
    if (name && checkEmail(email) && organisation) {
      deleteAccount({ email: email, name: name, organisation: organisation }).then((resp) => {
        setSubmited(true);
      });
    }
  };

  useEffect(() => {
    setSpinnerOpen(false);
    if (name && checkEmail(email) && organisation) {
      setCanSubmit(true);
    } else {
      setCanSubmit(false);
    }
  }, [name, email, organisation]);

  return (
    <div className="Auth">
      {!submited && (
        <div>
          <div className="Auth__header">
            <Box sx={{ mt: 4, mb: 4 }}>
              <img src="/logo3D.svg" height="50" alt="logo" />
            </Box>
            <Typography variant="h5" color={'primary'} sx={{ mb: 3 }}>
              Demande de suppression de votre compte
            </Typography>
          </div>

          <TextField
            label="Nom Prénom"
            fullWidth
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            label="Adresse email"
            fullWidth
            required
            type={'email'}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            label="Nom de l'organisation"
            fullWidth
            required
            value={organisation}
            onChange={(e) => setOrganisation(e.target.value)}
            sx={{ mb: 2 }}
          />
          <Stack className="Auth__submit">
            <Button
              variant="contained"
              size="medium"
              color={'primary'}
              onClick={handleSubmit}
              sx={{ mb: 2, px: '32px', py: '12px' }}
              disabled={!canSubmit}
            >
              Valider
            </Button>
          </Stack>
        </div>
      )}
      {submited && (
        <div>
          <div className="Auth__header">
            <Box sx={{ mt: 4, mb: 4 }}>
              <img src="/logo3D.svg" width="124" height="36" alt="logo Cibel" />
            </Box>
            <Typography variant="h5" color={'primary'} sx={{ mb: 3 }}>
              Email envoyé
            </Typography>
          </div>
          <Stack className="Auth__submit">
            <Typography variant="body1" sx={{ mb: 3 }}>
              Nous avons bien reçu votre demande de suppression de compte. Votre demande est en cours de traitement, et
              nous nous assurerons de mener à bien cette procédure dans les plus brefs délais.
            </Typography>
          </Stack>
        </div>
      )}
    </div>
  );
};

export default DeleteAccount;
