import { Grid2, Button } from '@mui/material';
import ProductItem from '../SubComponents/ProductItem';
import { useContext, useEffect, useState } from 'react';
import { MediaQueryContext } from '~/utils/context/MediaQueryProvider';

const productKinds = {
  1: { logoId: 12, title: 'Maison individuelle', subItems: [], apiProductKind: 12 },
  2: { logoId: 1, title: 'Appartement', subItems: [], apiProductKind: 1 },
  3: { logoId: 3, title: 'Partie commune', subItems: [], apiProductKind: 3 },
  4: { logoId: 30, title: 'Tertiaire', subItems: [6, 7, 8, 9] },
  5: { logoId: 40, title: 'Dépendance', subItems: [10, 11, 12, 13] },
  6: { logoId: 7, title: 'Local commercial', subItems: [], apiProductKind: 7 },
  7: { logoId: 2, title: 'Bureau', subItems: [], apiProductKind: 2 },
  8: { logoId: 8, title: 'Artisanat', subItems: [], apiProductKind: 8 },
  9: { logoId: 13, title: 'Industrie', subItems: [], apiProductKind: 13 },
  10: { logoId: 9, title: 'Parking', subItems: [], apiProductKind: 9 },
  11: { logoId: 10, title: 'Garage', subItems: [], apiProductKind: 10 },
  12: { logoId: 11, title: 'Cave', subItems: [], apiProductKind: 11 },
  13: { logoId: 0, title: 'Autre', subItems: [], apiProductKind: 0 }
};

const productsDefault = [1, 2, 3, 4, 5];

const ProductKindStep = ({ currentStep, setCurrentStep, setTitle, setProductDatas }) => {
  const mediaQueries = useContext(MediaQueryContext); // Accès aux media queries depuis le contexte

  const [productsToShow, setProductsToShow] = useState(productsDefault);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectionHasParentCategory, setSelectionHasParentCategory] = useState(false);

  const checkIfSelectedProductHasParentCategory = (selectedProduct) => {
    let parentCategory = null;
    Object.keys(productKinds).forEach((element) => {
      productKinds[element]?.subItems.forEach((subItem) => {
        if (subItem === selectedProduct) {
          parentCategory = element;
        }
      });
    });
    return parentCategory;
  };

  useEffect(() => {
    setTitle('Parmi les propositions suivantes, laquelle décrit le mieux votre bien ?');
  }, []);

  useEffect(() => {
    if (currentStep === 1) {
      setProductsToShow(productsDefault);
    }
  }, [currentStep]);

  useEffect(() => {
    if (selectedProduct) {
      setProductsToShow(productKinds[selectedProduct].subItems);
    }

    if (productKinds[selectedProduct]?.subItems.length === 0) {
      setSelectionHasParentCategory(false);
    } else {
      setSelectionHasParentCategory(true);
    }
  }, [selectedProduct]);

  useEffect(() => {
    if (selectedProduct !== null && !selectionHasParentCategory) {
      setProductDatas((prevDatas) => ({
        ...prevDatas,
        kind: productKinds[selectedProduct].apiProductKind
      }));
      setCurrentStep(3);
    }

    if (selectedProduct === null) {
      setTitle('Parmi les propositions suivantes, laquelle décrit le mieux votre bien ?');
    } else {
      setTitle('Et plus précisément ?');
    }
  }, [selectedProduct, selectionHasParentCategory]);

  useEffect(() => {
    if (currentStep === 2 && selectedProduct !== null) {
      const parentCategory = checkIfSelectedProductHasParentCategory(selectedProduct);
      if (!parentCategory) {
        setProductsToShow(productsDefault);
        setSelectedProduct(null);
      } else {
        setProductsToShow(productKinds[parentCategory].subItems);
        setSelectedProduct(parentCategory);
        setSelectionHasParentCategory(true);
      }
    }
  }, [currentStep]);

  return (
    <Grid2 container justifyContent={'center'}>
      <Grid2 size={12}>
        <Grid2 container spacing={3} justifyContent={'center'}>
          {productsToShow?.map((productChoice, index) => (
            <Grid2 size={{ xs: 6, md: 4, lg: 2 }} key={index}>
              <ProductItem
                id={productChoice}
                logoId={productKinds[productChoice].logoId}
                name={productKinds[productChoice].title}
                setSelectedProduct={setSelectedProduct}
              />
            </Grid2>
          ))}
        </Grid2>

        <Grid2
          container
          spacing={3}
          justifyContent={'center'}
          sx={{
            mt: '20px',
            position: 'sticky', // Rend les boutons "collants"
            bottom: 0, // Position en bas de la fenêtre si sticky s'applique
            bgcolor: 'white', // Fond blanc pour couvrir le contenu en dessous
            zIndex: 10, // S'assure que les boutons sont au premier plan
            boxShadow: '0 -2px 5px rgba(0,0,0,0)', // Ajoute une ombre douce si besoin
            pb: 2 // Ajoute du padding vertical pour éviter une hauteur trop compacte
          }}
        >
          <Grid2 size={12} sx={{ pt: '30px', textAlign: 'center' }}>
            <Button
              fullWidth={mediaQueries.matchesMd ? false : true}
              //   variant={'outlined'}
              color={'primary'}
              sx={{ textDecoration: 'underline' }}
              onClick={() => {
                if (selectedProduct !== null) {
                  let hasParentCategory = false;
                  Object.keys(productKinds).forEach((element) => {
                    productKinds[element]?.subItems.forEach((subItem) => {
                      if (subItem === selectedProduct) {
                        setProductsToShow(productKinds[element].subItems);
                        setSelectedProduct(element);
                        hasParentCategory = true;
                        setSelectionHasParentCategory(true);
                      }
                    });
                  });
                  if (!hasParentCategory) {
                    setProductsToShow(productsDefault);
                    setSelectedProduct(null);
                  }
                } else {
                  setCurrentStep(1);
                }
              }}
            >
              {'Retour'}
            </Button>
          </Grid2>
        </Grid2>
      </Grid2>
    </Grid2>
  );
};

export default ProductKindStep;
