import { EditOutlined } from '@mui/icons-material';
import { Grid2, Link, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { api } from '~/api';

export const Description = ({ productDescription, productDatas, setProductDatas }) => {
  const [description, setDescription] = useState('');
  const [editDescription, setEditDescription] = useState(false);

  useEffect(() => {
    setDescription(productDescription);
  }, [productDescription]);

  return (
    <Grid2 container spacing={2}>
      <Grid2 size={{ xs: 'grow' }}>
        {editDescription && (
          <TextField
            multiline
            rows={4}
            fullWidth
            placeholder="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            size="small"
          ></TextField>
        )}

        {!editDescription && (
          <Typography sx={{ pl: '14px', pt: '8px', color: 'grey' }}>
            {description === '' ? 'Vous pouvez ajouter une description' : description}
          </Typography>
        )}
      </Grid2>

      <Grid2 sx={{ flex: 'end' }}>
        {!editDescription && (
          <Link
            className="Product__link"
            onClick={() => {
              setEditDescription(true);
            }}
            color="primary"
          >
            <EditOutlined />
          </Link>
        )}

        {editDescription && (
          <Link
            className="Product__link"
            onClick={() => {
              setEditDescription(false);
              api.product.update(productDatas.uuid, { description: description }).then((res) => {});
              setProductDatas({ ...productDatas, description: description });
            }}
            color="primary"
          >
            <CheckCircleOutlineIcon />
          </Link>
        )}
      </Grid2>
    </Grid2>
  );
};
