import { Grid2, Accordion, AccordionSummary, Box, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { EquipmentsList } from '~/app/components/EquipmentsList/EquipmentsList';

export const LinkEquipmentForm = ({
  documentData,
  setDocumentData,
  documentTypeLayout,
  setWizzardEquipmentOpen,
  setSelectedUUIDEquipment,
  setWizzardEquipmentCreationMode,
  productDatas,
  prechekedList
}) => {
  return (
    <>
      {documentData && documentTypeLayout && documentTypeLayout.needEquipment && (
        <Grid2 size={12} style={{ paddingTop: '5px', paddingBottom: '25px' }}>
          <Accordion defaultExpanded={false} disableGutters={true}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <Box className="Events__lineBlock__title" sx={{ flexGrow: 1 }}>
                {'Associer des équipements'}
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <EquipmentsList
                precheckList={prechekedList}
                uuidDocument={documentData.uuid}
                context="documents"
                setWizzardEquipmentOpen={setWizzardEquipmentOpen}
                setSelectedUUIDEquipment={setSelectedUUIDEquipment}
                setWizzardEquipmentCreationMode={setWizzardEquipmentCreationMode}
                data={productDatas}
              ></EquipmentsList>
            </AccordionDetails>
          </Accordion>
        </Grid2>
      )}
    </>
  );
};
