import { Box, Tooltip, tooltipClasses, Zoom } from '@mui/material';
import { useState } from 'react';
import { appTheme } from '~/utils/theme';

const SubToolIcon = ({ toolId, activeId, generateIcon, setActiveIconId, isLandscape, name }) => {
  const [hover, setHover] = useState(false);
  const isActive = toolId === activeId;

  return (
    <Tooltip
      title={name}
      arrow
      placement={isLandscape ? 'left' : 'top'}
      slots={{
        transition: Zoom
      }}
      slotProps={{
        transition: { timeout: 600 },
        popper: {
          sx: {
            [`& .${tooltipClasses.arrow}`]: {
              color: appTheme.palette.primary.main
            },
            [`& .${tooltipClasses.tooltip}`]: {
              backgroundColor: appTheme.palette.primary.main
            },
            [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]: {
              marginLeft: '4px',
              marginTop: '10px'
            },
            [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]: {
              marginLeft: '4px',
              marginBottom: '10px'
            }
          }
        }
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '45px',
          cursor: 'pointer',
          flexDirection: isLandscape ? 'row' : 'column',
          pl: isLandscape ? 0 : '8px',
          pr: isLandscape ? 0 : '8px',
          backgroundColor: !isActive ? '#B19E8C' : 'white'
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={() => setActiveIconId(isActive ? null : toolId)}
      >
        {generateIcon(toolId, 30, hover || isActive ? appTheme.palette.primary.main : 'white')}
      </Box>
    </Tooltip>
  );
};

export default SubToolIcon;
