import { Box, Typography } from '@mui/material';
import { appTheme } from '~/utils/theme';
import { productKindLogos } from '../ProductItemLogos/ProductItemLogos';
import { useContext } from 'react';
import { MediaQueryContext } from '~/utils/context/MediaQueryProvider';

export const ProductTitle = ({ data, context = null, isLandscape = true }) => {
  const mediaQueries = useContext(MediaQueryContext);
  const showSituation = (data) => {
    const situation = [];

    if (data.address?.building_name && data.address?.building_name !== '') {
      situation.push(data.address?.building_name);
    }

    if (data.address_complement && data.address_complement !== '') {
      situation.push(data.address_complement);
    }

    return (
      <Typography variant="h5" sx={{ color: appTheme.palette.primary.main, fontWeight: 500 }}>
        {situation.join(' - ')}
      </Typography>
    );
  };

  return (
    <Box
      sx={{
        pt: context !== 'View3D' ? 2 : 1,
        display: 'flex',
        alignItems: 'flex-start',
        backgroundColor: context !== 'View3D' ? '' : isLandscape ? appTheme.palette.primary.main : 'white'
      }}
    >
      {context === 'View3D' && mediaQueries.matchesMd && (
        <Box
          sx={{ ml: '10px', backgroundColor: 'white' }}
          style={{
            display: 'inline-block',
            borderRadius: '20px',
            padding: '6px',
            width: mediaQueries.matchesXl
              ? '25px'
              : mediaQueries.matchesLg
                ? '20px'
                : mediaQueries.matchesMd
                  ? '15px'
                  : '10px',
            border: '1px solid #062746',
            height: mediaQueries.matchesXl
              ? '25px'
              : mediaQueries.matchesLg
                ? '20px'
                : mediaQueries.matchesMd
                  ? '15px'
                  : '10px'
          }}
        >
          {data?.kind
            ? productKindLogos(
                mediaQueries.matchesXl ? 25 : mediaQueries.matchesLg ? 20 : mediaQueries.matchesMd ? 15 : 100,
                data?.kind
              )
            : null}
        </Box>
      )}

      {context !== 'View3D' && (
        <Box sx={{ mr: 1 }}>{data?.kind ? productKindLogos(mediaQueries.matchesMd ? 32 : 20, data?.kind) : null}</Box>
      )}

      <Box sx={{ flexGrow: 1, mb: 1 }}>
        {data.address && data.address.street && (
          <Typography
            variant={
              mediaQueries.matchesXl
                ? 'h1'
                : mediaQueries.matchesLg
                  ? 'h2'
                  : mediaQueries.matchesMd
                    ? 'h3'
                    : mediaQueries.matchesSm
                      ? 'h4'
                      : 'h5'
            }
            sx={{
              pl: context === 'View3D' ? 1 : 0,
              color:
                context !== 'View3D'
                  ? appTheme.palette.primary.main
                  : !isLandscape
                    ? appTheme.palette.primary.main
                    : 'white',
              lineHeight: 1
            }}
          >
            {data.address.street}, {data.address.zip_code} {data.address.city}
          </Typography>
        )}

        {context !== 'View3D' && <>{showSituation(data)}</>}
      </Box>
    </Box>
  );
};
