import { axiosPost, axiosGet } from './axiosClient';

// Todo: to move to a link.ts file to create.
export const generateLink = (data) => {
  return axiosPost(`/links`, data);
};

// Todo: to move to a link.ts file to create.
export const generateQrCode = (idLink: string) => {
  return axiosGet(`/links/${idLink}/qrcode`);
};
