import { Box, Button, FormControl, Grid, Typography, Autocomplete, TextField, Grid2 } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { axiosClient, getOrganizations } from '~/api';
import Breadcrumb from '~/app/components/Breadcrumb/Breadcrumb';
import auth from '~/app/components/Auth/AuthApi';
import { MediaQueryContext } from '~/utils/context/MediaQueryProvider';
import './Organizations.scss';

export const Organizations = ({ setSpinnerOpen, spinnerOpen }) => {
  const [selectedIdOrganization, setIdOrganization] = useState('');
  const [selectedJWT, setJWT] = useState('');
  const [selectedRefreshJWT, setRefreshJWT] = useState('');
  const [organizations, setOrganizations] = useState([]);
  const mediaQueries = useContext(MediaQueryContext);
  const navigate = useNavigate();

  useEffect(() => {
    getOrganizations()
      .then((resp) => {
        setOrganizations(resp);
        setSpinnerOpen(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleAutocompleteChange = (event, newValue) => {
    if (newValue) {
      setIdOrganization(newValue.organization.name);
      setJWT(newValue.jwt);
      setRefreshJWT(newValue.refresh);
    }
  };

  const handleSelectOrganisation = () => {
    axiosClient.defaults.headers.common['Authorization'] = 'Bearer ' + selectedJWT;
    sessionStorage.setItem('av_jwt', selectedJWT);
    sessionStorage.setItem('av_refresh', selectedRefreshJWT);

    auth.getOrganizationName();
    setSpinnerOpen(true);
    navigate('/');

    organizations.forEach((orga) => {
      if (orga.organization.name === selectedIdOrganization) {
        localStorage.setItem('OrganizationName', orga.organization.name);
      }
    });
  };

  return (
    <div className="Organization">
      <Grid2 container spacing={2}>
        <Box sx={{ my: 2, width: 1, display: 'flex', alignItems: 'center' }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography
              variant={
                mediaQueries.matchesXl ? 'h1' : mediaQueries.matchesLg ? 'h2' : mediaQueries.matchesMd ? 'h3' : 'h4'
              }
              sx={{ color: 'primary.main' }}
            >
              Organisations
            </Typography>
            <Breadcrumb
              data={[{ label: 'Accueil', href: '/' }]}
              last={`Organisations`}
              setSpinnerOpen={setSpinnerOpen}
            />
          </Box>
        </Box>

        <Box sx={{ mb: 4, width: 1, display: 'flex', alignItems: 'center' }}>
          <Typography variant="body1" sx={{ color: 'primary.main' }}>
            Vous devez sélectionner une organisation pour laquelle vous ferez des modifications
          </Typography>
        </Box>
        <Grid2 size={{ xs: 12, md: 5 }}>
          <Grid2 container spacing={2}>
            <Grid2 size={{ xs: 12 }}>
              <Autocomplete
                id="organization-autocomplete"
                options={organizations}
                getOptionLabel={(option) => option.organization.name}
                onChange={handleAutocompleteChange}
                renderInput={(params) => (
                  <TextField {...params} label="Sélectionner une organisation" variant="outlined" />
                )}
                disabled={spinnerOpen}
              />
            </Grid2>
            <Grid2>
              {selectedIdOrganization && (
                <Button variant="contained" color="primary" onClick={handleSelectOrganisation}>
                  Continuer
                </Button>
              )}
            </Grid2>
          </Grid2>
        </Grid2>
      </Grid2>
    </div>
  );
};
