import { IOrganization, IOrganizationUpdate } from './organization.models';
import { axiosDelete, axiosGet, axiosPatch, axiosPost, axiosPostMultipart } from '.';
const { REACT_APP_TOKEN_DURATION } = process.env;

export const addOrganization = (data: IOrganization) => {
  return axiosPost('/organizations', data);
};

export const getOrganization = (idOrganization: string) => {
  return axiosGet(`/organizations/${idOrganization}`);
};

export const getOrganizations = () => {
  return axiosGet('/organizations');
};

export const patchOrganization = (idOrganization: string, data: IOrganizationUpdate) => {
  return axiosPatch(`/organizations/${idOrganization}`, data);
};

export const deleteOrganization = (idOrganization: string) => {
  return axiosDelete(`/organizations${idOrganization}`);
};

export const getContactsOrganization = (idOrganization: string) => {
  return axiosGet(`/organizations/${idOrganization}/contacts`);
};

export const addLogoOrganization = (idOrganization: string, data) => {
  return axiosPostMultipart(`/organizations/${idOrganization}/logo`, data);
};
