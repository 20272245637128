import { Box, Button, DialogActions, Grid } from '@mui/material';
import '~/app/components/WizzardEquipment/WizzardEquipment';

export const LocalizationChoice = ({ nextStepCallBack }) => {
  return (
    <Grid container spacing={0} direction="column">
      <Grid item xs={12}>
        <div className="Wizzards__contentTitle Wizzards__center">
          <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M53.5617 50.645L35 69.2067L16.4383 50.645C12.7672 46.9739 10.2672 42.2966 9.25436 37.2046C8.24153 32.1126 8.76138 26.8346 10.7482 22.038C12.735 17.2415 16.0995 13.1418 20.4163 10.2575C24.7331 7.37309 29.8083 5.83356 35 5.83356C40.1917 5.83356 45.2669 7.37309 49.5837 10.2575C53.9005 13.1418 57.265 17.2415 59.2518 22.038C61.2386 26.8346 61.7585 32.1126 60.7456 37.2046C59.7328 42.2966 57.2328 46.9739 53.5617 50.645ZM32.0833 29.1667H23.3333V35H32.0833V43.75H37.9167V35H46.6667V29.1667H37.9167V20.4167H32.0833V29.1667Z"
              fill="#F17633"
            />
          </svg>
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className="Wizzards__center Wizzards__form_item">
          <div className="Wizzards__contentTitle">Voulez-vous localiser cet équipement dans le modèle 3D ?</div>

          <Box textAlign="center">
            <DialogActions sx={{ justifyContent: 'center' }}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  nextStepCallBack('localizationChoice', false);
                }}
              >
                Non
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  nextStepCallBack('localizationChoice', true);
                }}
              >
                Oui
              </Button>
            </DialogActions>
          </Box>
        </div>
      </Grid>
    </Grid>
  );
};

export default LocalizationChoice;
