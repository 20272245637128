import { Box, Tooltip, tooltipClasses, Zoom } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { appTheme } from '~/utils/theme';

const ToolIcon = ({ toolId, activeId, generateIcon, setActiveIconId, isLandscape, name }) => {
  const [hover, setHover] = useState(false);
  const isActive = toolId === activeId;

  return (
    <Tooltip
      title={name}
      arrow
      sx={{ left: isLandscape ? 0 : '15px' }}
      placement={isLandscape ? 'left' : 'top'}
      slots={{
        transition: Zoom
      }}
      slotProps={{
        transition: { timeout: 600 },
        popper: {
          sx: {
            [`& .${tooltipClasses.arrow}`]: {
              color: appTheme.palette.primary.main
            },
            [`& .${tooltipClasses.tooltip}`]: {
              backgroundColor: appTheme.palette.primary.main
            },
            [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]: {
              marginLeft: '15px',
              marginTop: '10px'
            },
            [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]: {
              marginLeft: '15px',
              marginBottom: '10px'
            }
          }
        }
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '45px',
          cursor: 'pointer',
          flexDirection: isLandscape ? 'row' : 'column',
          pl: isLandscape ? 0 : '15px'
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={() => setActiveIconId(isActive ? null : toolId)}
      >
        {isLandscape && isActive && (
          <Box
            sx={{
              position: 'relative',
              left: '-4px',
              width: 0,
              height: 0,
              borderTop: '7px solid transparent',
              borderBottom: '7px solid transparent',
              borderLeft: '7px solid white'
            }}
          />
        )}
        {generateIcon(toolId, 30, hover || isActive ? appTheme.palette.secondary.main : 'white')}
        {!isLandscape && isActive && (
          <Box
            sx={{
              position: 'relative',
              top: '4px',
              width: 0,
              height: 0,
              borderLeft: '7px solid transparent',
              borderRight: '7px solid transparent',
              borderBottom: '7px solid white'
            }}
          />
        )}
      </Box>
    </Tooltip>
  );
};

export default ToolIcon;
