import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import {
  Backdrop,
  Box,
  Fade,
  Grid,
  IconButton,
  Link,
  Modal,
  Snackbar,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { grey } from '@mui/material/colors';
import { ReactNode, useEffect, useState, useContext } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
  deleteEvent,
  patchEvent,
  getProduct,
  getProductEquipments,
  referentielEventTypes,
  api,
  getProductContacts,
  getContactsOrganization
} from '~/api';
import { IProduct, ModalContentType } from '~/interfaces';
import { useWindowSize } from '~/utils/useWindowSize';
import { View3d } from '~/app/components/View3d/View3d';
import { Gallery } from '~/app/components/Gallery/Gallery';
import BasicModal from '~/app/components/BasicModal/BasicModal';
import Breadcrumb from '~/app/components/Breadcrumb/Breadcrumb';
import EventsList from '~/app/components/EventsList/EventsList';
import ProductCard from '~/app/components/ProductCard/ProductCard';
import PropertyInfo from '~/app/components/PropertyInfo/PropertyInfo';
import WizzardEquipment from '~/app/components/WizzardEquipment/WizzardEquipment';
import WizzardEvent from '~/app/components/WizzardEvent/WizzardEvent';
import auth, { isAdmin, isSuperAdmin } from '~/app/components/Auth/AuthApi';
import ProductContact from '~/app/components/ProductContact/ProductContact';
import { DocumentsList } from '~/app/components/DocumentsList/DocumentsList';
import { WizzardDocument } from '~/app/components/WizzardDocument/WizzardDocument';
import ImagePicker from '~/app/components/ImagePicker/ImagePicker';
import { EditOutlined } from '@mui/icons-material';
import EditPropertyInfos from '~/app/components/EditPropertyInfos/EditPropertyInfos';
import { appTheme } from '~/utils/theme';
import './Product.scss';
import { ProductTitle } from '~/app/components/ProductTitle/ProductTitle';
import { EventShareDialog } from '~/app/components/EventShareDialog/EventShareDialog';

import { AppContext } from '~/utils/context/AppContext';
import _ from 'lodash';
import { renderEquipmentIcon } from '~/utils/references';
import { WizzardMissionOrderForm } from '~/app/components/WizzardMissionOrder/WizzardMissionOrderFom';
import CloseIcon from '@mui/icons-material/Close';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import ApartmentIcon from '@mui/icons-material/Apartment';
import LinkIcon from '@mui/icons-material/Link';
import ShareIcon from '@mui/icons-material/Share';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import normalizedString from '~/utils/normalizedString';

export const Product = ({ setSpinnerOpen, spinnerOpen }) => {
  type TuseParams = {
    id: string;
  };
  const { id } = useParams<TuseParams>();
  const { isMobile } = useWindowSize();
  const [data, setData] = useState<null | IProduct>(null);
  const [eventId, setEventId] = useState<any>(null);
  const [error, setError] = useState({ isError: false, error: {} });
  const [tab, setTab] = useState<'info' | 'doc'>('doc');
  const [modalType, setModalType] = useState<ModalContentType>();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [rowsTickets, setRowsTickets] = useState([]);
  const [rowsDocuments, setRowsDocuments] = useState([]);
  const [rowsDocumentsFiltered, setRowsDocumentsFiltered] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [images, setImages] = useState([]);
  const [headerImage, setHeaderImage] = useState([]);
  const [equipKinds, setEquipmentKinds] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const [wizzardEquipmentOpen, setWizzardEquipmentOpen] = useState(false);
  const [wizzardEventOpen, setWizzardEventOpen] = useState<any>(false);
  const [wizzardDocumentOpen, setWizzardDocumentOpen] = useState<any>(false);
  const [wizzardEquipmentCreationMode, setWizzardEquipmentCreationMode] = useState<boolean>(false);
  const [wizzardEventCreationMode, setWizzardEventCreationMode] = useState<boolean>(false);
  const [selectedUUIDEvent, setSelectedUUIDEvent] = useState<string>('');
  const [selectedUUIDEquipment, setSelectedUUIDEquipment] = useState<string>('');
  const [uuidEventToRowId, setUuidEventToRowId] = useState({});
  const [contacts, setContacts] = useState([]);
  const [allContacts, setAllContacts] = useState([]);
  const [updateContact, setUpdateContact] = useState(true);
  const [searchTickets, setSearchTickets] = useState<string>('');
  const [searchDocuments, setSearchDocuments] = useState<string>('');
  const [selectedDocument, setSelectedDocument] = useState<any>(null);
  const [eventListFiltered, setEventListFiltered] = useState([]);
  const [galleryIndex, setGalleryIndex] = useState(0);
  const [buildingData, setBuildingData] = useState<any>(null);
  const [isMissionOrderOpen, setIsMissionOrderOpen] = useState(false);
  const [links, setLinks] = useState([]);
  const [shortLinkKind, setShortLinkKind] = useState('');
  const [selectedEquipmentsFamily, setSelectedEquipmentsFamily] = useState(null);
  const [selectedEquipmentsList, setSelectedEquipmentsList] = useState([]);
  const [searchedEquipmentsValue, setSearchedEquipmentsValue] = useState('');

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [openSnackBarMessage, setOpenSnackBarMessage] = useState('');
  const [openShareDialog, setOpenShareDialog] = useState(false);
  const [dataToShare, setDataToShare] = useState(null);
  const [linkToShare, setLinkToShare] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const [scanOrder, setScanOrder] = useState(false);

  const [equipmentData, setEquipmentData] = useState<any>(null);

  const { referencial } = useContext(AppContext);

  const navigate = useNavigate();

  const { equipmentReferencial } = useContext(AppContext);

  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.up('xs'));
  const matchesSm = useMediaQuery(theme.breakpoints.up('sm'));
  const matchesMd = useMediaQuery(theme.breakpoints.up('md'));
  const matchesLg = useMediaQuery(theme.breakpoints.up('lg'));
  const matchesXl = useMediaQuery(theme.breakpoints.up('xl'));

  useEffect(() => {
    console.log(matchesXs, matchesSm, matchesMd, matchesLg, matchesXl);
  }, [matchesXs, matchesSm, matchesMd, matchesLg, matchesXl]);

  const handleModal = (type: ModalContentType) => {
    setSpinnerOpen(false);
    setModalOpen(!modalOpen);
    setModalType(type);
  };

  const generateSubtitle = (equipmentData) => {
    const labels = [];
    if (
      equipmentReferencial &&
      equipmentData?.category_kind &&
      _.find(equipmentReferencial.equipmentCategoryKinds, {
        id: equipmentData.category_kind
      }) &&
      _.find(equipmentReferencial.equipmentCategoryKinds, {
        id: equipmentData.category_kind
      }).name
    ) {
      labels.push(
        _.find(equipmentReferencial.equipmentCategoryKinds, {
          id: equipmentData.category_kind
        }).name
      );
    }

    let equipmentName = null;
    if (
      equipmentReferencial &&
      equipmentData?.kind &&
      _.find(equipmentReferencial.equipments, { id: equipmentData.kind }) &&
      _.find(equipmentReferencial.equipments, { id: equipmentData.kind }).name
    ) {
      labels.push(_.find(equipmentReferencial.equipments, { id: equipmentData.kind }).name);
      equipmentName = _.find(equipmentReferencial.equipments, { id: equipmentData.kind }).name;
    }

    if (equipmentData?.title && equipmentData.title !== equipmentName) {
      labels.push(equipmentData.title);
    }

    if (equipmentData?.room && equipmentData.room.name) {
      labels.push(equipmentData.room.name);
    }
    return labels.join(' - ');
  };

  const handleCancelSuppressEvent = () => {
    setModalOpen(false);
  };

  const setRowsData = (events) => {
    const rowsByProduct = {};
    const rows = [];

    const arrSortedDate = events.sort((a, b) => {
      return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
    });
    arrSortedDate.forEach((event, index: number) => {
      if (event.status === 3 || event.status === 2) {
        rows.push({
          id: index,
          diagnosticType: referentielEventTypes[event.kind],
          status: event.status,
          date: event.created_at,
          desc: event.desc,
          action: 'action',
          uuid: event.uuid,
          uuid_owner: event.uuid_owner,
          urgent: event.urgent,
          kind: event.kind
        });
        rowsByProduct[event.uuid] = index;
      }
    });
    setUuidEventToRowId(rowsByProduct);
    setRowsTickets(rows);
    setEventListFiltered(rows);
  };

  useEffect(() => {
    if (selectedDocument?.uuid_owner.includes('equ')) {
      setEquipmentData(_.find(equipments, { uuid: selectedDocument.uuid_owner }));
    } else {
      setEquipmentData(null);
    }
  }, [selectedDocument]);

  useEffect(() => {
    if (searchedEquipmentsValue === '') {
      setSelectedEquipmentsFamily(null);
      setSelectedEquipmentsList([]);
      // setSearchParams({});
    } else {
      const equipmentsInthisFamily = [];
      equipments.forEach((equipment) => {
        if (equipment.kind && equipment.kind !== 0) {
          const equipmentCategories =
            equipment.category_kind !== 1 && equipment.kind !== 1
              ? _.find(equipmentReferencial['equipments'], { id: equipment.kind }).kinds
              : [1];
          if (
            normalizedString(_.find(equipmentReferencial['equipments'], { id: equipment.kind }).name).includes(
              normalizedString(searchedEquipmentsValue)
            )
          ) {
            equipmentsInthisFamily.push(equipment);
          } else {
            if (equipment.room && equipment.room.name) {
              if (normalizedString(equipment.room.name).includes(normalizedString(searchedEquipmentsValue))) {
                equipmentsInthisFamily.push(equipment);
              }
            } else {
              equipmentCategories.forEach((kind) => {
                if (normalizedString(GetEquipmentKind(kind)).includes(normalizedString(searchedEquipmentsValue))) {
                  equipmentsInthisFamily.push(equipment);
                }
              });
            }
          }
        }
      });
      setSelectedEquipmentsFamily(null);
      setSelectedEquipmentsList(equipmentsInthisFamily);
      setSearchParams({ equ_search: searchedEquipmentsValue });
    }
  }, [searchedEquipmentsValue]);

  useEffect(() => {
    if (searchParams.get('equ_cat') && equipmentReferencial && equipments.length > 0) {
      const index = Number(searchParams.get('equ_cat'));
      const equipmentsInthisFamily = [];
      equipments.forEach((equipment) => {
        if (index !== 18 && equipment.kind && equipment.kind !== 0 && equipment.category_kind === index) {
          equipmentsInthisFamily.push(equipment);
        } else if (index !== 18 && equipment.category_kind === null) {
          if (equipment.kind !== 1 && index !== 1) {
            const referentialEquipment = _.find(equipmentReferencial['equipments'], {
              id: equipment.kind
            });

            const referentialCategoryEquipment = referentialEquipment?.kinds?.map((categoryKindId) => {
              return _.find(equipmentReferencial['equipmentCategoryKinds'], {
                id: categoryKindId
              });
            });

            referentialCategoryEquipment?.forEach((category) => {
              if (category.id === index) {
                equipmentsInthisFamily.push(equipment);
              }
            });
          } else if (index === 1) {
            const referentialEquipment = _.find(equipmentReferencial['equipments'], {
              id: equipment.kind
            });

            const referentialCategoryEquipment = referentialEquipment?.kinds?.map((categoryKindId) => {
              return _.find(equipmentReferencial['equipmentCategoryKinds'], {
                id: categoryKindId
              });
            });

            referentialCategoryEquipment?.forEach((category) => {
              if (category.id === index) {
                equipmentsInthisFamily.push(equipment);
              }
            });
          }
        } else {
          if (index === 18 && equipment.tab === 'inventoryEquipments') {
            if (
              equipment.states &&
              equipment.states[0] &&
              (equipment.states[0]?.data?.inventoryNumber || equipment.states[0]?.images?.length > 0)
            ) {
              equipmentsInthisFamily.push(equipment);
            }
          }
        }
      });
      equipmentsInthisFamily.sort((a, b) => {
        if (a.room === null) return 1;
        if (b.room === null) return -1;
        return a.room.name.localeCompare(b.room.name);
      });
      setSelectedEquipmentsFamily(index);
      setSelectedEquipmentsList(equipmentsInthisFamily);
    }
    if (searchParams.get('equ_search') && equipmentReferencial && equipments.length > 0) {
      setSearchedEquipmentsValue(searchParams.get('equ_search'));
    }
  }, [searchParams, equipmentReferencial, equipments]);

  useEffect(() => {
    if (data && data.links) {
      const tempLinks = [];

      data.links.forEach((link) => {
        if (link.kind === 3) {
          tempLinks.push(link);
        }
        if (link.kind === 2) {
          tempLinks.push(link);
        }
        if (link.kind === 1) {
          tempLinks.push(link);
        }
      });

      // const filteredLinks = data.links.filter((link) => link.kind === 3);

      // if (filteredLinks.length > 0) {
      //   const mostRecentLink = filteredLinks.reduce((latest, current) => {
      //     return new Date(latest.created_at) > new Date(current.created_at) ? latest : current;
      //   });

      //   tempLinks.push(mostRecentLink);
      // }

      setLinks(tempLinks);
    }
  }, [data]);

  useEffect(() => {
    if (referencial) {
      setShortLinkKind(_.sortBy(referencial['short_link.kind'], ['id']));
    }
  }, [referencial]);

  const handleShareDialog = (data, isLink) => {
    setOpenShareDialog(!openShareDialog);
    if (isLink) {
      const avrtBaseUrl = process.env.REACT_APP_BASEURL_AVRT;
      const link = `${avrtBaseUrl}/${data.uuid}`;
      setDataToShare(link);
    } else {
      setDataToShare(data);
    }
  };
  useEffect(() => {
    if (data && data.events) {
      const filteredList = rowsTickets.filter((item) => {
        const normalizedSearch = normalizedString(searchTickets);
        return normalizedString(item.desc).indexOf(normalizedSearch) >= 0;
      });

      setEventListFiltered(filteredList);
    }
  }, [searchTickets]);

  useEffect(() => {
    getProductData();
  }, [id]);

  useEffect(() => {
    if (data && data.uuid_building) {
      api.building.get(data.uuid_building).then((building) => {
        setBuildingData(building);
      });
    }
  }, [data]);

  const getProductData = () => {
    getProduct(id)
      .then((res) => {
        if (res.events && res.events.length > 0) {
          setRowsData(res.events);
        }
        res.isBuilding = false;
        setData(res);
      })
      .catch((error) => {
        setError({ isError: true, error });
      })
      .finally(() => {
        setIsLoading(false);
        setSpinnerOpen(false);
      });
  };

  const GetEquipmentKind = (kind) => {
    return _.find(equipmentReferencial['equipmentCategoryKinds'], { id: kind }) &&
      _.find(equipmentReferencial['equipmentCategoryKinds'], { id: kind }).name
      ? _.find(equipmentReferencial['equipmentCategoryKinds'], { id: kind }).name
      : null;
  };

  // Filter Search
  const handleChangeSearchFilterTickets = (e) => {
    const search = e.target.value;
    setSearchTickets(search);
  };

  const handleChangeSearchFilterDcuments = (e) => {
    const search = e.target.value;
    setSearchDocuments(search);
  };

  const handleChangeSearchFilterEquipments = (e) => {
    const search = e.target.value;
    setSearchedEquipmentsValue(search);
    if (search === '') {
      setSearchParams({});
    }
  };

  useEffect(() => {
    if (data && data.files) {
      const filteredList = rowsDocuments.filter((item) => {
        const normalizedSearch = normalizedString(searchDocuments);
        return normalizedString(item.name).indexOf(normalizedSearch) >= 0;
      });

      setRowsDocumentsFiltered(filteredList);
    }
  }, [searchDocuments]);

  useEffect(() => {
    if (data?.files?.length > 0) {
      const rows = [];
      const allFiles = [];

      allFiles.push(...data.files);

      if (allFiles && allFiles.length > 0) {
        allFiles.forEach((file, index: number) => {
          let fileName = file.name;
          if (file.uuid_owner.includes('equ')) {
            const equipment = equipments.find((equipment) => equipment.uuid === file.uuid_owner);
            if (equipment) {
              fileName = `${generateSubtitle(equipment)} - ${file.name}`;
            }
          } else {
            fileName = file.name;
          }

          rows.push({
            id: index,
            date_creation: file.created_at,
            date: file.data?.date,
            name: fileName,
            action: 'action',
            uuid: file.uuid,
            uuid_owner: file.uuid_owner,
            kind: file.category,
            allData: file,
            isEquipment: file.uuid_owner.includes('equ'),
            equipmentData: equipments.find((equipment) => equipment.uuid === file.uuid_owner)
          });
        });
        setRowsDocuments(rows);
        setRowsDocumentsFiltered(rows);
      }
    }
  }, [data, equipments]);

  useEffect(() => {
    if (data !== null && data.events && data.events.length > 0) {
      setRowsData(data.events);
    }
  }, [data]);

  const refreshProductImages = () => {
    api.product
      .getImages(id)
      // getProductImages(id)
      .then((images) => {
        if (!images || images.length === 0) return;
        setImages(images);
        const feat = images.filter((image) => {
          return image.kind === 'thumbnail';
        });
        if (feat.length === 0 && images && images.length > 0) {
          feat.push(images[0]);
        }
        setHeaderImage(feat);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    refreshProductImages();
  }, [id]);

  useEffect(() => {
    const claims = auth.getJWT();

    if (updateContact) {
      getProductContacts(id)
        .then((resp) => {
          setContacts(resp);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setUpdateContact(false);
        });

      getContactsOrganization(claims.uuid_organization)
        .then((orgcontacts) => {
          const ctcs = orgcontacts.filter((contact) => {
            return contact.uuid_owner !== id;
          });
          setAllContacts(ctcs.reverse());
        })
        .finally(() => {
          setUpdateContact(false);
        });
    }
  }, [updateContact]);

  const handleSuppressEvent = () => {
    deleteEvent(eventId).then(() => {
      const newEventsArr = JSON.parse(JSON.stringify(data.events));
      data.events.forEach((event, index) => {
        if (event.uuid === eventId) {
          newEventsArr.splice(index, 1);
        }
      });

      const newDatas = JSON.parse(JSON.stringify(data));
      newDatas.events = newEventsArr.reverse();

      setData(newDatas);
      setModalOpen(!modalOpen);
    });
  };

  const updateSelectedDocumentData = (documentData) => {
    getProduct(id)
      .then((res) => {
        if (res.events && res.events.length > 0) {
          setRowsData(res.events);
        }
        res.isBuilding = false;
        setData(res);
      })
      .catch((error) => {
        setError({ isError: true, error });
      })
      .finally(() => {
        setIsLoading(false);
        setSpinnerOpen(false);
      });
  };

  const saveChanges = (dataForm) => {
    handleModifyEvent(dataForm);
  };

  const handleModifyEvent = (dataForm) => {
    if (dataForm === null) return;
    const newEvent = JSON.parse(JSON.stringify(dataForm));
    if (newEvent.tags !== null) delete newEvent.tags;
    if (newEvent.diagnosticType !== null) delete newEvent.diagnosticType;
    if (newEvent.files !== null) delete newEvent.files;
    if (newEvent.data !== null) delete newEvent.data;

    newEvent.status = dataForm.status;
    newEvent.urgent = dataForm.urgent;
    newEvent.kind = dataForm.kind;
    newEvent.desc = dataForm.desc;

    patchEvent(dataForm.uuid, newEvent)
      .then((res) => {
        let selectedEventIndex = null;
        for (const [id, value] of Object.entries(rowsTickets)) {
          if (uuidEventToRowId[dataForm.uuid] === value.id) {
            selectedEventIndex = Number(id);
          }
        }
        const newRows = JSON.parse(JSON.stringify(rowsTickets));
        newRows[selectedEventIndex].status = res.status;
        newRows[selectedEventIndex].urgent = res.urgent;
        newRows[selectedEventIndex].diagnosticType = referentielEventTypes[res.kind];
        newRows[selectedEventIndex].kind = res.kind;
        newRows[selectedEventIndex].desc = res.desc;
        setRowsTickets(newRows);
        setRowsData(newRows);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const refreshEquipments = () => {
    getProductEquipments(id)
      .then((resp) => {
        const kinds = [];
        const equips = [];

        resp.forEach((equipment) => {
          if (_.find(equipmentReferencial['equipments'], { id: equipment.kind }) !== undefined) {
            const arrKindsFamily = [];

            if (equipment.category_kind === 1) {
              arrKindsFamily.push(1);
            } else {
              if (equipment.kind !== 1) {
                if (_.find(equipmentReferencial['equipments'], { id: equipment.kind }).kinds.length > 0) {
                  _.find(equipmentReferencial['equipments'], { id: equipment.kind }).kinds.forEach((kind) => {
                    arrKindsFamily.push(kind);
                  });
                }
              } else {
                arrKindsFamily.push(equipment.category_kind);
              }
            }
            if (arrKindsFamily.length > 0) {
              arrKindsFamily.forEach((kind) => {
                if (kind !== null) {
                  kinds.push(kind);
                  if (!equips[kind]) {
                    equips[kind] = [equipment.uuid];
                  } else {
                    equips[kind].push(equipment.uuid);
                    equips[kind] = _.uniq(equips[kind]);
                  }
                }
              });
            } else {
              if (equipment.tab === 'inventoryEquipments') {
                if (
                  equipment.states &&
                  equipment.states[0] &&
                  (equipment.states[0]?.data?.inventoryNumber || equipment.states[0]?.images?.length > 0)
                ) {
                  kinds.push(18);

                  if (!equips[18]) {
                    equips[18] = [equipment.uuid];
                  } else {
                    equips[18].push(equipment.uuid);
                    equips[18] = _.uniq(equips[18]);
                  }
                }
              }
            }
          } else {
          }
        });

        setEquipmentKinds(_.uniq(kinds));
        setEquipments(resp);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Get equipments data
  useEffect(() => {
    if (equipmentReferencial) {
      refreshEquipments();
    }
  }, [equipmentReferencial]);

  if (error.isError) return <p>Une erreur est survenue</p>;

  const test = (value) => {
    setSpinnerOpen(value);
  };

  const handleContentModal = (type: ModalContentType): ReactNode => {
    switch (type) {
      case 'View3d':
        return (
          <View3d
            data={{ model_id: data.matterport_scan.model_id, uuid_owner: data.uuid, uuid_event: eventId }}
            matterport_scan_areas={data.data?.matterport_scan_areas}
            mode="viewer"
          />
        );
      case 'AdministrateView3d':
        return (
          <View3d
            data={{ model_id: data.matterport_scan.model_id, uuid_owner: data.uuid, uuid_event: eventId }}
            matterport_scan_areas={data.data?.matterport_scan_areas}
            mode="administrate"
            setData={setData}
          />
        );
      case 'Gallery':
        return <Gallery images={images} startIndex={galleryIndex} />;
      case 'EditPhotos':
        return (
          <ImagePicker
            matterport_model_id={data?.matterport_scan?.model_id}
            refreshProductImages={refreshProductImages}
            productData={data}
            buildingData={buildingData}
          />
        );
      case 'EditProductInfos':
        return (
          <div style={{ padding: '25px' }}>
            <EditPropertyInfos
              setSpinnerOpen={test}
              spinnerOpen={false}
              setModalOpen={setModalOpen}
              refreshData={getProductData}
              propertyData={data}
              setPropertyData={setData}
            ></EditPropertyInfos>
          </div>
        );
      case 'Suppress':
        return (
          <div>
            Êtes-vous sûr de vouloir supprimer cet évènement
            <Stack direction="row-reverse" spacing={1} style={{ paddingTop: '20px', paddingBottom: '20px' }}>
              <Button variant="contained" color={'success'} onClick={handleSuppressEvent}>
                Valider
              </Button>
              <Button variant="outlined" color={'success'} onClick={handleCancelSuppressEvent}>
                Annuler
              </Button>
            </Stack>
          </div>
        );
    }
  };

  const modalClosed = (str: string) => {
    if (data === null) return;
  };

  const onRowClickHandler = (row) => {
    navigate(`/product/${id}/events/${row.uuid}`);
  };

  const refreshEvents = (e) => {
    getProduct(id)
      .then((res) => {
        if (res.events && res.events.length > 0) {
          setRowsData(res.events);
        }
      })
      .catch((error) => {});
  };

  const setSelectedProduct = (row) => {
    setSelectedUUIDEvent(row.uuid);
    setWizzardEventCreationMode(false);
    setWizzardEventOpen(true);
  };

  const handleClose = () => {
    setIsMissionOrderOpen(!isMissionOrderOpen);
  };

  const handleCopyLink = (link) => {
    const avrtBaseUrl = process.env.REACT_APP_BASEURL_AVRT;

    if (link.kind === 2) {
      setOpenSnackBarMessage('Le lien de la vue 3D a été copié dans le presse papier');
      navigator.clipboard.writeText(`${avrtBaseUrl}/${link.uuid}`);
    } else if (link.kind === 1) {
      setOpenSnackBarMessage("Le lien de l'outil de ticketing a été copié dans le presse papier");
      navigator.clipboard.writeText(`${avrtBaseUrl}/${link.uuid}`);
    } else if (link.kind === 3) {
      setOpenSnackBarMessage("Le lien de l'EDL 3D a été copié dans le presse papier");
      navigator.clipboard.writeText(`${avrtBaseUrl}/${link.uuid}`);
    }
    setOpenSnackBar(true);
  };

  const handleScanOrder = () => {
    setIsMissionOrderOpen(true);
    setScanOrder(true);
  };

  return (
    <div className="Product">
      <div>
        <BasicModal
          modalOpen={modalOpen}
          onModalOpen={(open: boolean) => setModalOpen(open)}
          onModalClose={modalClosed}
        >
          {handleContentModal(modalType)}
        </BasicModal>
      </div>

      {data && (
        <>
          <ProductTitle data={data} buildingData={buildingData} />
          <Box
            sx={{
              mb: 4,
              width: 1,
              display: 'flex',
              alignItems: 'center'
            }}
          >
            {data.address && data.address.street && (
              <Box
                sx={{
                  flexGrow: 1,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                  // paddingRight: '20px'
                }}
              >
                <Breadcrumb
                  data={[
                    { label: 'Accueil', href: '/' },
                    { label: 'Portefeuille', href: '/' }
                  ]}
                  last={`Fiche du Bien`}
                  setSpinnerOpen={setSpinnerOpen}
                />
                {matchesMd && (
                  <Grid
                    item
                    // xs={2}
                    // sm={4}
                    // md={6}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      flex: 1,
                      paddingLeft: '24.55vw',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Button
                      color="secondary"
                      variant="outlined"
                      sx={{ borderRadius: '20px', borderColor: 'secondary.main' }}
                      onClick={handleClose}
                    >
                      <AddOutlinedIcon fontSize="small" />
                      &nbsp;Nouvel ordre de service
                    </Button>
                    {data.uuid_building !== '' && (
                      <Button
                        variant="outlined"
                        color="primary"
                        sx={{ borderRadius: '20px', borderColor: 'primary.main' }}
                        onClick={() => {
                          navigate(`/building/${data.uuid_building}`);
                        }}
                      >
                        <ApartmentIcon fontSize="small" />
                        &nbsp; Accéder à l'immeuble
                      </Button>
                    )}
                  </Grid>
                )}
              </Box>
            )}
          </Box>

          <div>
            <div className="Product__lineBlock">
              <Box
                sx={{
                  width: 1,
                  display: 'flex',
                  justifyContent: 'flex-end'
                }}
              >
                <div className="Product__link">
                  {' '}
                  <div className="test"> </div>
                </div>
              </Box>
            </div>
            {!matchesMd && (
              <Grid
                item
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  flex: 1,
                  justifyContent: 'space-between',
                  marginBottom: '1.5vh'
                }}
              >
                <Button
                  color="secondary"
                  variant="outlined"
                  sx={{ borderRadius: '20px', borderColor: 'secondary.main' }}
                  onClick={handleClose}
                  size="small"
                >
                  <AddOutlinedIcon fontSize="small" />
                  &nbsp;Ordre de service
                </Button>
                {data.uuid_building !== '' && (
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    sx={{ borderRadius: '20px', borderColor: 'primary.main' }}
                    onClick={() => {
                      navigate(`/building/${data.uuid_building}`);
                    }}
                  >
                    <ApartmentIcon fontSize="small" />
                    &nbsp; Accéder à l'immeuble
                  </Button>
                )}
              </Grid>
            )}
            <div className="Product__info">
              {isMobile && (
                <div className="Product__info__tab">
                  <div
                    className={`Product__info__tab__title ${tab === 'doc' && 'active'}`}
                    onClick={() => setTab('doc')}
                  >
                    Activité
                  </div>
                  <div
                    className={`Product__info__tab__title ${tab === 'info' && 'active'}`}
                    onClick={() => setTab('info')}
                  >
                    Informations
                  </div>
                </div>
              )}
              <div className="Product__info__content">
                {(!isMobile || tab === 'doc') && (
                  <div className={!isMobile ? 'Product__info__content__start' : 'Product__info__content__start_mobile'}>
                    <ProductCard
                      redirect={`/product/${id}/events`}
                      title="Derniers tickets"
                      action={!matchesMd ? 'Tickets' : 'Ajouter un ticket'}
                      onAction={() => {
                        setWizzardEventCreationMode(true);
                        setWizzardEventOpen(true);
                      }}
                      id={id}
                      search={searchTickets}
                      handleChangeSearchFilter={rowsTickets.length > 4 ? handleChangeSearchFilterTickets : null}
                    >
                      {eventListFiltered.length > 0 && (
                        <div style={{ width: '100%' }}>
                          <EventsList
                            loading={isLoading}
                            onRowsSelectionHandler={null}
                            handleModal={handleModal}
                            rows={eventListFiltered}
                            onData={null}
                            setOpen={null}
                            onRowClick={onRowClickHandler}
                            setWizzardEventCreationMode={setWizzardEventCreationMode}
                            setWizzardEventOpen={setWizzardEventOpen}
                            setSelectedUUIDEvent={setSelectedUUIDEvent}
                            setSelectedProduct={setSelectedProduct}
                          />
                        </div>
                      )}
                    </ProductCard>

                    {equipmentReferencial && equipmentReferencial['equipments'] && (
                      <ProductCard
                        redirect={null}
                        title="Liste des équipements"
                        action={!matchesMd ? 'Equipements' : 'Ajouter un équipement'}
                        onAction={() => {
                          setWizzardEquipmentCreationMode(true);
                          setWizzardEquipmentOpen(true);
                        }}
                        handleChangeSearchFilter={equipments.length > 0 ? handleChangeSearchFilterEquipments : null}
                        search={searchedEquipmentsValue}
                      >
                        <Grid container spacing={2}>
                          {equipKinds &&
                            equipmentReferencial &&
                            selectedEquipmentsFamily === null &&
                            selectedEquipmentsList.length === 0 &&
                            searchedEquipmentsValue === '' &&
                            equipKinds.map((kind, index) => (
                              <Grid item xs={6} md={4} key={index}>
                                {renderEquipmentIcon(kind)}

                                <Link
                                  style={{ paddingLeft: '5px', cursor: 'pointer' }}
                                  onClick={() => {
                                    const equipmentsInthisFamily = [];
                                    equipments.forEach((equipment) => {
                                      if (
                                        kind !== 18 &&
                                        equipment.kind &&
                                        equipment.kind !== 0 &&
                                        equipment.category_kind === equipKinds[index]
                                      ) {
                                        equipmentsInthisFamily.push(equipment);
                                      } else if (kind !== 18 && !equipment.category_kind) {
                                        if (equipment.kind !== 1 && kind !== 1) {
                                          const referentialEquipment = _.find(equipmentReferencial['equipments'], {
                                            id: equipment.kind
                                          });

                                          const referentialCategoryEquipment = referentialEquipment?.kinds?.map(
                                            (categoryKindId) => {
                                              return _.find(equipmentReferencial['equipmentCategoryKinds'], {
                                                id: categoryKindId
                                              });
                                            }
                                          );

                                          referentialCategoryEquipment?.forEach((category) => {
                                            if (category.id === kind) {
                                              equipmentsInthisFamily.push(equipment);
                                            }
                                          });
                                        } else if (index === 1) {
                                          const referentialEquipment = _.find(equipmentReferencial['equipments'], {
                                            id: equipment.kind
                                          });

                                          const referentialCategoryEquipment = referentialEquipment?.kinds?.map(
                                            (categoryKindId) => {
                                              return _.find(equipmentReferencial['equipmentCategoryKinds'], {
                                                id: categoryKindId
                                              });
                                            }
                                          );

                                          referentialCategoryEquipment?.forEach((category) => {
                                            if (category.id === kind) {
                                              equipmentsInthisFamily.push(equipment);
                                            }
                                          });
                                        }
                                      } else {
                                        if (kind === 18 && equipment.tab === 'inventoryEquipments') {
                                          if (
                                            equipment.states &&
                                            equipment.states[0] &&
                                            (equipment.states[0]?.data?.inventoryNumber ||
                                              equipment.states[0]?.images?.length > 0)
                                          ) {
                                            equipmentsInthisFamily.push(equipment);
                                          }
                                        }
                                      }
                                    });

                                    equipmentsInthisFamily.sort((a, b) => {
                                      if (a.room === null) return 1;
                                      if (b.room === null) return -1;
                                      return a.room.name.localeCompare(b.room.name);
                                    });

                                    setSearchParams({ equ_cat: equipKinds[index] });
                                    setSelectedEquipmentsFamily(equipKinds[index]);
                                    setSelectedEquipmentsList(equipmentsInthisFamily);
                                  }}
                                >
                                  <span style={{ fontWeight: '1000', fontSize: '14px' }}>{GetEquipmentKind(kind)}</span>
                                </Link>
                              </Grid>
                            ))}

                          {searchedEquipmentsValue !== '' &&
                            selectedEquipmentsFamily === null &&
                            selectedEquipmentsList.length === 0 && (
                              <Grid item xs={12}>
                                Aucun réultat
                              </Grid>
                            )}

                          <>
                            {selectedEquipmentsFamily !== null && (
                              <Grid item xs={12}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                  <Link
                                    sx={{ '&:hover': { textDecoration: 'none', color: '#F17633' } }}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      setSelectedEquipmentsFamily(null);
                                      setSelectedEquipmentsList([]);
                                      setSearchParams({});
                                    }}
                                  >
                                    <div style={{ borderRadius: '25px' }}>
                                      <ArrowBackIosIcon sx={{ position: 'relative', pt: '3px' }} />
                                    </div>
                                  </Link>
                                  {renderEquipmentIcon(selectedEquipmentsFamily)}
                                  <span style={{ fontWeight: '1000', fontSize: '14px', paddingLeft: '3px' }}>
                                    {GetEquipmentKind(selectedEquipmentsFamily)}
                                  </span>
                                </div>
                              </Grid>
                            )}
                            <Grid container spacing={1} sx={{ pl: '45px', pt: '10px', justifyContent: 'flex-start' }}>
                              {selectedEquipmentsList.length > 0 &&
                                selectedEquipmentsList.map((equipment, index) => (
                                  <Grid item xs={6} md={4} key={index}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                      <Link style={{ cursor: 'pointer' }} href={`/equipments/${equipment.uuid}`}>
                                        {`${_.find(equipmentReferencial['equipments'], { id: equipment.kind }).name}${equipment.title && equipment.title !== '' && _.find(equipmentReferencial['equipments'], { id: equipment.kind }).name !== equipment.title ? ' - ' + equipment.title : ''}${
                                          equipment.room !== null && equipment.room.name
                                            ? ' - ' + equipment.room.name
                                            : ''
                                        }${
                                          equipment.sub_tab !== null &&
                                          selectedEquipmentsFamily === 18 &&
                                          _.find(equipmentReferencial['inventoryKinds'], {
                                            id: Number(equipment.sub_tab)
                                          })
                                            ? ' - ' +
                                              _.find(equipmentReferencial['inventoryKinds'], {
                                                id: Number(equipment.sub_tab)
                                              }).name
                                            : ''
                                        }`}
                                      </Link>
                                    </div>
                                  </Grid>
                                ))}
                            </Grid>
                          </>
                        </Grid>
                      </ProductCard>
                    )}
                    <ProductCard
                      title="Documents du bien"
                      action={!matchesMd ? 'Documents' : 'Ajouter un document'}
                      onAction={() => {
                        setSelectedDocument(null);
                        setWizzardDocumentOpen(true);
                      }}
                      search={searchDocuments}
                      handleChangeSearchFilter={rowsDocuments.length > 4 ? handleChangeSearchFilterDcuments : null}
                    >
                      {rowsDocuments && rowsDocuments.length > 0 && (
                        <div style={{ width: '100%' }}>
                          <DocumentsList
                            productDatas={data}
                            setWizzardDocumentOpen={setWizzardDocumentOpen}
                            setSelectedDocument={setSelectedDocument}
                            updateSelectedDocumentData={updateSelectedDocumentData}
                            rows={rowsDocumentsFiltered}
                          />
                        </div>
                      )}
                    </ProductCard>
                  </div>
                )}
                {(!isMobile || tab === 'info') && (
                  <div className={!isMobile ? 'Product__info__content__end' : 'Product__info__content__end_mobile'}>
                    <Grid container spacing={2}>
                      {(isSuperAdmin || isAdmin) && (
                        <Grid item xs={12}>
                          <div className="BlockTitle">
                            <div className="Product__lineBlock" style={{ marginBottom: '0px' }}>
                              <div className="Product__lineBlock__title">Modèle</div>

                              {data.matterport_scan &&
                                data?.matterport_scan?.model_id &&
                                data?.matterport_scan?.scanned_at && (
                                  <>
                                    <span>{new Date(data.matterport_scan.scanned_at).toLocaleDateString('fr-FR')}</span>
                                    <Link
                                      className="Product__link"
                                      onClick={() => handleModal('AdministrateView3d')}
                                      color="primary"
                                    >
                                      <EditOutlined /> Editer zones
                                    </Link>
                                  </>
                                )}
                            </div>
                          </div>
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <div
                          className={
                            data.matterport_scan && data.matterport_scan.model_id
                              ? 'product_sheet_preview_pointer'
                              : 'product_sheet_preview_no_pointer'
                          }
                          onClick={() => {
                            if (data.matterport_scan && data.matterport_scan.model_id) {
                              handleModal('View3d');
                            }
                          }}
                          {...(headerImage[0] && {
                            style: {
                              borderRadius: '15px',
                              backgroundImage: `url(${process.env.REACT_APP_BASEURL_MEDIAS}/${headerImage[0].uri})`
                            }
                          })}
                          {...((!images || !headerImage || headerImage.length === 0) && {
                            style: {
                              borderRadius: '15px',
                              background:
                                'repeating-linear-gradient(-45deg,#0627461d,#0627461d 20px,#06274614 20px,#06274614 40px'
                            }
                          })}
                        >
                          {!data.matterport_scan && (
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                              <Typography variant="h4" sx={{ color: 'gray', textAlign: 'center' }}>
                                Vous ne disposez pas encore d'un jumeau numérique pour ce bien
                              </Typography>
                              <Button
                                color="secondary"
                                variant="contained"
                                sx={{
                                  borderRadius: '20px',
                                  borderColor: 'secondary.main',
                                  color: 'white',
                                  marginTop: '30px'
                                }}
                                onClick={handleScanOrder}
                              >
                                <AddOutlinedIcon fontSize="small" />
                                &nbsp;Commander le Scan 3D
                              </Button>
                            </div>
                          )}

                          <div>
                            <div>
                              {data.matterport_scan && data.matterport_scan.model_id && (
                                <PlayCircleIcon
                                  fontSize="large"
                                  sx={{
                                    color:
                                      !images || !headerImage || headerImage.length === 0
                                        ? appTheme.palette.primary.main
                                        : grey[50],
                                    transform: 'scale(2)'
                                  }}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </Grid>

                      <>
                        <Grid container spacing={2} item xs={12} className="zer">
                          <Grid item xs={12}>
                            <div className="BlockTitle">
                              <div className="Product__lineBlock" style={{ marginBottom: '0px' }}>
                                <div className="Product__lineBlock__title">Photos</div>
                                <Link
                                  className="Product__link"
                                  onClick={() => handleModal('EditPhotos')}
                                  color="primary"
                                >
                                  <EditOutlined /> Editer
                                </Link>
                              </div>
                            </div>
                          </Grid>
                          {!images && (
                            <Grid
                              item
                              xs={12}
                              sx={{ pb: '15px', textAlign: 'center' }}
                              style={{
                                margin: 0,
                                fontSize: '14px',
                                fontWeight: 400,
                                fontFamily: "'Roboto',sans-serif",
                                lineHeight: 1.5
                              }}
                            >
                              Pas de photos
                            </Grid>
                          )}
                          {images && (
                            <Grid item xs={12}>
                              <div className="hide_scrollbar" style={{ width: '100%', overflowX: 'scroll' }}>
                                <div style={{ position: 'relative', display: 'inline-flex' }}>
                                  {images.map((image, index) => (
                                    <div
                                      className="product_sheet_image"
                                      key={index}
                                      onClick={() => {
                                        setGalleryIndex(index);
                                        handleModal('Gallery');
                                      }}
                                      style={{
                                        backgroundImage: `url(${process.env.REACT_APP_BASEURL_MEDIAS}/${image.uri})`
                                      }}
                                    ></div>
                                    // </Grid>
                                  ))}
                                </div>
                              </div>
                            </Grid>
                          )}
                        </Grid>
                      </>
                    </Grid>
                    <Grid item xs={12}>
                      <div className="BlockTitle">
                        <div className="Product__lineBlock" style={{ marginTop: '10px' }}>
                          <div className="Product__lineBlock__title">Informations utiles</div>
                          <Link
                            className="Product__link"
                            onClick={() => {
                              handleModal('EditProductInfos');
                            }}
                            color="primary"
                          >
                            <EditOutlined /> Editer
                          </Link>
                        </div>
                      </div>
                    </Grid>
                    <PropertyInfo
                      onModalType={(type: ModalContentType) => setModalType(type)}
                      onOpen={(open: boolean) => setModalOpen(open)}
                      data={data}
                      buildingData={buildingData}
                      isBuilding={false}
                    />
                    <Grid item xs={12}>
                      <div className="BlockTitle">
                        <div className="Product__lineBlock" style={{ marginBottom: '0px' }}>
                          <div className="Product__lineBlock__title">Contacts Utiles</div>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <ProductContact
                        contacts={contacts}
                        productId={id}
                        allContacts={allContacts}
                        setUpdateContact={setUpdateContact}
                        setAllContacts={setAllContacts}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <div className="BlockTitle">
                        <div className="Product__lineBlock" style={{ marginBottom: '0px' }}>
                          <div className="Product__lineBlock__title">Liens Utiles</div>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 1 }}>
                      {links.length > 0 && (
                        <div>
                          {links.map((link, index) => {
                            if (shortLinkKind[link.kind] !== undefined) {
                              if (link.kind === 1) {
                                return (
                                  <div style={{ display: 'flex', alignItems: 'center' }} key={index}>
                                    <Typography
                                      variant="body1"
                                      style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                      onClick={() => {
                                        window.open(link.link, '_blank');
                                      }}
                                      sx={{ '&:hover': { color: theme.palette.secondary.main } }}
                                    >
                                      Ticketing
                                    </Typography>
                                    <IconButton
                                      sx={{
                                        '&:hover': { color: appTheme.palette.secondary.main },
                                        paddingRight: '0px',
                                        ml: 1
                                      }}
                                      aria-label="link"
                                      size="small"
                                      onClick={() => handleCopyLink(link)}
                                      disableRipple
                                      color="primary"
                                      data-index={index}
                                      data-type="declareEvent"
                                    >
                                      <LinkIcon />
                                    </IconButton>
                                    <IconButton
                                      sx={{
                                        '&:hover': { color: appTheme.palette.secondary.main },
                                        paddingRight: '0px',
                                        ml: 1
                                      }}
                                      aria-label="link"
                                      size="small"
                                      disableRipple
                                      color="primary"
                                      data-index={index}
                                      onClick={() => handleShareDialog(link, true)}
                                    >
                                      <ShareIcon fontSize="small" />
                                    </IconButton>
                                  </div>
                                );
                              } else if (link.kind === 2) {
                                return (
                                  <div style={{ display: 'flex', alignItems: 'center' }} key={index}>
                                    <Typography
                                      variant="body1"
                                      style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                      onClick={() => {
                                        window.open(link.link, '_blank');
                                      }}
                                      sx={{ '&:hover': { color: theme.palette.secondary.main } }}
                                    >
                                      Vue 3D{' '}
                                    </Typography>
                                    <IconButton
                                      sx={{
                                        '&:hover': { color: appTheme.palette.secondary.main },
                                        paddingRight: '0px',
                                        ml: 1
                                      }}
                                      aria-label="link"
                                      size="small"
                                      onClick={() => handleCopyLink(link)}
                                      disableRipple
                                      color="primary"
                                      data-index={index}
                                      data-type="declareEvent"
                                    >
                                      <LinkIcon />
                                    </IconButton>
                                    <IconButton
                                      sx={{
                                        '&:hover': { color: appTheme.palette.secondary.main },
                                        paddingRight: '0px',
                                        ml: 1
                                      }}
                                      aria-label="link"
                                      size="small"
                                      disableRipple
                                      color="primary"
                                      data-index={index}
                                      onClick={() => handleShareDialog(link, true)}
                                    >
                                      <ShareIcon fontSize="small" />
                                    </IconButton>
                                  </div>
                                );
                              } else if (link.kind === 3) {
                                const date = new Date(link.created_at);
                                return (
                                  <div style={{ display: 'flex', alignItems: 'center' }} key={index}>
                                    <Typography
                                      variant="body1"
                                      style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                      sx={{ '&:hover': { color: theme.palette.secondary.main } }}
                                      onClick={() => {
                                        window.open(link.link, '_blank');
                                      }}
                                    >
                                      {`EDL 3D - ${date.toLocaleDateString('fr-FR')} à ${date.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' })}`}
                                    </Typography>
                                    <IconButton
                                      sx={{
                                        '&:hover': { color: appTheme.palette.secondary.main },
                                        paddingRight: '0px',
                                        ml: 1
                                      }}
                                      aria-label="link"
                                      size="small"
                                      onClick={() => handleCopyLink(link)}
                                      disableRipple
                                      color="primary"
                                      data-index={index}
                                      data-type="declareEvent"
                                    >
                                      <LinkIcon />
                                    </IconButton>
                                    <IconButton
                                      sx={{
                                        '&:hover': { color: appTheme.palette.secondary.main },
                                        paddingRight: '0px',
                                        ml: 1
                                      }}
                                      aria-label="link"
                                      size="small"
                                      disableRipple
                                      color="primary"
                                      data-index={index}
                                      onClick={() => handleShareDialog(link, true)}
                                    >
                                      <ShareIcon fontSize="small" />
                                    </IconButton>
                                  </div>
                                );
                              }
                            }
                            return null;
                          })}
                        </div>
                      )}
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="body1">Accés</Typography>
                        <IconButton
                          sx={{
                            '&:hover': { color: appTheme.palette.secondary.main },
                            paddingRight: '0px',
                            ml: 1
                          }}
                          aria-label="link"
                          size="small"
                          disableRipple
                          color="primary"
                          onClick={() => handleShareDialog(data, false)}
                        >
                          <ShareIcon fontSize="small" />
                        </IconButton>
                      </div>
                    </Grid>

                    <br />
                  </div>
                )}
              </div>
            </div>

            <Snackbar
              open={openSnackBar}
              autoHideDuration={2000}
              onClose={() => setOpenSnackBar(false)}
              message={openSnackBarMessage}
              ContentProps={{
                sx: {
                  background: appTheme.palette.primary.main
                }
              }}
            />

            {openShareDialog && (
              <EventShareDialog
                open={openShareDialog}
                onClose={() => setOpenShareDialog(false)}
                eventData={data}
                isFromProductPage={true}
                dataToShare={dataToShare}
              />
            )}
            {wizzardEquipmentOpen && (
              <WizzardEquipment
                productDatas={data}
                setWizzardEquipmentOpen={setWizzardEquipmentOpen}
                wizzardEquipmentCreationMode={wizzardEquipmentCreationMode}
                selectedUUIDEquipment={selectedUUIDEquipment}
                refreshEquipments={refreshEquipments}
                buildingData={buildingData}
              ></WizzardEquipment>
            )}
            {wizzardEventOpen && (
              <WizzardEvent
                saveChanges={saveChanges}
                productDatas={data}
                setWizzardEventOpen={setWizzardEventOpen}
                refreshEvents={refreshEvents}
                wizzardEventCreationMode={wizzardEventCreationMode}
                selectedUUIDEvent={selectedUUIDEvent}
                wizzardOpen={wizzardEventOpen}
              ></WizzardEvent>
            )}
            {wizzardDocumentOpen && (
              <WizzardDocument
                productDatas={data}
                setWizzardDocumentOpen={setWizzardDocumentOpen}
                documentData={selectedDocument}
                setDocumentData={setSelectedDocument}
                updateSelectedDocumentData={updateSelectedDocumentData}
                setSelectedUUIDEquipment={setSelectedUUIDEquipment}
                setWizzardEquipmentCreationMode={setWizzardEquipmentCreationMode}
                setWizzardEquipmentOpen={setWizzardEquipmentOpen}
                generateSubtitleEquipment={generateSubtitle}
                equipmentData={equipmentData}
                equipmentReferential={equipmentReferencial}
              ></WizzardDocument>
            )}
            {isMissionOrderOpen && (
              <Modal
                open={isMissionOrderOpen}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 1000
                }}
              >
                <Fade in={isMissionOrderOpen}>
                  <Box
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '98%',
                      height: '100%',
                      backgroundColor: 'white',
                      p: 3
                    }}
                  >
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'end' }}>
                      <IconButton onClick={handleClose}>
                        <CloseIcon />
                      </IconButton>
                    </Grid>
                    <WizzardMissionOrderForm
                      setSpinnerOpen={setSpinnerOpen}
                      spinnerOpen={spinnerOpen}
                      handleClose={handleClose}
                      productData={data}
                      scanOrder={scanOrder}
                    />
                  </Box>
                </Fade>
              </Modal>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Product;
