import { Box, Button, Grid2, Link } from '@mui/material';
import { ReactNode, useEffect, useState, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getProduct, patchEvent, assignEquipmentToEvent } from '~/api';
import { IProduct, ModalContentType } from '~/interfaces';
import { GetEquipmentKind, referentielEventTypes } from '~/utils/references';
import Breadcrumb from '~/app/components/Breadcrumb/Breadcrumb';
import WizzardEquipment from '~/app/components/WizzardEquipment/WizzardEquipment';
import WizzardEvent from '~/app/components/WizzardEvent/WizzardEvent';
import { DocumentsList } from '~/app/components/DocumentsList/DocumentsList';
import './Equipments.scss';
import '../Product/Product.scss';
import { ProductTitle } from '~/app/components/ProductTitle/ProductTitle';
import { AppContext } from '~/utils/context/AppContext';
import _ from 'lodash';
import { api } from '~/api';
import { useWindowSize } from '~/utils/useWindowSize';
import ProductCard from '~/app/components/ProductCard/ProductCard';
import EventsList from '~/app/components/EventsList/EventsList';
import { EditOutlined } from '@mui/icons-material';
import { appTheme } from '~/utils/theme';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { grey } from '@mui/material/colors';
import BasicModal from '~/app/components/BasicModal/BasicModal';
import { View3d } from '~/app/components/View3d/View3d';
import { Gallery } from '~/app/components/Gallery/Gallery';
import { WizzardDocument } from '~/app/components/WizzardDocument/WizzardDocument';
import StatesList from '~/app/components/StatesList/StatesList';
import EquipmentInfos from '~/app/components/EquipmentInfos/EquipmentInfos';
import EditEquipmentInfos from '~/app/components/EditEquipmentInfos/EditEquipmentInfos';
import dayjs from 'dayjs';
import ImagePicker from '~/app/components/ImagePicker/ImagePicker';
import RemoveIcon from '@mui/icons-material/Remove';
import { ConfirmationDialog } from '~/app/components/ConfirmationDialog/ConfirmationDialog';
import { useDialog } from '~/utils/useDialog';
import { EquipmentSubTitle } from '~/app/components/EquipmentSubTitle/EquipmentSubTitle';
import { getTagForEquipment, getTagsForEquipmentsList } from '~/app/components/View3d/utilsView3D';

export const Equipments = ({ setSpinnerOpen, spinnerOpen }) => {
  type TuseParams = {
    id: string;
    idEquipment: string;
  };

  const [deleteDialogOpen, handleOpenDeleteDialog, handleCloseDeleteDialog] = useDialog();
  const [eventId, setEventId] = useState<any>(null);
  const [openShareDialog, setOpenShareDialog] = useState(false);
  const [dataToShare, setDataToShare] = useState(null);
  const [galleryIndex, setGalleryIndex] = useState(0);
  const [images, setImages] = useState([]);
  const [headerImage, setHeaderImage] = useState([]);
  const { isMobile } = useWindowSize();
  const { id, idEquipment } = useParams<TuseParams>();
  const [productData, setProductData] = useState<null | IProduct>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [error, setError] = useState({ isError: false, error: {} });

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [openSnackBarMessage, setOpenSnackBarMessage] = useState('');

  const [wizzardEquipmentOpen, setWizzardEquipmentOpen] = useState<boolean>(false);
  const [wizzardEventOpen, setWizzardEventOpen] = useState<any>(false);
  const [selectedUUIDEquipment, setSelectedUUIDEquipment] = useState<string>('');

  const [refresh, setRefresh] = useState(false);
  const [wizzardEquipmentCreationMode, setWizzardEquipmentCreationMode] = useState<boolean>(false);
  const [wizzardEventCreationMode, setWizzardEventCreationMode] = useState<boolean>(false);
  const [equipmentCategory, setEquipmentCategory] = useState<string>('');
  const [selectedUUIDEvent, setSelectedUUIDEvent] = useState<string>('');
  const [equipmentData, setEquipmentData] = useState<any>({});

  const [equipmentFamily, setEquipmentFamily] = useState<any>(null);
  const [pathContext, setPathContext] = useState('');
  const [searchTickets, setSearchTickets] = useState<string>('');
  const [rowsTickets, setRowsTickets] = useState([]);
  const [rowsStates, setRowsStates] = useState([]);
  const [searchDocuments, setSearchDocuments] = useState<string>('');
  const [eventListFiltered, setEventListFiltered] = useState([]);
  const [stateListFiltered, setStateListFiltered] = useState([]);
  const [wizzardDocumentOpen, setWizzardDocumentOpen] = useState<any>(false);
  const [uuidEventToRowId, setUuidEventToRowId] = useState({});

  const { ReactGA } = useContext(AppContext);

  const [selectedDocument, setSelectedDocument] = useState<any>(null);

  const [rowsDocuments, setRowsDocuments] = useState([]);
  const [rowsDocumentsFiltered, setRowsDocumentsFiltered] = useState([]);
  const [isMeter, setIsMeter] = useState(false);

  const [modalType, setModalType] = useState<ModalContentType>();

  const { equipmentReferencial } = useContext(AppContext);

  const [tags, setTags] = useState([]);

  const [tab, setTab] = useState<'info' | 'doc'>('doc');

  const handleSuppressEquipment = () => {
    api.equipments.delete(idEquipment).then(() => {
      setModalOpen(!modalOpen);
      navigate(`/${pathContext}/${equipmentData.uuid_owner}`);
    });
  };

  const navigate = useNavigate();

  const refreshEquipments = (data) => {
    api.equipments.get(idEquipment).then((res) => {
      setTags([getTagForEquipment(res)]);
      setEquipmentData(res);
      setRowsData(res.events);
      setRowsStatesData(res.states);
      setRowsDocumentsData(res.documents);
    });
  };

  const handleModal = (type: ModalContentType) => {
    setSpinnerOpen(false);
    setModalOpen(!modalOpen);
    setModalType(type);
  };

  const onRowClickHandler = (row) => {
    navigate(`/product/${productData.uuid}/events/${row.uuid}`);
  };

  const refreshEvents = () => {
    api.equipments.get(idEquipment).then((res) => {
      setEquipmentData(res);
      setRowsData(res.events);
      setRowsStatesData(res.states);
      setRowsDocumentsData(res.documents);
    });
  };

  const saveChanges = (dataForm) => {
    handleModifyEvent(dataForm);
  };

  useEffect(() => {
    if (equipmentData && equipmentReferencial && equipmentData.kind) {
      if (
        _.find(equipmentReferencial['equipmentCategoryKinds'], {
          id: _.find(equipmentReferencial.equipments, { id: equipmentData.kind }).kinds[0]
        }).name === 'Compteurs'
      ) {
        setIsMeter(true);
      }
    }
  }, [equipmentReferencial, equipmentData]);

  // Filter Search
  const handleChangeSearchFilterTickets = (e) => {
    const search = e.target.value;
    setSearchTickets(search);
  };

  const handleChangeSearchFilterDcuments = (e) => {
    const search = e.target.value;
    setSearchDocuments(search);
  };

  const setSelectedProduct = (row) => {
    setSelectedUUIDEvent(row.uuid);
    setWizzardEventCreationMode(false);
    setWizzardEventOpen(true);
  };

  const setRowsData = (events) => {
    const rowsByProduct = {};
    const rows = [];

    const arrSortedDate = events?.sort((a, b) => {
      return new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime();
    });
    arrSortedDate?.forEach((event, index: number) => {
      if (event.status === 3 || event.status === 2) {
        rows.push({
          id: index,
          diagnosticType: referentielEventTypes[event.kind],
          status: event.status,
          date: event.updated_at,
          desc: event.desc,
          action: 'action',
          uuid: event.uuid,
          uuid_owner: event.uuid_owner,
          urgent: event.urgent,
          kind: event.kind
        });
        rowsByProduct[event.uuid] = index;
      }
    });
    setUuidEventToRowId(rowsByProduct);
    setRowsTickets(rows);
    setEventListFiltered(rows);
  };

  const setRowsStatesData = (states) => {
    const rowsByProduct = {};
    const rows = [];

    const arrSortedDate = states.sort((a, b) => {
      return new Date(b.update_at).getTime() - new Date(a.update_at).getTime();
    });
    arrSortedDate.forEach((state, index: number) => {
      rows.push({
        id: index,
        damages: state.damages,
        state: state.state,
        date: state.update_at,
        rating: state.rating,
        comment: state.comment,
        desc: `Etat au ${dayjs(state.update_at).format('DD/MM/YYYY à HH:mm')}`,
        uuid: state.uuid,
        uuid_owner: state.uuid_owner,
        urgent: state.urgent,
        kind: state.kind,
        data: state.data
      });
      rowsByProduct[state.uuid] = index;
      // }
    });

    setRowsStates(rows);
    setStateListFiltered(rows);
  };

  const setRowsDocumentsData = (documents) => {
    const rows = [];
    documents?.forEach((document, index) => {
      rows.push({
        id: index,
        name: document.name,
        date_creation: document.created_at,
        date: document.data?.date,
        desc: document.desc,
        action: 'action',
        uuid: document.uuid,
        uuid_owner: document.uuid_owner,
        kind: document.kind
      });
    });
    setRowsDocuments(rows);
    setRowsDocumentsFiltered(rows);
  };

  const updateSelectedDocumentData = (documentData) => {
    getProduct(id)
      .then((res) => {
        // if (res.events && res.events.length > 0) {
        //   setRowsData(res.events);
        // }
        // res.isBuilding = false;
        setProductData(res);
      })
      .catch((error) => {
        setError({ isError: true, error });
      })
      .finally(() => {
        setIsLoading(false);
        setSpinnerOpen(false);
      });
    api.files.get(documentData.uuid).then((res) => {
      setSelectedDocument(res);
    });
    refreshEvents();
  };

  const handleModifyEvent = (dataForm) => {
    if (dataForm === null) return;
    const newEvent = JSON.parse(JSON.stringify(dataForm));
    if (newEvent.tags !== null) delete newEvent.tags;
    if (newEvent.diagnosticType !== null) delete newEvent.diagnosticType;
    if (newEvent.files !== null) delete newEvent.files;
    if (newEvent.data !== null) delete newEvent.data;

    newEvent.status = dataForm.status;
    newEvent.urgent = dataForm.urgent;
    newEvent.kind = dataForm.kind;

    patchEvent(dataForm.uuid, newEvent)
      .then((res) => {
        getProduct(res.uuid_owner).then((data) => {
          setProductData(data);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    // const pathname = window.location.pathname.split('/');
    if (equipmentData && equipmentData?.uuid_owner) {
      setPathContext('product');
    }
  }, [equipmentData]);

  useEffect(() => {
    if (productData && pathContext !== '') {
      setSpinnerOpen(true);

      const images = [];
      productData['images']?.forEach((image) => {
        if (image.kind === 'thumbnail') {
          images.push(image);
        }
      });

      setHeaderImage(images);
      setIsLoading(false);
      setSpinnerOpen(false);
    }
  }, [pathContext, productData]);

  const refreshProductImages = () => {
    api.equipments.get(idEquipment).then((res) => {
      setEquipmentData(res);

      const imgsStates = [];
      const imgsTags = [];
      const imgs = [];
      if (res?.states && res?.states?.length > 0)
        res?.states?.map((state) => {
          if (state?.images && state?.images?.length > 0) imgsStates.push(...state.images);
          if (state?.tags && state?.tags?.length > 0) {
            state?.tags?.map((tag) => {
              if (tag?.images && tag?.images?.length > 0) imgsTags.push(...tag.images);
            });
          }
        });

      if (res?.images && res?.images?.length > 0) imgs.push(...res.images);

      setImages([...imgs, ...imgsStates, ...imgsTags]);
    });
  };

  useEffect(() => {
    setEquipmentCategory(GetEquipmentKind(equipmentFamily + 1));
    setEquipmentFamily(equipmentFamily);

    if (equipmentFamily) {
      navigate(`/${pathContext}/${id}/equipments`);
    }
  }, [equipmentFamily]);

  const assignEventToEquipment = (uuidEvent) => {
    assignEquipmentToEvent(uuidEvent, idEquipment).then((res) => {
      refreshEquipments(idEquipment);
    });
  };
  const modalClosed = (str: string) => {
    if (productData === null) return;
  };

  useEffect(() => {
    if (idEquipment)
      api.equipments.get(idEquipment).then((res) => {
        setEquipmentData(res);
        setRowsData(res.events);
        setRowsStatesData(res.states);
        setRowsDocumentsData(res.documents);

        setTags([getTagForEquipment(res)]);
      });
  }, [idEquipment, pathContext]);

  useEffect(() => {
    if (equipmentData && pathContext) {
      api.product.get(equipmentData.uuid_owner).then((res) => {
        setProductData(res);
      });
    }
  }, [equipmentData]);

  useEffect(() => {
    const imgsStates = [];
    const imgsTags = [];
    const imgs = [];
    if (equipmentData?.states && equipmentData?.states?.length > 0)
      equipmentData?.states?.map((state) => {
        if (state?.images && state?.images?.length > 0) imgsStates.push(...state.images);
        if (state?.tags && state?.tags?.length > 0) {
          state?.tags?.map((tag) => {
            if (tag?.images && tag?.images?.length > 0) imgsTags.push(...tag.images);
          });
        }
      });

    if (equipmentData?.images && equipmentData?.images?.length > 0) imgs.push(...equipmentData.images);

    setImages([...imgs, ...imgsStates, ...imgsTags]);
  }, [equipmentData]);

  const handleContentModal = (type: ModalContentType): ReactNode => {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname + ' - modal - ' + type });
    switch (type) {
      case 'View3d':
        const tags = getTagsForEquipmentsList(productData?.equipments);
        return (
          <View3d
            productDatas={productData}
            tags={tags}
            mode="equipment-location"
            selectedUuidEquipment={equipmentData}
          />
        );
      case 'Gallery':
        return <Gallery images={images} startIndex={galleryIndex} />;
      case 'EditPhotos':
        return (
          <ImagePicker
            matterport_model_id={productData?.matterport_scan?.model_id}
            refreshProductImages={refreshProductImages}
            productData={productData}
            equipmentData={equipmentData}
            generateSubtitleEquipment={generateSubtitle}
            equipmentReferential={equipmentReferencial}
          />
        );
      case 'EditProductInfos':
        return (
          <div>
            <EditEquipmentInfos
              setSpinnerOpen={false}
              spinnerOpen={false}
              setModalOpen={setModalOpen}
              refreshData={refreshEquipments}
              equipmentData={equipmentData}
              setEquipmentData={setEquipmentData}
              productDatas={productData}
              generateSubtitleEquipment={generateSubtitle}
              equipmentReferential={equipmentReferencial}
            ></EditEquipmentInfos>
          </div>
        );
    }
  };

  const generateSubtitle = () => {
    const labels = [];
    if (
      equipmentReferencial &&
      equipmentData?.category_kind &&
      _.find(equipmentReferencial.equipmentCategoryKinds, {
        id: equipmentData.category_kind
      }) &&
      _.find(equipmentReferencial.equipmentCategoryKinds, {
        id: equipmentData.category_kind
      }).name
    ) {
      labels.push(
        _.find(equipmentReferencial.equipmentCategoryKinds, {
          id: equipmentData.category_kind
        }).name
      );
    }

    let equipmentName = null;
    if (
      equipmentReferencial &&
      equipmentData?.kind &&
      _.find(equipmentReferencial.equipments, { id: equipmentData.kind }) &&
      _.find(equipmentReferencial.equipments, { id: equipmentData.kind }).name
    ) {
      labels.push(_.find(equipmentReferencial.equipments, { id: equipmentData.kind }).name);
      equipmentName = _.find(equipmentReferencial.equipments, { id: equipmentData.kind }).name;
    }

    if (equipmentData?.title && equipmentName !== equipmentData.title) {
      labels.push(equipmentData.title);
    }

    if (equipmentData?.room && equipmentData.room.name) {
      labels.push(equipmentData.room.name);
    }

    return labels.join(' - ');
  };

  return (
    <div className="Equipments">
      <div>
        <BasicModal
          modalOpen={modalOpen}
          onModalOpen={(open: boolean) => setModalOpen(open)}
          onModalClose={modalClosed}
        >
          {handleContentModal(modalType)}
        </BasicModal>
      </div>

      {productData && (
        <>
          {productData && <ProductTitle data={productData} />}
          <Box
            sx={{
              mt: 0,
              width: 1,
              display: 'flex',
              alignItems: 'center',
              flexDirection: isMobile ? 'column' : 'row'
            }}
          >
            <Box sx={{ flexGrow: 1 }}>
              <Breadcrumb
                data={
                  idEquipment
                    ? [
                        { label: 'Accueil', href: '/' },
                        { label: 'Portefeuille', href: '/' },
                        {
                          label: 'Fiche du bien',
                          href: `/${pathContext}/${productData.uuid}`
                        }
                      ]
                    : [
                        { label: 'Accueil', href: '/' },
                        { label: 'Portefeuille', href: '/' },
                        {
                          label: 'Fiche du bien',
                          href: `/${pathContext}/${productData.uuid}`
                        }
                      ]
                }
                last={generateSubtitle()}
                setSpinnerOpen={setSpinnerOpen}
              />
            </Box>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                color="primary"
                variant="outlined"
                sx={{ borderRadius: '20px', paddingTop: '8px', marginBottom: '20px' }}
                onClick={() => {
                  handleOpenDeleteDialog();
                }}
                size="small"
              >
                <RemoveIcon fontSize="small" />
                <span style={{ fontWeight: 'bold', fontSize: '15px' }}>&nbsp; Supprimer l'équipement</span>
              </Button>
            </div>
          </Box>

          {equipmentData && equipmentData.kind && equipmentReferencial && (
            <EquipmentSubTitle
              generateSubtitleEquipment={generateSubtitle}
              equipmentData={equipmentData}
              equipmentReferencial={equipmentReferencial}
            />
          )}
          {!isMobile && <div style={{ width: isMobile ? '0vw' : '100vw', height: '20px' }}></div>}

          <div className="Product__info">
            {isMobile && (
              <div className="Product__info__tab" style={{ marginTop: '2vh' }}>
                <div className={`Product__info__tab__title ${tab === 'doc' && 'active'}`} onClick={() => setTab('doc')}>
                  Activité
                </div>
                <div
                  className={`Product__info__tab__title ${tab === 'info' && 'active'}`}
                  onClick={() => setTab('info')}
                >
                  Informations
                </div>
              </div>
            )}
            <div className="Product__info__content">
              {(!isMobile || tab === 'doc') && (
                <div className={!isMobile ? 'Product__info__content__start' : 'Product__info__content__start_mobile'}>
                  <ProductCard
                    redirect={`/product/${id}/events`}
                    title="Derniers tickets associés"
                    action="Ajouter un ticket"
                    onAction={() => {
                      setWizzardEventCreationMode(true);
                      setWizzardEventOpen(true);
                    }}
                    id={id}
                    search={searchTickets}
                    handleChangeSearchFilter={rowsTickets.length > 4 ? handleChangeSearchFilterTickets : null}
                  >
                    {eventListFiltered.length > 0 && (
                      <div style={{ width: '100%' }}>
                        <EventsList
                          loading={isLoading}
                          onRowsSelectionHandler={null}
                          handleModal={handleModal}
                          rows={eventListFiltered}
                          onData={null}
                          setOpen={null}
                          onRowClick={onRowClickHandler}
                          setWizzardEventCreationMode={setWizzardEventCreationMode}
                          setWizzardEventOpen={setWizzardEventOpen}
                          setSelectedUUIDEvent={setSelectedUUIDEvent}
                          setSelectedProduct={setSelectedProduct}
                        />
                      </div>
                    )}
                  </ProductCard>

                  <ProductCard
                    redirect={null}
                    title="Historique des états"
                    action={null}
                    onAction={() => {
                      setWizzardEventCreationMode(true);
                      setWizzardEventOpen(true);
                    }}
                    id={id}
                    search={searchTickets}
                    handleChangeSearchFilter={rowsTickets.length > 4 ? handleChangeSearchFilterTickets : null}
                  >
                    {stateListFiltered.length > 0 && (
                      <div style={{ width: '100%' }}>
                        <StatesList
                          loading={isLoading}
                          onRowsSelectionHandler={null}
                          handleModal={handleModal}
                          rows={stateListFiltered}
                          onData={null}
                          setOpen={null}
                          onRowClick={onRowClickHandler}
                          setWizzardStateCreationMode={setWizzardEventCreationMode}
                          setWizzardStateOpen={setWizzardEventOpen}
                          setSelectedUUIDState={setSelectedUUIDEvent}
                          setSelectedProduct={setSelectedProduct}
                          isMeter={isMeter}
                          equipmentKind={equipmentData.kind}
                        />
                      </div>
                    )}
                  </ProductCard>

                  <ProductCard
                    title="Documents de l'équipement"
                    action="Ajouter un document"
                    onAction={() => {
                      setSelectedDocument(null);
                      setWizzardEquipmentCreationMode(true);
                      setWizzardDocumentOpen(true);
                    }}
                    search={searchDocuments}
                    handleChangeSearchFilter={rowsDocuments.length > 4 ? handleChangeSearchFilterDcuments : null}
                  >
                    {rowsDocuments && rowsDocuments.length > 0 && (
                      <div style={{ width: '100%' }}>
                        <DocumentsList
                          productDatas={equipmentData}
                          setWizzardDocumentOpen={setWizzardDocumentOpen}
                          setSelectedDocument={setSelectedDocument}
                          updateSelectedDocumentData={updateSelectedDocumentData}
                          rows={rowsDocumentsFiltered}
                        />
                      </div>
                    )}
                  </ProductCard>
                </div>
              )}
              {(!isMobile || tab === 'info') && (
                <div className={!isMobile ? 'Product__info__content__end' : 'Product__info__content__end_mobile'}>
                  <Grid2 container spacing={2}>
                    {/* Infos equipement */}

                    <Grid2 size={12}>
                      <div className="BlockTitle">
                        <div className="Product__lineBlock" style={{ marginTop: '10px' }}>
                          <div className="Product__lineBlock__title">Informations utiles</div>
                          <Link
                            className="Product__link"
                            onClick={() => {
                              handleModal('EditProductInfos');
                            }}
                            color="primary"
                          >
                            <EditOutlined /> Editer
                          </Link>
                        </div>
                      </div>
                      <EquipmentInfos
                        onModalType={(type: ModalContentType) => setModalType(type)}
                        onOpen={(open: boolean) => setModalOpen(open)}
                        data={equipmentData}
                      />
                    </Grid2>

                    {/* Gallerie photos */}

                    <Grid2 container spacing={2} size={12} className="zer">
                      <Grid2 size={12}>
                        <div className="BlockTitle">
                          <div className="Product__lineBlock" style={{ marginBottom: '0px' }}>
                            <div className="Product__lineBlock__title">Photos</div>
                            <Link className="Product__link" onClick={() => handleModal('EditPhotos')} color="primary">
                              <EditOutlined /> Editer
                            </Link>
                          </div>
                        </div>
                      </Grid2>
                      {!images && (
                        <Grid2
                          size={12}
                          sx={{ pb: '15px', textAlign: 'center' }}
                          style={{
                            margin: 0,
                            fontSize: '14px',
                            fontWeight: 400,
                            fontFamily: "'Roboto',sans-serif",
                            lineHeight: 1.5
                          }}
                        >
                          Pas de photos
                        </Grid2>
                      )}
                      {images && (
                        <Grid2 size={12}>
                          <div className="hide_scrollbar" style={{ width: '100%', overflowX: 'scroll' }}>
                            <div style={{ position: 'relative', display: 'inline-flex' }}>
                              {images.map((image, index) => (
                                <div
                                  className="product_sheet_image"
                                  key={index}
                                  onClick={() => {
                                    setGalleryIndex(index);
                                    handleModal('Gallery');
                                  }}
                                  style={{
                                    backgroundImage: `url(${process.env.REACT_APP_BASEURL_MEDIAS}/${image.uri})`
                                  }}
                                ></div>
                              ))}
                            </div>
                          </div>
                        </Grid2>
                      )}
                    </Grid2>

                    {/* Localisation */}

                    <Grid2 size={12}>
                      <div className="BlockTitle">
                        <div className="Product__lineBlock" style={{ marginBottom: '0px' }}>
                          <div className="Product__lineBlock__title">Localisation</div>

                          <Link
                            className="Product__link"
                            onClick={(e) => {
                              setWizzardEquipmentOpen(true);
                              setWizzardEquipmentCreationMode(false);
                            }}
                            color="primary"
                          >
                            <EditOutlined /> Editer
                          </Link>
                        </div>
                      </div>
                    </Grid2>
                    {pathContext && (
                      <Grid2 size={12}>
                        <>
                          <div
                            className={
                              productData.matterport_scan && productData.matterport_scan.model_id
                                ? 'product_sheet_preview_pointer'
                                : 'product_sheet_preview_no_pointer'
                            }
                            onClick={() => {
                              if (productData.matterport_scan && productData.matterport_scan.model_id) {
                                handleModal('View3d');
                              }
                            }}
                            {...(headerImage[0] && {
                              style: {
                                borderRadius: '15px',
                                backgroundImage: `url(${headerImage[0].uri})`
                              }
                            })}
                            {...((!images || !headerImage || headerImage.length === 0) && {
                              style: {
                                borderRadius: '15px',
                                background:
                                  'repeating-linear-gradient(-45deg,#0627461d,#0627461d 20px,#06274614 20px,#06274614 40px'
                              }
                            })}
                          >
                            <div>
                              <div>
                                {productData.matterport_scan && productData.matterport_scan.model_id && (
                                  <PlayCircleIcon
                                    fontSize="large"
                                    sx={{
                                      color:
                                        !images || !headerImage || headerImage.length === 0
                                          ? appTheme.palette.primary.main
                                          : grey[50],
                                      transform: 'scale(2)'
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </>
                      </Grid2>
                    )}
                  </Grid2>
                  <br />
                </div>
              )}
            </div>
          </div>

          {wizzardEquipmentOpen && (
            <WizzardEquipment
              productDatas={productData}
              setWizzardEquipmentOpen={setWizzardEquipmentOpen}
              refreshEquipments={refreshEquipments}
              wizzardEquipmentCreationMode={wizzardEquipmentCreationMode}
              equipmentFamily={equipmentFamily}
              generateSubtitleEquipment={generateSubtitle}
              equipmentData={equipmentData}
              equipmentReferential={equipmentReferencial}
            ></WizzardEquipment>
          )}

          {wizzardDocumentOpen && (
            <WizzardDocument
              productDatas={productData}
              setWizzardDocumentOpen={setWizzardDocumentOpen}
              documentData={selectedDocument}
              setDocumentData={setSelectedDocument}
              updateSelectedDocumentData={updateSelectedDocumentData}
              setSelectedUUIDEquipment={setSelectedUUIDEquipment}
              setWizzardEquipmentCreationMode={setWizzardEquipmentCreationMode}
              setWizzardEquipmentOpen={setWizzardEquipmentOpen}
              generateSubtitleEquipment={generateSubtitle}
              equipmentData={equipmentData}
              equipmentReferential={equipmentReferencial}
            ></WizzardDocument>
          )}

          {wizzardEventOpen && (
            <WizzardEvent
              saveChanges={saveChanges}
              productDatas={productData}
              setWizzardEventOpen={setWizzardEventOpen}
              refreshEvents={refreshEvents}
              wizzardEventCreationMode={wizzardEventCreationMode}
              selectedUUIDEvent={selectedUUIDEvent}
              wizzardOpen={wizzardEventOpen}
              assignEventToEquipment={assignEventToEquipment}
              generateSubtitleEquipment={generateSubtitle}
              equipmentData={equipmentData}
              equipmentReferential={equipmentReferencial}
            ></WizzardEvent>
          )}

          <ConfirmationDialog
            message={'Etes vous certain de vouloir supprimer définitivement cet équipement'}
            onCancel={handleCloseDeleteDialog}
            onConfirm={handleSuppressEquipment}
            open={deleteDialogOpen}
            title={'Suppression'}
            variant={'error'}
            cancelText="Non"
            confirmText="Oui"
          />
        </>
      )}
    </div>
  );
};
