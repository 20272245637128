import { Logout } from '@mui/icons-material';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import ContactsIcon from '@mui/icons-material/Contacts';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import GroupsIcon from '@mui/icons-material/Groups';
import HomeIcon from '@mui/icons-material/Home';
import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import {
  AppBar,
  Avatar,
  Button,
  Container,
  Divider,
  ListItemAvatar,
  Menu,
  MenuItem,
  Toolbar,
  Typography
} from '@mui/material';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Auth from '~/utils/context/Auth';
import { auth, logOut } from '~/app/components/Auth/AuthApi';
import './Header.scss';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';

export default function Header({ setSpinnerOpen, spinnerOpen }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState<boolean>(false);
  const [organizationName, setOrganizationName] = useState('');
  const [userName, setUserName] = useState('');
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const { isAuthenticated, setIsAuthenticated } = useContext(Auth);

  const handleLogOut = () => {
    logOut();
    setIsAuthenticated(false);
    setSpinnerOpen(true);
  };

  useEffect(() => {
    const claims = auth.getJWT();
    setOrganizationName(auth.getOrganizationName);
    setIsAdmin(auth.isAdmin());
    setIsSuperAdmin(auth.isSuperAdmin());
    setUserName(claims.user_name);
    setSpinnerOpen(false);
  }, []);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <>
      <AppBar
        position="sticky"
        className="Header"
        sx={{
          backgroundColor: 'background.default',
          color: 'primary.main',
          width: '100%'
        }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            {location.pathname !== '/share' ? (
              <Box
                className="header__menu"
                sx={{
                  mr: 2,
                  display: { xs: 'flex', md: 'none' },
                  flexGrow: 1
                }}
              >
                <IconButton
                  onClick={() => setOpenMenu(!openMenu)}
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 2 }}
                >
                  <MenuIcon />
                </IconButton>
              </Box>
            ) : null}
            <Box
              className="logo"
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                flexGrow: 1
              }}
            >
              <div className="logo">
                <img src="/logo3D.svg" width="45" height="44" alt="Cibel logo desk" />
              </div>
            </Box>
            <Box
              className="logo"
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1
              }}
            >
              <div className="logo">
                <img src="/logo3D.svg" width="45" height="44" alt="Cibel logo mobile" />
              </div>
            </Box>
            {/* Menu Desk */}
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              <Button
                sx={{ fontWeight: '400' }}
                className={`${location.pathname === '/' || location.pathname.match('/product') || location.pathname.match('/building') ? 'link-active' : ''}`}
                onClick={() => {
                  if (location.pathname !== '/') {
                    setSpinnerOpen(true);
                    navigate('/');
                  }
                  setOpenMenu(false);
                }}
              >
                Portefeuille
              </Button>
              {isSuperAdmin && (
                <Button
                  sx={{ fontWeight: '400' }}
                  className={`${location.pathname.match('/dashboard') ? 'link-active' : ''}`}
                  onClick={() => {
                    if (location.pathname !== '/dashboard') {
                      setSpinnerOpen(true);
                      navigate('/dashboard');
                    }
                    setOpenMenu(false);
                  }}
                >
                  Tableau de bord
                </Button>
              )}

              <Button
                sx={{ fontWeight: '400' }}
                className={`${
                  location.pathname.match('/events') &&
                  !location.pathname.match('/product') &&
                  !location.pathname.match('/building')
                    ? 'link-active'
                    : ''
                }`}
                onClick={() => {
                  if (location.pathname !== '/events') {
                    setSpinnerOpen(true);
                    navigate('/events');
                  }
                  setOpenMenu(false);
                }}
              >
                Liste des tickets
              </Button>
              <Button
                sx={{ fontWeight: '400' }}
                className={`${location.pathname.match('/mission_order') ? 'link-active' : ''}`}
                onClick={() => {
                  if (location.pathname !== '/mission_order') {
                    setSpinnerOpen(true);
                    navigate('/mission_order');
                  }
                  setOpenMenu(false);
                }}
              >
                Ordres de missions
              </Button>
              {isSuperAdmin && (
                <Button
                  sx={{ fontWeight: '400' }}
                  className={`${location.pathname.match('/organizations') ? 'link-active' : ''}`}
                  onClick={() => {
                    if (location.pathname !== '/organizations') {
                      setSpinnerOpen(true);
                      navigate('/organizations');
                    }
                    setOpenMenu(false);
                  }}
                >
                  Organisations
                </Button>
              )}
              <Button
                sx={{ fontWeight: '400' }}
                className={`${location.pathname.match('/contacts') ? 'link-active' : ''}`}
                onClick={() => {
                  if (location.pathname !== '/contacts') {
                    setSpinnerOpen(true);
                    navigate('/contacts');
                  }
                  setOpenMenu(false);
                }}
              >
                Contacts
              </Button>
            </Box>
            <Box sx={{ flexGrow: 0 }}>
              <Button
                sx={{
                  padding: '16px 24x',
                  borderRadius: '10px',
                  display: { xs: 'none', md: 'flex' }
                }}
                color="primary"
                size="medium"
                startIcon={<AccountCircleOutlinedIcon />}
                variant="outlined"
                onClick={handleOpenUserMenu}
              >
                <Typography variant="body1" sx={{ py: 0.75 }}>
                  {userName}
                </Typography>
              </Button>
              <IconButton
                onClick={handleOpenUserMenu}
                sx={{
                  p: 0,
                  display: { xs: 'flex', md: 'none' },
                  color: 'primary.main'
                }}
              >
                <AccountCircleOutlinedIcon />
              </IconButton>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <ListItem>
                  <ListItemAvatar>
                    <Avatar variant="rounded" src="/broken-image.jpg" />
                  </ListItemAvatar>
                  <ListItemText primary={userName} secondary={organizationName} sx={{ color: 'primary.main' }} />
                </ListItem>
                <Divider />
                <MenuItem
                  sx={{ color: 'primary.main' }}
                  onClick={() => {
                    if (location.pathname !== '/profil') {
                      setSpinnerOpen(true);
                      navigate('/profil');
                      setAnchorElUser(null);
                    }
                  }}
                >
                  <ListItemIcon>
                    <PersonIcon fontSize="small" sx={{ color: 'primary.main' }} />
                  </ListItemIcon>
                  <Typography textAlign="center">Profil</Typography>
                </MenuItem>
                {(isSuperAdmin || isAdmin) && (
                  <MenuItem
                    sx={{ color: 'primary.main' }}
                    onClick={() => {
                      if (location.pathname !== '/settings') {
                        setSpinnerOpen(true);
                        navigate('/settings');
                        setAnchorElUser(null);
                      }
                    }}
                  >
                    <ListItemIcon>
                      <SettingsIcon fontSize="small" sx={{ color: 'primary.main' }} />
                    </ListItemIcon>
                    <Typography textAlign="center">Paramètres</Typography>
                  </MenuItem>
                )}

                <MenuItem
                  sx={{ color: 'primary.main' }}
                  onClick={() => {
                    handleLogOut();
                  }}
                >
                  <ListItemIcon>
                    <Logout fontSize="small" sx={{ color: 'primary.main' }} />
                  </ListItemIcon>
                  <Typography textAlign="center">Se déconnecter</Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Drawer anchor={'left'} open={openMenu} onClose={() => setOpenMenu(false)}>
        <List sx={{ color: 'primary.main' }}>
          <ListItem disablePadding>
            <ListItemButton
              component="a"
              onClick={() => {
                if (location.pathname !== '/') {
                  setSpinnerOpen(true);
                  navigate('/');
                }
                setOpenMenu(false);
              }}
            >
              <ListItemIcon>
                <HomeIcon sx={{ color: 'primary.main' }} />
              </ListItemIcon>
              <ListItemText primary={'Portefeuille'} />
            </ListItemButton>
          </ListItem>

          {isSuperAdmin && (
            <ListItem disablePadding>
              <ListItemButton
                component="a"
                onClick={() => {
                  if (location.pathname !== '/dashboard') {
                    setSpinnerOpen(true);
                    navigate('/dashboard');
                  }
                  setOpenMenu(false);
                }}
              >
                <ListItemIcon>
                  <FormatListBulletedIcon sx={{ color: 'primary.main' }} />
                </ListItemIcon>
                <ListItemText primary={'Tableau de bord'} />
              </ListItemButton>
            </ListItem>
          )}

          <ListItem disablePadding>
            <ListItemButton
              component="a"
              onClick={() => {
                if (location.pathname !== '/events') {
                  setSpinnerOpen(true);
                  navigate('/events');
                }
                setOpenMenu(false);
              }}
            >
              <ListItemIcon>
                <ConfirmationNumberIcon sx={{ color: 'primary.main' }} />
              </ListItemIcon>
              <ListItemText primary={'Liste des tickets'} />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton
              component="a"
              onClick={() => {
                if (location.pathname !== '/mission_order') {
                  setSpinnerOpen(true);
                  navigate('/mission_order');
                }
                setOpenMenu(false);
              }}
            >
              <ListItemIcon>
                <FormatListBulletedIcon sx={{ color: 'primary.main' }} />
              </ListItemIcon>
              <ListItemText primary={'Ordre de missions'} />
            </ListItemButton>
          </ListItem>
          {isSuperAdmin && (
            <ListItem disablePadding>
              <ListItemButton
                component="a"
                onClick={() => {
                  if (location.pathname !== '/organizations') {
                    setSpinnerOpen(true);
                    navigate('/organizations');
                  }
                  setOpenMenu(false);
                }}
              >
                <ListItemIcon>
                  <GroupsIcon sx={{ color: 'primary.main' }} />
                </ListItemIcon>
                <ListItemText primary={'Organisations'} />
              </ListItemButton>
            </ListItem>
          )}
          <ListItem disablePadding>
            <ListItemButton
              component="a"
              onClick={() => {
                if (location.pathname !== '/contacts') {
                  setSpinnerOpen(true);
                  navigate('/contacts');
                }
                setOpenMenu(false);
              }}
            >
              <ListItemIcon>
                <ContactsIcon sx={{ color: 'primary.main' }} />
              </ListItemIcon>
              <ListItemText primary={'Contacts'} />
            </ListItemButton>
          </ListItem>

          {(isSuperAdmin || isAdmin) && (
            <ListItem disablePadding>
              <ListItemButton
                component="a"
                onClick={() => {
                  if (location.pathname !== '/settings') {
                    setSpinnerOpen(true);
                    navigate('/settings');
                  }
                  setOpenMenu(false);
                }}
              >
                <ListItemIcon>
                  <SettingsIcon sx={{ color: 'primary.main' }} />
                </ListItemIcon>
                <ListItemText primary={'Paramètres'} />
              </ListItemButton>
            </ListItem>
          )}
        </List>
      </Drawer>
    </>
  );
}
