import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useState } from 'react';
import { deleteUser, patchUser } from '~/api';
import Tag from '~/app/components/Tag/Tag';
import { MuiTelInput } from 'mui-tel-input';
import { CountryCode } from 'libphonenumber-js';

export const Profil = ({ data, currentUser, callback, callBackSuppressUser, callBackEditMode }) => {
  const [newUserDataForm, setNewUserDataForm] = useState(data);
  const [countryCode, setCountryCode] = useState<CountryCode>('FR');

  const handleModifyUser = () => {
    const backUpUserDatas = JSON.parse(JSON.stringify(newUserDataForm));
    delete newUserDataForm.email;
    delete newUserDataForm.password;
    patchUser(data.uuid, newUserDataForm).then((repsUser) => {
      setEditMode(false);
      callback(newUserDataForm);
      setNewUserDataForm(backUpUserDatas);
    });
  };

  const [editMode, setEditMode] = useState(false);

  const handleEditMode = () => {
    callBackEditMode(data.uuid);
    setEditMode(true);
  };

  const handleCancelEditMode = () => {
    setEditMode(false);
  };

  const handleDeleteUser = () => {
    deleteUser(data.uuid).then((resp) => {
      callBackSuppressUser(data);
      setEditMode(false);
    });
  };

  return (
    <div>
      {currentUser !== data.uuid && (
        <div>
          <div className="Profil__userBox">
            <Tag
              variant={
                data.kind === 'client-admin' || data.kind === 'admin' || data.kind === 'super-admin'
                  ? 'danger'
                  : 'success'
              }
              icon={<PersonOutlineIcon />}
            />{' '}
            {`${data.first_name} ${data.last_name} ${
              data.kind === 'client-admin' || data.kind === 'admin' || data.kind === 'super-admin' ? '(admin)' : ''
            } `}
            &nbsp;&nbsp;&nbsp;
            {!editMode && (
              <Grid item xs={1} md={2}>
                <Button variant="contained" color={'primary'} onClick={handleEditMode}>
                  Edition
                </Button>
              </Grid>
            )}
          </div>

          {editMode && (
            <div>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Prénom"
                    fullWidth
                    value={newUserDataForm.first_name}
                    onChange={(e) => setNewUserDataForm({ ...newUserDataForm, first_name: e.target.value })}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Nom"
                    fullWidth
                    value={newUserDataForm.last_name}
                    onChange={(e) => setNewUserDataForm({ ...newUserDataForm, last_name: e.target.value })}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <MuiTelInput
                    label="Numéro de téléphone"
                    fullWidth
                    value={newUserDataForm.phone1}
                    onChange={(value, info) => setNewUserDataForm({ ...newUserDataForm, phone1: info.numberValue })}
                    defaultCountry="FR"
                    forceCallingCode
                    continents={['EU']}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Adresse email"
                    disabled
                    fullWidth
                    value={newUserDataForm.email}
                    onChange={(e) => setNewUserDataForm({ ...newUserDataForm, email: e.target.value })}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <InputLabel id="userType">Type d&apos;utilisateur</InputLabel>
                    <Select
                      labelId="userType"
                      label="Type d'utilisateur"
                      value={newUserDataForm.kind}
                      onChange={(e) => setNewUserDataForm({ ...newUserDataForm, kind: e.target.value })}
                    >
                      <MenuItem value={'client-admin'}>Administrateur</MenuItem>
                      <MenuItem value={'client'}>Utilisateur</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <br></br>
              {editMode && (
                <Grid item xs={12} md={6}>
                  <Button variant="outlined" color={'primary'} onClick={handleCancelEditMode}>
                    Annuler
                  </Button>
                  &nbsp;&nbsp;&nbsp;
                  <Button variant="contained" color={'primary'} onClick={handleModifyUser}>
                    Valider
                  </Button>
                  &nbsp;&nbsp;&nbsp;
                  <Button variant="contained" color={'primary'} onClick={handleDeleteUser}>
                    Supprimer
                  </Button>
                </Grid>
              )}
              <br></br>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Profil;
