import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { appTheme } from '~/utils/theme';
import {
  Box,
  Grid,
  Typography,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  InputBase,
  useMediaQuery,
  useTheme
} from '@mui/material';

const ListFilter = ({
  handleChangeTypeFilter = null,
  buildRefEventTypesValues = null,
  handleChangeSearchFilter,
  search,
  walletChecked = null,
  handleChangeWalletChecked = null,
  closedChecked = null,
  handleChangeClosedChecked = null,
  archivedChecked = null,
  handleChangeArchivedChecked = null,
  productEventsLayout = false
}) => {
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.up('xs'));
  const matchesSm = useMediaQuery(theme.breakpoints.up('sm'));
  const matchesMd = useMediaQuery(theme.breakpoints.up('md'));
  const matchesLg = useMediaQuery(theme.breakpoints.up('lg'));
  const matchesXl = useMediaQuery(theme.breakpoints.up('xl'));

  return (
    <Box
      sx={
        handleChangeTypeFilter && buildRefEventTypesValues
          ? {
              pt: 2,
              pb: 4,
              width: '100%'
            }
          : {}
      }
    >
      <Grid container alignItems="flex-end">
        <Grid item xs={12} sm>
          <Box sx={{ flex: 1, mb: 2, width: 1 }}>
            <Box
              sx={{
                mt: handleChangeTypeFilter && buildRefEventTypesValues ? 4 : 0,
                width: 1,
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <Grid container>
                <Grid
                  item
                  xs={8}
                  sm={8}
                  md={1.75}
                  sx={{ display: 'flex', alignItems: 'center', mb: !matchesMd ? '1vh' : 0 }}
                >
                  {handleChangeTypeFilter && buildRefEventTypesValues && (
                    <>
                      <Box sx={{ mr: 2 }}>
                        <Typography variant="body1" sx={{ color: 'primary.main' }}>
                          Filtrer:
                        </Typography>
                      </Box>

                      <Box sx={{ flexGrow: 1, mr: 2 }}>
                        <FormControl fullWidth>
                          <InputLabel id="userType">Type de ticket</InputLabel>
                          <Select
                            labelId="userType"
                            label="Type de ticket"
                            defaultValue=""
                            onChange={handleChangeTypeFilter}
                          >
                            {buildRefEventTypesValues()}
                          </Select>
                        </FormControl>
                      </Box>
                    </>
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <Box sx={{ flexGrow: 3, mr: 2 }}>
                    <div
                      style={{
                        alignItems: 'center',
                        backgroundColor: appTheme.palette.primary[50],
                        border: `1px solid`,
                        borderColor: appTheme.palette.primary[100],
                        borderRadius: '20px',
                        display: 'flex',
                        height: '36px',
                        fontSize: '16px',
                        width: '100%'
                      }}
                    >
                      <InputBase
                        autoComplete="off"
                        placeholder="Rechercher"
                        onChange={handleChangeSearchFilter}
                        value={search}
                        inputProps={{
                          sx: {
                            pl: 1.8,
                            '&::placeholder': {}
                          }
                        }}
                        sx={{ flex: 1 }}
                      />
                      <SearchOutlinedIcon
                        sx={{
                          color: 'primary.main',
                          ml: 2,
                          mr: 1
                        }}
                        fontSize="medium"
                      />
                    </div>
                  </Box>
                </Grid>
                <Grid item xs={9} sm={12} md={6.25}>
                  <Box>
                    <Typography variant="body1" sx={{ color: 'primary.main' }}>
                      {!productEventsLayout && handleChangeWalletChecked && (
                        <>
                          <Checkbox
                            checked={walletChecked}
                            onChange={handleChangeWalletChecked}
                            id="myEvents"
                            sx={{
                              '& .MuiSvgIcon-root': { fontSize: !matchesMd ? 18 : 22 },
                              ml: !matchesMd ? '10px' : '20px'
                            }}
                          />
                          <label htmlFor="myEvents">Mes tickets</label>
                        </>
                      )}
                      {handleChangeClosedChecked && (
                        <>
                          <Checkbox
                            checked={closedChecked}
                            onChange={handleChangeClosedChecked}
                            id="closedEvents"
                            sx={{
                              '& .MuiSvgIcon-root': { fontSize: !matchesMd ? 18 : 22 },
                              ml: !matchesMd ? '10px' : '20px'
                            }}
                          />
                          <label htmlFor="closedEvents">Tickets terminés</label>
                        </>
                      )}
                      {handleChangeArchivedChecked && (
                        <>
                          <Checkbox
                            checked={archivedChecked}
                            onChange={handleChangeArchivedChecked}
                            id="archivedEvents"
                            sx={{
                              '& .MuiSvgIcon-root': { fontSize: !matchesMd ? 18 : 22 },
                              ml: !matchesMd ? '10px' : '20px'
                            }}
                          />
                          <label htmlFor="archivedEvents">Tickets archivés</label>
                        </>
                      )}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ListFilter;
