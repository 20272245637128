import { Box, Button, Divider, Stack, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { forgotPassword } from '~/api';
import { checkEmail } from '~/utils/check';
import '../Auth.scss';

export const ForgotPassword = ({ setSpinnerOpen, spinnerOpen }) => {
  const [email, setEmail] = useState<string>('');
  const [submit, setSubmit] = useState<boolean>(false);
  const [submited, setSubmited] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleSubmit = () => {
    setSubmit(true);
    if (checkEmail(email)) {
      forgotPassword({ email: email }).then((resp) => {
        if (resp.email_send === true || resp.success === true) {
          setSubmited(true);
        }
      });
    }
  };

  useEffect(() => {
    setSpinnerOpen(false);
    const keyDownHandler = (event) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        handleSubmit();
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  });

  return (
    <div className="Auth">
      {!submited && (
        <div>
          <div className="Auth__header">
            <Box sx={{ mt: 4, mb: 4 }}>
              <img src="/logo3D.svg" height="50" alt="logo" />
            </Box>
            <Typography variant="h5" color={'primary'} sx={{ mb: 3 }}>
              Réinitialisation mot de passe
            </Typography>
          </div>
          <TextField
            label="Adresse email"
            fullWidth
            required
            error={submit && !checkEmail(email)}
            helperText={submit ? (checkEmail(email) ? '' : 'Adresse email non valide') : ''}
            type={'email'}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Stack className="Auth__submit">
            <Button
              variant="contained"
              size="medium"
              color={'primary'}
              onClick={handleSubmit}
              sx={{ mb: 2, px: '32px', py: '12px' }}
            >
              Valider
            </Button>
          </Stack>
          <Stack className="Auth__hr">
            <Divider sx={{ my: 2 }}>ou</Divider>
            <Button
              variant="outlined"
              color={'primary'}
              sx={{ mb: 2, px: '32px', py: '12px' }}
              onClick={() => {
                setSpinnerOpen(true);
                navigate('/login');
              }}
            >
              Se connecter
            </Button>
          </Stack>
        </div>
      )}
      {submited && (
        <div>
          <div className="Auth__header">
            <Box sx={{ mt: 4, mb: 4 }}>
              <img src="/logo3D.svg" width="124" height="36" alt="logo Cibel" />
            </Box>
            <Typography variant="h5" color={'primary'} sx={{ mb: 3 }}>
              Mot de passe envoyé
            </Typography>
          </div>
          <Stack className="Auth__submit">
            <Typography variant="body1" sx={{ mb: 3 }}>
              Si l'adresse saisie est bien celle associée à votre compte, vous allez recevoir un e-mail de
              renouvellement de votre mot de passe.
            </Typography>
            <Button
              variant="contained"
              color={'primary'}
              sx={{ mb: 2, px: '32px', py: '12px' }}
              onClick={() => {
                setSpinnerOpen(true);
                navigate('/login');
              }}
            >
              Se connecter
            </Button>
          </Stack>
        </div>
      )}
    </div>
  );
};

export default ForgotPassword;
