import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { Button } from '@mui/material';
import { ReactNode, useContext } from 'react';
import { Link } from 'react-router-dom';
import ListFilter from '~/app/components/ListFilter/ListFilter';
import '~/app/pages/Product/Product.scss';
import { MediaQueryContext } from '~/utils/context/MediaQueryProvider';

interface ProductCardProps {
  redirect?: string;
  redirect2?: string;
  children?: ReactNode;
  title: string;
  action?: string;
  id?: string;
  onAction?: () => void;
  handleChangeSearchFilter?: (search: string) => void;
  search?: string;
}

const ProductCard = ({
  redirect,
  redirect2,
  children,
  title,
  action,
  id,
  onAction,
  handleChangeSearchFilter = null,
  search = null
}: ProductCardProps) => {
  const mediaQueries = useContext(MediaQueryContext); //
  return (
    <div className="Product__card">
      <div className="Product__lineBlock" style={!children ? { paddingBottom: '0px', marginBottom: '0px' } : {}}>
        <div className="Product__lineBlock__title" style={{ cursor: redirect ? 'pointer' : 'default' }}>
          {redirect ? <Link to={redirect}> {title} </Link> : <div>{title}</div>}
        </div>
        {handleChangeSearchFilter && (
          <div className="Product__lineBlock__title" style={{ cursor: 'pointer' }}>
            <ListFilter handleChangeSearchFilter={handleChangeSearchFilter} search={search}></ListFilter>
          </div>
        )}
        {action && (
          <div className="Product__linkBlock__title" onClick={() => onAction && onAction()}>
            {redirect2 ? (
              <Button
                color="secondary"
                variant="outlined"
                sx={{ color: 'primary', borderRadius: '20px', borderColor: 'primary.main', minWidth: 0 }}
              >
                <AddOutlinedIcon fontSize="small" /> {action}
              </Button>
            ) : (
              <Button
                color="secondary"
                variant="outlined"
                size="small"
                sx={
                  !mediaQueries.matchesSm
                    ? {
                        color: 'primary',
                        borderRadius: '20px',
                        borderColor: 'secondary.main',
                        minWidth: 0,
                        padding: 0,
                        position: 'relative',
                        top: '-4px'
                      }
                    : {
                        color: 'primary',
                        borderRadius: '20px',
                        borderColor: 'secondary.main',
                        position: 'relative',
                        top: '-4px'
                      }
                }
              >
                <AddOutlinedIcon fontSize="small" />
                {!mediaQueries.matchesSm ? '' : action}
              </Button>
            )}
          </div>
        )}
      </div>
      {children && <div className="Product__card__content">{children}</div>}
    </div>
  );
};

export default ProductCard;
