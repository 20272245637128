import { Box, Grid, Pagination, Typography, useMediaQuery, useTheme } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  frFR,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
  useGridApiRef
} from '@mui/x-data-grid';
import React from 'react';
import { CreateOutlined } from '@mui/icons-material';
import { RenderCellExpand } from '~/utils/renderCellExpand';
import { AppContext } from '~/utils/context/AppContext';
import './DocumentsList.scss';
import { api } from '~/api';
import { renderEquipmentIcon } from '~/utils/references';
import { appTheme } from '~/utils/theme';

var _ = require('lodash');

interface IDocumentsListProps {
  productDatas: any;
  setWizzardDocumentOpen: any;
  setSelectedDocument: any;
  updateSelectedDocumentData: any;
  search?: string;
  handleChangeSearchFilter?: any;
  rows?: any;
}

export const DocumentsList = ({
  productDatas,
  setWizzardDocumentOpen,
  setSelectedDocument,
  updateSelectedDocumentData,
  search = null,
  handleChangeSearchFilter = null,
  rows = null
}: IDocumentsListProps) => {
  const apiRef = useGridApiRef();
  const { referencial } = React.useContext(AppContext);
  const { equipmentReferencial } = React.useContext(AppContext);

  const displayIcon = (equipmentData, color = appTheme.palette.primary.main) => {
    if (equipmentData) {
      if (equipmentData?.category_kind === null) {
        if (equipmentData.tab === 'inventoryEquipments') {
          return renderEquipmentIcon(18, false, color);
        } else {
          return renderEquipmentIcon(
            _.find(equipmentReferencial.equipmentCategoryKinds, {
              id:
                _.find(equipmentReferencial.equipments, { id: equipmentData.kind }) &&
                _.find(equipmentReferencial.equipments, { id: equipmentData.kind }).kinds.length > 0
                  ? _.find(equipmentReferencial.equipments, { id: equipmentData.kind }).kinds[0]
                  : null
            })
              ? _.find(equipmentReferencial.equipmentCategoryKinds, {
                  id:
                    _.find(equipmentReferencial.equipments, { id: equipmentData.kind }) &&
                    _.find(equipmentReferencial.equipments, { id: equipmentData.kind }).kinds.length > 0
                      ? _.find(equipmentReferencial.equipments, { id: equipmentData.kind }).kinds[0]
                      : null
                }).id
              : 0,
            false,
            color
          );
        }
      } else {
        return renderEquipmentIcon(equipmentData.category_kind, false, color);
      }
    }
    return null;
  };
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.up('xs'));
  const matchesSm = useMediaQuery(theme.breakpoints.up('sm'));
  const matchesMd = useMediaQuery(theme.breakpoints.up('md'));
  const matchesLg = useMediaQuery(theme.breakpoints.up('lg'));
  const matchesXl = useMediaQuery(theme.breakpoints.up('xl'));

  const CustomPagination = () => {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    if (pageCount === 1) return <div></div>;
    return (
      <Pagination
        color="primary"
        count={pageCount}
        page={page + 1}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />
    );
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Nom',
      width: !matchesSm ? 120 : 400,
      flex: !matchesSm ? 0 : 0.5,
      headerClassName: 'cibel-app-theme--header',
      renderCell: (params) => {
        return (
          <Box
            sx={{
              mt: 4.7,
              width: 1,
              display: 'flex'
            }}
          >
            <div
              style={{
                position: 'relative',
                top: '-3px'
              }}
            >
              {displayIcon(params.row.equipmentData)}
            </div>
            <Typography style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
              {params.row.name}
            </Typography>
          </Box>
        );
      }
    },

    {
      field: 'date_creation',
      headerName: "Date de l'ajout",
      width: !matchesSm ? 80 : 120,
      headerClassName: 'cibel-app-theme--header',
      renderCell: (params) => {
        const date = new Date(params.row.date_creation);
        const today = new Date();
        const theDate =
          date.toLocaleDateString('fr') === today.toLocaleDateString('fr')
            ? date.toLocaleTimeString(['fr'], { timeStyle: 'short' })
            : date.toLocaleDateString('fr');
        return (
          <Box
            sx={{
              mt: 4,
              width: 1,
              display: 'flex'
            }}
          >
            <Typography style={{ paddingTop: '5px' }}>{theDate}</Typography>
          </Box>
        );
      }
    },
    {
      field: 'date',
      headerName: 'Date',
      width: !matchesSm ? 80 : 120,
      headerClassName: 'cibel-app-theme--header',
      renderCell: (params) => {
        const date = params.row.allData?.data?.date ? new Date(params.row?.allData?.data?.date) : '';
        const today = new Date();
        const theDate = date
          ? date.toLocaleDateString('fr') === today.toLocaleDateString('fr')
            ? date.toLocaleTimeString(['fr'], { timeStyle: 'short' })
            : date.toLocaleDateString('fr')
          : null;
        return (
          <Box
            sx={{
              mt: 4,
              width: 1,
              display: 'flex'
            }}
          >
            <Typography style={{ paddingTop: '5px' }}>{theDate}</Typography>
          </Box>
        );
      }
    },
    {
      field: 'kind',
      headerName: 'Type',
      width: !matchesSm ? 90 : 120,
      headerClassName: 'cibel-app-theme--header',
      renderCell: (params) => {
        const folderName = _.find(referencial['document.category'], { name: params.row.kind });
        const kindName = folderName !== undefined ? folderName.name : '';
        return (
          <Box
            sx={{
              mt: 4,
              width: 1,
              display: 'flex'
            }}
          >
            <Typography style={{ paddingTop: '5px' }}>{kindName}</Typography>
          </Box>
        );
      }
    },
    {
      field: 'action',
      headerName: '',
      width: !matchesSm ? 12 : 20,
      flex: !matchesSm ? 1 : 0,
      sortable: false,
      headerClassName: 'cibel-app-theme--header',
      renderCell: (params) => {
        return (
          <Box sx={{ pr: 2, py: 3 }}>
            <Box
              sx={{
                mt: 4,
                width: 1,
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <CreateOutlined
                onClick={(e) => {
                  console.log(e);
                  e.preventDefault();
                  setWizzardDocumentOpen(true);
                  setSelectedDocument(params.row.allData);
                }}
                sx={{ fontSize: '16px', color: 'primary.main', cursor: 'pointer', mr: 1 }}
                style={{ position: 'relative', top: '-15px' }}
              />
            </Box>
          </Box>
        );
      }
    }
  ];

  const testIfFooter = rows.length < 6 ? true : false;

  return (
    <>
      {referencial && rows.length > 0 && (
        <Grid sx={{ height: 'calc(100%)' }}>
          <Grid item xs={12} md={12} className="hidescrollbar">
            <DataGrid
              apiRef={apiRef}
              className="DocumentsList"
              sx={{ maxHeight: `${window.innerHeight - 350}px` }}
              localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
              hideFooterSelectedRowCount
              hideFooter={testIfFooter}
              getRowHeight={() => 'auto'}
              rows={rows}
              columns={columns}
              pageSizeOptions={[5]}
              columnVisibilityModel={{
                uuid: false,
                uuid_product: false
              }}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'date_creation', sort: 'desc' }]
                },
                pagination: { paginationModel: { page: 0, pageSize: 5 } }
              }}
              sortingOrder={['desc', 'asc']}
              checkboxSelection={false}
              disableColumnMenu={true}
              components={{
                Pagination: CustomPagination
              }}
              onCellClick={(e) => {}}
              onRowClick={(e) => {
                console.log(e);
                console.log(e.row);

                api.files.get(e.row.uuid).then((response) => {
                  setWizzardDocumentOpen(true);
                  setSelectedDocument(response);
                  console.log(response);
                });
              }}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};
