import { createTheme } from '@mui/material/styles';
import { frFR } from '@mui/x-date-pickers/locales';

const background = {
  default: '#FFFFFF',
  paper: '#FFFFFF'
};

const text = {
  secondary: '#929292'
};

const primary = {
  contrast: '#FFFFFF',
  dark: '#F17633',
  light: '#EDF2F5',
  main: '#062746',
  50: '#FAFCFF',
  100: '#EEF3F6',
  200: '#E9D7FE',
  300: '#D6BBFB',
  400: '#B692F6',
  500: '#9E77ED',
  600: '#7F56D9',
  700: '#6941C6'
};

const secondary = {
  contrast: '#FFFFFF',
  dark: '#1D2939',
  light: '#EEC5AE',
  main: '#F17633'
};

const success = {
  contrast: '#FFFFFF',
  dark: '#1B774D',
  light: '#EBFFD0',
  main: '#508D00'
};

const error = {
  contrast: '#FFFFFF',
  dark: '#A53531',
  light: '#FFE8E4',
  main: '#EF5A41'
};

const warning = {
  contrast: '#FFFFFF',
  dark: '#975B1E',
  light: '#FFF5D3',
  main: '#D0A60F'
};

const info = {
  contrast: '#FFFFFF',
  dark: '#0B4E8D',
  light: '#F1F8FF',
  main: '#1D5589'
};

export const appTheme = createTheme({
  palette: {
    background,
    text,
    primary,
    secondary,
    success,
    error,
    warning,
    info
  },
  typography: {
    fontFamily: `'Roboto', sans-serif`,
    h1: {
      fontSize: 40,
      fontWeight: 700,
      lineHeight: 1.5
    },
    h2: {
      fontSize: 36,
      fontWeight: 600,
      lineHeight: 1.5
    },
    h3: {
      fontSize: 32,
      fontWeight: 600,
      lineHeight: 1.5
    },
    h4: {
      fontSize: 22,
      fontWeight: 600,
      lineHeight: 1.5
    },
    h5: {
      fontSize: 18,
      fontWeight: 600,
      lineHeight: 1.5
    },
    h6: {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: 1.5
    },
    body1: {
      fontSize: 14,
      fontWeight: 400
    },
    body2: {},
    subtitle1: {
      fontSize: 12,
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: 1.75
    },
    subtitle2: {
      fontSize: 11,
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: 1.57
    },
    caption: {
      fontWeight: 500
    },
    overline: {
      fontSize: 12,
      fontWeight: 600,
      letterSpacing: 1,
      lineHeight: 1.66
    },
    button: {
      fontWeight: 500,
      boxShadow: 'none',
      textTransform: 'none'
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        outlined: {
          border: `1px solid ${primary.main}`
        },
        // Large button
        sizeLarge: {
          fontSize: '14px',
          padding: '16px 24px',
          borderRadius: '10px'
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& label': {
            color: 'primary.light'
          },
          '& label.Mui-focused': {
            color: 'primary.main'
          },
          '& .MuiInput-underline:after': {
            borderBottomColor: 'primary.main'
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'primary.light'
            },
            '&:hover fieldset': {
              borderColor: 'primary.main',
              borderWidth: '0.15rem'
            },
            '&.Mui-focused fieldset': {
              borderColor: 'primary.main'
            }
          }
        }
      }
    },
    //@ts-ignore - this isn't in the TS because DataGird is not exported from `@mui/material`
    MuiDataGrid: {
      styleOverrides: {
        row: {
          '&.Mui-selected': {
            backgroundColor: '#F1F8FF',
            borderLeft: '2px solid',
            borderColor: '#1D5589',
            '&:hover': {
              backgroundColor: '#F1F8FF'
            }
          }
        },
        root: {
          '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus, & .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within':
            {
              outline: 'none'
            }
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          textTransform: 'initial',
          fontSize: '1rem'
        }
      }
    }
  },
  frFR
});
