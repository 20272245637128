import { useContext, useEffect, useState } from 'react';
import BasicModal from '../BasicModal/BasicModal';
import {
  Grid2,
  Button,
  Typography,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  TextField,
  Autocomplete,
  Chip,
  Box,
  InputAdornment
} from '@mui/material';
import { api } from '~/api';
import { ProductTitle } from '../ProductTitle/ProductTitle';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { AppContext } from '~/utils/context/AppContext';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { MediaQueryContext } from '~/utils/context/MediaQueryProvider';
import { WizzardContact } from '~/app/components/WizzardContact/WizzardContact';
import { ILease } from '~/interfaces';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import EditOffOutlinedIcon from '@mui/icons-material/EditOffOutlined';
import ToggleOnOutlinedIcon from '@mui/icons-material/ToggleOnOutlined';
import SyncProblemOutlinedIcon from '@mui/icons-material/SyncProblemOutlined';
import CloseIcon from '@mui/icons-material/Close';

export const LeaseAddUpdate = ({ productDatas, setLeaseAddOpen, setPropertyData, leaseData }) => {
  const { referencial } = useContext(AppContext);
  const mediaQueries = useContext(MediaQueryContext);

  const [modalOpen, setModalOpen] = useState<boolean>(true);

  const [allContactsOrgaForm, setAllContactsOrgaForm] = useState([]);
  const [addContact, setAddContact] = useState<boolean>(false);
  const [allContacts, setAllContacts] = useState([]);
  const [createLease, setCreateLease] = useState(leaseData ? false : true);
  const [autocompleteComponents, setAutocompleteComponents] = useState([{ id: 0, value: null }]);

  const [leaseTmpData, setLeaseTmpData] = useState<ILease>(null);

  const [error, setError] = useState(false);

  const [status, setStatus] = useState(
    leaseData?.status !== 0 ? referencial['lease.status'].find((status) => status.id === leaseData.status) : null
  );

  const modalClosed = () => {
    setLeaseAddOpen(false);
  };

  const handleRemoveAutocomplete = (index) => {
    if (autocompleteComponents.length <= 1) {
      return;
    }

    const updatedComponents = [...autocompleteComponents];
    updatedComponents.splice(index, 1);
    setAutocompleteComponents(updatedComponents);

    if (!leaseTmpData || !leaseTmpData.uuid_contacts || !leaseTmpData.contacts) {
      return;
    }

    const updatedResidentsUuid = [...leaseTmpData.uuid_contacts];
    updatedResidentsUuid.splice(index, 1);

    const updatedResidents = [...leaseTmpData.contacts];
    updatedResidents.splice(index, 1);

    setLeaseTmpData({ ...leaseTmpData, uuid_contacts: updatedResidentsUuid, contacts: updatedResidents });
  };

  const handleAddAutocomplete = () => {
    const lastId = autocompleteComponents[autocompleteComponents.length - 1].id;
    setAutocompleteComponents([...autocompleteComponents, { id: lastId + 1, value: null }]);
  };

  const handleError = () => {
    setError(!error);
  };

  useEffect(() => {
    if (leaseData && leaseData?.contacts?.length > 1) {
      const tempArray = leaseData?.contacts.map((item, index) => {
        return { id: index, value: item };
      });
      setAutocompleteComponents(tempArray);
    }
    if (leaseData) {
      setLeaseTmpData(leaseData);
    }
  }, [leaseData]);

  useEffect(() => {
    if (!productDatas) return;
    api.product.contact.get(productDatas.uuid).then((res) => {
      if (res) {
        setAllContacts(res);
      }
    });
  }, [productDatas]);

  const getAllContacts = () => {
    api?.contacts?.search().then((res) => {
      setAllContactsOrgaForm(res);
    });
  };

  useEffect(() => {
    getAllContacts();
  }, [productDatas]);

  const createNewLease = () => {
    const lease = JSON.parse(JSON.stringify(leaseTmpData));
    lease.uuid_product = productDatas.uuid;

    api.lease
      .create(lease)
      .then((response) => {
        setPropertyData({ ...productDatas, leases: [...productDatas.leases, response] });
        setModalOpen(false);
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const updateLease = () => {
    api.lease
      .update(leaseTmpData.uuid, leaseTmpData)
      .then((response) => {
        setPropertyData({
          ...productDatas,
          leases: productDatas.leases.map((lease) => (lease.uuid === response.uuid ? response : lease))
        });
        setModalOpen(false);
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const handleCloseModalContact = () => {
    setAddContact(!addContact);
  };

  const LeaseStatusChip = ({ status }) => {
    let icon = null;
    let label = null;
    let color = null;
    let backgroundColor = null;

    switch (status) {
      case 0:
        label = 'Inconnu';
        icon = <HelpOutlineOutlinedIcon style={{ color: '#f5a623' }} />;
        color = '#f5a623';
        backgroundColor = '#fff3e0';
        break;
      case 1:
        label = 'Brouillon';
        icon = <EditOffOutlinedIcon style={{ color: '#f5a623' }} />;
        color = '#f5a623';
        backgroundColor = '#fff3e0';
        break;
      case 2:
        label = 'Actif';
        icon = <ToggleOnOutlinedIcon style={{ color: '#4caf50' }} />;
        color = '#4caf50';
        backgroundColor = '#e8f5e9';
        break;
      case 3:
        label = 'Non renouvellé';
        icon = <SyncProblemOutlinedIcon style={{ color: '#4caf50' }} />;
        color = '#4caf50';
        backgroundColor = '#e8f5e9';
        break;
      case 4:
        label = 'Clôturé';
        icon = <CloseIcon style={{ color: '#d32f2f' }} />; // Icône rouge
        color = '#d32f2f'; // Couleur rouge
        backgroundColor = '#ffcdd2'; // Fond rouge clair
        break;
      default:
        label = 'Inconnu';
        icon = <HelpOutlineOutlinedIcon style={{ color: '#f5a623' }} />;
        color = '#f5a623';
        backgroundColor = '#fff3e0';
        break;
    }

    return <Chip label={label} size="small" icon={icon} style={{ backgroundColor, color }} />;
  };

  const handleStatus = (status) => {
    setStatus(status);
    if (status?.id) setLeaseTmpData({ ...leaseTmpData, status: status.id });
  };

  return (
    <BasicModal
      modalOpen={modalOpen}
      onModalOpen={(open: boolean) => setModalOpen(open)}
      onModalClose={modalClosed}
      confirmClose={false}
    >
      <Grid2 container spacing={2}>
        <Grid2
          size={12}
          style={{
            backgroundColor: '#fff7f3',
            width: '100vw',
            paddingLeft: '15px',
            position: 'sticky',
            top: 0,
            zIndex: 1
          }}
        >
          <ProductTitle data={productDatas} />
        </Grid2>

        <Grid2
          container
          spacing={2}
          justifyContent={'center'}
          alignItems={'center'}
          display={'flex'}
          sx={{ marginX: mediaQueries?.matchesMd ? '12vw' : '6vw' }}
        >
          <Grid2 size={12} sx={{ textAlign: 'center' }}>
            {createLease ? (
              <Typography variant="h4">Renseignez les informations liées au bail </Typography>
            ) : (
              <Typography variant="h4">Modifier les informations liées au bail </Typography>
            )}
          </Grid2>

          {/* Type de bail */}
          <Grid2 size={{ xs: 12, md: 6 }}>
            <TextField
              label="Durée du bail (mois) *"
              variant="outlined"
              size={mediaQueries?.matchesMd ? 'small' : 'medium'}
              fullWidth
              onChange={(e) => {
                setLeaseTmpData({ ...leaseTmpData, duration: Number(e.target.value) });
              }}
              type="number"
              value={leaseTmpData?.duration}
              error={error && !leaseTmpData?.duration}
              slotProps={{
                input: {
                  endAdornment: <InputAdornment position="end">mois</InputAdornment>
                }
              }}
            >
              {leaseTmpData?.duration}
            </TextField>
          </Grid2>

          {/* Régime locatif */}
          <Grid2 size={{ xs: 12, md: 6 }}>
            <Grid2 size={{ xs: 12 }}>
              <FormControl fullWidth>
                <InputLabel id="type-bail-label" size="small" error={error && !leaseTmpData?.kind}>
                  Régime locatif *
                </InputLabel>
                <Select
                  labelId="type-bail-label"
                  value={leaseTmpData?.kind}
                  onChange={(e) => {
                    setLeaseTmpData({ ...leaseTmpData, kind: Number(e.target.value) });
                  }}
                  required
                  label="Régime locatif"
                  size={mediaQueries?.matchesMd ? 'small' : 'medium'}
                  error={error && !leaseTmpData?.kind}
                >
                  {referencial['lease.kind']?.map((lease, index) => (
                    <MenuItem key={index} value={lease.id}>
                      {lease.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid2>
          </Grid2>

          {/* Dates */}
          <Grid2 size={{ xs: 12, md: createLease ? 6 : 4 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
              <DatePicker
                label="Début de bail *"
                value={leaseTmpData?.start_at ? dayjs(leaseTmpData?.start_at) : null}
                onChange={(date) => {
                  setLeaseTmpData({ ...leaseTmpData, start_at: date.format() });
                }}
                format="DD/MM/YYYY"
                sx={{ width: '100%' }}
                slotProps={{
                  textField: {
                    error: error && !leaseTmpData?.start_at
                  }
                }}
              />
            </LocalizationProvider>
          </Grid2>

          <Grid2 size={{ xs: 12, md: createLease ? 6 : 4 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
              <DatePicker
                value={leaseTmpData?.end_at ? dayjs(leaseTmpData?.end_at) : null}
                onChange={(date) => {
                  setLeaseTmpData({ ...leaseTmpData, end_at: date.format() });
                }}
                format="DD/MM/YYYY"
                label="Fin de bail *"
                sx={{ width: '100%' }}
                slotProps={{
                  textField: {
                    error: error && !leaseTmpData?.end_at
                  }
                }}
              />
            </LocalizationProvider>
          </Grid2>

          {!createLease && (
            <Grid2 size={{ xs: 12, md: createLease ? 6 : 4 }}>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                <DatePicker
                  value={dayjs(leaseTmpData?.exit_at)}
                  onChange={(date) => {
                    setLeaseTmpData({ ...leaseTmpData, exit_at: date.format() });
                  }}
                  format="DD/MM/YYYY"
                  label="Date de sortie *"
                  sx={{ width: '100%' }}
                  slotProps={{
                    textField: {
                      error: error && !leaseTmpData?.exit_at
                    }
                  }}
                />
              </LocalizationProvider>
            </Grid2>
          )}

          {/* Statut du bail */}
          <Grid2 size={{ xs: 12, md: 6 }}>
            <Autocomplete
              value={status?.name}
              onChange={(event, newValue) => handleStatus(newValue)}
              options={referencial['lease.status'].filter((status) => status.id !== 1 && status.id !== 0)}
              size={mediaQueries?.matchesMd ? 'small' : 'medium'}
              getOptionLabel={(option) => {
                if (typeof option === 'object' && option !== null) {
                  return option.name;
                } else {
                  return option;
                }
              }}
              renderInput={(params) => <TextField {...params} label="Statut" variant="outlined" value={status} />}
              renderOption={(props, option) => (
                <Box component="li" {...props}>
                  <LeaseStatusChip status={option.id} />
                </Box>
              )}
            />
          </Grid2>

          <Grid2 size={{ xs: 12, md: 6 }} />

          <Grid2 size={12}>
            {autocompleteComponents.map((component, index) => (
              <Grid2 container key={component.id} spacing={2} alignItems="center" sx={{ marginBottom: '16px' }}>
                <Grid2 size={{ xs: 10, md: 6 }}>
                  <Autocomplete
                    options={allContactsOrgaForm}
                    getOptionLabel={(option) => option?.name || ''}
                    size={mediaQueries?.matchesMd ? 'small' : 'medium'}
                    onChange={(e, newValue) => {
                      const updatedResidentsUuid = leaseTmpData?.uuid_contacts ? [...leaseTmpData?.uuid_contacts] : [];
                      const updatedResidents = leaseTmpData?.contacts ? [...leaseTmpData?.contacts] : [];

                      if (newValue) {
                        updatedResidentsUuid[index] = newValue.uuid;
                        updatedResidents[index] = newValue;
                      } else {
                        updatedResidentsUuid.splice(index, 1);
                        updatedResidents.splice(index, 1);
                      }

                      setLeaseTmpData({
                        ...leaseTmpData,
                        uuid_contacts: updatedResidentsUuid,
                        contacts: updatedResidents
                      });
                    }}
                    isOptionEqualToValue={(option, value) => option?.name === value?.name}
                    value={
                      (leaseTmpData?.uuid_contacts &&
                        allContactsOrgaForm.find((contact) => contact.uuid === leaseTmpData?.uuid_contacts[index])) ||
                      null
                    }
                    renderInput={(params) => (
                      <TextField {...params} label="Rechercher un locataire *" variant="outlined" />
                    )}
                    noOptionsText="Aucun locataire trouvé"
                  />
                </Grid2>
                <Grid2 size={{ xs: 2, md: 1 }}>
                  {autocompleteComponents.length > 1 && (
                    <Button onClick={() => handleRemoveAutocomplete(index)}>
                      <PersonRemoveIcon color="secondary" />
                    </Button>
                  )}
                </Grid2>
              </Grid2>
            ))}
          </Grid2>

          <Grid2 size={12}>
            {autocompleteComponents.length > 0 && (
              <Button onClick={handleAddAutocomplete}>
                <PersonAddIcon color="primary" />
              </Button>
            )}
          </Grid2>

          <Grid2 size={12}>
            <Button
              variant="contained"
              sx={{ borderRadius: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              onClick={() => setAddContact(!addContact)}
            >
              <AddOutlinedIcon /> Créer un nouveau contact
            </Button>
          </Grid2>

          {addContact && (
            <WizzardContact
              open={addContact}
              isProductPage={true}
              onClose={handleCloseModalContact}
              isNewContact={true}
              productId={productDatas.uuid}
              contacts={allContacts}
              setContacts={setAllContacts}
            />
          )}

          {error && (
            <Typography variant="caption" color="error">
              Veuillez remplir tous les champs
            </Typography>
          )}

          {/* Sticky Buttons */}
          <Grid2
            size={12}
            sx={{
              position: 'sticky',
              bottom: 0,
              backgroundColor: '#fff',
              padding: 2,
              display: 'flex',
              justifyContent: 'center',
              zIndex: 10
            }}
          >
            <Button
              variant="outlined"
              sx={{ marginRight: 2 }}
              onClick={() => {
                setModalOpen(false);
              }}
            >
              Annuler
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                if (
                  !leaseTmpData.kind ||
                  !leaseTmpData.start_at ||
                  !leaseTmpData.end_at ||
                  !leaseTmpData?.duration ||
                  leaseTmpData?.uuid_contacts.length === 0
                ) {
                  handleError();
                }
                if (createLease) {
                  createNewLease();
                } else {
                  updateLease();
                }
              }}
            >
              Enregistrer
            </Button>
          </Grid2>
        </Grid2>
      </Grid2>
    </BasicModal>
  );
};
