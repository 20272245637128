import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import type { FC } from 'react';

interface ConfirmationDialogProps {
  message: string;
  cancelText?: string;
  confirmText?: string;
  onCancel: () => void;
  onConfirm: () => void;
  open: boolean;
  title: string;
  variant: 'error' | 'warning' | 'info';
}

const icons = {
  error: <ErrorIcon color="error" fontSize="large" />,
  warning: <WarningIcon color="warning" fontSize="large" />,
  info: <InfoIcon color="info" fontSize="large" />
};

export const ConfirmationDialog: FC<ConfirmationDialogProps> = (props) => {
  const {
    message,
    onCancel,
    onConfirm,
    open = false,
    title,
    variant,
    cancelText = 'Annuler',
    confirmText = 'Valider',
    ...other
  } = props;

  return (
    <Dialog
      onClose={onCancel}
      open={open}
      PaperProps={{
        sx: {
          width: '100%'
        }
      }}
      {...other}
    >
      <DialogTitle
        sx={{
          alignItems: 'center',
          display: 'flex'
        }}
      >
        {icons[variant]}
        <Typography color="inherit" sx={{ ml: 2 }} variant="inherit">
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography color="textPrimary" variant="body1">
          {message}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onCancel} variant="outlined">
          {cancelText}
        </Button>
        <Button color="primary" onClick={onConfirm} variant="contained">
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmationDialog.propTypes = {
  message: PropTypes.string.isRequired,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['error', 'warning', 'info'])
};
