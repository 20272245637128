import { useEffect, useState } from 'react';
import ProductContact from '../ProductContact/ProductContact';
import { api, getContactsOrganization, getProductContacts } from '~/api';
import auth from '../Auth/AuthApi';
import Grid from '@mui/material/Grid';

export const CreateProductContactsSection = ({
  dpeDatas,
  setDpeDatas,
  setNextStepPossible,
  productKind,
  setProductKind,
  buildingKind,
  setBuildingKind,
  setPropertyDatas,
  productDatas,
  currentStep,
  productBuildingUuid
}) => {
  const [allContacts, setAllContacts] = useState([]);
  const [updateContact, setUpdateContact] = useState(true);
  const [contacts, setContacts] = useState([]);

  const callContactsOrganizaiton = () => {
    const claims = auth.getJWT();
    getContactsOrganization(claims.uuid_organization)
      .then((orgcontacts) => {
        setAllContacts(orgcontacts);
      })
      .finally(() => {
        setUpdateContact(false);
      });
  };

  useEffect(() => {
    const claims = auth.getJWT();
    if (currentStep === 2) {
      if (productDatas.uuid || productDatas.kind === 2) {
        if (productDatas.kind === 2) {
          api.building.contact
            .get(productBuildingUuid)
            .then((resp) => {
              setContacts(resp);
              setUpdateContact(false);
            })
            .catch((error) => {})
            .finally(() => {});
        } else {
          api.product.contact
            .get(productDatas.uuid)
            .then((resp) => {
              setContacts(resp);
              setUpdateContact(false);
            })
            .catch((error) => {})
            .finally(() => {});
        }

        getContactsOrganization(claims.uuid_organization)
          .then((orgcontacts) => {
            const ctcs = orgcontacts.filter((contact) => {
              return contact.uuid_owner !== productDatas.uuid;
            });
          })
          .finally(() => {});
      }
    }
  }, [updateContact, allContacts, productDatas.uuid, setNextStepPossible, currentStep]);

  useEffect(() => {
    if (contacts && contacts.length > 0) {
      setNextStepPossible(true);
    }
    setNextStepPossible(true);
  }, [contacts]);

  useEffect(() => {
    callContactsOrganizaiton();
    setNextStepPossible(true);
  }, []);

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center" alignContent="center">
      <Grid item xs={8}>
        <ProductContact
          contacts={contacts}
          productId={productDatas.uuid ? productDatas.uuid : productBuildingUuid}
          allContacts={allContacts}
          setUpdateContact={setUpdateContact}
          setAllContacts={setAllContacts}
          isBuilding={productDatas.kind === 2}
          propertyCreationContext={true}
        />
      </Grid>
    </Grid>
  );
};
