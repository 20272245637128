import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import React from 'react';

export const EDLKindForm = ({ documentData, setDocumentData, documentTypeLayout }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const data = { ...documentData.data };
    data.EDLKind = event.target.value;
    setDocumentData({ ...documentData, data: data });
  };

  return (
    <>
      {documentData && documentTypeLayout && documentTypeLayout.needEDLKind && (
        <>
          <FormControl style={{ paddingBottom: '15px' }}>
            <RadioGroup
              row
              aria-labelledby="demo-radio-buttons-group-label"
              value={documentData.data.EDLKind ? documentData.data.EDLKind : ''}
              name="radio-buttons-group"
              onChange={handleChange}
            >
              <FormControlLabel value="entry" control={<Radio />} label="Entrée" />
              <FormControlLabel value="exit" control={<Radio />} label="Sortie" />
            </RadioGroup>
          </FormControl>
        </>
      )}
    </>
  );
};
