import { axiosGet } from '.';

export const productsNumber = () => {
  return axiosGet('/stats/products/number');
};

export const productsDpe = () => {
  return axiosGet('/stats/products/dpe');
};

export const productsKind = () => {
  return axiosGet('/stats/products/kind');
};

export const usersEvents = () => {
  return axiosGet('/stats/users/tickets');
};

export const allStats = () => {
  return axiosGet('/stats');
};
