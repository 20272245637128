import axios from 'axios';
import {
  JWTValues,
  getJWT,
  isValidToken,
  JWTSessionStorageKey,
  getRefreshJWT,
  setSession,
  getRefreshToken,
  logOut,
  getToken
} from '~/app/components/Auth/AuthApi';
import { jwtDecode } from 'jwt-decode';
import { api } from './api';

import packageInfo from '../../package.json';

export const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
});

export const axiosClientRefresh = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
});

const setRedirect = (): void => {
  if (!isCurrentPagePublic() && window.location.pathname !== '/organizations') {
    sessionStorage.setItem('av_redirect', window.location.pathname);
  }
};

export const onAxiosError = async (error) => {
  const token = getToken();
  const refreshToken = getRefreshToken();

  if (
    (token === null ||
      refreshToken === null ||
      token === undefined ||
      token === 'undefined' ||
      refreshToken === undefined ||
      refreshToken === 'undefined') &&
    !isCurrentPagePublic()
  ) {
    logOut();
    return Promise.reject(error);
  }
  const originalRequest = error?.config;

  if ((error?.response?.status === 401 || error?.response?.status === 400) && !originalRequest?._retry) {
    originalRequest._retry = true;
    const access_token = await tryRefreshToken();
    if (access_token === 'ok') {
      currentUser = jwtDecode<JWTValues>(getToken());
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + getToken();
      return axiosClient(originalRequest);
    } else {
      logOut();
      return Promise.reject(error);
    }
  } else {
    return Promise.reject(error);
  }
};

export const tryRefreshToken = async () => {
  const tokenJwt = getJWT();
  const refreshJwt = getRefreshJWT();
  const refreshToken = getRefreshToken();
  if (tokenJwt === null || refreshJwt === null || !isValidToken(refreshJwt)) {
    logOut();
    return null;
  }

  currentUser = jwtDecode<JWTValues>(getToken());

  const responseRefreshToken = await api.users
    .refresh({ refresh: refreshToken })
    .then((res) => {
      setSession(res);
      return 'ok';
    })
    .catch((err) => {
      return null;
    });
  return responseRefreshToken;
};

export function isCurrentPagePublic(): boolean {
  const currentePage = window.location.pathname;
  if (
    currentePage === '/login' ||
    currentePage === '/forgot-password' ||
    currentePage === '/register' ||
    currentePage === '/delete-account'
  ) {
    return true;
  }
  return false;
}

export function checkSession() {
  if (!isCurrentPagePublic()) {
    const claims = getJWT();
    if (claims === null) {
      if (!isValidToken(claims)) {
        window.location.pathname = 'login';
      }
    }
  }
}

export let currentUser: JWTValues;

axiosClient.interceptors.request.use(
  async (config) => {
    setRedirect();
    config.headers['application_kind'] = 'app_property_manager';
    config.headers['application_version'] = `v${packageInfo.version}`;

    if (!isCurrentPagePublic()) {
      if (!isValidToken(getJWT())) {
        const refreshTokenresponse = await tryRefreshToken();
        if (refreshTokenresponse === 'ok') {
          const jwtValueForHeader = sessionStorage.getItem(JWTSessionStorageKey);
          currentUser = jwtDecode<JWTValues>(jwtValueForHeader);
          config.headers.Authorization = 'Bearer ' + jwtValueForHeader;
        } else {
          logOut();
        }
      } else if (isValidToken(getJWT())) {
        const jwtValueForHeader = sessionStorage.getItem(JWTSessionStorageKey);
        currentUser = jwtDecode<JWTValues>(jwtValueForHeader);
        config.headers.Authorization = 'Bearer ' + jwtValueForHeader;
      }
    }

    return config;
  },
  (error) => {
    onAxiosError(error);
  }
);

axiosClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    onAxiosError(error);
  }
);

export async function axiosGet(arg, urlParams = null) {
  return await axiosClient
    .get(arg, urlParams)
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
      return Promise.reject(err);
    });
}

export async function axiosPost(arg, data, urlParams = null) {
  return await axiosClient
    .post(arg, data, urlParams)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

export async function axiosRefreshPost(arg, data, urlParams = null) {
  return await axiosClientRefresh
    .post(arg, data, urlParams)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

export async function axiosPostMultipart(arg, data) {
  return await axiosClient
    .post(arg, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then((res) => res.data)
    .catch((err) => {
      return Promise.reject(err);
    });
}

export async function axiosPostWithoutData(arg) {
  return await axiosClient
    .post(arg)
    .then((res) => res.data)
    .catch((err) => {
      return Promise.reject(err);
    });
}

export async function axiosDelete(arg) {
  return await axiosClient
    .delete(arg)
    .then((res) => res.data)
    .catch((err) => {
      return Promise.reject(err);
    });
}

export async function axiosPatch(arg, data) {
  return await axiosClient
    .patch(arg, data)
    .then((res) => res.data)
    .catch((err) => {
      return Promise.reject(err);
    });
}

export default axiosClient;
