import { Grid, Button } from '@mui/material';
import { StepsViewer } from '~/app/components/StepsViewer/StepsViewer';
import { appTheme } from '~/utils/theme';
import { useState, useEffect } from 'react';
import { api } from '~/api';
import { IBuildingCreate, IBuildingUpdate } from '~/api/building.models';
import { INewProduct } from '~/interfaces';
import { CreateGeneralInfos } from '~/app/components/CreateProduct/CreateGeneralInfos';
import { CreateInformations } from './CreateInformations';
import { CreateProductContactsSection } from './CreateProductContactsSection';
import { CreateProductValidation } from './CreateProductValidation';
import { ConfirmationDialog } from '~/app/components/ConfirmationDialog/ConfirmationDialog';

export const WizzardCreateProduct = ({
  setSpinnerOpen,
  spinnerOpen,
  setModalOpen,
  placeSelected = null,
  setPlaceSelected = null,
  closeModal = null,
  cancelCreationConfirmation,
  setCancelCreationConfirmation
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const steps = ['Adresse', 'Informations', 'Contacts'];
  const [nextStepPossible, setNextStepPossible] = useState(false);
  const [dpeDatas, setDpeDatas] = useState(null);

  const [propertyNature, setPropertyNature] = useState(''); // ['house', 'apartment', 'building'

  const [propertyIdSelected, setPropertyIdSelected] = useState(''); // id of the building or product

  const [productKind, setProductKind] = useState('');
  const [buildingKind, setBuildingKind] = useState('');

  const [abandonModalOpen, setAbandonModalOpen] = useState(false);
  const [newBuildingCreated, setNewBuildingCreated] = useState(false);

  const [propertyDataObject, setPropertyDataObject] = useState({}); // ['house', 'apartment', 'building'

  const initialBuildingDatas = {
    id_internal: '',
    name: '',
    description: '',
    construction_year: null,
    construction_period: null,
    kind: 3,
    data: {},
    is_draft: true,
    address_complement: null,
    address: {
      street: '',
      city: '',
      country: '',
      zip_code: ''
    }
  };

  const initialProductDatas = {
    id_internal: '',
    kind: null,
    matterport_scan: null,
    data: {},
    is_draft: true,
    address_complement: null,
    uuid: null,
    uuid_address: null,
    uuid_building: null
  };

  const [buildingDatas, setBuildingDatas] = useState(initialBuildingDatas);

  const [matterPortId, setMatterPortId] = useState(null);

  const [productBuildingUuid, setProductBuildingUuid] = useState(null); // uuid of the building
  const [productDatas, setProductDatas] = useState(initialProductDatas);

  const [isCancel, setIsCancel] = useState(false);

  useEffect(() => {
    if (cancelCreationConfirmation) {
      setAbandonModalOpen(true);
      if (isCancel) {
        if (productBuildingUuid || productDatas.uuid || buildingDatas.address.street) {
          if (productDatas.uuid) {
            api.product.delete(productDatas.uuid).then((res) => {});
          }
          if (newBuildingCreated) {
            api.building.delete(productBuildingUuid).then((res) => {});
          }
        } else {
          setModalOpen(false);
          setCancelCreationConfirmation(false);
          setIsCancel(false);
        }
      }
    }
  }, [cancelCreationConfirmation, isCancel]);

  const handleCancelCreation = () => {
    setIsCancel(true);
  };

  useEffect(() => {
    if (buildingKind && buildingKind !== '') {
      setBuildingDatas((prev) => ({
        ...prev,
        kind: Number(buildingKind)
      }));
    }
  }, [buildingKind]);

  useEffect(() => {
    if (productKind && productKind !== '') {
      setProductDatas((prev) => ({
        ...prev,
        kind: Number(productKind)
      }));
    }
  }, [productKind]);

  useEffect(() => {
    if (propertyNature === 'product') {
      setBuildingDatas((prev) => ({
        ...prev,
        data: {}
      }));
    } else if (propertyNature === 'building') {
      setPropertyDataObject(buildingDatas);
    }
  }, [propertyNature]);

  const nextStepHandler = () => {
    if (currentStep === 0) {
      if (productBuildingUuid !== null) {
        if (productBuildingUuid) {
          const payload = JSON.parse(JSON.stringify(buildingDatas));
          delete payload.address;

          if (
            buildingDatas.id_internal !== payload.id_internal ||
            buildingDatas.name !== payload.name ||
            buildingDatas.description !== payload.description ||
            (buildingDatas?.construction_year && buildingDatas?.construction_year !== payload?.construction_year) ||
            (buildingDatas?.construction_period && buildingDatas?.construction_period !== payload?.construction_period)
          ) {
            api.building.update(productBuildingUuid, payload as IBuildingUpdate).then((res) => {
              setCurrentStep(currentStep + 1);
            });
          } else {
            setCurrentStep(currentStep + 1);
          }
        }
      } else {
        api.building.create(buildingDatas as IBuildingCreate).then((res) => {
          setProductBuildingUuid(res.uuid);
          setCurrentStep(currentStep + 1);
          setProductDatas((prev) => ({
            ...prev,
            uuid_address: res.address.uuid,
            uuid_building: res.uuid
          }));
          setNewBuildingCreated(true);
        });
      }
      setNextStepPossible(false);
    } else if (currentStep === 1) {
      if (productDatas.uuid === null && propertyNature !== 'building') {
        api.product.create(productDatas as INewProduct).then((res) => {
          setCurrentStep(currentStep + 1);
          setProductDatas((prev) => ({
            ...prev,
            uuid: res.uuid
          }));
        });
      } else if (propertyNature !== 'building') {
        api.product.update(productDatas.uuid, productDatas as INewProduct).then((res) => {
          setCurrentStep(currentStep + 1);
        });
      } else {
        api.building.update(productBuildingUuid, buildingDatas as IBuildingUpdate).then((res) => {
          if (matterPortId) {
            api.matterport.create({ uuid_owner: res.uuid, model_id: matterPortId }).then((res) => {
              setCurrentStep(currentStep + 1);
            });
          } else {
            setCurrentStep(currentStep + 1);
          }
        });
      }
      setNextStepPossible(true);
    } else if (currentStep === 2) {
      setCurrentStep(currentStep + 1);
      if (propertyNature === 'product') {
        api.product
          .update(productDatas.uuid, { is_draft: false })
          .then((res) => {})
          .catch((err) => {});
        api.building
          .update(productDatas.uuid_building, { is_draft: false })
          .then((res) => {})
          .catch((err) => {});
      } else if (propertyNature === 'building') {
        api.building
          .update(productBuildingUuid, { is_draft: false })
          .then((res) => {})
          .catch((err) => {});
      }
    }
  };

  return (
    <Grid container>
      <Grid item xs={12} justifyContent="center" alignItems="center" style={{ textAlign: 'center' }}>
        <h2 style={{ color: appTheme.palette.primary.main, height: '60px' }}>Création de produit</h2>
      </Grid>
      <Grid item xs={4} style={{ display: currentStep < 3 ? 'block' : 'none' }}>
        <StepsViewer steps={steps} currentStep={currentStep} />
      </Grid>
      <Grid item xs={currentStep < 3 ? 8 : 12}>
        <div style={{ display: currentStep === 0 ? 'block' : 'none' }}>
          <CreateGeneralInfos
            productKind={productKind}
            setProductKind={setProductKind}
            buildingKind={buildingKind}
            setBuildingKind={setBuildingKind}
            propertyNature={propertyNature}
            setPropertyNature={setPropertyNature}
            dpeDatas={dpeDatas}
            setDpeDatas={setDpeDatas}
            setNextStepPossible={setNextStepPossible}
            setBuildingDatas={setBuildingDatas}
            buildingDatas={buildingDatas}
            setProductBuildingUuid={setProductBuildingUuid}
            setPropertyDatas={propertyNature === 'product' ? setProductDatas : setBuildingDatas}
            currentStep={currentStep}
            propertyIdSelected={propertyIdSelected}
            setPropertyIdSelected={setPropertyIdSelected}
            initialBuildingDatas={initialBuildingDatas}
            setProductDatas={setProductDatas}
            initialProductDatas={initialProductDatas}
          />
        </div>
        <div style={{ display: currentStep === 1 ? 'block' : 'none' }}>
          <CreateInformations
            dpeDatas={dpeDatas}
            productKind={productKind}
            buildingKind={buildingKind}
            setBuildingKind={setBuildingKind}
            setProductKind={setProductKind}
            propertyNature={propertyNature}
            setPropertyNature={setPropertyNature}
            setDpeDatas={setDpeDatas}
            setNextStepPossible={setNextStepPossible}
            setPropertyDatas={propertyNature === 'product' ? setProductDatas : setBuildingDatas}
            currentStep={currentStep}
            setMatterPortId={setMatterPortId}
            buildingDatas={buildingDatas}
          ></CreateInformations>
        </div>

        <div style={{ display: currentStep === 2 ? 'block' : 'none' }}>
          <CreateProductContactsSection
            dpeDatas={dpeDatas}
            productKind={productKind}
            setProductKind={setProductKind}
            buildingKind={buildingKind}
            setBuildingKind={setBuildingKind}
            setDpeDatas={setDpeDatas}
            setNextStepPossible={setNextStepPossible}
            setPropertyDatas={propertyNature === 'product' ? setProductDatas : setBuildingDatas}
            productDatas={buildingDatas.kind !== 2 ? productDatas : buildingDatas}
            currentStep={currentStep}
            productBuildingUuid={productBuildingUuid}
          ></CreateProductContactsSection>
        </div>

        <div style={{ display: currentStep === 3 ? 'block' : 'none' }}>
          <CreateProductValidation
            productBuildingUuid={productBuildingUuid}
            currentStep={currentStep}
            productDatas={productDatas}
          ></CreateProductValidation>
        </div>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ mt: 2 }}
        style={{
          width: '95vw',
          zIndex: 1,
          background: 'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 72%, rgba(255,255,255,0) 100%)'
        }}
      >
        <Grid
          container
          spacing={1}
          style={{
            width: '100%',
            justifyContent: 'center',
            display: currentStep === 3 ? 'none' : 'flex'
          }}
        >
          <Grid item xs={1} sx={{ textAlign: 'center' }}>
            <Button
              onClick={() => {
                if (currentStep === 0) {
                  setModalOpen(false);
                } else {
                  setCurrentStep(currentStep - 1);
                  setNextStepPossible(true);
                }
              }}
              color="primary"
              size="medium"
              variant="outlined"
            >
              {currentStep === 0 ? 'Annuler' : 'Précédent'}
            </Button>
          </Grid>
          <Grid item xs={1} sx={{ textAlign: 'center' }}>
            <Button
              onClick={nextStepHandler}
              disabled={!nextStepPossible}
              color="primary"
              size="medium"
              variant="contained"
            >
              Suivant
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <ConfirmationDialog
        message={'Etes-vous certain de vouloir abondonner la création de ce bien ?'}
        onCancel={() => {
          setAbandonModalOpen(false);
          setCancelCreationConfirmation(false);
        }}
        onConfirm={() => {
          setAbandonModalOpen(false);
          handleCancelCreation();
          setModalOpen(false);
          setCancelCreationConfirmation(false);
        }}
        open={abandonModalOpen}
        title={'Abandon de création de bien'}
        variant={'error'}
      />
    </Grid>
  );
};

export default WizzardCreateProduct;
