import { ReactNode } from 'react';
import './Tag.scss';

interface TagProps {
  children?: ReactNode;
  variant: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info' | 'error';
  icon?: ReactNode;
}

export const Tag = ({ children, variant, icon }: TagProps) => {
  return (
    <div
      className={`Tag ${variant}`}
      style={children ? { padding: '3px 13px', borderRadius: '25px' } : { padding: '9px', borderRadius: '50%' }}
    >
      {icon && children ? <div className="Tag__icon">{icon}</div> : icon}
      {children}
    </div>
  );
};

export default Tag;
