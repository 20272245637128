import { axiosDelete, axiosGet, axiosPatch, axiosPost } from '~/api/axiosClient';
import { ContactResponse } from './contact.models';

export const createContact = (data): Promise<ContactResponse> => {
  return axiosPost('/contacts', data);
};

export const getContact = (idContact: string) => {
  return axiosGet(`/contacts/${idContact}`);
};

export const getContacts = (): Promise<ContactResponse[]> => {
  return axiosGet(`/contacts`);
};

export const getAllContacts = (): Promise<ContactResponse[]> => {
  return axiosGet(`/contacts`);
};

export const updateContact = (idContact: string, data: any): Promise<ContactResponse> => {
  return axiosPatch(`/contacts/${idContact}`, data);
};

export const deleteContact = (idContact: string) => {
  return axiosDelete(`/contacts/${idContact}`);
};
