import { useContext, useEffect, useState } from 'react';
import { ConfirmationDialog } from '../ConfirmationDialog/ConfirmationDialog';
import { api } from '~/api';
import { AppContext } from '~/utils/context/AppContext';
import PresentationStep from './Steps/PresentationStep';
import ProductKindStep from './Steps/ProductKindStep';
import SearchAddressStep from './Steps/SearchAddressStep';
import Header from './SubComponents/Header';
import CheckSameAddressExist from './Steps/CheckSameAddressExistStep';
import AddressDatasStep from './Steps/AddressDatasStep';
import PrincipalInformations from './Steps/PrincipalInformations';
import MoreInformations from './Steps/MoreInformations';
import AddContacts from './Steps/AddContacts';
import ValidationStep from './Steps/ValidationStep';
import { IProductCreate } from '~/interfaces';

export const WizzardCreateProduct = ({
  setModalOpen,
  closeModal,
  cancelCreationConfirmation,
  setCancelCreationConfirmation
}) => {
  const { referencial } = useContext(AppContext);

  const newProductDatas = {
    id_internal: '',
    kind: null,
    matterport_scan: null,
    is_draft: true,
    address_complement: null,
    uuid: null,
    uuid_address: null
  };

  const [abandonModalOpen, setAbandonModalOpen] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  const [productDatas, setProductDatas] = useState(newProductDatas);
  const [newAddressUuid, setNewAddressUuid] = useState(null);
  const [currentStep, setCurrentStep] = useState(1);
  const [googlePlaceDatas, setGooglePlaceDatas] = useState(null);
  const [ademeDatas, setAdemeDatas] = useState(null);
  const [address, setAddress] = useState(null);
  const [addressToAttach, setAddressToAttach] = useState(null);
  const [sameBuildingsList, setSameBuildingsList] = useState([]);
  const [dpe, setDpe] = useState(null);

  const [title, setTitle] = useState('');

  useEffect(() => {
    if (ademeDatas) {
      setDpe(ademeDatas);

      setAddress({
        street: ademeDatas.address.Street,
        zip_code: ademeDatas.address.ZipCode,
        city: ademeDatas.address.City,
        country: ademeDatas.address.Country,
        latitude: ademeDatas.address.Latitude,
        longitude: ademeDatas.address.Longitude
      });

      const ProductDatasFromAdeme: IProductCreate = {};

      if (ademeDatas.living_space) {
        if (!ProductDatasFromAdeme.surface) {
          ProductDatasFromAdeme.surface = {};
        }
        ProductDatasFromAdeme.surface.living_space = ademeDatas.living_space;
      }

      if (ademeDatas.ceiling_height) {
        if (!ProductDatasFromAdeme.surface) {
          ProductDatasFromAdeme.surface = {};
        }
        ProductDatasFromAdeme.surface.ceiling_height = ademeDatas.ceiling_height;
      }
      setProductDatas((prev) => {
        return { ...prev, ...ProductDatasFromAdeme };
      });
    }
  }, [ademeDatas]);

  useEffect(() => {
    setProductDatas((prev) => {
      return { ...prev, address: { ...address } };
    });
  }, [address]);

  useEffect(() => {
    setDpe(null);

    if (googlePlaceDatas) {
      let streetNumber = null;
      let name = null;
      let zip_code = null;
      let city = null;
      let country = null;
      for (var i = 0; i < googlePlaceDatas.address_components.length; i++) {
        for (var j = 0; j < googlePlaceDatas.address_components[i].types.length; j++) {
          if (googlePlaceDatas.address_components[i].types[j] == 'postal_code') {
            zip_code = googlePlaceDatas.address_components[i].long_name;
          }
          if (googlePlaceDatas.address_components[i].types[j] == 'locality') {
            city = googlePlaceDatas.address_components[i].long_name;
          }
          if (googlePlaceDatas.address_components[i].types[j] == 'country') {
            country = googlePlaceDatas.address_components[i].long_name;
          }
          if (googlePlaceDatas.address_components[i].types[j] == 'route') {
            name = googlePlaceDatas.address_components[i].long_name;
          }
          if (googlePlaceDatas.address_components[i].types[j] == 'street_number') {
            streetNumber = googlePlaceDatas.address_components[i].long_name;
          }
        }
      }

      var latitude = googlePlaceDatas.geometry.location.lat();
      var longitude = googlePlaceDatas.geometry.location.lng();

      setAddress({
        street: streetNumber + ' ' + name,
        zip_code: zip_code,
        city: city,
        country: country,
        latitude: latitude,
        longitude: longitude
      });
    }
  }, [googlePlaceDatas]);

  const handleCancelCreation = () => {
    if (productDatas.uuid) {
      api.product.delete(productDatas.uuid).then((res) => {});
    }
    if (productDatas.uuid_address) {
      let addressAlreadyUsed = false;
      sameBuildingsList.forEach((address) => {
        if (address.uuid === productDatas.uuid_address) {
          addressAlreadyUsed = true;
        }
      });
      if (!addressAlreadyUsed) {
        api.address.delete(productDatas.uuid_address).then((res) => {});
      }
    }
    setIsCancel(true);
  };

  useEffect(() => {
    if (cancelCreationConfirmation) {
      setAbandonModalOpen(true);
      if (isCancel) {
        if (productDatas.uuid) {
          if (productDatas.uuid) {
            api.product.delete(productDatas.uuid).then((res) => {});
          }
          if (newAddressUuid) {
            // End point to delete Adress
          }
        } else {
          setModalOpen(false);
          setCancelCreationConfirmation(false);
          setIsCancel(false);
        }
      }
    }
  }, [cancelCreationConfirmation, isCancel]);
  return (
    <>
      <Header title={title} />
      {/* STEP 1 */}
      {currentStep === 1 && <PresentationStep setCurrentStep={setCurrentStep} setTitle={setTitle} />}
      {/* STEP 2 */}
      {currentStep === 2 && (
        <ProductKindStep
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          setTitle={setTitle}
          setProductDatas={setProductDatas}
        />
      )}
      {/* STEP 3 */}
      {currentStep === 3 && (
        <SearchAddressStep
          setCurrentStep={setCurrentStep}
          setTitle={setTitle}
          ademeDatas={ademeDatas}
          setAdemeDatas={setAdemeDatas}
          googlePlaceDatas={googlePlaceDatas}
          setGooglePlaceDatas={setGooglePlaceDatas}
        />
      )}
      {/* STEP 4 */}
      {currentStep === 4 && (
        <CheckSameAddressExist
          setCurrentStep={setCurrentStep}
          setTitle={setTitle}
          address={address}
          setAdemeDatas={setAdemeDatas}
          setGooglePlaceDatas={setGooglePlaceDatas}
          setAddressToAttach={setAddressToAttach}
          setProductDatas={setProductDatas}
          setSameBuildingsList={setSameBuildingsList}
        />
      )}
      {/* STEP 5 */}
      {currentStep === 5 && (
        <AddressDatasStep
          setTitle={setTitle}
          setCurrentStep={setCurrentStep}
          addressToAttach={addressToAttach}
          setAddressToAttach={setAddressToAttach}
          productDatas={productDatas}
          setProductDatas={setProductDatas}
        />
      )}
      {/* STEP 6 */}
      {currentStep === 6 && (
        <PrincipalInformations
          setTitle={setTitle}
          setCurrentStep={setCurrentStep}
          productDatas={productDatas}
          setProductDatas={setProductDatas}
        ></PrincipalInformations>
      )}
      {/* STEP 7 */}
      {currentStep === 7 && (
        <MoreInformations
          setTitle={setTitle}
          setCurrentStep={setCurrentStep}
          productDatas={productDatas}
          setProductDatas={setProductDatas}
        ></MoreInformations>
      )}
      {/* STEP 8 */}
      {currentStep === 8 && (
        <AddContacts
          setTitle={setTitle}
          setCurrentStep={setCurrentStep}
          productDatas={productDatas}
          setProductDatas={setProductDatas}
        ></AddContacts>
      )}
      {/* STEP 9 */}
      {currentStep === 9 && (
        <ValidationStep
          setTitle={setTitle}
          setCurrentStep={setCurrentStep}
          productDatas={productDatas}
          setProductDatas={setProductDatas}
          setModalOpen={setModalOpen}
          dpe={dpe}
        ></ValidationStep>
      )}

      <ConfirmationDialog
        message={'Etes-vous certain de vouloir abondonner la création de ce bien ?'}
        onCancel={() => {
          setAbandonModalOpen(false);
          setCancelCreationConfirmation(false);
        }}
        onConfirm={() => {
          setAbandonModalOpen(false);
          handleCancelCreation();
          setModalOpen(false);
          setCancelCreationConfirmation(false);
        }}
        open={abandonModalOpen}
        title={'Abandon de création de bien'}
        variant={'error'}
      />
    </>
  );
};

export default WizzardCreateProduct;
