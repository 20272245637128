import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { Box, Grid, Typography, InputBase, Button, useTheme, useMediaQuery } from '@mui/material';
import Stack from '@mui/material/Stack';
import { ReactNode, useEffect, useState } from 'react';
import { getContactsOrganization } from '~/api';
import Breadcrumb from '~/app/components/Breadcrumb/Breadcrumb';
import { auth } from '~/app/components/Auth/AuthApi';
import { WizzardContact } from '~/app/components/WizzardContact/WizzardContact';
import ContactBlock from '~/app/components/ContactBlock/ContactBlock';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { appTheme } from '~/utils/theme';
import './Contacts.scss';

export const Contacts = ({ spinnerOpen, setSpinnerOpen }) => {
  const [contacts, setContacts] = useState([]);
  const [contactFiltered, setContactFiltered] = useState([]);
  const [addContact, setAddContact] = useState<boolean>(false);
  const [contactBlocks, setContactBlocks] = useState<ReactNode[]>([]);
  const [filterValue, setFilterValue] = useState('');
  const [updateContact, setUpdateContact] = useState(true);

  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.up('xs'));
  const matchesSm = useMediaQuery(theme.breakpoints.up('sm'));
  const matchesMd = useMediaQuery(theme.breakpoints.up('md'));
  const matchesLg = useMediaQuery(theme.breakpoints.up('lg'));
  const matchesXl = useMediaQuery(theme.breakpoints.up('xl'));

  useEffect(() => {
    const blocks = [];
    if (updateContact) {
      getContactsOrganization(auth.getOrganizationUUID())
        .then((contacts) => {
          setContacts(contacts.reverse());
          contacts.map((contact) => {
            blocks.push(
              <ContactBlock
                contact={contact}
                key={contact.uuid}
                setUpdateContact={setUpdateContact}
                contacts={contacts}
                setContacts={setContacts}
              />
            );
          });
          setContactBlocks(blocks);
          setSpinnerOpen(false);
        })
        .finally(() => {
          setUpdateContact(false);
        });
    }
  }, [updateContact, contacts]);

  useEffect(() => {
    const tempTab = [];
    contactBlocks.filter((contact) => {
      tempTab.push(contact);
    });

    const contactFilter = tempTab.filter((contact) => {
      const contactName = contact.props.contact.name.toLowerCase();
      const companyName = contact.props.contact.company_name.toLowerCase();
      const keywords = contact.props.contact.keywords || [];
      const keywordsLower = keywords.map((keyword) => keyword.toLowerCase());
      const filterValueLower = filterValue.toLowerCase();
      return (
        contactName.includes(filterValueLower) ||
        companyName.includes(filterValueLower) ||
        keywordsLower.some((keyword) => keyword.includes(filterValueLower))
      );
    });
    setContactFiltered(contactFilter);
  }, [filterValue, contactBlocks]);

  const renderContactBlocks = () => {
    if (!filterValue) {
      return contactBlocks;
    } else if (contactFiltered.length > 0) {
      return contactFiltered;
    } else {
      return (
        <Typography variant="h3" sx={{ color: 'primary.main' }}>
          Aucun contact trouvé
        </Typography>
      );
    }
  };

  return (
    <div className="Contacts">
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="h1" sx={{ color: 'primary.main' }}>
          Liste des contacts
        </Typography>
        <Breadcrumb
          data={[{ label: 'Accueil', href: '/' }]}
          last={`Liste des contacts`}
          setSpinnerOpen={setSpinnerOpen}
        />
      </Box>
      <br />
      <div
        className="Contacts__lineBlock"
        style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '15px' }}
      >
        <Button
          color="secondary"
          variant="contained"
          sx={{ color: 'white', borderRadius: '20px', paddingTop: '8px' }}
          onClick={() => {
            setAddContact(!addContact);
          }}
        >
          <AddOutlinedIcon fontSize="small" />
          {!matchesMd ? (
            <span style={{ fontWeight: 'bold', fontSize: '15px' }}>&nbsp;Contacts</span>
          ) : (
            <span style={{ fontWeight: 'bold', fontSize: '15px' }}>&nbsp; Ajouter un contact</span>
          )}
        </Button>
      </div>
      <br />

      <Box sx={{ flexGrow: 3, mr: 2 }}>
        <div
          style={{
            alignItems: 'center',
            backgroundColor: appTheme.palette.primary[50],
            border: `1px solid`,
            borderColor: appTheme.palette.primary[100],
            borderRadius: '20px',
            display: 'flex',
            height: '36px',
            fontSize: '16px',
            width: '100%'
          }}
        >
          <InputBase
            value={filterValue}
            onChange={(e) => {
              setFilterValue(e.target.value);
            }}
            placeholder="Catégorie, Société, Nom ..."
            autoComplete="off"
            inputProps={{
              sx: {
                pl: 1.8,
                '&::placeholder': {}
              }
            }}
            sx={{ flex: 1 }}
          />
          <SearchOutlinedIcon
            sx={{
              color: 'primary.main',
              ml: 2,
              mr: 1
            }}
            fontSize="medium"
          />
        </div>
      </Box>

      <br />
      <Grid container spacing={3} sx={{ pl: !matchesMd ? '10vw' : '0vw' }}>
        <Stack
          direction="row"
          sx={{
            ml: '30px',
            mt: '20px',
            height: 'fit-content',
            width: 'fit-content'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '20px',
              width: '100%'
            }}
          >
            {renderContactBlocks()}
          </Box>
        </Stack>
      </Grid>

      {addContact && (
        <WizzardContact
          onClose={() => {
            setAddContact(false);
            setUpdateContact(true);
          }}
          open={addContact}
          isNewContact={true}
          contacts={contacts}
        />
      )}
      <br />
    </div>
  );
};
