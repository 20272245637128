export const DropZoneAreaIcon = () => {
  return (
    <svg
      style={{ padding: '20px' }}
      width="76"
      height="75"
      viewBox="0 0 76 75"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2470_40516)">
        <path
          d="M38 39.3313L51.2594 52.5875L46.8375 57.0094L41.125 51.2969V68.75H34.875V51.2906L29.1625 57.0094L24.7406 52.5875L38 39.3313ZM38 6.25C43.3656 6.25026 48.544 8.22223 52.5506 11.791C56.5573 15.3598 59.1128 20.2764 59.7313 25.6063C63.6196 26.6666 67.0116 29.0597 69.3146 32.3673C71.6175 35.6749 72.6848 39.6865 72.3301 43.7012C71.9753 47.7159 70.2209 51.4782 67.3736 54.3307C64.5263 57.1831 60.7672 58.9442 56.7531 59.3063L56.75 53.125C56.7549 48.2098 54.8296 43.4893 51.3886 39.9795C47.9476 36.4698 43.2659 34.4516 38.3516 34.3595C33.4373 34.2673 28.6833 36.1085 25.1131 39.4867C21.5428 42.8649 19.4419 47.51 19.2625 52.4219L19.25 53.125V59.3063C15.2358 58.9448 11.4763 57.1841 8.62854 54.3319C5.78082 51.4796 4.02602 47.7174 3.67086 43.7026C3.31569 39.6878 4.38275 35.6759 6.68553 32.368C8.98831 29.0602 12.3803 26.6668 16.2688 25.6063C16.8866 20.2762 19.4419 15.3591 23.4487 11.7902C27.4555 8.22131 32.6342 6.24957 38 6.25Z"
          fill="#062746"
        />
      </g>
      <defs>
        <clipPath id="clip0_2470_40516">
          <rect width="75" height="75" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const NoIcon = () => {
  return <div></div>;
};
