import { Grid2, Typography } from '@mui/material';
import { ReactNode, useContext, useEffect, useState } from 'react';
import { api } from '~/api';
import { AppContext } from '~/utils/context/AppContext';
import { EditOutlined } from '@mui/icons-material';
import { ModalContentType } from '~/interfaces/product';
import BasicModal from '../BasicModal/BasicModal';
import EditEquipmentInfos from '../EditEquipmentInfos/EditEquipmentInfos';
import _ from 'lodash';
import dayjs from 'dayjs';
import { periodicityRef } from '~/utils/references';
import { StateItem } from './StateItem';
import { EventItem } from './EventItem';
import Gallery from '../Gallery/Gallery';
import DocumentItem from './DocumentItem';
import { WizzardDocument } from '../WizzardDocument/WizzardDocument';

export const EquipmentDetails = ({ equipment, setUuidEquipmentSelected, productDatas }) => {
  const [equipmentFullDatas, setEquipmentFullDatas] = useState(null);
  const [images, setImagesList] = useState(null);
  const { referencial, equipmentReferencial } = useContext(AppContext);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalType, setModalType] = useState<ModalContentType>();
  const [lastState, setLastState] = useState(null);
  const [stateHistory, setStateHistory] = useState(null);
  const [showAllStates, setShowAllStates] = useState(false);

  const [showAllEvents, setShowAllEvents] = useState(false);

  const [showAllDocuments, setShowAllDocuments] = useState(false);

  const [lastEvent, setLastEvent] = useState(null);
  const [allEvents, setAllEvents] = useState([]);
  const [galleryIndex, setGalleryIndex] = useState(0);
  const [wizzardDocumentOpen, setWizzardDocumentOpen] = useState<any>(false);
  const [wizzardEquipmentOpen, setWizzardEquipmentOpen] = useState<boolean>(false);
  const [selectedDocument, setSelectedDocument] = useState<any>(null);

  const [wizzardEquipmentCreationMode, setWizzardEquipmentCreationMode] = useState<boolean>(false);

  const generateSubtitle = () => {
    const labels = [];
    if (
      equipmentReferencial &&
      equipmentFullDatas?.category_kind &&
      _.find(equipmentReferencial.equipmentCategoryKinds, {
        id: equipmentFullDatas.category_kind
      }) &&
      _.find(equipmentReferencial.equipmentCategoryKinds, {
        id: equipmentFullDatas.category_kind
      }).name
    ) {
      labels.push(
        _.find(equipmentReferencial.equipmentCategoryKinds, {
          id: equipmentFullDatas.category_kind
        }).name
      );
    }

    let equipmentName = null;
    if (
      equipmentReferencial &&
      equipmentFullDatas?.kind &&
      _.find(equipmentReferencial.equipments, { id: equipmentFullDatas.kind }) &&
      _.find(equipmentReferencial.equipments, { id: equipmentFullDatas.kind }).name
    ) {
      labels.push(_.find(equipmentReferencial.equipments, { id: equipmentFullDatas.kind }).name);
      equipmentName = _.find(equipmentReferencial.equipments, { id: equipmentFullDatas.kind }).name;
    }

    if (equipmentFullDatas?.title && equipmentName !== equipmentFullDatas.title) {
      labels.push(equipmentFullDatas.title);
    }

    if (equipmentFullDatas?.room && equipmentFullDatas.room.name) {
      labels.push(equipmentFullDatas.room.name);
    }

    return labels.join(' - ');
  };

  const refreshEquipments = () => {
    api.equipments.get(equipment.uuid).then((res) => {
      setEquipmentFullDatas(res);
    });
  };

  const updateSelectedDocumentData = (documentData) => {
    refreshEquipments();
    api.files.get(documentData.uuid).then((res) => {
      setSelectedDocument(res);
    });
  };

  const handleContentModal = (type: ModalContentType): ReactNode => {
    switch (type) {
      case 'Gallery':
        return <Gallery images={images} startIndex={galleryIndex} />;
      case 'EditProductInfos':
        return (
          <div>
            <EditEquipmentInfos
              setSpinnerOpen={false}
              spinnerOpen={false}
              setModalOpen={setModalOpen}
              refreshData={refreshEquipments}
              equipmentData={equipmentFullDatas}
              setEquipmentData={setEquipmentFullDatas}
              productDatas={productDatas}
              generateSubtitleEquipment={generateSubtitle}
              equipmentReferential={equipmentReferencial}
            ></EditEquipmentInfos>
          </div>
        );
    }
  };

  const modalClosed = () => {
    // console.log('modalClosed');
  };

  useEffect(() => {
    if (equipment?.uuid)
      api.equipments.get(equipment.uuid).then((res) => {
        setEquipmentFullDatas(res);
      });
  }, [equipment]);

  useEffect(() => {
    if (equipmentFullDatas) {
      const images = [];
      if (equipmentFullDatas.images && equipmentFullDatas.images.length > 0)
        equipmentFullDatas.images.forEach((image) => {
          if (!images.some((img) => img.uri === image.uri)) {
            images.push(image);
          }
        });

      if (equipmentFullDatas.states && equipmentFullDatas.states.length > 0) {
        equipmentFullDatas.states.forEach((state) => {
          if (state.images && state.images.length > 0)
            state.images.forEach((image) => {
              if (!images.some((img) => img.uri === image.uri)) {
                images.push(image);
              }
            });

          if (state.tags && state.tags.length > 0) {
            state.tags.forEach((tag) => {
              if (tag.images && tag.images.length > 0) {
                tag.images.forEach((image) => {
                  if (!images.some((img) => img.uri === image.uri)) {
                    images.push(image);
                  }
                });
              }
            });
          }
        });

        let lastState = null;
        const statesHistory = [];

        if (equipmentFullDatas.states && equipmentFullDatas.states.length > 0) {
          lastState = _.maxBy(equipmentFullDatas.states, 'created_at');
        }

        if (equipmentFullDatas.states && equipmentFullDatas.states.length > 1) {
          equipmentFullDatas.states.forEach((state) => {
            if (state.uuid !== lastState.uuid) {
              statesHistory.push(state);
            }
          });

          setStateHistory(_.orderBy(statesHistory, ['created_at'], ['desc']));
        }

        let lastEvent = null;
        const events = [];

        if (equipmentFullDatas.events && equipmentFullDatas.events.length > 0) {
          lastEvent = _.maxBy(equipmentFullDatas.events, 'created_at');
        }

        if (equipmentFullDatas.events && equipmentFullDatas.events.length > 1) {
          equipmentFullDatas.events.forEach((event) => {
            if (event.uuid !== lastEvent.uuid) {
              events.push(event);
            }
          });

          setAllEvents(_.orderBy(events, ['created_at'], ['desc']));
        }
        setLastEvent(lastEvent);

        setLastState(lastState);
      }
      setImagesList(images);
    }
  }, [equipmentFullDatas]);

  const categoryKindName = () => {
    return (
      equipment.category_kind &&
      equipmentReferencial.equipmentCategoryKinds.find((category) => category.id === equipment.category_kind)?.name
    );
  };
  const categoryName = () => {
    return equipment.kind
      ? equipmentReferencial.equipments.find((category) => category.id === equipment.kind)?.name
      : null;
  };

  const EquipmentName = () => {
    if (equipment.title !== null && equipment.title !== undefined && equipment.title !== '') {
      return equipment.title;
    } else {
      return null;
    }
  };

  const handleModal = (type: ModalContentType) => {
    // setSpinnerOpen(false);
    setModalOpen(!modalOpen);
    setModalType(type);
  };

  return (
    <>
      <Grid2 container id="viewer-content-container">
        <Grid2 size={12}>
          <Grid2 container>
            <Grid2 size={'auto'}>
              {/* <ArrowBackIcon sx={{ cursor: 'pointer' }} onClick={() => setUuidEquipmentSelected(null)} /> */}
            </Grid2>
            <Grid2 size={'grow'}></Grid2>
            <Grid2
              size={'auto'}
              onClick={() => {
                handleModal('EditProductInfos');
              }}
            >
              <EditOutlined sx={{ fontSize: '18px', cursor: 'pointer' }} />
            </Grid2>
          </Grid2>
        </Grid2>
        <Grid2 size={12}></Grid2>
        {images && (
          <Grid2 size={12}>
            <div className="hide_scrollbar" style={{ width: '100%', overflowX: 'scroll' }}>
              <div style={{ position: 'relative', display: 'inline-flex' }}>
                {images
                  .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
                  .map((image, index) => (
                    <div
                      className="product_sheet_image"
                      key={index}
                      onClick={() => {
                        setGalleryIndex(index);
                        handleModal('Gallery');
                      }}
                      style={{
                        backgroundImage: `url(${process.env.REACT_APP_BASEURL_MEDIAS}/${image.uri})`
                      }}
                    ></div>
                  ))}
              </div>
            </div>
          </Grid2>
        )}

        <Grid2 size={12} sx={{ pb: '20px' }}>
          <Grid2 container alignItems={'baseline'} justifyContent={'center'}>
            <Grid2 size={'auto'} sx={{ pr: '10px' }}>
              <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M7.5 0.0849609C3.36 0.0849609 0 3.61899 0 7.97342C0 12.3279 3.36 15.8619 7.5 15.8619C11.64 15.8619 15 12.3279 15 7.97342C15 3.61899 11.64 0.0849609 7.5 0.0849609ZM8.25 11.9177H6.75V7.18458H8.25V11.9177ZM8.25 5.60688H6.75V4.02919H8.25V5.60688Z"
                  fill="black"
                />
              </svg>
            </Grid2>
            <Grid2 size={'grow'}>
              <Typography variant="h4">Caractéristiques</Typography>
            </Grid2>
          </Grid2>
        </Grid2>
        <Grid2 container>
          <Grid2 size={12} sx={{ pb: '20px' }}>
            <Typography variant="h5">Classification</Typography>
          </Grid2>
          <Grid2 size={12} sx={{ pb: '5px' }}>
            <span style={{ color: '#929292' }}>Catégorie d’équipement : </span>
            <span>{categoryKindName()}</span>
          </Grid2>
          <Grid2 size={12} sx={{ pb: '5px' }}>
            <span style={{ color: '#929292' }}>Type d’équipement : </span>
            <span>{categoryName()}</span>{' '}
          </Grid2>
          {EquipmentName() && (
            <Grid2 size={12} sx={{ pb: '5px' }}>
              <span style={{ color: '#929292' }}>Nom : </span>
              <span>{EquipmentName()}</span>{' '}
            </Grid2>
          )}
        </Grid2>

        <Grid2 size={12} sx={{ pt: '10px', pb: '10px' }}>
          {(equipmentFullDatas?.data?.model_brand ||
            equipmentFullDatas?.data?.model_id ||
            equipmentFullDatas?.data?.model_serial_number ||
            equipmentFullDatas?.data?.energy_class ||
            equipmentFullDatas?.data?.meter_pmr) && (
            <Grid2 container>
              <Grid2 size={12} sx={{ pb: '20px' }}>
                <Typography variant="h5">Général</Typography>
              </Grid2>
              {equipmentFullDatas?.data?.model_brand && (
                <Grid2 size={12} sx={{ pb: '5px' }}>
                  <span style={{ color: '#929292' }}>Marque : </span>
                  <span>{equipmentFullDatas?.data?.model_brand}</span>
                </Grid2>
              )}
              {equipmentFullDatas?.data?.model_id && (
                <Grid2 size={12} sx={{ pb: '5px' }}>
                  <span style={{ color: '#929292' }}>Modèle : </span>
                  <span>{equipmentFullDatas?.data?.model_id}</span>
                </Grid2>
              )}
              {equipmentFullDatas?.data?.model_serial_number && (
                <Grid2 size={12} sx={{ pb: '5px' }}>
                  <span style={{ color: '#929292' }}>Numéro de série : </span>
                  <span>{equipmentFullDatas?.data?.model_serial_number}</span>
                </Grid2>
              )}
              {equipmentFullDatas?.data?.energy_class && (
                <Grid2 size={12} sx={{ pb: '5px' }}>
                  <span style={{ color: '#929292' }}>Classe énergétique : </span>
                  <span>{equipmentFullDatas?.data?.energy_class}</span>
                </Grid2>
              )}
              {equipmentFullDatas?.data?.meter_pmr && (
                <Grid2 size={12} sx={{ pb: '5px' }}>
                  <span style={{ color: '#929292' }}>Point de livraison : </span>
                  <span>{equipmentFullDatas?.data?.meter_pmr}</span>
                </Grid2>
              )}
            </Grid2>
          )}
        </Grid2>

        <Grid2 size={12} sx={{ pt: '10px', pb: '10px' }}>
          {(equipmentFullDatas?.installed_at ||
            equipmentFullDatas?.maintenance_periodicity ||
            equipmentFullDatas?.warranty_end ||
            equipmentFullDatas?.maintenance_next_date) && (
            <Grid2 container>
              <Grid2 size={12} sx={{ pb: '20px' }}>
                <Typography variant="h5">Dates</Typography>
              </Grid2>
              {equipmentFullDatas?.installed_at && (
                <Grid2 size={12} sx={{ pb: '5px' }}>
                  <span style={{ color: '#929292' }}>Date d'installation : </span>
                  <span>{dayjs(equipmentFullDatas?.installed_at).format('DD/MM/YYYY')}</span>
                </Grid2>
              )}
              {equipmentFullDatas?.maintenance_periodicity && (
                <Grid2 size={12} sx={{ pb: '5px' }}>
                  <span style={{ color: '#929292' }}>Périodicité de maintenance : </span>
                  <span>{periodicityRef[equipmentFullDatas?.maintenance_periodicity]}</span>
                </Grid2>
              )}
              {equipmentFullDatas?.warranty_end && (
                <Grid2 size={12} sx={{ pb: '5px' }}>
                  <span style={{ color: '#929292' }}>Fin de garantie : </span>
                  <span>{dayjs(equipmentFullDatas?.warranty_end).format('DD/MM/YYYY')}</span>
                </Grid2>
              )}
              {equipmentFullDatas?.maintenance_next_date && (
                <Grid2 size={12} sx={{ pb: '5px' }}>
                  <span style={{ color: '#929292' }}>Date de prochaine intervention : </span>
                  <span>{dayjs(equipmentFullDatas?.maintenance_next_date).format('DD/MM/YYYY')}</span>
                </Grid2>
              )}
            </Grid2>
          )}
        </Grid2>

        <Grid2 size={12} sx={{ pt: '10px', pb: '10px' }}>
          {(equipmentFullDatas?.data?.tech_specs || equipmentFullDatas?.data?.more_infos) && (
            <Grid2 container>
              <Grid2 size={12} sx={{ pb: '20px' }}>
                <Typography variant="h5">Informations</Typography>
              </Grid2>
              {equipmentFullDatas?.data?.tech_specs && (
                <Grid2 size={12} sx={{ pb: '20px' }}>
                  <span style={{ color: '#929292' }}>Caractéristiques techniques : </span>
                  <br />
                  <span>{equipmentFullDatas?.data?.tech_specs}</span>
                </Grid2>
              )}
              {equipmentFullDatas?.data?.more_infos && (
                <Grid2 size={12} sx={{ pb: '5px' }}>
                  <span style={{ color: '#929292' }}>Informations complémentaires : </span>
                  <br />
                  <span>{equipmentFullDatas?.data?.more_infos}</span>
                </Grid2>
              )}
            </Grid2>
          )}
        </Grid2>

        <div style={{ width: '100%', height: '1px', borderTop: '1px solid #EEEEEE' }}></div>

        {equipmentFullDatas && equipmentFullDatas.states && equipmentFullDatas.states.length > 0 && (
          <Grid2 size={12}>
            <Grid2 container alignItems={'baseline'} justifyContent={'center'} sx={{ pt: '20px', pb: '20px' }}>
              <Grid2 size={'auto'} sx={{ pr: '10px' }}>
                <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M20 3.57374H11V5.67733H20V3.57374ZM20 11.9881H11V14.0917H20V11.9881ZM3.54 7.78092L0 4.05756L1.41 2.57453L3.53 4.80434L7.77 0.344727L9.18 1.82776L3.54 7.78092ZM3.54 16.1953L0 12.4719L1.41 10.9889L3.53 13.2187L7.77 8.75909L9.18 10.2421L3.54 16.1953Z"
                    fill="black"
                  />
                </svg>
              </Grid2>
              <Grid2 size={'grow'}>
                <Typography variant="h4">Etat</Typography>
              </Grid2>
              {equipmentFullDatas.states.length > 1 && (
                <Grid2
                  size={'auto'}
                  onClick={() => {
                    setShowAllStates(!showAllStates);
                  }}
                  sx={{ cursor: 'pointer', textDecoration: 'underline' }}
                >
                  {!showAllStates ? "Voir l'historique complet" : "Masquer l'historique complet"}
                </Grid2>
              )}
            </Grid2>

            {lastState && (
              <Grid2 size={12}>
                <StateItem state={lastState}></StateItem>
              </Grid2>
            )}
            {stateHistory && stateHistory.length > 0 && showAllStates && (
              <Grid2 size={12}>
                <Grid2 container>
                  {stateHistory.map((state, index) => (
                    <Grid2 size={12} key={index}>
                      <StateItem state={state}></StateItem>
                    </Grid2>
                  ))}
                </Grid2>
              </Grid2>
            )}
          </Grid2>
        )}

        {equipmentFullDatas && equipmentFullDatas.events && equipmentFullDatas.events.length > 0 && (
          <Grid2 size={12}>
            <Grid2 container alignItems={'baseline'} justifyContent={'center'} sx={{ pt: '20px', pb: '20px' }}>
              <Grid2 size={'auto'} sx={{ pr: '10px' }}>
                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M9.11342 2.125L15.861 13.8125C15.9232 13.9202 15.9559 14.0423 15.9559 14.1667C15.9559 14.291 15.9232 14.4131 15.861 14.5208C15.7988 14.6285 15.7094 14.7179 15.6017 14.7801C15.4941 14.8423 15.3719 14.875 15.2476 14.875H1.75242C1.62808 14.875 1.50593 14.8423 1.39826 14.7801C1.29058 14.7179 1.20116 14.6285 1.139 14.5208C1.07683 14.4131 1.0441 14.291 1.0441 14.1667C1.0441 14.0423 1.07683 13.9202 1.139 13.8125L7.88658 2.125C7.94875 2.01733 8.03817 1.92792 8.14585 1.86575C8.25352 1.80359 8.37567 1.77086 8.5 1.77086C8.62433 1.77086 8.74647 1.80359 8.85415 1.86575C8.96183 1.92792 9.05125 2.01733 9.11342 2.125ZM2.97925 13.4583H8.5H14.0208L8.5 3.89583L2.97925 13.4583ZM7.79167 11.3333H9.20833V12.75H7.79167V11.3333ZM7.79167 6.375H9.20833V9.91667H7.79167V6.375Z"
                    fill="#062746"
                  />
                </svg>
              </Grid2>
              <Grid2 size={'grow'}>
                <Typography variant="h4">Tickets</Typography>
              </Grid2>
              {equipmentFullDatas.events.length > 1 && (
                <Grid2
                  size={'auto'}
                  onClick={() => {
                    // setShowAllStates(!showAllStates);
                    setShowAllEvents(!showAllEvents);
                  }}
                  sx={{ cursor: 'pointer', textDecoration: 'underline' }}
                >
                  {!showAllEvents ? 'Voir tous les tickets' : "Masquer l'historique complet"}
                </Grid2>
              )}
            </Grid2>

            {lastEvent && (
              <Grid2 size={12}>
                <EventItem event={lastEvent}></EventItem>
              </Grid2>
            )}

            {allEvents && allEvents.length > 0 && showAllEvents && (
              <Grid2 size={12}>
                <Grid2 container>
                  {allEvents.map((event, index) => (
                    <Grid2 size={12} key={index}>
                      <EventItem event={event}></EventItem>
                    </Grid2>
                  ))}
                </Grid2>
              </Grid2>
            )}
          </Grid2>
        )}

        {equipmentFullDatas && equipmentFullDatas.documents && equipmentFullDatas.documents.length > 0 && (
          <Grid2 size={12}>
            <Grid2 container alignItems={'baseline'} justifyContent={'center'} sx={{ pt: '20px', pb: '20px' }}>
              <Grid2 size={'auto'} sx={{ pr: '10px' }}>
                <svg width="13" height="18" viewBox="0 0 13 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M8.125 0.485596H1.625C0.73125 0.485596 0.00812519 1.24289 0.00812519 2.16847L0 15.6314C0 16.557 0.723125 17.3143 1.61687 17.3143H11.375C12.2688 17.3143 13 16.557 13 15.6314V5.53421L8.125 0.485596ZM9.75 13.9486H3.25V12.2657H9.75V13.9486ZM9.75 10.5828H3.25V8.89996H9.75V10.5828ZM7.3125 6.37565V1.74775L11.7812 6.37565H7.3125Z"
                    fill="black"
                  />
                </svg>
              </Grid2>
              <Grid2 size={'grow'}>
                <Typography variant="h4">Documents</Typography>
              </Grid2>
              {equipmentFullDatas.documents.length > 3 && (
                <Grid2
                  size={'auto'}
                  onClick={() => {
                    // setShowAllStates(!showAllStates);
                    // setShowAllEvents(!showAllEvents);
                    setShowAllDocuments(!showAllDocuments);
                  }}
                  sx={{ cursor: 'pointer', textDecoration: 'underline' }}
                >
                  {!showAllDocuments ? 'Voir tous les documents' : 'Masquer tous les documents'}
                </Grid2>
              )}
            </Grid2>

            {equipmentFullDatas.documents.length > 0 && (
              <Grid2 size={12}>
                <Grid2 container>
                  {equipmentFullDatas.documents.map((document, index) => (
                    <>
                      {!showAllDocuments && index < 3 && (
                        <Grid2 size={12} key={index}>
                          <DocumentItem
                            document={document}
                            setWizzardDocumentOpen={setWizzardDocumentOpen}
                            setSelectedDocument={setSelectedDocument}
                          ></DocumentItem>
                        </Grid2>
                      )}

                      {showAllDocuments && (
                        <Grid2 size={12} key={index}>
                          <DocumentItem
                            document={document}
                            setWizzardDocumentOpen={setWizzardDocumentOpen}
                            setSelectedDocument={setSelectedDocument}
                          ></DocumentItem>
                        </Grid2>
                      )}
                    </>
                  ))}
                </Grid2>
              </Grid2>
            )}
          </Grid2>
        )}
      </Grid2>

      <BasicModal modalOpen={modalOpen} onModalOpen={(open: boolean) => setModalOpen(open)} onModalClose={modalClosed}>
        {handleContentModal(modalType)}
      </BasicModal>

      {wizzardDocumentOpen && (
        <WizzardDocument
          productDatas={productDatas}
          setWizzardDocumentOpen={setWizzardDocumentOpen}
          documentData={selectedDocument}
          setDocumentData={setSelectedDocument}
          updateSelectedDocumentData={updateSelectedDocumentData}
          setSelectedUUIDEquipment={setUuidEquipmentSelected}
          setWizzardEquipmentCreationMode={setWizzardEquipmentCreationMode}
          setWizzardEquipmentOpen={setWizzardEquipmentOpen}
          generateSubtitleEquipment={generateSubtitle}
          equipmentData={equipmentFullDatas}
          equipmentReferential={equipmentReferencial}
        ></WizzardDocument>
      )}
    </>
  );
};
