import FormControl from '@mui/material/FormControl';
import Switch from '@mui/material/Switch';
import { useEffect, useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { EquipmentDetail } from '~/app/components/EquipmentDetail/EquipmentDetail';
import './EquipmentsList.scss';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { api, assignEquipmentToEvent, unassignEquipmentToEvent } from '~/api';
import { renderEquipmentIcon } from '~/utils/references';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import { AppContext } from '~/utils/context/AppContext';

import _ from 'lodash';

export const EquipmentsList = ({
  data,
  assignMode,
  idEvent = null,
  precheckList = null,
  setWizzardEquipmentOpen = null,
  setWizzardEventOpen = null,
  refreshRequired = null,
  idEquipment = null,
  setWizzardEquipmentCreationMode = null,
  setWizzardEventCreationMode = null,
  wizzardEquipmentCreationMode = null,
  setSelectedUUIDEquipment = null,
  setSelectedUUIDEvent = null,
  setEquipmentFamily = null,
  refreshEquipments = null,
  context = 'equipments',
  uuidDocument = null
}) => {
  type TuseParams = {
    id: string;
    idEquipment: string;
    idKind: string;
  };
  const { id } = useParams<TuseParams>();
  const { idKind } = useParams<TuseParams>();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [equipments, setEquipments] = useState(null);
  const [equipmentKinds, setEquipmentKinds] = useState(null);
  const [activeId, setActiveId] = useState();
  const [checked, setChecked] = useState([]);
  const [familyOpened, setFamilyOpened] = useState({});
  const [pathContext, setPathContext] = useState('');

  const { equipmentReferencial } = useContext(AppContext);

  const handleKindClick = (e, key) => {
    if (setEquipmentFamily) setEquipmentFamily(key);
    e.preventDefault();
    if (!assignMode) {
      if (selectedIndex !== key) {
        setSelectedIndex(key);
      } else {
        setSelectedIndex(null);
      }
    } else {
      if (key + 2 < 20) {
        if (familyOpened[key + 2]) {
          setFamilyOpened({ ...familyOpened, [key + 2]: false });
        } else {
          setFamilyOpened({ ...familyOpened, [key + 2]: true });
        }
      } else {
        // Pour la gestion des 'autres'
        if (familyOpened[1]) {
          setFamilyOpened({ ...familyOpened, 1: false });
        } else {
          setFamilyOpened({ ...familyOpened, 1: true });
        }
      }
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const handleClick = (e, eid) => {
    navigate(`/equipments/${eid}`);
    setActiveId(eid);
  };

  const getEquipmentFromProperty = (propertyType) => {
    api[propertyType]
      .getEquipments(id === undefined ? data.uuid_owner : id)
      .then((resp) => {
        setEquipments(resp);
        if (idEquipment !== null && idEquipment !== undefined) {
          handleClick('', idEquipment);
          const curr = resp.filter((item) => item.uuid === idEquipment);
          setSelectedIndex(
            _.find(equipmentReferencial['equipmentCategoryKinds'], {
              id: _.find(equipmentReferencial['equipments'], { id: curr[0].kind }).kinds[0]
            }).id - 2
          );
        }
        if (idKind !== null && idKind !== undefined) {
          setSelectedIndex(idKind);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    const equipmentsAssigned = precheckList
      ? precheckList.map((equipment) => {
          return equipment.uuid;
        })
      : [];
    setChecked(equipmentsAssigned);
  };

  const getEquipments = () => {
    if (pathContext !== '' && pathContext !== 'events' && api[pathContext]) {
      getEquipmentFromProperty(pathContext);
    } else if (pathContext === 'events') {
      const context = data.uuid_owner.split('-')[0] === 'pro' ? 'product' : 'building';
      getEquipmentFromProperty(context);
    }
  };

  const setFamilyChecked = () => {
    const familyOpened = {};

    if (precheckList && equipmentReferencial) {
      precheckList.forEach((equipment) => {
        if (_.find(equipmentReferencial['equipments'], { id: equipment.kind }) !== undefined)
          _.find(equipmentReferencial['equipments'], { id: equipment.kind }).kinds.forEach((category_kind) => {
            if (equipment.kind !== 1) {
              familyOpened[category_kind] = true;
            } else if (equipment.kind === 1 && category_kind === 1) {
              familyOpened[category_kind] = true;
            }
          });
      });
    }
    const equipmentsAssigned = precheckList
      ? precheckList.map((equipment) => {
          return equipment.uuid;
        })
      : [];

    setChecked(equipmentsAssigned);
    setFamilyOpened(familyOpened);
  };

  useEffect(() => {
    if (equipmentReferencial) {
      getEquipments();
    }
  }, [refreshRequired, equipmentReferencial]);

  useEffect(() => {
    const pathname = window.location.pathname.split('/');
    if (pathname[1] === 'product') {
      setPathContext('product');
    } else if (pathname[1] === 'building') {
      setPathContext('building');
    } else if (pathname[1] === 'events') {
      setPathContext('events');
    }
  }, []);

  useEffect(() => {
    if (pathContext !== '') {
      if (equipmentReferencial) getEquipments();
    }
  }, [pathContext]);

  useEffect(() => {
    if (equipmentReferencial) getEquipments();
  }, [data]);

  useEffect(() => {
    setFamilyChecked();
  }, [precheckList]);

  useEffect(() => {
    setFamilyChecked();
  }, [equipments]);

  useEffect(() => {
    if (equipmentReferencial) {
      const KindList = [];
      for (let i = 0; i < equipmentReferencial['equipmentCategoryKinds'].length; i++) {
        KindList.push(equipmentReferencial['equipmentCategoryKinds'][i].id);
      }

      KindList.shift();
      KindList.push(equipmentReferencial['equipmentCategoryKinds'][0].id);

      setEquipmentKinds(KindList);
    }
  }, [id, equipmentReferencial]);

  const equipCatsWithItems = (key) => {
    if (!equipmentReferencial || !equipments || !key) {
      return [];
    }
    const tmpArray = [];
    if (equipments) {
      equipments.forEach((equipment) => {
        if (_.find(equipmentReferencial['equipments'], { id: equipment.kind }) !== undefined) {
          _.find(equipmentReferencial['equipments'], { id: equipment.kind }).kinds.forEach((category_kind) => {
            if (_.find(equipmentReferencial['equipmentCategoryKinds'], { id: category_kind }).id === key) {
              if (equipment.kind !== 1) {
                tmpArray.push(equipment);
              } else if (equipment.kind === 1 && category_kind === 1) {
                tmpArray.push(equipment);
              }
            }
          });
        }
      });
    }
    return tmpArray;
  };

  const handleToggle = (value, element) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
    if (checked.indexOf(value) === -1) {
      if (context === 'equipments') {
        api.events.equipment.assign(idEvent, value).catch((err) => {
          console.log(err);
        });
      } else if (context === 'documents') {
        api.files.equipmentLink.create(idEvent.uuid, value);
      }
    } else {
      if (context === 'equipments') {
        api.events.equipment.unassign(idEvent, value);
      } else if (context === 'documents') {
        api.files.equipmentLink.delete(idEvent.uuid, value);
      }
    }
  };

  const GetEquipmentKind = (kind) => {
    if (equipmentReferencial) {
      return _.find(equipmentReferencial['equipmentCategoryKinds'], { id: kind }).name;
    } else {
      return '';
    }
  };

  const getRoomName = (equipment) => {
    return equipment.room !== '' && equipment.room !== null && equipment.room !== undefined
      ? ' - ' + equipment.room.name
      : '';
  };

  const buildEquipmentsList = () => {
    if (equipmentKinds) {
      const equipmentsList = equipmentKinds.map((equipmentkind, index) =>
        !assignMode || (equipCatsWithItems(equipmentkind) && equipCatsWithItems(equipmentkind).length > 0) ? (
          <div key={index}>
            <ListItemButton
              key={index}
              onClick={(e) => handleKindClick(e, index)}
              selected={selectedIndex === index}
              style={
                assignMode && familyOpened
                  ? {}
                  : selectedIndex === index
                    ? { color: '#062746', background: '#F1F8FF' }
                    : {}
              }
              autoFocus={selectedIndex === index}
              disabled={equipCatsWithItems(equipmentkind).length > 0 ? false : true}
            >
              <ListItemIcon>{renderEquipmentIcon(equipmentkind)}</ListItemIcon>
              <ListItemText primary={GetEquipmentKind(equipmentkind)} />
              {assignMode && familyOpened ? (
                familyOpened[equipmentkind] ? (
                  <ExpandLess />
                ) : (
                  <ExpandMore />
                )
              ) : selectedIndex === index ? (
                <ExpandLess />
              ) : (
                <ExpandMore />
              )}
            </ListItemButton>
            <Collapse
              in={assignMode && familyOpened ? familyOpened[equipmentkind] : selectedIndex === index}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                {equipments &&
                  equipCatsWithItems(equipmentkind).map((equip, index) => (
                    <ListItem
                      key={index}
                      secondaryAction={
                        assignMode ? (
                          <Switch
                            color={'secondary'}
                            edge="end"
                            onChange={handleToggle(equip.uuid, this)}
                            checked={checked.indexOf(equip.uuid) !== -1}
                            inputProps={{
                              'aria-labelledby':
                                equip.title !== '' && equip.title !== null && equip.title !== undefined
                                  ? _.find(equipmentReferencial['equipments'], { id: equip.kind }).name +
                                    ' : ' +
                                    equip.title +
                                    getRoomName(equip)
                                  : _.find(equipmentReferencial['equipments'], { id: equip.kind }).name +
                                    getRoomName(equip)
                            }}
                          />
                        ) : null
                      }
                      disablePadding
                    >
                      {!assignMode && (
                        <ListItemButton
                          key={index}
                          onClick={(e) => {
                            handleClick(e, equip.uuid);
                          }}
                          selected={equip.uuid === activeId}
                          style={activeId === equip.uuid ? { color: '#062746', background: '#F1F8FF' } : {}}
                          autoFocus={activeId === equip.uuid ? true : false}
                        >
                          <ListItemText
                            primary={
                              equip.title !== '' && equip.title !== null && equip.title !== undefined
                                ? _.find(equipmentReferencial['equipments'], { id: equip.kind }).name +
                                  ' : ' +
                                  equip.title +
                                  getRoomName(equip)
                                : _.find(equipmentReferencial['equipments'], { id: equip.kind }).name +
                                  getRoomName(equip)
                            }
                          />
                        </ListItemButton>
                      )}
                      {assignMode && (
                        <>
                          <ListItem
                            key={index}
                            style={activeId === equip.uuid ? { color: '#062746', background: '#F1F8FF' } : {}}
                          >
                            <ListItemText
                              style={{ cursor: 'pointer' }}
                              onClick={(e) => {
                                navigate(`/equipments/${equip.uuid}`);
                              }}
                              primary={
                                equip.title !== '' && equip.title !== null && equip.title !== undefined
                                  ? _.find(equipmentReferencial['equipments'], { id: equip.kind }).name +
                                    ' : ' +
                                    equip.title +
                                    getRoomName(equip)
                                  : _.find(equipmentReferencial['equipments'], { id: equip.kind }).name +
                                    getRoomName(equip)
                              }
                            />
                          </ListItem>
                          {/* <CreateOutlinedIcon
                            onClick={() => {
                              setSelectedUUIDEquipment(equip.uuid);
                              setWizzardEquipmentCreationMode(false);
                              setWizzardEquipmentOpen(true);
                            }}
                            sx={{
                              fontSize: '16px',
                              color: 'primary.main',
                              cursor: 'pointer',
                              mr: 1,
                              paddingRight: '60px'
                            }}
                          ></CreateOutlinedIcon> */}
                        </>
                      )}
                    </ListItem>
                  ))}
              </List>
            </Collapse>
            <Divider />
          </div>
        ) : null
      );
      return (
        <div>
          {equipmentsList}
          {assignMode && (
            <ListItemButton
              className="EquipmentSublist__add"
              sx={{ pl: 6 }}
              style={{ color: '#062746', background: '#FFF' }}
              component="a"
              onClick={() => {
                setWizzardEquipmentCreationMode(true);
                setWizzardEquipmentOpen(true);
              }}
            >
              <ListItemText className="EquipmentsList__add">
                <AddOutlinedIcon /> Ajouter un équipement
              </ListItemText>
            </ListItemButton>
          )}
        </div>
      );
    }
  };

  return (
    <div className="hide_scrollbar" style={{ height: 'calc(100%)', overflow: 'hidden' }}>
      <FormControl
        className={assignMode ? 'EquipmentsList__formWithinEvent' : 'EquipmentsList__form'}
        component="fieldset"
        style={{ minWidth: 'auto', overflow: 'scroll' }}
      >
        <List sx={{ pt: 0 }}>
          <Divider />
          {equipmentKinds && buildEquipmentsList()}
        </List>
      </FormControl>
      {activeId && idEquipment && (
        <EquipmentDetail
          idEquipment={idEquipment}
          activeId={activeId}
          setWizzardEquipmentOpen={setWizzardEquipmentOpen}
          setWizzardEventOpen={setWizzardEventOpen}
          setWizzardEquipmentCreationMode={setWizzardEquipmentCreationMode}
          setWizzardEventCreationMode={setWizzardEventCreationMode}
          refreshRequired={refreshRequired}
          setSelectedUUIDEvent={setSelectedUUIDEvent}
          refreshEquipments={refreshEquipments}
        />
      )}
    </div>
  );
};
