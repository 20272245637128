import { Box, Typography, Chip, Link } from '@mui/material';
import { EditOutlined } from '@mui/icons-material';
import { AppContext } from '~/utils/context/AppContext';
import { useContext, useState } from 'react';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import EditOffOutlinedIcon from '@mui/icons-material/EditOffOutlined';
import ToggleOnOutlinedIcon from '@mui/icons-material/ToggleOnOutlined';
import SyncProblemOutlinedIcon from '@mui/icons-material/SyncProblemOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { LeaseAddUpdate } from './LeaseAddUpdate';

const LeaseLine = ({ productDatas, leaseData, setPropertyData }) => {
  const { referencial } = useContext(AppContext);
  const getStatusChip = () => {
    let icon = null;
    let label = null;
    let color = null;
    let backgroundColor = null;
    switch (leaseData.status) {
      case 0:
        label = 'Inconnu';
        icon = <HelpOutlineOutlinedIcon style={{ color: '#f5a623' }} />;
        color = '#f5a623';
        backgroundColor = '#fff3e0';
        break;
      case 1:
        label = 'Brouillon';
        icon = <EditOffOutlinedIcon style={{ color: '#f5a623' }} />;
        color = '#f5a623';
        backgroundColor = '#fff3e0';
        break;
      case 2:
        label = 'Actif';
        icon = <ToggleOnOutlinedIcon style={{ color: '#4caf50' }} />;
        color = '#4caf50';
        backgroundColor = '#e8f5e9';
        break;
      case 3:
        label = 'Non renouvellé';
        icon = <SyncProblemOutlinedIcon style={{ color: '#4caf50' }} />;
        color = '#4caf50';
        backgroundColor = '#e8f5e9';
        break;
      case 4:
        label = 'Clôturé';
        icon = <CloseIcon style={{ color: '#d32f2f' }} />; // Icône rouge
        color = '#d32f2f'; // Couleur rouge
        backgroundColor = '#ffcdd2'; // Fond rouge clair
        break;
      default:
        label = 'Inconnu';
        icon = <HelpOutlineOutlinedIcon style={{ color: '#f5a623' }} />;
        color = '#f5a623';
        backgroundColor = '#fff3e0';
        break;
    }

    return <Chip label={label} size="small" icon={icon} style={{ backgroundColor: backgroundColor, color: color }} />;
  };

  const [openEditLease, setOpenEditLease] = useState(false);

  const hanldeOpenEditLease = () => {
    setOpenEditLease(!openEditLease);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '8px 0'
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>{getStatusChip()}</Box>

      <Box sx={{ flex: 3, paddingLeft: '16px' }}>
        <Typography variant="body2" color="textSecondary">
          {new Date(leaseData.start_at).toLocaleDateString()} - {new Date(leaseData.end_at).toLocaleDateString()}
        </Typography>
        <Typography variant="body1" style={{ fontWeight: 500 }}>
          {referencial['lease.kind'].find((leaseKind) => leaseKind.id === leaseData.kind).name} - {leaseData.duration} -{' '}
          {leaseData.contacts?.map((tenant) => tenant.name).join(' - ')}
        </Typography>
      </Box>

      <Box sx={{ flex: 0 }}>
        <Link className="Product__link" onClick={hanldeOpenEditLease} color="primary">
          <EditOutlined />
        </Link>
      </Box>

      {openEditLease && (
        <LeaseAddUpdate
          productDatas={productDatas}
          setLeaseAddOpen={hanldeOpenEditLease}
          setPropertyData={setPropertyData}
          leaseData={leaseData}
        />
      )}
    </Box>
  );
};

export default LeaseLine;
