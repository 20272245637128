import { Typography } from '@mui/material';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import Link from '@mui/material/Link';
import './Breadcrumb.scss';

type BreadcrumbItem = {
  label: string;
  href: string;
};

interface BreadcrumbProps {
  data: BreadcrumbItem[];
  last: string;
  setSpinnerOpen?: any;
}

export const Breadcrumb = ({ data, last, setSpinnerOpen }: BreadcrumbProps) => {
  return (
    <div className="Breadcrumb">
      {data.map((item: BreadcrumbItem, key: number) => (
        <Link key={key} component={RouterLink} to={item.href} variant="body2">
          {item.label}
        </Link>
      ))}
      <Typography variant="body1" component="span" sx={{ color: 'secondary.main' }}>
        {last}
      </Typography>
    </div>
  );
};

export default Breadcrumb;
