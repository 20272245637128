import { Dialog, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { addTag, api, deleteEvent, getEvent, patchEvent, patchTag } from '~/api';
import { useDialog } from '~/utils/useDialog';
import BasicModal from '~/app/components/BasicModal/BasicModal';
import { ConfirmationDialog } from '~/app/components/ConfirmationDialog/ConfirmationDialog';
import MatterportViewer from '~/app/components/MatterportViewer/MatterportViewer';
// Steps
import GeneralInfos from '~/app/components/WizzardBlocks/GeneralInfosEvent';
import DocumentUploader from '~/app/components/WizzardBlocks/DocumentUploader';
import LocalizationChoice from '~/app/components/WizzardBlocks/LocalizationChoice';
import MoreInfos from '~/app/components/WizzardBlocks/MoreInfos';
import RecapInfos from '~/app/components/WizzardBlocks/RecapInfos';
import Validate from '~/app/components/WizzardBlocks/Validate';
import '~/app/components/WizzardBlocks/Wizzards.scss';
import { ProductTitle } from '../ProductTitle/ProductTitle';
import { EquipmentSubTitle } from '../EquipmentSubTitle/EquipmentSubTitle';
import React from 'react';

const steps = ['generalInfos', 'images', 'moreInfos', 'matterport', 'validate', 'localizationChoice', 'localization'];
let interval = null;

export const WizzardEvent = ({
  setWizzardEventOpen,
  productDatas,
  refreshEvents,
  wizzardEventCreationMode,
  selectedUUIDEvent = null,
  saveChanges = null,
  wizzardOpen,
  assignEventToEquipment = null,
  generateSubtitleEquipment = null,
  equipmentData = null,
  equipmentReferential = null
}) => {
  const { id, idEvent } = useParams();
  const [modalOpen, setModalOpen] = useState<boolean>(true);
  const [deleteDialogOpen, handleOpenDeleteDialog, handleCloseDeleteDialog] = useDialog();
  const [hasMatterportScan, setHasMatterportScan] = useState(false);
  const [hasDatasFilled, setHasDatasFilled] = useState(false);
  const [currentStep, setCurrentStep] = useState<string>('generalInfos');
  const [currentEventUUID, setCurrentEventUUID] = useState<string>('');
  const [eventDatas, setEventDatas] = useState<any>({ uuid_owner: productDatas.uuid, is_draft: true });
  const [eventDatasOriginal, setEventDatasOriginal] = useState<any>({});
  const [moreInfosDatas, setMoreInfosDatas] = useState<any>({ data: {} });
  const [tags, setTags] = useState<any>([]);
  const [localizationTagUUID, setLocalizationTagUUID] = useState<string>('');
  const [stepToReturn, setStepToReturn] = useState<string>('');
  const [hasBeenValidated, setHasBeenValidated] = useState<boolean>(false);
  const [relocalization, setRelocalization] = useState<boolean>(false);
  const [localizationActivated, setLocalizationActivated] = useState<boolean>(true);
  const [deleteEventWarningMode, setDeleteEventWarningMode] = useState<boolean>(false);
  const [backUpTags, setBackUpTags] = useState<any>([]);
  const [warningMessage, setWarningMessage] = useState<string>('');
  const [reinitialize, setReinitialize] = useState<boolean>(false);
  const [pathContext, setPathContext] = useState('');
  const [zoomImgUrl, setZoomImgUrl] = useState('');
  const [zoomImageOpened, setZoomImageOpened] = useState(false);
  const [buildingData, setBuildingData] = useState<any>(null);

  const [mainOffset, setMainOffset] = useState(0);

  const headerRef = React.useRef(null);
  const headerEquiRef = React.useRef(null);

  function localizationTagCallback(tag, sid, uuidEvent) {
    if (tag.anchorPosition.x !== 0 && tag.anchorPosition.y !== 0 && tag.anchorPosition.z !== 0) {
      const tagPayload = {
        uuid_owner: uuidEvent,
        owner_kind: 'event',
        position: {
          x: tag.anchorPosition.x,
          y: tag.anchorPosition.y,
          z: tag.anchorPosition.z,
          steam_x: tag.stemVector.x,
          steam_y: tag.stemVector.y,
          steam_z: tag.stemVector.z
        },
        sid: sid,
        icone_id: tag.icone_id,
        description: tag.description,
        is_principal: true
      };

      if (localizationTagUUID === null || localizationTagUUID === undefined || localizationTagUUID === '') {
        addTag([tagPayload])
          .then((resp) => {
            setLocalizationTagUUID(resp[0].uuid);
          })
          .catch((reason) => {});
      } else {
        patchTag(localizationTagUUID, { position: tagPayload.position })
          .then((resp) => {})
          .catch((reason) => {});
      }
      setTags((oldArray) => [...oldArray, JSON.parse(JSON.stringify(tagPayload))]);
    }
  }

  const modifyInfos = () => {
    setCurrentStep('moreInfos');
    setStepToReturn('localization');
  };

  const modifyPhoto = () => {
    setCurrentStep('images');
    setStepToReturn('localization');
  };

  const modifyDocument = () => {
    setCurrentStep('documents');
    setStepToReturn('localization');
  };

  const modifyBasicInfos = () => {
    setCurrentStep('generalInfos');
    setStepToReturn('localization');
  };

  const modifyLocalization = () => {
    setStepToReturn('');
    setRelocalization(true);
  };

  const suppressEvent = () => {
    setDeleteEventWarningMode(true);

    handleOpenDeleteDialog();
  };

  const removeTag = (sid) => {
    const backUpTags = JSON.parse(JSON.stringify(tags));
    setBackUpTags(backUpTags);
    const newTags = tags.filter((tag) => tag.uuid !== sid);
    setTags(JSON.parse(JSON.stringify(newTags)));
  };

  const cancelLocalization = () => {
    if (!wizzardEventCreationMode) {
      setTags(backUpTags);
      setLocalizationActivated(false);
      setRelocalization(false);
      setStepToReturn('');
      setBackUpTags([]);
    } else {
      setCurrentStep('localizationChoice');
    }
  };

  const deleteDraftEvent = (): void => {
    handleCloseDeleteDialog();
    setModalOpen(false);

    if (deleteEventWarningMode) {
      deleteEvent(currentEventUUID).then((resp) => {});
      refreshEvents();
      setModalOpen(false);
      setWizzardEventOpen(false);
      return;
    }
    if (currentEventUUID !== '') {
      deleteEvent(currentEventUUID)
        .then((resp) => {})
        .catch((reason) => {});
    }
  };

  const modalClosed = () => {
    setWizzardEventOpen(false);
  };

  const reinitialiszation = (callback = null) => {
    setEventDatas({ uuid_owner: productDatas.uuid });
    setHasDatasFilled(false);
    setMoreInfosDatas({ data: {} });
    setLocalizationTagUUID('');
    setCurrentEventUUID('');
    setHasBeenValidated(true);
    const newTags = [];
    setTags(newTags);
    refreshEvents();
    setStepToReturn('');
    if (callback) {
      callback();
    }
    setReinitialize(false);
    setReinitialize(true);
  };

  useEffect(() => {}, [wizzardOpen]);

  const validateNewEvent = (callback = null) => {
    if (eventDatas.uuid) {
      saveChanges(eventDatas);
      if (callback) {
        refreshEvents();
        callback();
      }
    } else {
      patchEvent(currentEventUUID, { is_draft: false, ...moreInfosDatas }).then((resp) => {
        refreshEvents();

        if (assignEventToEquipment !== null) {
          assignEventToEquipment(currentEventUUID);
        }

        if (callback) {
          refreshEvents();
          callback();
        }
      });
    }
  };

  const handleConfirm = () => {
    if (hasDatasFilled && wizzardEventCreationMode) {
      handleOpenDeleteDialog();
    } else {
      if (!wizzardEventCreationMode && JSON.stringify(eventDatas) !== JSON.stringify(eventDatasOriginal)) {
        //
        setWarningMessage(
          'Vous avez des modifications non sauvegardées. Voulez-vous fermer la fenêtre et les abandonner ?'
        );
        handleOpenDeleteDialog();
      } else {
        setModalOpen(false);
      }
    }
  };

  const handleNextStep = (step, choice = false, goBack = false) => {
    console.log(step, choice, goBack);
    // EDITION MODE
    if (!wizzardEventCreationMode) {
      if (step === 'localization') {
        // refreshEvents();
        setModalOpen(false);

        setWizzardEventOpen(false);
      } else {
        setCurrentStep('localization');
      }
      // refreshEvents();
      return;
    }

    // RETURN TO STEP
    if (stepToReturn !== '') {
      setCurrentStep(stepToReturn);
      setStepToReturn('');
      return;
    }

    // HAS MATTERSCAN
    if (hasMatterportScan) {
      // General infos
      if (step === 'generalInfos') {
        setCurrentStep('images');
      }

      // Images
      else if (step === 'images' && goBack) {
        setCurrentStep('generalInfos');
        return;
      } else if (step === 'images') {
        setCurrentStep('documents');
      }

      // Doucments
      else if (step === 'documents' && goBack) {
        setCurrentStep('generalInfos');
        return;
      } else if (step === 'documents') {
        setCurrentStep('localizationChoice');
      }

      // More infos
      else if (step === 'moreInfos' && goBack) {
        setCurrentStep('images');
        return;
      } else if (step === 'moreInfos') {
        setCurrentStep('localizationChoice');
      }

      // Localization choice
      else if (step === 'localizationChoice' && !choice) {
        validateNewEvent(() => {
          setCurrentStep('validate');
          reinitialiszation();
        });
        // setCurrentStep('validate');
      } else if (step === 'localizationChoice' && choice) {
        setCurrentStep('localization');
      }

      // Localization
      else if (step === 'localization') {
        validateNewEvent(() => {
          setCurrentStep('validate');
          reinitialiszation();
        });
      }
    }

    // NO MATTERSCAN
    else {
      // General infos
      if (step === 'generalInfos') {
        setCurrentStep('images');
      }

      // Images
      else if (step === 'images' && !choice) {
        validateNewEvent(() => {
          reinitialiszation(() => {
            setCurrentStep('validate');
          });
        });
      } else if (step === 'images' && choice) {
        setCurrentStep('moreInfos');
      }

      // more infos
      else if (step === 'moreInfos') {
        validateNewEvent(() => {
          setCurrentStep('validate');
          reinitialiszation();
        });
      }
    }
  };

  useEffect(() => {
    console.log(productDatas);
    if (
      productDatas.uuid.includes('pro') &&
      productDatas.uuid_building !== null &&
      productDatas.uuid_building !== undefined &&
      productDatas.uuid_building !== ''
    ) {
      api.building.get(productDatas.uuid_building).then((res) => {
        setBuildingData(res);
      });
    }
  }, [productDatas]);

  useEffect(() => {
    if (productDatas !== null && wizzardEventCreationMode !== null && wizzardEventCreationMode !== undefined) {
      if (
        productDatas.matterport_scan !== null &&
        productDatas.matterport_scan !== undefined &&
        productDatas.matterport_scan !== '' &&
        productDatas.matterport_scan.model_id !== null &&
        productDatas.matterport_scan.model_id !== undefined &&
        productDatas.matterport_scan.model_id !== ''
      ) {
        setHasMatterportScan(true);
      } else {
        setHasMatterportScan(false);
      }

      const pathname = window.location.pathname.split('/');
      if (pathname[1] === 'product' || (pathname[1] === 'events' && productDatas.uuid.split('-')[0] === 'pro')) {
        setPathContext('product');
      } else if (
        pathname[1] === 'building' ||
        (pathname[1] === 'events' && productDatas.uuid.split('-')[0] === 'bui')
      ) {
        setPathContext('building');
      }

      if (!wizzardEventCreationMode) {
        const uuidEvent = selectedUUIDEvent ? selectedUUIDEvent : idEvent;

        if (uuidEvent !== null && uuidEvent !== undefined && uuidEvent !== '') {
          getEvent(uuidEvent).then((resp) => {
            console.log('resp : ', resp);
            console.log(resp.equipments);

            const allTags = [...resp.tags];
            const promises = [];

            resp.equipments?.map((equipment) => {
              promises.push(api.equipments.get(equipment.uuid));
            });

            Promise.all(promises).then((equipments) => {
              console.log('equipments : ', equipments);
              equipments.map((equipment) => {
                console.log(equipment);
                equipment.tags.map((tag) => {
                  allTags.push(tag);
                });
                equipment.states?.map((state) => {
                  state.tags?.map((tag) => {
                    allTags.push(tag);
                  });
                });
              });

              setTags(allTags);

              console.log('allTags : ', allTags);
            });

            // setTags(resp.tags);
            setCurrentEventUUID(uuidEvent);

            const infosDatas = JSON.parse(JSON.stringify(resp));

            // delete infosDatas.desc;
            delete infosDatas.documents;
            delete infosDatas.events;
            delete infosDatas.image_thumbnail;
            delete infosDatas.images;
            delete infosDatas.tags;
            setMoreInfosDatas(infosDatas);
            setEventDatas(infosDatas);
            setEventDatasOriginal(infosDatas);
            setCurrentStep('localization');
            setLocalizationActivated(false);
            setRelocalization(false);
            setLocalizationTagUUID(resp.tags && resp.tags.length > 0 ? resp.tags[0].uuid : null);
          });
        }
      }
    }
  }, []);

  useEffect(() => {
    if (
      (eventDatas.kind !== null && eventDatas.kind !== undefined && eventDatas.kind !== '') ||
      (eventDatas.title !== null && eventDatas.title !== undefined && eventDatas.title !== '')
    ) {
      setHasDatasFilled(true);
      refreshEvents();
    }
  }, [eventDatas]);

  useEffect(() => {
    let offset = 0;
    if (headerRef.current) {
      offset = headerRef.current.clientHeight;
    }
    if (headerEquiRef.current) {
      offset += headerEquiRef.current.clientHeight;
    }
    offset += 16;
    setMainOffset(offset);
  });

  if (!productDatas || !productDatas.uuid || (!wizzardEventCreationMode && !eventDatas)) return null;

  return (
    <>
      <div className="Wizzards__container">
        <div>
          <BasicModal
            modalOpen={modalOpen}
            onModalOpen={(open: boolean) => setModalOpen(open)}
            onModalClose={modalClosed}
            confirmClose={true}
            handleConfirm={handleConfirm}
          >
            <Grid container spacing={0}>
              <Grid container>
                <div style={{ backgroundColor: '#fff7f3', width: '100vw' }}>
                  <Grid item xs={12} ref={headerRef}>
                    <ProductTitle data={productDatas} buildingData={buildingData} />
                  </Grid>
                </div>
                {generateSubtitleEquipment && equipmentData && equipmentReferential && (
                  <Grid item xs={12} ref={headerEquiRef}>
                    <EquipmentSubTitle
                      generateSubtitleEquipment={generateSubtitleEquipment}
                      equipmentData={equipmentData}
                      equipmentReferencial={equipmentReferential}
                    />
                  </Grid>
                )}
              </Grid>

              {!wizzardEventCreationMode && !eventDatas?.uuid ? (
                <div></div>
              ) : (
                <>
                  {mainOffset && (
                    <Grid item xs={12} sx={{ height: `calc(100vh - ${mainOffset}px)` }}>
                      <div className="Wizzards__content">
                        {currentStep === 'generalInfos' && (
                          <GeneralInfos
                            elementDatas={eventDatas}
                            setElementDatas={setEventDatas}
                            nextStepCallBack={handleNextStep}
                            currentElementUUID={currentEventUUID}
                            setCurrentElementUUID={setCurrentEventUUID}
                          ></GeneralInfos>
                        )}{' '}
                        {currentStep === 'images' && (
                          <DocumentUploader
                            nextStepCallBack={handleNextStep}
                            currentElementUUID={currentEventUUID}
                            hasMatterportScan={hasMatterportScan}
                            showCheckBox={stepToReturn === '' ? true : false}
                            wizzardCreationMode={wizzardEventCreationMode}
                            stepToReturn={stepToReturn}
                            elementType="event"
                            filesType="images"
                          ></DocumentUploader>
                        )}
                        {currentStep === 'documents' && (
                          <DocumentUploader
                            nextStepCallBack={handleNextStep}
                            currentElementUUID={currentEventUUID}
                            hasMatterportScan={hasMatterportScan}
                            showCheckBox={stepToReturn === '' ? true : false}
                            wizzardCreationMode={wizzardEventCreationMode}
                            stepToReturn={stepToReturn}
                            elementType="event"
                            filesType="documents"
                          ></DocumentUploader>
                        )}
                        {currentStep === 'moreInfos' && (
                          <MoreInfos
                            elementDatas={eventDatas}
                            nextStepCallBack={handleNextStep}
                            hasNextStep={hasMatterportScan && stepToReturn === '' ? true : false}
                            validateNewElement={validateNewEvent}
                            moreInfosDatas={moreInfosDatas}
                            setMoreInfosDatas={setMoreInfosDatas}
                            wizzardCreationMode={wizzardEventCreationMode}
                            stepToReturn={stepToReturn}
                          ></MoreInfos>
                        )}
                        {currentStep === 'localizationChoice' && (
                          <LocalizationChoice nextStepCallBack={handleNextStep}></LocalizationChoice>
                        )}
                        {currentStep === 'localization' && <div></div>}
                        {currentStep === 'validate' && (
                          <Validate
                            setCurrentStep={setCurrentStep}
                            setModalOpen={setModalOpen}
                            elementType={'event'}
                          ></Validate>
                        )}
                        <div style={{ height: '100%', display: currentStep === 'localization' ? 'block' : 'none' }}>
                          <Grid container spacing={0} sx={{ height: '100%', overflow: 'hidden' }}>
                            <Grid
                              item
                              xs={tags.length > 0 || !wizzardEventCreationMode ? 8 : 12}
                              style={{ backgroundColor: '#222' }}
                            >
                              {hasMatterportScan && (
                                <MatterportViewer
                                  productId={productDatas.uuid}
                                  modelId={productDatas.matterport_scan.model_id}
                                  mode={'localize'}
                                  uuid_event={null}
                                  placedTagCallback={localizationTagCallback}
                                  mpHelp={1}
                                  mpDh={0}
                                  mpHl={0}
                                  mpGt={0}
                                  mpHr={0}
                                  mpMls={0}
                                  mpF={1}
                                  mpNozoom={0}
                                  mpWh={0}
                                  mpLang="fr"
                                  mpSearch={0}
                                  tags={tags}
                                  removeTag={removeTag}
                                  typeEquipment={eventDatas?.kind}
                                  cancelLocalization={cancelLocalization}
                                  hasBeenValidated={hasBeenValidated}
                                  modifyLocalization={relocalization}
                                  setModifyLocalization={setRelocalization}
                                  localizationActivated={localizationActivated}
                                  wizzardCreationMode={wizzardEventCreationMode}
                                  currentElementUUID={currentEventUUID}
                                  reinitialize={reinitialize}
                                  matterport_scan_areas={productDatas.data.matterport_scan_areas}
                                  setZoomImageOpened={setZoomImageOpened}
                                  setZoomImgUrl={setZoomImgUrl}
                                  mainOffset={mainOffset}
                                ></MatterportViewer>
                              )}
                              {!hasMatterportScan && (
                                <div
                                  style={{
                                    textAlign: 'center',
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                  }}
                                >
                                  <div
                                    style={{
                                      border: '1px solid grey',
                                      margin: 'auto',
                                      borderRadius: '10px',
                                      width: '90%',
                                      height: '90%',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center'
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontFamily: 'Roboto',
                                        fontStyle: 'normal',
                                        fontWeight: '500',
                                        fontSize: '36px',
                                        lineHeight: '42px',
                                        textAlign: 'center',

                                        color: '#929292'
                                      }}
                                    >
                                      Vous ne disposez pas d’un jumeau numérique pour ce bien
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Grid>
                            {(tags.length > 0 || !wizzardEventCreationMode) && (
                              <Grid item xs={4}>
                                <RecapInfos
                                  currentElementUUID={currentEventUUID}
                                  moreInfosDatas={moreInfosDatas}
                                  validateNewElement={validateNewEvent}
                                  modifyBasicInfos={modifyBasicInfos}
                                  modifyInfos={modifyInfos}
                                  modifyPhoto={modifyPhoto}
                                  modifyDocument={modifyDocument}
                                  currentStep={currentStep}
                                  modifyLocalization={modifyLocalization}
                                  wizzardCreationMode={wizzardEventCreationMode}
                                  suppressElement={suppressEvent}
                                  hasMatterportScan={hasMatterportScan}
                                  nextStepCallBack={handleNextStep}
                                  elementType="event"
                                  elementDatas={eventDatas}
                                  mainOffset={mainOffset}
                                ></RecapInfos>
                              </Grid>
                            )}
                          </Grid>
                        </div>
                      </div>
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          </BasicModal>
          <ConfirmationDialog
            message={
              warningMessage !== ''
                ? warningMessage
                : deleteEventWarningMode
                  ? 'Etes vous certain de vouloir supprimer définitivement ce ticket ?'
                  : 'Voulez-vous abandonner la création de ce ticket ?'
            }
            onCancel={handleCloseDeleteDialog}
            onConfirm={
              warningMessage !== ''
                ? () => {
                    setWarningMessage('');
                    setModalOpen(false);
                  }
                : deleteDraftEvent
            }
            open={deleteDialogOpen}
            title={deleteEventWarningMode ? 'Suppression' : 'Abandon'}
            variant={deleteEventWarningMode ? 'error' : 'warning'}
            cancelText="Non"
            confirmText="Oui"
          />
          <Dialog
            open={zoomImageOpened}
            onClose={() => {
              setZoomImageOpened(false);
              setZoomImgUrl('');
            }}
          >
            <div style={{ padding: '10px', paddingBottom: '7px' }}>
              <img src={zoomImgUrl} alt={zoomImgUrl} style={{ width: '100%', height: '50%', objectFit: 'contain' }} />
            </div>
          </Dialog>
        </div>
      </div>
    </>
  );
};

export default WizzardEvent;
