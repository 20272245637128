import { Grid2, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { AppContext } from '~/utils/context/AppContext';
import { characteristicsIcons } from '~/utils/references';
import { appTheme } from '~/utils/theme';

export const CharacteristicsList = ({ productDatas, isMobile = false }) => {
  const { referencial } = React.useContext(AppContext);
  const [characteristicsList, setCharacteristicsList] = React.useState([]);
  const [groupedCharacteristics, setGroupedCharacteristics] = React.useState({});
  const [hasMore, setHasMore] = React.useState(false);
  const [showMore, setShowMore] = React.useState(true);

  const getCategoryName = (categoryId: number) => {
    const categoryName =
      referencial['product.characteristics-category']?.find((category) => category.id === categoryId)?.name ||
      'Sans catégorie';
    return categoryName;
  };

  useEffect(() => {
    if (productDatas && productDatas.characteristics && productDatas.characteristics.length > 0) {
      setCharacteristicsList(productDatas.characteristics);
    }
  }, [productDatas]);

  useEffect(() => {
    if (characteristicsList.length === 0) return;
    const characteristics = referencial['product.characteristics'].filter((characteristic) =>
      characteristicsList.some((characteristicId) => characteristic.id === characteristicId)
    );

    // Regroup characteristics by category ID
    const groupedCharacteristics = characteristics?.reduce(
      (acc: Record<string, any[]>, characteristic: { id: number; id_category?: string; name: string }) => {
        if (characteristic.id === 0) return acc; // Exclude characteristic with id 0
        const categoryId = characteristic.id_category || 'default'; // Use 'default' if no categoryId exists

        if (!acc[categoryId]) {
          acc[categoryId] = [];
        }

        // Push the characteristic into the correct category
        acc[categoryId].push(characteristic);

        // Sort the characteristics in this category by name
        acc[categoryId].sort((a, b) => a.name.localeCompare(b.name, 'fr', { sensitivity: 'base' }));

        return acc;
      },
      {}
    );

    const hasMore =
      Object.values(groupedCharacteristics).some((group) => (group as any[]).length > 3) ||
      Object.values(groupedCharacteristics).length > 2;

    setHasMore(hasMore);
    setGroupedCharacteristics(groupedCharacteristics);
  }, [characteristicsList]);

  return (
    <Grid2 container spacing={0}>
      {groupedCharacteristics && (
        <>
          {Object.keys(groupedCharacteristics).map((categoryId, indexCategory) => (
            <Grid2
              size={!isMobile ? { xs: 12, sm: 6, md: 6, lg: 4 } : { xs: 12, sm: 6, md: 4, lg: 4 }}
              key={categoryId + indexCategory}
              sx={{ marginBottom: '0px', padding: '0px' }}
            >
              {(indexCategory < 3 || !showMore) && (
                <Grid2
                  container
                  spacing={1}
                  key={categoryId + indexCategory}
                  sx={{ marginTop: '20px', padding: '0px' }}
                >
                  <Typography sx={{ height: '40px', fontWeight: '800' }} key={categoryId + indexCategory}>
                    {getCategoryName(Number(categoryId))}
                  </Typography>
                  {groupedCharacteristics[categoryId].map((characteristic, index) => (
                    <Grid2 size={12} key={characteristic.id}>
                      {(index < 3 || !showMore) && (
                        <>
                          <span>{characteristicsIcons(22, Number(characteristic.id), characteristic.name)}</span>
                          <span style={{ position: 'relative', top: '-5px', left: '5px' }} key={characteristic.id}>
                            {characteristic.name}
                          </span>
                        </>
                      )}
                    </Grid2>
                  ))}
                </Grid2>
              )}
            </Grid2>
          ))}
          {hasMore && (
            <Grid2
              size={12}
              style={{ cursor: 'pointer', marginTop: '10px', textAlign: 'right' }}
              onClick={() => {
                setShowMore(!showMore);
              }}
              sx={{
                '&:hover': {
                  color: appTheme.palette.secondary.main // Change this to your desired color
                }
              }}
            >
              {showMore ? 'Voir plus' : 'Voir moins'}
            </Grid2>
          )}
        </>
      )}
    </Grid2>
  );
};

export default CharacteristicsList;
