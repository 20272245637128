import { Button, Dialog, Divider, Grid, TextField } from '@mui/material';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { useEffect, useState } from 'react';
import { api } from '~/api';
import MatterportViewer from '~/app/components/MatterportViewer/MatterportViewer';
import EditZone from '../EditZone/EditZone';
import { ZonePanel } from '../ZonePanel/ZonePanel';
import './View3d.scss';
import { isSuperAdmin } from '../Auth/AuthApi';

interface IProps {
  data: {
    model_id: string;
    uuid_owner: string;
    uuid_event: string;
    setOpen?: any;
    productData?: any;
    onData?: any;
    refreshEvents?: any;
  };
  mode?: 'viewer' | 'event-viewer' | 'pin' | 'administrate' | 'equipment-location';
  placedTagCallback?: null | ((sid, tagObject) => void);
  mattertagsData?: any;
  eventDetails?: any;
  tags?: any;
  tagIndexSelected?: any;
  removeTag?: any;
  updateTag?: any;
  dataForm?: any;
  setDataForm?: any;
  handleSubmit?: any;
  onOpen?: any;
  context?: string;
  editMode?: boolean;
  handleDeleteEquipment?: any;
  matterport_scan_areas?: any;
  setData?: any;
  equipmentTab?: string;
}

let interval = null;

export const View3d = ({
  data: { model_id, uuid_owner, uuid_event },
  mode,
  placedTagCallback,
  tags = null,
  mattertagsData: dataSet,
  tagIndexSelected = null,
  removeTag = null,
  dataForm = null,
  context = '',
  matterport_scan_areas,
  setData = null,
  equipmentTab = ''
}: IProps) => {
  const [tagIndex, setTagIndex] = useState(tagIndexSelected);
  const [activated, setActivated] = useState(false);
  const [newZoneLabel, setNewZoneLabel] = useState('');
  const [pathContext, setPathContext] = useState('');
  const [zonePanelOpen, setZonePanelOpen] = useState(false);
  const [zoomImageOpened, setZoomImageOpened] = useState(false);
  const [zoomImgUrl, setZoomImgUrl] = useState('');

  const [currentSweepArea, setCurrentSweepArea] = useState(
    matterport_scan_areas && matterport_scan_areas.default && matterport_scan_areas.default.sweeps
      ? matterport_scan_areas.default.sweeps
      : []
  );

  const [currentZoneId, setCurrentZoneId] = useState('default');

  const reinitialize = () => {
    api.product.get(uuid_owner).then((res) => {
      const data = res.data;
      delete data.matterport_scan_areas;
      api.product.update(uuid_owner, { data: data }).then((res) => {
        setData(res);
      });
    });
  };

  useEffect(() => {
    const pathname = window.location.pathname.split('/');
    console.log(pathname);
    if (pathname[1] === 'product') {
      setPathContext('product');
    } else if (pathname[1] === 'building') {
      setPathContext('building');
    } else if (pathname[1] === 'events') {
      setPathContext('events');
    }

    return () => {
      console.log('VIEWWWW 3D :::: model_ID', model_id);
      if (model_id) {
        if (pathname[1] === 'events') {
          const pathname = window.location.pathname.split('/');
          console.log(pathname);
          console.log(dataForm);
          // console.log(data);
        }
      }
    };
  }, []);

  useEffect(() => {
    setCurrentSweepArea(
      matterport_scan_areas && matterport_scan_areas[currentZoneId] && matterport_scan_areas[currentZoneId].sweeps
        ? matterport_scan_areas[currentZoneId].sweeps
        : []
    );
  }, [currentZoneId]);

  const addZone = () => {
    api.product.get(uuid_owner).then((res) => {
      const matterport_scan_areas = res.data && res.data.matterport_scan_areas ? res.data.matterport_scan_areas : {};
      matterport_scan_areas[newZoneLabel] = {};
      matterport_scan_areas[newZoneLabel].sweeps = [];
      matterport_scan_areas[newZoneLabel].restrictive = false;
      api.product.update(uuid_owner, { data: { ...res.data, matterport_scan_areas } }).then((res) => {
        setData(res);
        setCurrentZoneId(newZoneLabel);
      });
    });
    setNewZoneLabel('');
  };

  return (
    <Grid container sx={{ height: '100vh' }} spacing={0}>
      <Grid item xs={mode === 'viewer' || mode === 'equipment-location' ? 12 : 8} sx={{ height: '100%' }}>
        <Grid container justifyContent="center" sx={{ height: '100%' }}>
          <>
            <MatterportViewer
              productId={uuid_owner}
              modelId={model_id}
              mode={mode}
              uuid_event={uuid_event}
              placedTagCallback={placedTagCallback}
              mpHelp={1}
              mpDh={mode === 'equipment-location' ? 0 : 1}
              mpHl={0}
              mpGt={0}
              mpHr={0}
              mpMls={0}
              mpF={1}
              mpNozoom={0}
              mpWh={0}
              mpLang="fr"
              mpSearch={0}
              mattertagsData={dataSet}
              tagIndexSelected={tagIndex}
              setTagIndex={setTagIndex}
              tags={tags}
              removeTag={removeTag}
              context={context}
              status={dataForm?.status}
              typeEquipment={dataForm?.kind}
              setSelectedTagSid={null}
              matterport_scan_areas={matterport_scan_areas}
              setCurrentSweepArea={setCurrentSweepArea}
              currentSweepArea={currentSweepArea}
              restrictive={
                matterport_scan_areas && matterport_scan_areas[currentZoneId]
                  ? matterport_scan_areas[currentZoneId].restrictive
                  : false
              }
              equipmentTab={equipmentTab}
              setZoomImageOpened={setZoomImageOpened}
              setZoomImgUrl={setZoomImgUrl}
            />
            {mode === 'viewer' && matterport_scan_areas && Object.keys(matterport_scan_areas).length > 1 && (
              <ZonePanel
                zonePanelOpen={zonePanelOpen}
                matterport_scan_areas={matterport_scan_areas}
                currentZoneId={currentZoneId}
                setCurrentSweepArea={setCurrentSweepArea}
                setCurrentZoneId={setCurrentZoneId}
                setZonePanelOpen={setZonePanelOpen}
              />
            )}
          </>
        </Grid>
      </Grid>
      {mode === 'administrate' && (
        <Grid item xs={4} sx={{ height: '100%', overflowX: 'scroll' }}>
          <div style={{ padding: '10px' }}>
            <h3 style={{ marginBottom: '10px' }}>Zones</h3>
            {isSuperAdmin && (
              <span
                onClick={() => {
                  reinitialize();
                }}
              >
                Réinit
              </span>
            )}
            <EditZone
              setData={setData}
              uuid_product={uuid_owner}
              currentSweepArea={currentSweepArea}
              setCurrentSweepArea={setCurrentSweepArea}
              matterport_scan_areas={matterport_scan_areas}
              scan_area_id={'default'}
              currentZoneId={currentZoneId}
              setCurrentZoneId={setCurrentZoneId}
            />
            {matterport_scan_areas &&
              Object.keys(matterport_scan_areas).map((key, index) => {
                if (key !== 'default')
                  return (
                    <EditZone
                      key={index}
                      setData={setData}
                      uuid_product={uuid_owner}
                      currentSweepArea={currentSweepArea}
                      setCurrentSweepArea={setCurrentSweepArea}
                      matterport_scan_areas={matterport_scan_areas}
                      scan_area_id={key}
                      currentZoneId={currentZoneId}
                      setCurrentZoneId={setCurrentZoneId}
                    />
                  );
              })}

            <Divider sx={{ marginTop: '10px' }} />

            <div>
              <h4>Créer une zone</h4>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <TextField
                    autoComplete="off"
                    size={'small'}
                    id="outlined-basic"
                    label="Nom de la zone"
                    variant="outlined"
                    value={newZoneLabel}
                    onChange={(e) => setNewZoneLabel(e.target.value)}
                    sx={{ width: '100%' }}
                  ></TextField>
                </Grid>
                <Grid item xs={4}>
                  <span className="Product__linkBlock__title">
                    {newZoneLabel.length > 0 && (
                      <Button
                        color="secondary"
                        variant="outlined"
                        onClick={addZone}
                        sx={{
                          color: 'primary',
                          borderRadius: '20px',
                          borderColor: 'secondary.main',
                          minWidth: '0px',
                          padding: '5px'
                        }}
                      >
                        <AddOutlinedIcon fontSize="small" />
                      </Button>
                    )}
                  </span>
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
      )}
      <Dialog
        open={zoomImageOpened}
        onClose={() => {
          setZoomImageOpened(false);
          setZoomImgUrl('');
        }}
      >
        <div style={{ padding: '10px', paddingBottom: '7px' }}>
          <img src={zoomImgUrl} alt={zoomImgUrl} style={{ width: '100%', height: '50%', objectFit: 'contain' }} />
        </div>
      </Dialog>
    </Grid>
  );
};

export default View3d;
