import { Chip, Grid2 } from '@mui/material';
import dayjs from 'dayjs';
import { referentielEventStatus, referentielEventTypes } from '~/utils/references';

import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

export const EventItem = ({ event }) => {
  const getVariant = (type: any): 'error' | 'success' | 'primary' | 'secondary' | 'warning' => {
    switch (type) {
      case 1:
        return 'success';
      case 2:
        return 'error';
      case 3:
        return 'warning';
      case 4:
        return 'success';
      case 5:
        return 'secondary';
      default:
        return 'success';
    }
  };

  const getIcon = (type: any): JSX.Element => {
    switch (type) {
      case 5:
        return <LockOutlinedIcon />;
      case 4:
        return <CheckOutlinedIcon />;
      case 3:
        return <LockOpenOutlinedIcon />;
      default:
        return <ClearOutlinedIcon />;
    }
  };
  return (
    <div>
      <Grid2 size={12}>
        {event.created_at && event.created_at !== '' && (
          <Grid2 container>
            <Grid2 size={'grow'} sx={{ pb: '10px' }}>
              <span style={{ color: '#929292' }}>Date : </span> <br />
              <span>
                {dayjs(event.created_at).format('DD/MM/YYYY') + ' - ' + dayjs(event.created_at).format('HH:mm')}{' '}
              </span>{' '}
            </Grid2>
            {event.status !== 0 && (
              <Grid2 size={'auto'}>
                <Chip
                  size="small"
                  icon={getIcon(event.status)}
                  label={referentielEventStatus[event.status]}
                  color={getVariant(event.status)}
                  sx={{
                    color: getVariant(event.status) + '.main',
                    backgroundColor: getVariant(event.status) + '.light'
                  }}
                />
              </Grid2>
            )}
            {event.kind !== 0 && (
              <Grid2 size={12} sx={{ pb: '10px' }}>
                <span style={{ color: '#929292' }}>Type : </span> <br />
                <span>{referentielEventTypes[event.kind]}</span>{' '}
              </Grid2>
            )}

            {event.desc && event.desc !== '' && (
              <Grid2 size={12} sx={{ pb: '10px' }}>
                <span style={{ color: '#929292' }}>Description : </span> <br />
                <span>{event.desc}</span>{' '}
              </Grid2>
            )}
          </Grid2>
        )}
      </Grid2>
      <hr style={{ borderTop: '1px solid #EEEEEE' }} />
    </div>
  );
};
