import { axiosGet, axiosPatch, axiosPost, axiosPostWithoutData } from '.';
import { IAccount, IRoleUserProfil, IUser } from '~/interfaces';
import { axiosDelete, axiosRefreshPost } from '~/api/axiosClient';
import { auth } from '~/app/components/Auth/AuthApi';
import { ILogin, IPasswordRecovery } from './user.models';

export const getUsers = () => {
  return axiosGet(`/users`);
};

export const getUser = (idUser: string) => {
  return axiosGet(`/users/${idUser}`);
};

export const deleteUser = (idUser: string) => {
  return axiosDelete(`/users/${idUser}`);
};

export const createUser = (data: IRoleUserProfil) => {
  return axiosPost('/users', data);
};

export const loginUser = (data: ILogin) => {
  return axiosPost('/login', data); // { params: { 'ttl-jwt': 10 } } for debug
};

export const refreshToken = (data: any) => {
  return axiosRefreshPost('/login/refresh', data); // { params: { 'ttl-jwt': 10, 'ttl-refresh': 30 } } for debug
};

export const forgotPassword = (data: IPasswordRecovery) => {
  return axiosPost('/password-recovery', data);
};

export const registerAccount = (data: IAccount) => {
  return axiosPost('/register', data);
};

export const patchUser = (idUser: string, data: IUser) => {
  return axiosPatch(`/users/${idUser}`, data);
};

export const assignProductToUser = (idUser: string, uuidProduct: string) => {
  return axiosPostWithoutData(`/users/${idUser}/products/${uuidProduct}`);
};

export const unAssignProductToUser = (idUser: string, uuidProduct: string) => {
  return axiosDelete(`/users/${idUser}/products/${uuidProduct}`);
};

// Todo: has to change to another data payload for IUpdatePasswordPayload interface.
export const updatePassword = (idUser: string, data) => {
  return axiosPost(`/users/${idUser}/update-password`, data);
};

export const deleteAccount = (data) => {
  return axiosPost('/delete-account', data);
};

export function CheckUser() {
  if (auth.isAdmin()) {
    return 'IsAdmin';
  }
  if (auth.isSuperAdmin()) {
    return 'IsSuperAdmin';
  }
  return auth.getUserKind();
}

export const unregister = () => {
  return axiosPost('/unregister', {});
};
