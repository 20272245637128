export interface IFile {
  uuid: string;
  uuid_owner: string;
  uuid_tag: string;
  name: string;
  uri: string;
  kind: string;
  category: string;
  created_at: string;
  update_at: string;
  data?: {
    [key: string]: any;
  };
}

export enum FileKind {
  DOCUMENT = 'document',
  GALLERY = 'gallery',
  IMAGE = 'image',
  PDF = 'pdf',
  THUMBNAIL = 'thumbnail',
  UNKNOWN = 'unknown'
}
