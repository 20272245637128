import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { addEvent } from '~/api/event';
import { referentielEventTypes, referentielEventStatus } from '~/utils/references';
import '~/app/components/WizzardEquipment/WizzardEquipment';

export const GeneralInfos = ({
  elementDatas,
  setElementDatas,
  nextStepCallBack,
  currentElementUUID,
  setCurrentElementUUID
}) => {
  const createEvent = () => {
    if (currentElementUUID === '') {
      addEvent(elementDatas)
        .then((res) => {
          setCurrentElementUUID(res.uuid);
          nextStepCallBack('generalInfos');
        })
        .catch((reason) => {});
    } else {
      nextStepCallBack('generalInfos');
    }
  };

  return (
    <Grid container spacing={0} direction="column">
      <Grid item xs={12}>
        <div className="Wizzards__contentTitle Wizzards__center">Création d'un nouveau ticket</div>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={8}>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={6} className="Wizzards__form_item">
                <FormControl fullWidth>
                  <InputLabel id="category">Type de ticket *</InputLabel>
                  <Select
                    // disabled={currentElementUUID === '' ? false : true}
                    labelId="category"
                    label="Type de ticket *"
                    value={elementDatas.kind ? elementDatas.kind : ''}
                    onChange={(e) => setElementDatas({ ...elementDatas, kind: e.target.value })}
                  >
                    {referentielEventTypes &&
                      referentielEventTypes
                        .filter((EventType) => EventType !== '')
                        .map((EventType, index) => (
                          <MenuItem key={index + 1} value={index + 1}>
                            {EventType}
                          </MenuItem>
                        ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} className="Wizzards__form_item">
                <FormControl fullWidth>
                  <InputLabel id="category">Statut *</InputLabel>
                  <Select
                    // disabled={currentElementUUID === '' ? false : true}
                    labelId="statut"
                    label="Statut *"
                    value={elementDatas.status ? elementDatas.status : ''}
                    onChange={(e) => setElementDatas({ ...elementDatas, status: e.target.value })}
                  >
                    {referentielEventStatus &&
                      referentielEventStatus
                        .filter((EventStatus) => EventStatus !== '')
                        .map((EventStatus, index) => (
                          <MenuItem key={index + 1} value={index + 1}>
                            {EventStatus}
                          </MenuItem>
                        ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <div className="Wizzards__form_item">
              <TextField
                label="Description *"
                fullWidth
                value={elementDatas.desc ? elementDatas.desc : ''}
                onChange={(e) => setElementDatas({ ...elementDatas, desc: e.target.value })}
                autoComplete="off"
                multiline
                rows={4}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <div className="Wizzards__center Wizzards__form_item">
          <Button
            disabled={
              elementDatas.kind !== null &&
              elementDatas.kind !== undefined &&
              elementDatas.kind !== '' &&
              elementDatas.status !== null &&
              elementDatas.status !== undefined &&
              elementDatas.status !== '' &&
              elementDatas.desc !== null &&
              elementDatas.desc !== undefined &&
              elementDatas.desc !== ''
                ? false
                : true
            }
            variant="contained"
            color="primary"
            onClick={() => {
              createEvent();
            }}
          >
            Suivant
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};

export default GeneralInfos;
