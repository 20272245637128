import { Box, Chip, Grid2, IconButton, Modal, Rating } from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';
import { AppContext } from '~/utils/context/AppContext';
import _ from 'lodash';
import dayjs from 'dayjs';
import { MediaQueryContext } from '~/utils/context/MediaQueryProvider';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useSwipeable } from 'react-swipeable';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

export const StateItem = ({ state }) => {
  const { referencial } = useContext(AppContext);
  const mediaQueries = useContext(MediaQueryContext);
  const [images, setImages] = useState([]);

  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  const getIcon = (type: any): JSX.Element => {
    switch (type) {
      case 2:
        return <CheckOutlinedIcon />;
      case 3:
        return <LockOpenOutlinedIcon />;
      case 4:
        return <LockOutlinedIcon />;
      default:
        return null;
    }
  };

  const getVariant = (type: any): 'error' | 'success' | 'primary' | 'secondary' | 'warning' => {
    switch (type) {
      case 2:
        return 'success';
      case 3:
        return 'error';
      case 4:
        return 'warning';
      case 1:
        return 'secondary';
    }
  };

  useEffect(() => {
    if (state) {
      const imgs = [];
      if (state.images && state.images.length > 0) {
        state.images.map((image) => {
          imgs.push(image);
        });
      }
      if (state.tags && state.tags.length > 0) {
        state.tags.map((tag) => {
          if (tag.images && tag.images.length > 0) {
            tag.images.map((image) => {
              imgs.push(image);
            });
          }
        });
      }
      setImages(imgs);
    }
  }, [state]);

  const handleOpen = (index) => {
    setSelectedImage(index);
    setSelectedImageIndex(index);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedImage(null);
  };

  const handleNext = useCallback(() => {
    setSelectedImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  }, [images]);

  const handlePrev = useCallback(() => {
    setSelectedImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  }, [images]);
  return (
    <div>
      <Grid2 container>
        <Grid2 size={12}>
          <Grid2 container>
            <Grid2 size={'grow'} sx={{ pb: '10px' }}>
              <Rating size={mediaQueries.matchesSm ? 'medium' : 'small'} value={state.rating} readOnly></Rating>
            </Grid2>
            {state.state !== 0 && (
              <Grid2 size={'auto'}>
                <Chip
                  size="small"
                  icon={getIcon(state.state)}
                  color={getVariant(state.state)}
                  sx={{
                    color: getVariant(state.state) + '.main',
                    backgroundColor: getVariant(state.state) + '.light'
                  }}
                  label={_.find(referencial['equipment_state.status'], { id: Number(state.state) }).name}
                ></Chip>
              </Grid2>
            )}
          </Grid2>
        </Grid2>
        <Grid2 size={12}>
          <Grid2 container>
            <Grid2 size={'grow'}>
              <Grid2 container></Grid2>
              {state.created_at && state.created_at !== '' && (
                <Grid2 container>
                  <Grid2 size={'grow'} sx={{ pb: '10px' }}>
                    <span style={{ color: '#929292' }}>Etat au : </span> <br />
                    <span>
                      {dayjs(state.created_at).format('DD/MM/YYYY') + ' - ' + dayjs(state.created_at).format('HH:mm')}{' '}
                    </span>{' '}
                    <br />
                  </Grid2>
                </Grid2>
              )}
              {state.comment && state.comment !== '' && (
                <Grid2 container>
                  <Grid2 size={'grow'} sx={{ pb: '10px' }}>
                    <span style={{ color: '#929292' }}>Commentaire : </span> <br />
                    <span>{state.comment}</span>
                  </Grid2>
                </Grid2>
              )}
              {state?.data?.meterValue &&
                state?.data?.meterUnity &&
                (state?.data?.meterValue2 === null ||
                  state?.data?.meterValue2 === undefined ||
                  state?.data?.meterValue2 === '') && (
                  <Grid2 container>
                    <Grid2 size={'grow'} sx={{ pb: '10px' }}>
                      <span style={{ color: '#929292' }}>Consommation : </span> <br />
                      <span>
                        {state?.data?.meterValue} {state?.data?.meterUnity}
                      </span>
                    </Grid2>
                  </Grid2>
                )}

              {state?.data?.meterValue2 && state?.data?.meterUnity && state?.data?.meterValue2 && (
                <Grid2 container>
                  <Grid2 size={'grow'} sx={{ pb: '10px' }}>
                    <span style={{ color: '#929292' }}>Consommation HP : </span> <br />
                    <span>
                      {state?.data?.meterValue} {state?.data?.meterUnity}
                    </span>
                  </Grid2>
                </Grid2>
              )}

              {state?.data?.meterValue2 && state?.data?.meterUnity && (
                <Grid2 container>
                  <Grid2 size={'grow'} sx={{ pb: '10px' }}>
                    <span style={{ color: '#929292' }}>Consommation HC : </span> <br />
                    <span>
                      {state?.data?.meterValue2} {state?.data?.meterUnity}
                    </span>
                  </Grid2>
                </Grid2>
              )}

              {state?.data?.serialNumber && state?.data?.serialNumber && (
                <Grid2 container>
                  <Grid2 size={'grow'} sx={{ pb: '10px' }}>
                    <span style={{ color: '#929292' }}>Numéro de série : </span> <br />
                    <span>{state?.data?.serialNumber}</span>
                  </Grid2>
                </Grid2>
              )}

              {state?.data?.inventoryNumber && state?.data?.inventoryNumber && (
                <Grid2 container>
                  <Grid2 size={'grow'} sx={{ pb: '10px' }}>
                    <span style={{ color: '#929292' }}>Nombre : </span> <br />
                    <span>{state?.data?.inventoryNumber}</span>
                  </Grid2>
                </Grid2>
              )}

              {state?.data?.keyNumber && state?.data?.keyNumber && (
                <Grid2 container>
                  <Grid2 size={'grow'} sx={{ pb: '10px' }}>
                    <span style={{ color: '#929292' }}>Nombre de clés : </span> <br />
                    <span>{state?.data?.keyNumber}</span>
                  </Grid2>
                </Grid2>
              )}
            </Grid2>
            <Grid2 size={'auto'}>
              <Grid2 container>
                {images && (
                  <Grid2 size={12}>
                    <div className="hide_scrollbar" style={{ width: '100%', overflowX: 'scroll' }}>
                      <div style={{ position: 'relative', display: 'inline-flex' }}>
                        {images
                          .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
                          .map((image, index) => (
                            <div
                              className="product_sheet_image_event"
                              key={index}
                              onClick={() => {
                                setSelectedImage(index);
                                handleOpen(index);
                              }}
                              style={{
                                backgroundImage: `url(${process.env.REACT_APP_BASEURL_MEDIAS}/${image.uri})`
                              }}
                            ></div>
                          ))}
                      </div>
                    </div>
                  </Grid2>
                )}
              </Grid2>
            </Grid2>
          </Grid2>
        </Grid2>
      </Grid2>

      <hr style={{ borderTop: '1px solid #EEEEEE' }} />

      <Modal
        open={open}
        onClose={handleClose}
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Box
          style={{ position: 'relative', maxWidth: '90%', maxHeight: '90%', borderRadius: '20px', overflow: 'hidden' }}
          {...useSwipeable({
            onSwipedLeft: handleNext,
            onSwipedRight: handlePrev
          })}
        >
          {selectedImageIndex !== null && (
            <>
              {images.length > 1 && (
                <IconButton
                  onClick={handlePrev}
                  style={{
                    position: 'absolute',
                    left: 10,
                    top: '50%',
                    transform: 'translateY(-50%)',
                    background: 'rgba(0,0,0,0.5)'
                  }}
                >
                  <ArrowBackIosIcon style={{ color: 'white' }} />
                </IconButton>
              )}
              <img
                src={`${process.env.REACT_APP_BASEURL_MEDIAS}/${images[selectedImageIndex].uri}`}
                alt="Equipment"
                style={{ width: '100%', height: 'auto', maxHeight: '90vh' }}
              />
              {images.length > 1 && (
                <IconButton
                  onClick={handleNext}
                  style={{
                    position: 'absolute',
                    right: 10,
                    top: '50%',
                    transform: 'translateY(-50%)',
                    background: 'rgba(0,0,0,0.5)'
                  }}
                >
                  <ArrowForwardIosIcon style={{ color: 'white' }} />
                </IconButton>
              )}
            </>
          )}
        </Box>
      </Modal>
    </div>
  );
};
