import { axiosDelete, axiosGet, axiosPatch, axiosPost } from '.';
import { IAddressCreate, IAddress, IAddressUpdate } from './address.models';

export const addAddress = (data: IAddressCreate): Promise<IAddress> => {
  return axiosPost(`/addresses`, data);
};

export const getAddress = (idAddress: string): Promise<IAddress> => {
  return axiosGet(`/addresses/${idAddress}`);
};

export const getAllAddress = (): Promise<IAddress[]> => {
  return axiosGet(`/addresses`);
};

export const patchAddress = (idAddress: string, data: IAddressUpdate): Promise<IAddress> => {
  return axiosPatch(`/addresses/${idAddress}`, data);
};

export const deleteAddress = (idAddress: string) => {
  return axiosDelete(`/addresses/${idAddress}`);
};

export const getBuildingsSameAdress = (params) => {
  return axiosGet('/addresses/buildings-products', { params });
};
