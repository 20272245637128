import { Grid2, Typography, Button } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { MediaQueryContext } from '~/utils/context/MediaQueryProvider';
import ProductContact from '../../ProductContact/ProductContact';
import { api } from '~/api';
import auth from '../../Auth/AuthApi';

const AddContacts = ({ setTitle, setCurrentStep, productDatas, setProductDatas }) => {
  const mediaQueries = useContext(MediaQueryContext);
  const [updateContact, setUpdateContact] = useState(true);
  const [contacts, setContacts] = useState([]);
  const [allContacts, setAllContacts] = useState([]);
  useEffect(() => {
    setTitle('Pour finir, associez des contacts à ce bien');

    getContacts();
  }, []);

  const getContacts = () => {
    const claims = auth.getJWT();

    if (updateContact) {
      api.product.contact
        .get(productDatas.uuid)
        .then((resp) => {
          setContacts(resp);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setUpdateContact(false);
        });

      api.organizations.contact.get(claims.uuid_organization).then((orgcontacts) => {
        const ctcs = orgcontacts.filter((contact) => {
          return contact.uuid_owner !== productDatas.uuid;
        });
        setAllContacts(ctcs.reverse());
      });
    }
  };

  useEffect(() => {
    getContacts();
  }, [updateContact]);

  return (
    <>
      <Grid2 size={12} sx={{ textAlign: 'center' }}>
        <Typography variant={mediaQueries.matchesSm ? 'h5' : 'h6'} sx={{ lineHeight: '1.3' }}>
          {'Par exemple, celui du propriétaire, du locataire actuel, du plombier, etc ... '}
        </Typography>
        <Typography variant={mediaQueries.matchesSm ? 'h5' : 'h6'} sx={{ lineHeight: '1.3' }}>
          {'Cela simplifiera les échanges lors des interventions. '}
        </Typography>
        <br />
        <br />
        <Typography variant={mediaQueries.matchesSm ? 'h5' : 'h6'} sx={{ lineHeight: '1.3' }}>
          {'Vous pourrez toujours les ajouter par la suite.'}
        </Typography>
      </Grid2>
      <Grid2
        container
        spacing={0}
        justifyContent={'center'}
        alignItems="center"
        alignContent="center"
        sx={{ mt: '50px' }}
      >
        <Grid2 container spacing={2} justifyContent="center" alignItems="center" alignContent="center">
          <Grid2 sx={{ justifyContent: 'center', textAlign: 'center' }}>
            <ProductContact
              contacts={contacts}
              productId={productDatas.uuid}
              allContacts={allContacts}
              setUpdateContact={setUpdateContact}
              setAllContacts={setAllContacts}
              propertyCreationContext={true}
            />
          </Grid2>
        </Grid2>
      </Grid2>
      <Grid2
        container
        spacing={3}
        justifyContent={'center'}
        sx={{
          mt: '20px',
          position: 'sticky', // Rend les boutons "collants"
          bottom: 0, // Position en bas de la fenêtre si sticky s'applique
          bgcolor: 'white', // Fond blanc pour couvrir le contenu en dessous
          zIndex: 10, // S'assure que les boutons sont au premier plan
          boxShadow: '0 -2px 5px rgba(0,0,0,0)', // Ajoute une ombre douce si besoin
          pb: 2 // Ajoute du padding vertical pour éviter une hauteur trop compacte
        }}
      >
        <Grid2 size={6} sx={{ pt: '30px', textAlign: 'right' }}>
          <Button
            fullWidth={mediaQueries.matchesMd ? false : true}
            color={'primary'}
            sx={{ textDecoration: 'underline' }}
            onClick={() => {
              setCurrentStep(7);
            }}
          >
            {'Retour'}
          </Button>
        </Grid2>
        <Grid2 size={6} sx={{ pt: '30px', textAlign: 'left' }}>
          <Button
            fullWidth={mediaQueries.matchesMd ? false : true}
            variant={'contained'}
            color={'primary'}
            sx={{ color: 'white' }}
            onClick={() => {
              setCurrentStep(9);
            }}
          >
            suivant
          </Button>
        </Grid2>
      </Grid2>
    </>
  );
};

export default AddContacts;
