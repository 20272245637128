import { Card, CardHeader, CardContent, Box, useTheme } from '@mui/material';
import { PieChart } from '@mui/x-charts/PieChart';

export const PiesChart = ({ title, data }) => {
  const theme = useTheme();
  const formattedData = [
    { id: 0, value: data.done, label: 'Terminés' },
    { id: 1, value: data.in_progress, label: 'En cours' },
    { id: 2, value: data.to_qualify, label: 'A qualifier' }
  ];

  return (
    <Card sx={{ borderRadius: '10px' }}>
      <CardHeader title={title} />
      <CardContent>
        <Box>
          <PieChart
            colors={[theme.palette.primary.main, theme.palette.secondary.main, 'lightgray']}
            series={[
              {
                data: formattedData,
                highlightScope: { faded: 'global', highlighted: 'item' },
                faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' }
              }
            ]}
            height={200}
          />
        </Box>
      </CardContent>
    </Card>
  );
};
