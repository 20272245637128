import FormControl from '@mui/material/FormControl';
import Switch from '@mui/material/Switch';
import { useEffect, useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { EquipmentDetail } from '~/app/components/EquipmentDetail/EquipmentDetail';
import './EquipmentsList.scss';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { api } from '~/api';
import { renderEquipmentIcon } from '~/utils/references';
import { AppContext } from '~/utils/context/AppContext';

import _ from 'lodash';
import { Rating } from '@mui/material';

export const EquipmentsList = ({
  data,
  idEvent = null,
  precheckList = null,
  setWizzardEquipmentOpen = null,
  setWizzardEventOpen = null,
  refreshRequired = null,
  // idEquipment = null,
  setWizzardEquipmentCreationMode = null,
  setWizzardEventCreationMode = null,
  wizzardEquipmentCreationMode = null,
  setSelectedUUIDEquipment = null,
  setSelectedUUIDEvent = null,
  setEquipmentFamily = null,
  refreshEquipments = null,
  context = 'equipments',
  uuidDocument = null,
  uuidEquipmentSelected = null,
  setUuidEquipmentSelected = null
}) => {
  type TuseParams = {
    id: string;
    idEquipment: string;
    idKind: string;
  };
  const { id } = useParams<TuseParams>();
  const { idKind } = useParams<TuseParams>();
  const { idEquipment } = useParams<TuseParams>();
  const navigate = useNavigate();
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [equipments, setEquipments] = useState(null);
  const [equipmentKinds, setEquipmentKinds] = useState(null);
  const [activeId, setActiveId] = useState();
  const [checked, setChecked] = useState([]);
  const [familyOpened, setFamilyOpened] = useState({});
  const [pathContext, setPathContext] = useState('');

  const { equipmentReferencial } = useContext(AppContext);

  const handleKindClick = (e, key) => {
    if (setEquipmentFamily) setEquipmentFamily(key);
    e.preventDefault();

    if (key + 2 < 20) {
      if (familyOpened[key + 2]) {
        setFamilyOpened({ ...familyOpened, [key + 2]: false });
      } else {
        setFamilyOpened({ ...familyOpened, [key + 2]: true });
      }
    } else {
      // Pour la gestion des 'autres'
      if (familyOpened[1]) {
        setFamilyOpened({ ...familyOpened, 1: false });
      } else {
        setFamilyOpened({ ...familyOpened, 1: true });
      }
    }
  };

  useEffect(() => {
    if ((idEquipment || uuidEquipmentSelected) && data && equipments) {
      const curr = equipments.filter((item) => item.uuid === idEquipment || item.uuid === uuidEquipmentSelected);

      const familyOpened = {};
      const id = _.find(equipmentReferencial['equipmentCategoryKinds'], {
        id: _.find(equipmentReferencial['equipments'], { id: curr[0].kind }).kinds[0]
      }).id;

      familyOpened[id] = true;
      setFamilyOpened(familyOpened);
    }
  }, [idEquipment, data, equipments, uuidEquipmentSelected]);

  const handleClick = (e, eid) => {
    navigate(`/equipments/${eid}`);
    setActiveId(eid);
  };

  const getEquipmentFromProperty = (propertyType) => {
    api.product
      .getEquipments(propertyType === 'events' ? data.uuid_owner : data.uuid)
      .then((resp) => {
        setEquipments(resp);
        if (idEquipment !== null && idEquipment !== undefined) {
          handleClick('', idEquipment);
          const curr = resp.filter((item) => item.uuid === idEquipment);
          // console.log(equipmentReferencial['equipmentCategoryKinds']);
          // console.log('curr', curr);
          // console.log(_.find(equipmentReferencial['equipments'], { id: curr[0].kind }).kinds[0]);
          // console.log(
          //   _.find(equipmentReferencial['equipmentCategoryKinds'], {
          //     id: _.find(equipmentReferencial['equipments'], { id: curr[0].kind }).kinds[0]
          //   }).id
          // );
          setSelectedIndex(
            _.find(equipmentReferencial['equipmentCategoryKinds'], {
              id: _.find(equipmentReferencial['equipments'], { id: curr[0].kind }).kinds[0]
            }).id
          );
        }
        // if (idKind !== null && idKind !== undefined) {
        //   setSelectedIndex(idKind);
        // }
      })
      .catch((err) => {
        console.log(err);
      });
    const equipmentsAssigned = precheckList
      ? precheckList.map((equipment) => {
          return equipment.uuid;
        })
      : [];
    setChecked(equipmentsAssigned);
  };

  const getEquipments = () => {
    getEquipmentFromProperty(pathContext);
  };

  const setFamilyChecked = () => {
    const familyOpened = {};

    if (precheckList && equipmentReferencial) {
      precheckList.forEach((equipment) => {
        if (_.find(equipmentReferencial['equipments'], { id: equipment.kind }) !== undefined)
          _.find(equipmentReferencial['equipments'], { id: equipment.kind }).kinds.forEach((category_kind) => {
            if (equipment.kind !== 1) {
              familyOpened[category_kind] = true;
            } else if (equipment.kind === 1 && category_kind === 1) {
              familyOpened[category_kind] = true;
            }
          });
      });
    }
    const equipmentsAssigned = precheckList
      ? precheckList.map((equipment) => {
          return equipment.uuid;
        })
      : [];

    if (idEquipment && data && equipments) {
      const curr = equipments.filter((item) => item.uuid === idEquipment);
      const id = _.find(equipmentReferencial['equipmentCategoryKinds'], {
        id: _.find(equipmentReferencial['equipments'], { id: curr[0].kind }).kinds[0]
      }).id;
      familyOpened[id] = true;
    }

    setChecked(equipmentsAssigned);
    setFamilyOpened(familyOpened);
  };

  useEffect(() => {
    if (equipmentReferencial) {
      getEquipments();
    }
  }, [refreshRequired, equipmentReferencial]);

  useEffect(() => {
    const pathname = window.location.pathname.split('/');
    if (pathname[1] === 'product' && pathname[3] !== 'events') {
      setPathContext('product');
    } else if (pathname[1] === 'events' || (pathname[1] === 'product' && pathname[3] === 'events')) {
      setPathContext('events');
    }
  }, []);

  useEffect(() => {
    if (pathContext !== '') {
      if (equipmentReferencial) getEquipments();
    }
  }, [pathContext]);

  useEffect(() => {
    if (equipmentReferencial) getEquipments();
  }, [data]);

  useEffect(() => {
    setFamilyChecked();
  }, [precheckList]);

  useEffect(() => {
    setFamilyChecked();
  }, [equipments]);

  useEffect(() => {
    if (equipmentReferencial) {
      const KindList = [];
      for (let i = 0; i < equipmentReferencial['equipmentCategoryKinds'].length; i++) {
        KindList.push(equipmentReferencial['equipmentCategoryKinds'][i].id);
      }

      KindList.shift();
      KindList.push(equipmentReferencial['equipmentCategoryKinds'][0].id);

      setEquipmentKinds(KindList);
    }
  }, [id, equipmentReferencial]);

  const equipCatsWithItems = (key) => {
    if (!equipmentReferencial || !equipments || !key) {
      return [];
    }
    const tmpArray = [];
    if (equipments) {
      equipments.forEach((equipment) => {
        if (_.find(equipmentReferencial['equipments'], { id: equipment.kind }) !== undefined) {
          _.find(equipmentReferencial['equipments'], { id: equipment.kind }).kinds.forEach((category_kind) => {
            if (_.find(equipmentReferencial['equipmentCategoryKinds'], { id: category_kind }).id === key) {
              if (equipment.kind !== 1) {
                tmpArray.push(equipment);
              } else if (equipment.kind === 1 && category_kind === 1) {
                tmpArray.push(equipment);
              }
            }
          });
        }
      });
    }
    return tmpArray;
  };

  const handleToggle = (value, element) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
    if (checked.indexOf(value) === -1) {
      if (context === 'equipments') {
        api.events.equipment.assign(idEvent, value).catch((err) => {
          console.log(err);
        });
      } else if (context === 'documents') {
        api.files.equipmentLink.create(idEvent.uuid, value);
      }
    } else {
      if (context === 'equipments') {
        api.events.equipment.unassign(idEvent, value);
      } else if (context === 'documents') {
        api.files.equipmentLink.delete(idEvent.uuid, value);
      }
    }
  };

  const GetEquipmentKind = (kind) => {
    if (equipmentReferencial) {
      return _.find(equipmentReferencial['equipmentCategoryKinds'], { id: kind }).name;
    } else {
      return '';
    }
  };

  const getRoomName = (equipment) => {
    return equipment.room !== '' && equipment.room !== null && equipment.room !== undefined
      ? ' - ' + equipment.room.name
      : '';
  };

  const getRating = (equipment) => {
    // console.log(equipment);
    if (equipment.states && equipment.states?.length > 0) {
      // console.log(equipment.states);
      const lastState = equipment.states[equipment.states?.length - 1];
      return (
        <Rating
          size={'medium'}
          sx={{ paddingRight: '15px' }}
          name="half-rating-read"
          defaultValue={lastState.rating}
          precision={0.5}
          readOnly
        />
      );
    } else {
      return '';
    }
    // return null;
  };

  const getTagPinPoint = (equipment) => {
    let tag = null;

    if (equipment.tags && equipment.tags.length > 0) {
      tag = equipment.tags[0];
    }

    if (equipment.states && equipment.states.length > 0) {
      equipment.states.forEach((state) => {
        if (state.tags && state.tags[0]) {
          tag = state.tags[0];
        }
      });

      // if (equipment.states[0].tags && equipment.states[equipment.states.length - 1].tags[0]) {
      //   tag = equipment.states[equipment.states.length - 1].tags[0];
      // }
    }

    // console.log('tag', tag);

    if (tag !== null) {
      return (
        <svg
          style={{ cursor: 'pointer' }}
          onClick={(e) => handleClick(e, equipment.uuid)}
          width="15"
          height="25"
          viewBox="0 0 20 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_9924_7990)">
            <path
              d="M10 0.714355C4.47143 0.714355 0 5.18578 0 10.7144C0 18.2144 10 29.2858 10 29.2858C10 29.2858 20 18.2144 20 10.7144C20 5.18578 15.5286 0.714355 10 0.714355ZM10 14.2858C8.02857 14.2858 6.42857 12.6858 6.42857 10.7144C6.42857 8.74293 8.02857 7.14293 10 7.14293C11.9714 7.14293 13.5714 8.74293 13.5714 10.7144C13.5714 12.6858 11.9714 14.2858 10 14.2858Z"
              fill="#F17633"
            />
          </g>
          <defs>
            <clipPath id="clip0_9924_7990">
              <rect width="20" height="28.5714" fill="white" transform="translate(0 0.714355)" />
            </clipPath>
          </defs>
        </svg>
      );
    } else {
      return <svg width="15" height="25" viewBox="0 0 20 30" fill="none" xmlns="http://www.w3.org/2000/svg"></svg>;
    }
  };

  const buildEquipmentsList = () => {
    if (equipmentKinds) {
      const equipmentsList = equipmentKinds.map((equipmentkind, index) =>
        equipCatsWithItems(equipmentkind) && equipCatsWithItems(equipmentkind).length > 0 ? (
          <div key={index}>
            <ListItemButton
              id={index}
              key={index}
              onClick={(e) => handleKindClick(e, index)}
              selected={selectedIndex === index}
              style={
                familyOpened
                  ? {
                      backgroundColor: familyOpened[equipmentkind] ? 'rgb(249 249 249)' : 'rgb(249 249 249)',
                      borderBottom: '1px solid #E0E0E0'
                    }
                  : selectedIndex === index
                    ? { color: '#062746', background: '#F1F8FF' }
                    : {}
              }
              autoFocus={selectedIndex === index}
              disabled={equipCatsWithItems(equipmentkind).length > 0 ? false : true}
            >
              <ListItemIcon>{renderEquipmentIcon(equipmentkind)}</ListItemIcon>
              <ListItemText primary={GetEquipmentKind(equipmentkind)} />
              {familyOpened ? (
                familyOpened[equipmentkind] ? (
                  <ExpandLess />
                ) : (
                  <ExpandMore />
                )
              ) : selectedIndex === index ? (
                <ExpandLess />
              ) : (
                <ExpandMore />
              )}
            </ListItemButton>
            <Collapse
              in={familyOpened ? familyOpened[equipmentkind] : selectedIndex === index}
              timeout="auto"
              unmountOnExit
            >
              <>
                {equipments &&
                  equipCatsWithItems(equipmentkind).map((equip, index) => (
                    <ListItem
                      key={index}
                      secondaryAction={
                        context !== 'View3D' ? (
                          <Switch
                            color={'secondary'}
                            edge="end"
                            onChange={handleToggle(equip.uuid, this)}
                            checked={checked.indexOf(equip.uuid) !== -1}
                            inputProps={{
                              'aria-labelledby':
                                equip.title !== '' && equip.title !== null && equip.title !== undefined
                                  ? _.find(equipmentReferencial['equipments'], { id: equip.kind }).name +
                                    ' : ' +
                                    equip.title +
                                    getRoomName(equip)
                                  : _.find(equipmentReferencial['equipments'], { id: equip.kind }).name +
                                    getRoomName(equip)
                            }}
                          />
                        ) : null
                      }
                      disablePadding
                    >
                      <>
                        <div
                          key={index}
                          style={{
                            display: 'inline-flex',
                            width: '100%',
                            color:
                              equip.uuid === idEquipment || equip.uuid === uuidEquipmentSelected ? 'red' : '#062746',
                            padding: '10px',
                            borderBottom:
                              index < equipCatsWithItems(equipmentkind).length - 1
                                ? '1px solid rgb(216, 215, 215)'
                                : null
                          }}
                        >
                          <ListItemText
                            style={{ cursor: 'pointer' }}
                            onClick={(e) => {
                              if (context === 'View3D') {
                                api.equipments.get(equip.uuid).then((resp) => {});
                                api.product.getEquipments(equip.uuid_owner).then((resp) => {});
                              } else {
                                navigate(`/equipments/${equip.uuid}`);
                              }
                            }}
                            primary={
                              equip.title !== '' && equip.title !== null && equip.title !== undefined
                                ? _.find(equipmentReferencial['equipments'], { id: equip.kind }).name +
                                  ' : ' +
                                  equip.title +
                                  getRoomName(equip)
                                : _.find(equipmentReferencial['equipments'], { id: equip.kind }).name +
                                  getRoomName(equip)
                            }
                          ></ListItemText>
                          {context === 'View3D' && (
                            <>
                              {getRating(equip)}

                              {getTagPinPoint(equip)}
                            </>
                          )}
                        </div>
                      </>
                    </ListItem>
                  ))}
              </>
            </Collapse>
            <Divider />
          </div>
        ) : null
      );
      return (
        <div>
          {equipmentsList}
          <ListItemButton
            className="EquipmentSublist__add"
            sx={{ pl: 6 }}
            style={{ color: '#062746', background: '#FFF' }}
            component="a"
            onClick={() => {
              if (context !== 'View3D') {
                setWizzardEquipmentCreationMode(true);
                setWizzardEquipmentOpen(true);
              } else {
              }
            }}
          >
            <ListItemText className="EquipmentsList__add">
              <AddOutlinedIcon /> Ajouter un équipement
            </ListItemText>
          </ListItemButton>
        </div>
      );
    }
  };

  return (
    <div
      className="hide_scrollbar"
      style={{ height: 'calc(100%)', overflow: context !== 'View3D' ? 'hidden' : 'auto' }}
    >
      <FormControl
        className={'EquipmentsList__formWithinEvent'}
        component="fieldset"
        style={{ minWidth: 'auto', overflow: 'scroll' }}
      >
        <List sx={{ pt: 0 }}>
          <Divider />
          {equipmentKinds && buildEquipmentsList()}
        </List>
      </FormControl>
      {activeId && idEquipment && (
        <EquipmentDetail
          idEquipment={idEquipment}
          activeId={activeId}
          setWizzardEquipmentOpen={setWizzardEquipmentOpen}
          setWizzardEventOpen={setWizzardEventOpen}
          setWizzardEquipmentCreationMode={setWizzardEquipmentCreationMode}
          setWizzardEventCreationMode={setWizzardEventCreationMode}
          refreshRequired={refreshRequired}
          setSelectedUUIDEvent={setSelectedUUIDEvent}
          refreshEquipments={refreshEquipments}
        />
      )}
    </div>
  );
};
