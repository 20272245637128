import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  Radio,
  RadioGroup,
  Autocomplete,
  useMediaQuery,
  useTheme
} from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { api } from '~/api';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { WizzardContact } from '~/app/components/WizzardContact/WizzardContact';
import dayjs, { Dayjs } from 'dayjs';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import 'dayjs/locale/fr';
import normalizedString from '~/utils/normalizedString';

export const WizzardMissionOrder = ({
  activeStep,
  setActiveStep,
  product,
  setData,
  data,
  scanData,
  setScanData,
  handleCreateMissionOrder,
  closeModal,
  scanOrder
}) => {
  const [inputValue, setInputValue] = useState('');
  const [productList, setProductList] = useState(null);
  const [productFilter, setProductFilter] = useState(null);
  const [productSelected, setProductSelected] = useState(product || null);

  const [step, setStep] = useState(0);

  const [isEdl, setIsEdl] = useState(false);
  const [isEdl3D, setIsEdl3D] = useState(false);
  const [isScan3D, setIsScan3D] = useState(false);

  const [isRescan, setIsRescan] = useState('non');

  const [kindEdl, setKindEdl] = useState('');

  const [endLease, setEndLease] = React.useState<Dayjs | null>();
  const [startLease, setStartLease] = React.useState<Dayjs | null>();
  const [edlExpectedDate, setEdlExpectedDate] = React.useState<Dayjs | null>();

  const [scanExpectedDate, setScanExpectedDate] = React.useState<Dayjs | null>();

  const [allContacts, setAllContacts] = useState([]);
  const [allContactsOrga, setAllContactsOrga] = useState([]);
  const [allTechs, setAllTechs] = useState([]);
  const [allUsersOrganization, setAllUsersOrganization] = useState([]);
  const [allPhotographs, setAllPhotographs] = useState([]);
  const [addContact, setAddContact] = useState<boolean>(false);

  const [autocompleteComponents, setAutocompleteComponents] = useState([{ id: 0, value: null }]);

  const [refFolderEdl, setRefFolderEdl] = useState('');

  const [refFolderScan, setRefFolderScan] = useState('');

  const [residents, setResidents] = useState([]);

  const [isFurnished, setIsFurnished] = useState(null);

  const [hasInventory, setHasInventory] = useState(false);

  const [worksCommentary, setWorksCommentary] = useState('');

  const [contact, setContact] = useState('');

  const [buildingCode, setBuildingCode] = useState(null);

  const [commentary, setCommentary] = useState('');

  const [contactTechEdl, setContactTechEdl] = useState(null);
  const [contactPhotographScan, setContactPhotographScan] = useState(null);

  const [state, setState] = useState('');

  const [userTech, setUserTech] = useState(null);
  const [disabled, setDisabled] = useState(true);

  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.up('xs'));
  const matchesSm = useMediaQuery(theme.breakpoints.up('sm'));
  const matchesMd = useMediaQuery(theme.breakpoints.up('md'));
  const matchesLg = useMediaQuery(theme.breakpoints.up('lg'));
  const matchesXl = useMediaQuery(theme.breakpoints.up('xl'));

  useEffect(() => {
    api.product.search().then((res) => {
      setProductList(res);
    });
  }, []);

  useEffect(() => {
    if (scanOrder) {
      setIsScan3D(scanOrder);
    }
  }, []);

  const handleSetFurnishedBuildingCode = async () => {
    const res = await api.product.get(productSelected.uuid);
    if (isFurnished === null) {
      setIsFurnished(res?.data?.furnished);
    }
    if (buildingCode === null) {
      if (res?.building) setBuildingCode(res?.building?.data.access_code);
    }
    setData((prevData) => ({ ...prevData, data: { ...prevData.data, with_inventory: hasInventory } }));

    setData((prevData) => ({ ...prevData, data: { ...prevData.data, is_furnished: res?.data?.furnished } }));
    setScanData((prevData) => ({ ...prevData, data: { ...prevData.data, is_furnished: res?.data?.furnished } }));

    if (res?.building) {
      setData((prevData) => ({
        ...prevData,
        data: { ...prevData.data, building_code: res.building.data.access_code }
      }));
      setScanData((prevData) => ({
        ...prevData,
        data: { ...prevData.data, building_code: res.building.data.access_code }
      }));
    }
  };

  useEffect(() => {
    if (productSelected && activeStep === 1 && isFurnished === null && buildingCode === null) {
      handleSetFurnishedBuildingCode();
    }
  }, [productSelected, activeStep]);

  useEffect(() => {
    if (productSelected && allContacts && residents.length === 0) {
      const tempArray = [];
      allContacts.forEach((contact) => {
        if (contact.kind === 2) {
          tempArray.push(contact);
        }
      });
      setResidents(tempArray);
      setData((prevData) => ({
        ...prevData,
        data: { ...prevData.data, residents: tempArray }
      }));
      if (tempArray.length > 1) {
        setAutocompleteComponents(
          tempArray.map((item, index) => {
            return { id: index, value: item };
          })
        );
      }
    }
  }, [productSelected, allContacts]);

  useEffect(() => {
    if (product) {
      setScanData({ ...scanData, uuid_property: product.uuid });
      setData({ ...data, uuid_property: product.uuid });
    }
  }, [product]);

  useEffect(() => {
    if (!productSelected) return;
    api.organizations.get(productSelected?.address.uuid_organization).then((res) => {
      setAllUsersOrganization(res.users);
    });
  }, [productSelected]);

  useEffect(() => {
    if (!productList) return;
    const filteredList = productList.filter((item) => {
      const normalizedSearch = normalizedString(inputValue);

      return (
        item.address &&
        (normalizedString(item.address.street).indexOf(normalizedSearch) >= 0 ||
          normalizedString(item.address.city).indexOf(normalizedSearch) >= 0 ||
          normalizedString(item.address.zip_code).indexOf(normalizedSearch) >= 0 ||
          (item.owner && normalizedString(item.owner.name).indexOf(normalizedSearch) >= 0) ||
          (item.owner && normalizedString(item.owner.company_name).indexOf(normalizedSearch) >= 0) ||
          (item.name && normalizedString(item.name).indexOf(normalizedSearch) >= 0) ||
          (item.id_internal && normalizedString(item.id_internal).indexOf(normalizedSearch) >= 0))
      );
    });
    setProductFilter(filteredList);
  }, [inputValue]);

  useEffect(() => {
    if (!productSelected) return;

    api.product.contact.get(productSelected.uuid).then((res) => {
      setAllContacts(res);
    });

    api.contacts.search().then((res) => {
      setAllContactsOrga(res);
    });
  }, [productSelected, addContact]);

  useEffect(() => {
    api.missionOrders.getTechnicians().then((res) => {
      setAllTechs(res.technicians);
    });

    api.missionOrders.getPhotographs().then((res) => {
      setAllPhotographs(res.photographs);
    });
  }, []);

  useEffect(() => {
    if (!product) return;
    handleNext();
  }, []);

  useEffect(() => {
    if (isRescan === 'oui') {
      setIsScan3D(true);
      setData((prevData) => ({ ...prevData, data: { ...prevData.data, matterport_scan_to_create: true } }));
    } else {
      setData((prevData) => ({ ...prevData, data: { ...prevData.data, matterport_scan_to_create: false } }));
    }
  }, [isRescan]);

  useEffect(() => {
    if (isEdl && kindEdl === 'Entrée') {
      setData((prevData) => ({ ...prevData, data: { ...prevData.data, edl_kind: 1 } }));
    } else if (isEdl && kindEdl === 'Sortie') {
      setData((prevData) => ({ ...prevData, data: { ...prevData.data, edl_kind: 2 } }));
    } else if (isEdl3D && kindEdl === 'Entrée') {
      setData((prevData) => ({ ...prevData, data: { ...prevData.data, edl_kind: 3 } }));
    } else if (isEdl3D && kindEdl === 'Sortie') {
      setData((prevData) => ({ ...prevData, data: { ...prevData.data, edl_kind: 4 } }));
    }
  }, [isEdl, isEdl3D, kindEdl]);

  useEffect(() => {
    if (isEdl3D && !productSelected?.matterport_scan) {
      setIsScan3D(true);
    }
  }, [isEdl3D]);

  useEffect(() => {
    if (isEdl) setData((prevData) => ({ ...prevData, kind: 1 }));
    if (isEdl3D) setData((prevData) => ({ ...prevData, kind: 1 }));
    if (isScan3D) setScanData((prevData) => ({ ...prevData, kind: 2 }));

    if (isEdl3D && isScan3D) {
      setData((prevData) => ({ ...prevData, kind: 1 }));
    }

    if (isEdl || isEdl3D) {
      setData((prevData) => ({ ...prevData, data: { ...prevData.data, is_furnished: isFurnished } }));
    } else if (isScan3D) {
      setScanData((prevData) => ({ ...prevData, data: { ...prevData.data, is_furnished: isFurnished } }));
    }
  }, [isEdl, isEdl3D, isScan3D]);

  useEffect(() => {
    handleDisabled();
  }, [kindEdl, isEdl, isEdl3D, isScan3D]);

  useEffect(() => {
    if (isEdl && isEdl3D)
      setData((prevData) => ({ ...prevData, data: { ...prevData.data, with_inventory: hasInventory } }));
  }, [hasInventory]);

  useEffect(() => {
    if (isEdl && isEdl3D)
      setData((prevData) => ({ ...prevData, data: { ...prevData.data, is_furnished: isFurnished } }));
    if (isScan3D) setScanData((prevData) => ({ ...prevData, data: { ...prevData.data, is_furnished: isFurnished } }));
  }, [isFurnished]);

  useEffect(() => {
    if (isEdl && isEdl3D)
      if (buildingCode) {
        setData((prevData) => ({ ...prevData, data: { ...prevData.data, building_code: buildingCode.toString() } }));
      } else {
        setData((prevData) => ({ ...prevData, data: { ...prevData.data, building_code: '' } }));
      }
    if (isScan3D)
      if (buildingCode) {
        setScanData((prevData) => ({
          ...prevData,
          data: { ...prevData.data, building_code: buildingCode.toString() }
        }));
      } else {
        setScanData((prevData) => ({ ...prevData, data: { ...prevData.data, building_code: '' } }));
      }
  }, [buildingCode]);

  const handleDisabled = () => {
    if (isEdl && kindEdl) {
      setDisabled(false);
    } else if (isEdl3D && kindEdl) {
      setDisabled(false);
    } else if (isScan3D && !isEdl3D) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleProductSelected = (product) => {
    setProductSelected(product);
    setData({ ...data, uuid_property: product.uuid });
    setScanData({ ...scanData, uuid_property: product.uuid });
  };

  const handleNext = () => {
    setStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleNextActiveStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBackActiveStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleRescanChange = (e) => {
    setIsRescan(e.target.value);
    if (e.target.value === 'oui') {
      setIsScan3D(true);
    } else {
      setIsScan3D(false);
    }
  };

  const handleIsScan3D = (e) => {
    setIsScan3D(e.target.checked);
    if (isEdl3D && isRescan === 'oui' && e.target.checked === false) {
      setIsRescan('non');
    }
    if (isEdl3D && isRescan === 'non' && e.target.checked === true) {
      setIsRescan('oui');
    }
    if (isEdl) {
      setIsEdl(false);
    }
  };
  useEffect(() => {
    if (isRescan === 'oui') {
      setIsScan3D(true);
    }
  }, [isRescan]);

  useEffect(() => {
    if (isScan3D && isEdl3D) {
      setIsRescan('oui');
    } else {
      setIsRescan('non');
    }
  }, [isScan3D, isEdl3D]);

  const handleKindEdl = (event) => {
    setKindEdl(event.target.value);
  };

  const handleAddAutocomplete = () => {
    const lastId = autocompleteComponents[autocompleteComponents.length - 1].id;
    setAutocompleteComponents([...autocompleteComponents, { id: lastId + 1, value: null }]);
  };

  const handleRemoveAutocomplete = (index) => {
    if (autocompleteComponents.length <= 1) {
      return;
    }

    const updatedComponents = [...autocompleteComponents];
    updatedComponents.splice(index, 1);
    setAutocompleteComponents(updatedComponents);

    const updatedResidents = [...residents];
    updatedResidents.splice(index, 1);
    setResidents(updatedResidents);
    setData((prevData) => ({ ...prevData, data: { ...prevData.data, residents: updatedResidents } }));
  };

  const handleRefFolder = (e) => {
    setRefFolderEdl(e.target.value);
    setData((prevData) => ({ ...prevData, ref_internal: e.target.value }));
  };

  const handleRefFolderScan = (e) => {
    setRefFolderScan(e.target.value);
    setScanData((prevData) => ({ ...prevData, ref_internal: e.target.value }));
  };

  const handleResidents = (e, newValue, index) => {
    const updatedResidents = [...residents];

    if (newValue) {
      updatedResidents[index] = newValue;
    } else {
      updatedResidents[index] = null;
    }
    setResidents(updatedResidents);
    setData((prevData) => ({
      ...prevData,
      data: { ...prevData.data, residents: updatedResidents }
    }));
  };

  const handleState = (e, newValue) => {
    setState(newValue);
    switch (newValue) {
      case 'NC':
        setData((prevData) => ({ ...prevData, data: { ...prevData.data, general_state: 1 } }));
        break;
      case 'Neuf':
        setData((prevData) => ({ ...prevData, data: { ...prevData.data, general_state: 2 } }));
        break;
      case 'Rénové':
        setData((prevData) => ({ ...prevData, data: { ...prevData.data, general_state: 3 } }));
        break;
      case 'Bon état':
        setData((prevData) => ({ ...prevData, data: { ...prevData.data, general_state: 4 } }));
        break;
      case "Etat d'usage":
        setData((prevData) => ({ ...prevData, data: { ...prevData.data, general_state: 5 } }));
        break;
      case 'Dégradé':
        setData((prevData) => ({ ...prevData, data: { ...prevData.data, general_state: 6 } }));
        break;
      default:
        setData((prevData) => ({ ...prevData, data: { ...prevData.data, general_state: 0 } }));
        break;
    }
  };

  const handleIsFurnished = (e) => {
    setIsFurnished(e.target.value);

    if (isEdl || isEdl3D) {
      if (e.target.value === 'true') {
        setData((prevData) => ({ ...prevData, data: { ...prevData.data, is_furnished: true } }));
      } else {
        setData((prevData) => ({ ...prevData, data: { ...prevData.data, is_furnished: false } }));
      }
    }
    if (isScan3D) {
      if (e.target.value === 'true') {
        setScanData((prevData) => ({ ...prevData, data: { ...prevData.data, is_furnished: true } }));
      } else {
        setScanData((prevData) => ({ ...prevData, data: { ...prevData.data, is_furnished: false } }));
      }
    }
  };

  const handleHasInventory = (e) => {
    setHasInventory(e.target.value);
    if (isEdl || isEdl3D) {
      if (e.target.value === 'true') {
        setData((prevData) => ({ ...prevData, data: { ...prevData.data, with_inventory: true } }));
      } else {
        setData((prevData) => ({ ...prevData, data: { ...prevData.data, with_inventory: false } }));
      }
    }
  };

  const handleWorksCommentary = (e) => {
    setWorksCommentary(e.target.value);
    setData((prevData) => ({ ...prevData, data: { ...prevData.data, works_commentary: e.target.value } }));
  };

  const handleContact = (e, newValue) => {
    if (!newValue) {
      setContact('');
      if (isEdl || isEdl3D) {
        setData((prevData) => ({ ...prevData, data: { ...prevData.data, contact: null } }));
      }
      if (isScan3D) {
        setScanData((prevData) => ({ ...prevData, data: { ...prevData.data, contact: null } }));
      }
    } else {
      setContact(newValue);
      if (isEdl || isEdl3D) {
        setData((prevData) => ({ ...prevData, data: { ...prevData.data, contact: newValue } }));
      }
      if (isScan3D) {
        setScanData((prevData) => ({ ...prevData, data: { ...prevData.data, contact: newValue } }));
      }
    }
  };

  const handleBuildingCode = (e) => {
    setBuildingCode(e.target.value);
    if (isEdl || isEdl3D) {
      setData((prevData) => ({ ...prevData, data: { ...prevData.data, building_code: e.target.value } }));
    }
    if (isScan3D) {
      setScanData((prevData) => ({ ...prevData, data: { ...prevData.data, building_code: e.target.value } }));
    }
  };

  const handleCommentary = (e) => {
    setCommentary(e.target.value);
    if (isEdl || isEdl3D) {
      setData((prevData) => ({ ...prevData, data: { ...prevData.data, commentary: e.target.value } }));
    }
    if (isScan3D) {
      setScanData((prevData) => ({ ...prevData, data: { ...prevData.data, commentary: e.target.value } }));
    }
  };

  const handleAssignTechContact = (e, newValue) => {
    if (!newValue) return '';
    if (isEdl || isEdl3D) {
      setContactTechEdl(newValue);
    }
  };

  const handleAssignTechUser = (e, newValue) => {
    if (!newValue) return '';
    if (isEdl || isEdl3D) {
      setUserTech(newValue);
    }
  };

  const handleContactPhotograph = (e, newValue) => {
    if (!newValue) return '';
    if (isScan3D) {
      setContactPhotographScan(newValue);
    }
  };

  const handleStartLease = (date) => {
    setStartLease(date);
    setData((prevData) => ({ ...prevData, data: { ...prevData.data, start_lease: date.format() } }));
  };

  const handleEndLease = (date) => {
    setEndLease(date);
    setData((prevData) => ({ ...prevData, data: { ...prevData.data, end_lease: date.format() } }));
  };

  const handleDateEdl = (date) => {
    setEdlExpectedDate(date);
    setData((prevData) => ({
      ...prevData,
      expected_date: date.format()
    }));
  };

  const handleDateScan = (date) => {
    setScanExpectedDate(date);
    setScanData((prevData) => ({ ...prevData, expected_date: date.format() }));
  };

  return (
    <Grid container spacing={2}>
      {activeStep === 0 && (
        <>
          {step === 0 && !productSelected && (
            <>
              <Grid item xs={12} md={8}>
                <TextField
                  autoComplete="off"
                  label={'Recherche du bien concerné'}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  value={inputValue}
                  onChange={handleInputChange}
                  helperText="Entrer 3 caractères minimum"
                  InputProps={{
                    endAdornment: (
                      <SearchOutlinedIcon
                        sx={{
                          color: 'primary.main',
                          ml: 2,
                          mr: 1
                        }}
                        fontSize="medium"
                      />
                    )
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                {inputValue.length > 2 &&
                  productFilter?.map((item, index) => (
                    <Button
                      key={index}
                      onClick={() => {
                        handleNext();
                        handleProductSelected(item);
                      }}
                    >
                      <Typography variant="h4" sx={{ color: 'primary.main' }}>
                        {item.address.street}, {item.address.zip_code} {item.address.city} {item.address_complement}
                      </Typography>
                    </Button>
                  ))}
              </Grid>
            </>
          )}
          {step === 1 && (
            <>
              <Grid item xs={12}>
                <Typography variant="h4" sx={{ color: 'primary.main', mb: '1.5vh' }}>
                  {productSelected.address.street}, {productSelected.address.zip_code} {productSelected.address.city}{' '}
                  {productSelected.address_complement}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <Typography variant="h6" gutterBottom sx={{ color: 'primary.main', mb: '1.5vh' }}>
                    Choix de services :
                  </Typography>
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(e) => {
                            setIsEdl(e.target.checked);
                            if (isEdl3D) setIsEdl3D(!e.target.checked);
                            if (isScan3D) setIsScan3D(!e.target.checked);
                          }}
                          checked={isEdl}
                        />
                      }
                      label="Etat des lieux"
                      sx={{
                        '& .MuiTypography-root': {
                          color: 'primary.main',
                          fontSize: '18px',
                          fontWeight: 'bold'
                        }
                      }}
                    />
                    {isEdl && (
                      <>
                        <FormControl component="fieldset" sx={{ ml: '3vw' }}>
                          <RadioGroup
                            aria-label="entree-sortie"
                            name="entree-sortie"
                            value={kindEdl}
                            onChange={handleKindEdl}
                          >
                            <FormControlLabel value="Entrée" control={<Radio />} label="Entrée" />
                            <FormControlLabel value="Sortie" control={<Radio />} label="Sortie" />
                          </RadioGroup>
                        </FormControl>
                      </>
                    )}
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(e) => {
                            if (isEdl) setIsEdl(!e.target.checked);
                            setIsEdl3D(e.target.checked);
                          }}
                          checked={isEdl3D}
                        />
                      }
                      label="Etat des lieux 3D (Scan 3D + EDL)"
                      sx={{
                        '& .MuiTypography-root': {
                          color: 'primary.main',
                          fontSize: '18px',
                          fontWeight: 'bold'
                        }
                      }}
                    />
                    {isEdl3D && (
                      <>
                        <FormControl component="fieldset" sx={{ ml: '3vw' }}>
                          <RadioGroup
                            aria-label="entree-sortie-edl3d"
                            name="entree-sortie-edl3d"
                            value={kindEdl}
                            onChange={handleKindEdl}
                          >
                            <FormControlLabel value="Entrée" control={<Radio />} label="Entrée" />
                            <FormControlLabel value="Sortie" control={<Radio />} label="Sortie" />
                          </RadioGroup>
                        </FormControl>
                        {productSelected?.matterport_scan && (
                          <FormControl component="fieldset" sx={{ ml: '3vw' }}>
                            {productSelected?.matterport_scan && (
                              <Typography variant="body1" sx={{ color: 'primary.main', mt: '2vh' }}>
                                Un scan datant du{' '}
                                {dayjs(productSelected.matterport_scan.scanned_at).format('DD/MM/YYYY')} existe pour ce
                                bien, souhaitez-vous le mettre à jour ?
                              </Typography>
                            )}

                            <RadioGroup
                              aria-label="rescan-option-edl3d"
                              name="rescan-option-edl3d"
                              value={isRescan}
                              onChange={handleRescanChange}
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                ml: '3vw'
                              }}
                            >
                              <FormControlLabel value="oui" control={<Radio />} label="Oui" />
                              <FormControlLabel value="non" control={<Radio />} label="Non" />
                            </RadioGroup>
                          </FormControl>
                        )}
                      </>
                    )}

                    <FormControlLabel
                      control={<Checkbox checked={isScan3D} onChange={handleIsScan3D} />}
                      label="Scan 3D du bien"
                      sx={{
                        '& .MuiTypography-root': {
                          color: 'primary.main',
                          fontSize: '18px',
                          fontWeight: 'bold'
                        }
                      }}
                    />
                  </Box>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ my: 2 }}>
                  <Button
                    onClick={() => {
                      if (product) {
                        setProductSelected(null);
                        closeModal();
                      } else {
                        setProductSelected(null);
                        setStep(0);
                      }
                    }}
                    sx={{ marginRight: 2 }}
                  >
                    Retour
                  </Button>
                  <Button variant="contained" disabled={disabled} onClick={handleNextActiveStep}>
                    Suivant
                  </Button>
                </Box>
              </Grid>
            </>
          )}
        </>
      )}

      {activeStep === 1 && (
        <Grid
          container
          spacing={2}
          sx={{
            maxHeight: '88vh',
            overflowY: 'auto',
            width: '100%',
            '&::-webkit-scrollbar': {
              display: 'none'
            },
            '&.example': {
              msOverflowStyle: 'none',
              scrollbarWidth: 'none'
            }
          }}
        >
          <Grid item xs={12}>
            <Typography variant="h4" sx={{ color: 'primary.main' }}>
              {productSelected.address.street}, {productSelected.address.zip_code} {productSelected.address.city}{' '}
              {productSelected.address_complement}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4" sx={{ color: 'primary.main' }}>
              Informations nécessaires
            </Typography>
          </Grid>

          {isScan3D && (
            <React.Fragment>
              <Grid item xs={12}>
                <Typography variant="h6" sx={{ color: 'primary.main' }}>
                  Scan 3D:
                </Typography>
              </Grid>
              <Grid item xs={12} md={5}>
                <TextField
                  label="Référence du dossier"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={refFolderScan}
                  onChange={handleRefFolderScan}
                />
              </Grid>
              {!isEdl3D && (
                <Grid item xs={12}>
                  <Typography variant="h6" sx={{ color: 'primary.main' }}>
                    Meublé:
                  </Typography>
                  <FormControl component="fieldset">
                    <RadioGroup row aria-label="meuble" name="meuble" value={isFurnished} onChange={handleIsFurnished}>
                      <FormControlLabel value={true} control={<Radio />} label="Oui" />
                      <FormControlLabel value={false} control={<Radio />} label="Non" />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              )}
            </React.Fragment>
          )}
          {isEdl || isEdl3D ? (
            <React.Fragment>
              <Grid item xs={12}>
                <Typography variant="h6" sx={{ color: 'primary.main' }}>
                  EDL {kindEdl} :
                </Typography>
              </Grid>
              <Grid item xs={12} md={5}>
                <TextField
                  label="Référence du dossier"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={refFolderEdl}
                  onChange={handleRefFolder}
                />
              </Grid>
              <Grid item xs={12} md={7}></Grid>
              <Grid item xs={12} md={4.5} sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body2" sx={{ color: 'primary.main', fontWeight: 'bold' }}>
                  Date de début du bail :
                </Typography>
              </Grid>

              <Grid item xs={12} md={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                  <DatePicker value={endLease} onChange={(date) => handleEndLease(date)} format="DD/MM/YYYY" />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12}></Grid>
              <Grid item xs={12} md={4.5} sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body2" sx={{ color: 'primary.main', fontWeight: 'bold' }}>
                  {kindEdl === 'Entrée' ? '  Date de fin du bail précédent  :' : '  Date de fin du bail :'}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                  <DatePicker value={startLease} onChange={(date) => handleStartLease(date)} format="DD/MM/YYYY" />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">
                  {kindEdl === 'Entrée' ? 'Locataire(s) entrant(s) :' : 'Locataire(s) sortant(s) :'}
                </Typography>
              </Grid>

              {autocompleteComponents.map((component, index) => (
                <React.Fragment key={index}>
                  <Grid item xs={11} md={6.1}>
                    <Autocomplete
                      options={allContactsOrga}
                      getOptionLabel={(option) => option?.name || ''}
                      size="small"
                      onChange={(e, newValue) => handleResidents(e, newValue, index)}
                      isOptionEqualToValue={(option, value) => option?.name === value?.name}
                      value={residents[index] || null}
                      renderInput={(params) => (
                        <TextField {...params} label="Rechercher un locataire" variant="outlined" />
                      )}
                      noOptionsText="Aucun locataire trouvé"
                    />
                  </Grid>
                  {index === autocompleteComponents.length - 1 && (
                    <Grid item xs={8} md={4.9}>
                      {autocompleteComponents.length > 1 && (
                        <Button onClick={() => handleRemoveAutocomplete(index)}>
                          <PersonRemoveIcon color="secondary" />
                        </Button>
                      )}
                      <Button onClick={handleAddAutocomplete}>
                        <PersonAddIcon color="primary" />
                      </Button>
                    </Grid>
                  )}
                </React.Fragment>
              ))}

              <Grid item xs={12}>
                <Button
                  variant="contained"
                  sx={{ borderRadius: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                  onClick={() => setAddContact(!addContact)}
                >
                  <AddOutlinedIcon /> Créer un nouveau contact
                </Button>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="h6" sx={{ color: 'primary.main' }}>
                  Meublé :
                </Typography>
                <FormControl component="fieldset">
                  <RadioGroup row aria-label="meuble" name="meuble" value={isFurnished} onChange={handleIsFurnished}>
                    <FormControlLabel value={true} control={<Radio />} label="Oui" />
                    <FormControlLabel value={false} control={<Radio />} label="Non" />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="h6" sx={{ color: 'primary.main' }}>
                  Inventaire :
                </Typography>
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    aria-label="inventory"
                    name="inventory"
                    value={hasInventory}
                    onChange={handleHasInventory}
                  >
                    <FormControlLabel value={true} control={<Radio />} label="Oui" />
                    <FormControlLabel value={false} control={<Radio />} label="Non" />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={10} md={5}>
                <Autocomplete
                  options={['NC', 'Neuf', 'Rénové', 'Bon état', "Etat d'usage", 'Dégradé']}
                  value={state}
                  onChange={handleState}
                  renderInput={(params) => <TextField {...params} label="Etat du bien" variant="outlined" />}
                />
              </Grid>

              <Grid item xs={10} md={12}>
                <Typography variant="h6" sx={{ color: 'primary.main' }}>
                  Des travaux ont-ils été effectués ? :
                </Typography>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  value={worksCommentary}
                  onChange={handleWorksCommentary}
                />
              </Grid>
            </React.Fragment>
          ) : null}
          <Grid item xs={12} display={'flex'} sx={{ height: '150px' }}>
            <Button onClick={handleBackActiveStep} sx={{ marginRight: 2, height: '40px' }}>
              Retour
            </Button>
            {isEdl3D && isScan3D ? (
              <Button
                variant="contained"
                size="small"
                disabled={refFolderScan && refFolderEdl && endLease && startLease && residents[0] ? false : true}
                onClick={handleNextActiveStep}
              >
                Suivant
              </Button>
            ) : isScan3D ? (
              <Button
                variant="contained"
                size="small"
                sx={{ height: '40px' }}
                disabled={refFolderScan ? false : true}
                onClick={handleNextActiveStep}
              >
                Suivant
              </Button>
            ) : isEdl || isEdl3D ? (
              <Button
                variant="contained"
                size="small"
                sx={{ height: '40px' }}
                disabled={refFolderEdl && endLease && startLease && residents[0] ? false : true}
                onClick={handleNextActiveStep}
              >
                Suivant
              </Button>
            ) : null}
          </Grid>
        </Grid>
      )}

      {activeStep === 2 && (
        <Grid
          container
          spacing={2}
          sx={{
            maxHeight: '85vh',
            overflowY: 'auto',
            width: '100%',
            '&::-webkit-scrollbar': {
              display: 'none'
            },
            '&.example': {
              msOverflowStyle: 'none',
              scrollbarWidth: 'none'
            }
          }}
        >
          <Grid item xs={12}>
            <Typography variant="h4" sx={{ color: 'primary.main' }}>
              {productSelected.address.street}, {productSelected.address.zip_code} {productSelected.address.city}{' '}
              {productSelected.address_complement}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4" sx={{ color: 'primary.main', mb: '1.5vh' }}>
              Informations pratiques
            </Typography>
          </Grid>
          {isScan3D && (
            <>
              <Grid item xs={12}>
                <Typography variant="h6">DATE SOUHAITÉES POUR LA RÉALISATION DU SCAN 3D :</Typography>
              </Grid>

              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                <Grid item xs={12} md={4}>
                  <DesktopDatePicker
                    format="DD/MM/YYYY"
                    value={scanExpectedDate}
                    onChange={(date) => handleDateScan(date)}
                    label="Date"
                  />
                </Grid>
              </LocalizationProvider>
            </>
          )}
          {isEdl || isEdl3D ? (
            <>
              <Grid item xs={12}>
                <Typography variant="h6">DATE SOUHAITÉE POUR LA RÉALISATION DE L’ÉTAT DES LIEUX :</Typography>
              </Grid>

              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                <Grid item xs={10} md={4}>
                  <DesktopDatePicker
                    format="DD/MM/YYYY"
                    value={edlExpectedDate}
                    onChange={(date) => handleDateEdl(date)}
                    label="Date"
                  />
                </Grid>
              </LocalizationProvider>
            </>
          ) : null}

          <Grid item xs={12}>
            <Typography variant="h5">Personne à contacter pour la prise de RDV :</Typography>
          </Grid>
          <Grid item xs={12} md={5}>
            <Autocomplete
              options={allContactsOrga}
              getOptionLabel={(option) => option?.name || ''}
              onChange={handleContact}
              renderInput={(params) => <TextField {...params} label="Nom du contact" variant="outlined" />}
              size="small"
              noOptionsText="Aucun contact trouvé"
              isOptionEqualToValue={(option, value) => option?.name === value}
              value={contact}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Button
              variant="contained"
              sx={{ borderRadius: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              onClick={() => setAddContact(!addContact)}
            >
              <AddOutlinedIcon /> Créer un nouveau contact
            </Button>
          </Grid>
          <Grid item xs={12} md={5}>
            <TextField
              label="Code accès immeuble"
              variant="outlined"
              size="small"
              fullWidth
              value={buildingCode}
              onChange={handleBuildingCode}
            />
          </Grid>
          <Grid item xs={12} md={9.5}>
            <TextField
              label="Commentaires"
              multiline
              rows={2}
              placeholder="Où se trouvent les clés, Où se trouvent les compteurs"
              variant="outlined"
              size="small"
              fullWidth
              value={commentary}
              onChange={handleCommentary}
            />
          </Grid>
          {isScan3D && (
            <React.Fragment>
              <Grid item xs={12}>
                <Typography variant="h5">Attribuer la mission Scan :</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  options={allPhotographs}
                  getOptionLabel={(option) => option?.name || ''}
                  value={contactPhotographScan}
                  onChange={handleContactPhotograph}
                  renderInput={(params) => <TextField {...params} label="Nom du photographe" variant="outlined" />}
                  size="small"
                  noOptionsText="Aucun contact trouvé"
                  isOptionEqualToValue={(option, value) => option?.name === value?.name}
                  onInputChange={(event, newInputValue) => {
                    if (!newInputValue) {
                      setContactPhotographScan(null);
                    }
                  }}
                />
              </Grid>
            </React.Fragment>
          )}
          {isEdl || isEdl3D ? (
            <React.Fragment>
              <Grid item xs={12}>
                <Typography variant="h5" sx={{ color: contactTechEdl ? 'grey' : 'primary' }}>
                  Attribuer la mission EDL à un collaborateur :
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  disabled={contactTechEdl ? true : false}
                  options={allUsersOrganization}
                  getOptionLabel={(option) => option?.first_name + ' ' + option?.last_name || ''}
                  value={userTech}
                  onChange={handleAssignTechUser}
                  renderInput={(params) => <TextField {...params} label="Nom de l'utilisateur" variant="outlined" />}
                  size="small"
                  noOptionsText="Aucun contact trouvé"
                  isOptionEqualToValue={(option, value) => option?.name === value?.name}
                  onInputChange={(event, newInputValue) => {
                    if (!newInputValue) {
                      setUserTech(null);
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5" sx={{ color: userTech ? 'grey' : 'primary' }}>
                  Attribuer la mission EDL à un prestataire :
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  disabled={userTech ? true : false}
                  options={allTechs}
                  getOptionLabel={(option) => option?.name || ''}
                  value={contactTechEdl}
                  onChange={handleAssignTechContact}
                  renderInput={(params) => <TextField {...params} label="Nom du prestataire" variant="outlined" />}
                  size="small"
                  noOptionsText="Aucun contact trouvé"
                  isOptionEqualToValue={(option, value) => {
                    return option?.name === value?.name;
                  }}
                  onInputChange={(event, newInputValue) => {
                    if (!newInputValue) {
                      setContactTechEdl(null);
                    }
                  }}
                />
              </Grid>
            </React.Fragment>
          ) : null}

          <Grid item xs={12}>
            <Box sx={{ my: 2 }}>
              <Button onClick={handleBackActiveStep} sx={{ marginRight: 2 }}>
                Retour
              </Button>
              {isEdl && (
                <Button
                  variant="contained"
                  disabled={edlExpectedDate && (contactTechEdl?.users[0] || userTech) ? false : true}
                  onClick={() => handleCreateMissionOrder(false, true, null, contactTechEdl?.users[0], userTech)}
                >
                  TERMINER
                </Button>
              )}
              {isEdl3D && !isScan3D && (
                <Button
                  variant="contained"
                  disabled={edlExpectedDate && (contactTechEdl?.users[0] || userTech) ? false : true}
                  onClick={() => handleCreateMissionOrder(false, true, null, contactTechEdl?.users[0], userTech)}
                >
                  TERMINER
                </Button>
              )}
              {isEdl3D && isScan3D && (
                <Button
                  variant="contained"
                  disabled={
                    edlExpectedDate && scanExpectedDate && (contactTechEdl?.users[0] || userTech) ? false : true
                  }
                  onClick={() =>
                    handleCreateMissionOrder(
                      true,
                      true,
                      contactPhotographScan?.users[0],
                      contactTechEdl?.users[0],
                      userTech
                    )
                  }
                >
                  TERMINER
                </Button>
              )}
              {isScan3D && !isEdl3D && (
                <Button
                  variant="contained"
                  disabled={!scanExpectedDate ? true : false}
                  onClick={() => handleCreateMissionOrder(true, false, contactPhotographScan?.users[0], null, userTech)}
                >
                  TERMINER
                </Button>
              )}
            </Box>
          </Grid>
          <Grid item xs={12}></Grid>
        </Grid>
      )}
      {addContact && (
        <WizzardContact
          open={addContact}
          isProductPage={true}
          onClose={() => setAddContact(false)}
          isNewContact={true}
          productId={productSelected.uuid}
          contacts={allContacts}
          setContacts={setAllContacts}
        />
      )}
    </Grid>
  );
};
