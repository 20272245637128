import {
  Box,
  IconButton,
  AccordionDetails,
  AccordionSummary,
  Typography,
  TextField,
  Paper,
  Accordion,
  Button
} from '@mui/material';
import { useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import Breadcrumb from '~/app/components/Breadcrumb/Breadcrumb';
import _ from 'lodash';
import './AdemeSearch.scss';
import { api } from '~/api';

export const AdemeSearch = ({ setSpinnerOpen, spinnerOpen }) => {
  const [idDpe, setIdDpe] = useState('');
  const [searchResult, setSearchResult] = useState([]);
  const [nextPageUrl, setNextPageUrl] = useState('');

  useEffect(() => {}, [searchResult]);

  const handleSearch = () => {
    api.ademe.getList(idDpe, 40).then((res) => {
      const results = [];
      res.forEach((response) => {
        results.push(...response.data.results);
      });
      setSearchResult(results);
    });
  };

  const handleNextPage = () => {
    fetch(nextPageUrl).then((res) => {
      res.json().then((data) => {
        setSearchResult(data.results);
        setNextPageUrl(data.next);
      });
    });
  };

  const objRender = (obj, title) => {
    return (
      <Paper className="dpe-card" style={{ padding: '15px', marginBottom: '10px' }}>
        {/* <div className=""> */}
        {title && <div style={{ marginBottom: '10px' }}>{title}</div>}
        {Object.entries(obj)
          .sort()
          .map((t, k) => (
            <div key={k}>
              <Typography>
                <span style={{ color: '#062746' }}>{t[0]}</span>
                <span style={{ color: '#F17633' }}> : {t[1].toString()}</span>
              </Typography>
            </div>
          ))}
        {/* </div> */}
      </Paper>
    );
  };

  const dpeCard = (dpe) => {
    const clonedDpeObj = JSON.parse(JSON.stringify(dpe));
    const banInfos = {};
    for (const [key, value] of Object.entries(clonedDpeObj)) {
      if (
        (key.indexOf('BAN') !== -1 || key.indexOf('_geopoint') !== -1) &&
        key.indexOf('Coordonnée_cartographique_X_(BAN)') === -1 &&
        key.indexOf('Coordonnée_cartographique_Y_(BAN)') === -1 &&
        key.indexOf('Score_BAN') === -1
      ) {
        banInfos[key] = value;
        delete clonedDpeObj[key];
      }
    }

    const moreInfos = {};
    for (const [key, value] of Object.entries(clonedDpeObj)) {
      if (
        key.indexOf('Adresse_brute') !== -1 ||
        key.indexOf('Code_postal_(brut)') !== -1 ||
        key.indexOf("Complément_d'adresse_bâtiment") !== -1 ||
        key.indexOf("Complément_d'adresse_logement") !== -1 ||
        key.indexOf('Hauteur_sous-plafond') !== -1 ||
        key.indexOf('Invariant_fiscal_logement') !== -1 ||
        key.indexOf('N°_étage_appartement') !== -1 ||
        key.indexOf('N°_immatriculation_copropriété') !== -1 ||
        key.indexOf('N°_RPLS_logement') !== -1 ||
        key.indexOf('Nom_résidence') !== -1 ||
        key.indexOf('Nombre_appartement') !== -1 ||
        key.indexOf('Nombre_niveau_immeuble') !== -1 ||
        key.indexOf('Nombre_niveau_logement') !== -1 ||
        key.indexOf('Période_construction') !== -1 ||
        key.indexOf('Position_logement_dans_immeuble') !== -1 ||
        key.indexOf('Surface_habitable_immeuble') !== -1 ||
        key.indexOf('Surface_habitable_logement') !== -1 ||
        key.indexOf('Surface_tertiaire_immeuble') !== -1 ||
        key.indexOf('Type_bâtiment') !== -1 ||
        key.indexOf('Année_construction') !== -1
      ) {
        moreInfos[key] = value;
        delete clonedDpeObj[key];
      }
    }

    const energyInfos = {};
    for (const [key, value] of Object.entries(clonedDpeObj)) {
      if (
        key.indexOf('Description_') !== -1 ||
        ((key.indexOf('Type_') !== -1 ||
          key.indexOf('Configuration_installation_') !== -1 ||
          key.indexOf('Catégorie_ENR') !== -1 ||
          key.indexOf('Volume_stockage_générateur_ECS') !== -1) &&
          key.indexOf('Type_bâtiment') === -1)
      ) {
        energyInfos[key] = value;
        delete clonedDpeObj[key];
      }
    }

    const dpeInfos = {};
    for (const [key, value] of Object.entries(clonedDpeObj)) {
      if (
        key.indexOf('Description_') !== -1 ||
        ((key.indexOf('Conso_5_usages_par_m') !== -1 ||
          key.indexOf('Emission_GES_5_usages_par_m') !== -1 ||
          key.indexOf('Classe_altitude') !== -1 ||
          key.indexOf('DPE') !== -1 ||
          key.indexOf('Zone_climatique_') !== -1 ||
          key.indexOf('Etiquette_') !== -1) &&
          key.indexOf('Type_bâtiment') === -1)
      ) {
        dpeInfos[key] = value;
        delete clonedDpeObj[key];
      }
    }
    return (
      <Accordion style={{ backgroundColor: '#f3F3F3' }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography sx={{ fontWeight: 900 }}>
            DPE n° {dpe['N°DPE']} - {dpe['Adresse_(BAN)']} - ({Object.keys(dpe).length} informations)
          </Typography>
        </AccordionSummary>

        <AccordionDetails>
          {objRender(banInfos, 'BAN/ADRESSE')}
          {objRender(moreInfos, 'INFORMATIONS COMPLEMENTAIRES')}
          {objRender(energyInfos, 'EQUIPEMENTS ENERGIE')}
          {objRender(dpeInfos, 'DPE / Note')}
          <Accordion style={{ backgroundColor: '#f3F3F3' }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <Typography>Autres Infos</Typography>
            </AccordionSummary>

            <AccordionDetails>{objRender(clonedDpeObj, null)}</AccordionDetails>
          </Accordion>
        </AccordionDetails>
      </Accordion>
    );
  };

  return (
    <div className="AdemeSearch">
      <div className="">
        <Box
          sx={{
            my: 2,
            width: 1,
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="h1" sx={{ color: 'primary.main' }}>
              Recherche DPE
            </Typography>
            <Breadcrumb
              data={[{ label: 'Accueil', href: '/' }]}
              last={`Recherche DPE`}
              setSpinnerOpen={setSpinnerOpen}
            />
          </Box>
        </Box>
        <div>
          <TextField
            autoComplete="off"
            type="text"
            name="idDpe"
            sx={{ ml: 1, flex: 1 }}
            label="DPE"
            value={idDpe}
            onChange={(e) => {
              setIdDpe(e.target.value);
            }}
            onKeyDown={(ev) => {
              if (ev.key === 'Enter') {
                handleSearch();
              }
            }}
          />
          <IconButton onClick={handleSearch} type="button" sx={{ p: '10px' }} aria-label="search">
            <SearchIcon />
          </IconButton>
        </div>
        {searchResult.length > 0 && (
          <div style={{ paddingLeft: '10px', paddingTop: '30px' }}>Résultats : {searchResult.length}</div>
        )}
        {searchResult.length > 99 && <Button onClick={handleNextPage}>Next</Button>}
        <div style={{ paddingLeft: '10px', paddingTop: '30px' }}>
          {searchResult.map((dpe, k) => (
            <div key={k} style={{ paddingBottom: '30px' }}>
              {dpeCard(dpe)}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
