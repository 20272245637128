import { ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/fr';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { appTheme } from '~/utils/theme';
import AppRouter from './AppRouter';
import { AppContext } from '~/utils/context/AppContext';
import { useEffect, useState } from 'react';
import { api } from '~/api';
import packageInfo from '../../package.json';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ErrorBoundary } from 'react-error-boundary';
import { MediaQueryProvider } from '~/utils/context/MediaQueryProvider';

const queryClient = new QueryClient();

export default function App({ ReactGA }) {
  const [referencial, setReferencial] = useState(null);
  const [equipmentReferencial, setequipmentReferencial] = useState(null);

  const disableZoom = () => {
    document.addEventListener(
      'wheel',
      (event) => {
        if (event.ctrlKey) {
          event.preventDefault();
        }
      },
      { passive: false }
    );

    document.addEventListener('keydown', (event) => {
      if (event.ctrlKey && (event.key === '=' || event.key === '-' || event.key === '0')) {
        event.preventDefault();
      }
    });
  };

  function App() {
    useEffect(() => {
      disableZoom();
    }, []);

    return (
      <div>
        <h1>Zoom Désactivé</h1>
      </div>
    );
  }

  useEffect(() => {
    api.referencial.get().then((res) => {
      setReferencial(res);
    });
    api.referencial.equipments.get().then((res) => {
      setequipmentReferencial(res);
    });
  }, []);

  const fallBackIfErrorRender = ({ error, resetErrorBoundary }) => {
    console.log(error);
    return (
      <div
        style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          fontSize: '34px',
          textAlign: 'center'
        }}
      >
        <img src="/error.png" width="200" height="200" alt="Cibel logo desk" />
        <div className="logo">{/* <img src="/logo3D.svg" width="45" height="44" alt="Cibel logo desk" /> */}</div>
        Désolé ... <br />
        <div style={{ fontSize: '24px' }}>Malgré nos efforts une erreur est survenue</div>
        <pre style={{ textAlign: 'center', fontSize: '14px', color: 'red' }}>
          <br />
          <a href="/">{`=> Revenir sur votre portefeuille`}</a>
          <br />
          <a href="#" onClick={() => window.history.back()}>
            {`=> Revenir à la page précédente`}
          </a>
        </pre>
      </div>
    );
  };

  const logError = (error: Error, info: { componentStack: string }) => {
    // Do something with the error, e.g. log to an external API
    console.log(error, info);
  };

  return (
    <>
      <ErrorBoundary fallbackRender={fallBackIfErrorRender} onError={logError}>
        <QueryClientProvider client={queryClient}>
          <div style={{ height: '100%' }}>
            <AppContext.Provider
              value={{ referencial: referencial, equipmentReferencial: equipmentReferencial, ReactGA: ReactGA }}
            >
              <ThemeProvider theme={appTheme}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                  <MediaQueryProvider>
                    <AppRouter ReactGA={ReactGA} />
                  </MediaQueryProvider>
                </LocalizationProvider>
              </ThemeProvider>
            </AppContext.Provider>
            <span
              style={{
                position: 'fixed',
                zIndex: 99999999999999999999,
                bottom: 0,
                right: 0,
                fontSize: '12px',
                padding: '2px',
                margin: '2px',
                color: 'grey'
              }}
            >{`v${packageInfo.version}`}</span>
          </div>
        </QueryClientProvider>
      </ErrorBoundary>
    </>
  );
}
