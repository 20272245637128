import { Box, Grid2, Typography } from '@mui/material';
import { useContext, useEffect, useMemo, useRef, useState, useLayoutEffect } from 'react';
import MatterportViewer2 from '~/app/components/MatterportViewer/MatterportViewer2';
import { ProductTitle } from '~/app/components/ProductTitle/ProductTitle';
import { IProduct } from '~/interfaces';
import { appTheme } from '~/utils/theme';
import ToolIcon from './ToolIcon';
import { toolLogo } from '~/utils/references';
import ViewerContentContainer from './ViewerContentContainer';
import SubToolIcon from './SubToolIcon';
import './View3d.scss';
import { AppContext } from '~/utils/context/AppContext';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { getEquipmentByTagUuid, getTagForEquipment, getTagPinPoint } from './utilsView3D';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

interface IProps {
  mode?: 'viewer' | 'event-viewer' | 'pin' | 'administrate' | 'equipment-location';
  productDatas?: IProduct;
  setData?: any;
  tags?: any;
  tagIndexSelected?: number;
  mattertagsData?: any;
  equipmentTab?: any;
  refreshProductData?: any;
  selectedUuidEquipment?: any;
}

export const View3d = ({
  productDatas,
  mode,
  setData,
  tags,
  tagIndexSelected,
  mattertagsData,
  equipmentTab,
  refreshProductData = null,
  selectedUuidEquipment = null
}: IProps) => {
  const [activeMainToolId, setActiveMainToolId] = useState<number | null>(null);
  const [activeSubToolId, setActiveSubToolId] = useState<number | null>(null);
  const [mainToolHasSubTools, setMainToolHasSubTools] = useState<boolean>(false);
  const [isLandscape, setIsLandscape] = useState<boolean>(window.innerWidth > window.innerHeight);
  const contentLandscapeRef = useRef(null);
  const contentPortraitRef = useRef(null);
  const titleLandscapeRef = useRef(null);
  const titlePortraitRef = useRef(null);

  const [paddingContent, setPaddingContent] = useState<number>(10);
  const [contentHeight, setContentHeight] = useState<number>(0);
  const [contentWidth, setContentWidth] = useState<number>(0);
  const [contentXPos, setContentXPos] = useState<number>(0);
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState<number>(window.innerHeight);
  const [forceUpdate, setForceUpdate] = useState(0);

  const [cameraMoving, _setCameraMoving] = useState(false);
  const cameraMovingRef = useRef(cameraMoving);

  const setCameraMoving = (value: boolean) => {
    cameraMovingRef.current = value;
    _setCameraMoving(value);
  };

  // const [fullTagsCollection, setFullTagsCollection] = useState<any>(null);

  const fullTagsCollection = useRef<any>(null);

  const [uuidEquipmentSelected, _setUuidEquipmentSelected] = useState<string | null>(null);

  const [equipments, setEquipments] = useState<any[]>([]);

  const { referencial, equipmentReferencial } = useContext(AppContext);

  const [uuidTagSelected, _setUuidTagSelected] = useState<string | null>(null);

  const [mpSdk, setMpSdk] = useState<any | null>(null);
  const [threeRender, setThreeRender] = useState<any | null>(null);

  const [startDrag, setStartDrag] = useState(false);

  const ProductTitleRef = useRef(null);

  const uuidTagSelectedRef = useRef(uuidTagSelected);

  const [separatorHeight, setSeparatorHeight] = useState(0);

  const setUuidEquipmentSelected = (uuid: string | null) => {
    if (uuid === null) {
      mpSdk.Tag.close(uuidTagSelected)
        .then(() => {})
        .catch((error) => {});
      setUuidTagSelected(null);
    }
    _setUuidEquipmentSelected(uuid);
  };

  const setUuidTagSelected = (uuid: string | null) => {
    uuidTagSelectedRef.current = uuid;

    setTimeout(() => {
      if (uuid) {
        mpSdk.Tag.open(uuid, { force: true }).then(() => {});
      }
    }, 200);

    _setUuidTagSelected(uuid);
  };

  // Met à jour uniquement si `activeMainToolId` change

  const contentTitle = () => {
    return (
      <Grid2 container spacing={0} sx={{ height: '100%', alignItems: 'baseline', justifyContent: 'center' }}>
        <Grid2 sx={{ minHeight: '40px', textAlign: 'center' }}>
          {activeMainToolId === 3 && (
            <ArrowBackIcon
              sx={{ cursor: uuidEquipmentSelected ? 'pointer' : 'auto', opacity: uuidEquipmentSelected ? 1 : 0 }}
              onClick={() => setUuidEquipmentSelected(null)}
            />
          )}
        </Grid2>
        <Grid2 size={'grow'} sx={{ textAlign: 'center' }}>
          <Typography variant="h4" sx={{ color: appTheme.palette.primary.main }}>
            <span style={{ top: '6px', position: 'relative', right: '4px' }}>
              {toolLogo(activeMainToolId, 30, appTheme.palette.primary.main)}
            </span>
            <span>{mainToolsNames[activeMainToolId]}</span>
          </Typography>
        </Grid2>
        <Grid2 sx={{ minHeight: '40px', textAlign: 'center' }}>
          {activeMainToolId === 3 && (
            <div
              onClick={() => {
                navigateToTag(getTagForEquipment(uuidEquipmentSelected).uuid);
              }}
            >
              {getTagPinPoint(uuidEquipmentSelected)}
            </div>
          )}
        </Grid2>
      </Grid2>
    );
  };

  const takeScreenShot = async () => {
    if (mpSdk) {
      const resolution = { width: 1920, height: 1080 };
      const visibility = {
        mattertags: false,
        sweeps: false
      };
      const screenShotUri = await mpSdk.Renderer.takeScreenShot(resolution, visibility);

      return screenShotUri;
    } else {
      return null;
    }
  };

  const [tagsInMatterport, setTagsInMatterport] = useState<any[]>([]);

  useEffect(() => {
    const handleResize = () => {
      const isLandscape = window.innerWidth > window.innerHeight;
      setIsLandscape(isLandscape);
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useLayoutEffect(() => {
    if (contentLandscapeRef.current) {
      setContentHeight(
        contentLandscapeRef.current.clientHeight - contentLandscapeRef.current.clientHeight - 2 * paddingContent
      );
      setContentWidth(contentLandscapeRef.current.clientWidth);
      setContentXPos(contentLandscapeRef.current.getBoundingClientRect().x);
    }
  }, [contentLandscapeRef, activeMainToolId, isLandscape, paddingContent, windowHeight, windowWidth, separatorHeight]);

  useLayoutEffect(() => {
    if (contentPortraitRef.current) {
      setContentHeight(
        contentPortraitRef.current.clientHeight - titlePortraitRef.current.clientHeight - 2 * paddingContent
      );
    }
  }, [contentPortraitRef, activeMainToolId, isLandscape, paddingContent, windowHeight, windowWidth, separatorHeight]);

  useEffect(() => {
    if (mpSdk) {
      // console.log(mpSdk.context);
      // mpSdk.Scene.configure(async function (renderer, three, effectComposer) {
      //   // console.log('renderer', renderer);
      //   // console.log('three', three);
      //   // console.log('effectComposer', effectComposer);
      //   // console.log('renderer', renderer.getRenderTarget());
      //   // setThreeRender(renderer);
      //   // const R3F = await mpSdk.R3F.registerR3F();
      //   // console.log('R3F', R3F);
      //   // console.log('mpSdk', mpSdk);
      // });
    }
  }, [mpSdk]);

  useEffect(() => {
    if (mpSdk) {
      mpSdk.Tag.data.subscribe({
        onAdded(index, item, collection) {
          // console.log('Tag added to the collection', index, item, collection);
        },
        onRemoved(index, item, collection) {
          // console.log('Tag removed from the collection', index, item, collection);
        },
        onUpdated(index, item, collection) {
          // console.log('Tag updated in place in the collection', index, item, collection);
        },
        onCollectionUpdated(collection) {
          // console.log('The full collection of Tags looks like', collection);
          fullTagsCollection.current = collection;
        }
      });

      mpSdk.Sweep.current.subscribe(function (currentSweep) {
        // Change to the current sweep has occurred.
        if (currentSweep.sid === '') {
          // console.log('Not currently stationed at a sweep position');
          setCameraMoving(true);
        } else {
          setCameraMoving(false);
          // console.log('Currently at sweep', currentSweep.sid);
          // console.log('Current position', currentSweep.position);
          // console.log('On floor', currentSweep.floorInfo.sequence);
        }
      });

      mpSdk.Tag.openTags.subscribe({
        prevState: {
          hovered: null,
          docked: null,
          selected: null
        },
        onChanged(newState) {
          if (newState.hovered !== this.prevState.hovered) {
            if (newState.hovered) {
              // console.log(newState.hovered, 'was hovered');
            } else {
              if (uuidTagSelectedRef.current !== null) {
                // console.log('reopen tag', uuidTagSelected);
                // mpSdk.Tag.open(uuidTagSelectedRef.current, { force: true }).then(() => {});
              }
              // console.log(this.prevState.hovered, 'is no longer hovered');
            }
          }
          if (newState.docked !== this.prevState.docked) {
            if (newState.docked) {
              // console.log(newState.docked, 'was docked');
            } else {
              // console.log(this.prevState.docked, 'was undocked');
            }
          }

          // only compare the first 'selected' since only one tag is currently supported
          const [selected = null] = newState.selected; // destructure and coerce the first Set element to null
          if (selected !== this.prevState.selected) {
            if (selected) {
              // console.log(selected, 'was selected');

              // console.log('reopen tag', uuidTagSelected);
              // console.log(fullTagsCollection.current);

              const equipments = productDatas?.equipments;

              let uuidEquipment = getEquipmentByTagUuid(equipments, selected);

              if (uuidEquipment) setUuidEquipmentSelected(uuidEquipment);

              setUuidTagSelected(selected);
              // if (cameraMovingRef.current === false) {
              mpSdk.Tag.open(selected, {
                force: fullTagsCollection.current[selected].description !== '' ? true : false
              })
                .then(() => {})
                .catch((error) => {
                  console.error('Error opening tag:', error);
                });
              // }
            } else {
              // mpSdk.Tag.editColor(this.prevState.selected, { r: 1, g: 0.8, b: 0 });

              // console.log(this.prevState.selected);
              // console.log(uuidTagSelectedRef.current);
              // console.log(selected);
              // if (selected === null) {
              //   mpSdk.Tag.open(this.prevState.selected, {
              //     force: fullTagsCollection.current[this.prevState.selected].description !== '' ? true : false
              //   }).then(() => {});
              // }
              if (selected !== null && this.prevState.selected !== uuidTagSelectedRef.current) setUuidTagSelected(null);
              // setUuidEquipmentSelected(null);
              // console.log(this.prevState.selected, 'was deselected');
            }
          }

          // clone and store the new state
          this.prevState = {
            ...newState,
            selected
          };
        }
      });
    }
  }, [mpSdk]);

  useEffect(() => {
    if (selectedUuidEquipment) {
      console.log('selectedUuidEquipment', selectedUuidEquipment);
      setActiveMainToolId(3);
      setUuidEquipmentSelected(selectedUuidEquipment);
    }
  }, [selectedUuidEquipment]);

  useEffect(() => {
    if (uuidEquipmentSelected) {
      setActiveMainToolId(3);
      if (
        document.getElementById('viewer-content-container') !== null &&
        document.getElementById('viewer-content-container') !== undefined
      ) {
        document.getElementById('viewer-content-container').scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      // if (uuidTagSelected) mpSdk.Tag.close(uuidTagSelected).then(() => {});
    }
    // setForceUpdate((prev) => prev + 1);
  }, [uuidEquipmentSelected]);

  const getCategorytName = (tagUuidOwner) => {
    // Trouver l'équipement correspondant au tag
    let equipment = null;

    // Le tag est il dans un équipement ou dans un état d'équipement
    const prefixUuidOwner = tagUuidOwner.split('-')[0];

    // Trouver l'équipement correspondant au tag
    if (prefixUuidOwner === 'equ') {
      equipment = equipments.find((equipment) => equipment.uuid === tagUuidOwner);
    } else if (prefixUuidOwner === 'eqh') {
      equipments.forEach((equ) => {
        equ?.states?.forEach((state) => {
          if (state.uuid === tagUuidOwner) {
            equipment = equ;
          }
        });
      });
    }

    // Si l'équipement n'est pas trouvé, retourner une chaîne vide
    if (equipment === null || equipment === undefined) return '';

    // Récupérer le nom de l'équipement et de la catégorie
    const title = equipment.title && equipment.title !== '' ? equipment.title : '';
    const categoryName = equipment.kind
      ? equipmentReferencial.equipments.find((category) => category.id === equipment.kind)?.name
      : '';
    const categoryKindName = equipment.category_kind
      ? 'Catégorie : ' +
        equipmentReferencial.equipmentCategoryKinds.find((category) => category.id === equipment.category_kind)?.name
      : equipment.tab !== ''
        ? 'Onglet : ' + equipment.tab
        : '';

    // Retourner le titre de l'équipement ou la catégorie
    return `${title !== '' ? title : categoryName}\n${categoryKindName}`;
  };

  const hideTags = (tagsUuids) => {
    tagsUuids.forEach((tagUuid) => {
      mpSdk.Tag.editOpacity(tagUuid, 0);
      mpSdk.Tag.editStem(tagUuid, { stemVisible: false, stemHeight: -10000 });
    });
  };

  const showTags = (tagsUuids) => {
    if (mpSdk === null) return;
    tags.forEach((tagData) => {
      if (tagsUuids.includes(tagData.uuid)) {
        mpSdk.Tag.editOpacity(tagData.uuid, 1);
        mpSdk.Tag.editStem(tagData.uuid, { stemVisible: true, stemHeight: 0.15 });
      } else {
        mpSdk.Tag.editOpacity(tagData.uuid, 0);
        mpSdk.Tag.editStem(tagData.uuid, { stemVisible: false, stemHeight: -10000 });
      }
    });
  };

  useEffect(() => {
    const addTags = async () => {
      if (tags && tags.length > 0 && mpSdk) {
        try {
          const tagPromises = tags.map(async (tagData) => {
            const description =
              getCategorytName(tagData.uuid_owner) !== '\n' ? getCategorytName(tagData.uuid_owner) : '';
            const tagIds = await mpSdk.Tag.add({
              id: tagData.uuid,
              description: description,
              icon: 'public_characters_circle-e',
              color: { r: 1, g: 0.8, b: 0 },
              stemVector: {
                x: tagData.position.steam_x,
                y: tagData.position.steam_y,
                z: tagData.position.steam_z
              },
              anchorPosition: {
                x: tagData.position.x,
                y: tagData.position.y,
                z: tagData.position.z
              }
            });

            const tagId = tagIds[0];

            await mpSdk.Tag.allowAction(tagId, {
              navigating: true,
              opening: description === '' || description === '\n' ? false : true
            });

            await mpSdk.Tag.editStem(tagId, { stemHeight: 0.15 });

            return tagId;
          });

          const tagIds = await Promise.all(tagPromises);

          // Navigate to the first tag after all tags are created

          if (tagIds.length > 0 && mode === 'equipment-location') {
            if (cameraMoving === false) {
              if (getTagForEquipment(selectedUuidEquipment).uuid) {
                mpSdk.Mattertag.navigateToTag(getTagForEquipment(selectedUuidEquipment).uuid).catch((error) => {
                  console.error('Error opening tag:', error);
                });
              }
            }
          }

          setTagsInMatterport((prev) => {
            return [...prev, ...tagIds];
          });
        } catch (error) {
          console.error('Error adding tags:', error);
        }
      }
    };

    addTags();
  }, [mpSdk, tags]);

  useEffect(() => {
    if (productDatas) {
      const equipments = productDatas?.equipments;

      if (equipments) {
        setEquipments(equipments);
      }
    }
  }, [productDatas]);

  useEffect(() => {
    const handleMove = (e) => {
      const minHeight = 250;
      if (!startDrag) return;

      // Détecter si c'est un événement tactile ou souris
      let clientY = e.touches ? e.touches[0].clientY : e.clientY;

      let sepHeight = clientY + 22;
      if (sepHeight < minHeight) sepHeight = minHeight;
      if (sepHeight > window.innerHeight - minHeight) sepHeight = window.innerHeight - minHeight;

      setSeparatorHeight(sepHeight);
    };

    const handleEnd = () => {
      setStartDrag(false);
      document.removeEventListener('mousemove', handleMove);
      document.removeEventListener('mouseup', handleEnd);
      document.removeEventListener('touchmove', handleMove);
      document.removeEventListener('touchend', handleEnd);
    };

    if (startDrag) {
      document.addEventListener('mousemove', handleMove);
      document.addEventListener('mouseup', handleEnd);
      document.addEventListener('touchmove', handleMove);
      document.addEventListener('touchend', handleEnd);
    }

    return () => {
      document.removeEventListener('mousemove', handleMove);
      document.removeEventListener('mouseup', handleEnd);
      document.removeEventListener('touchmove', handleMove);
      document.removeEventListener('touchend', handleEnd);
    };
  }, [startDrag]);

  // const mainTools = [0, 1, 2, 3, 4, 5, 6];
  const mainTools = [3, 5];
  const mainToolsNames = [
    'Informations',
    'Etat des lieux',
    'Tickets',
    'Equipements',
    'Documents',
    'Photos',
    'Réglages'
  ];

  const subTools = {
    // 0: {
    //   tools: [0, 1, 2, 3, 4, 5, 6],
    //   names: ['Test', 'Test 2', 'Test 3', 'Test 4', 'Test 5', 'Test 6', 'Test 7']
    // },
    // 1: {
    //   tools: [0, 1, 2, 3],
    //   names: ['Test 8', 'Test 9', 'Test 10', 'Test 11']
    // },
    // 2: {
    //   tools: [0, 1, 2],
    //   names: ['Test 12', 'Test 13', 'Test 14']
    // }
    // 3: {
    //   tools: [0, 1, 2],
    //   names: ['Test 15', 'Test 16', 'Test 17']
    // }
  };

  /** Rend les icônes d'outils */
  const renderTools = () =>
    mainTools.map((toolId) => (
      <ToolIcon
        key={toolId}
        toolId={toolId}
        activeId={activeMainToolId}
        setActiveIconId={setActiveMainToolId}
        isLandscape={isLandscape}
        generateIcon={toolLogo}
        name={mainToolsNames[toolId]}
      />
    ));

  const navigateToTag = (tagUuid) => {
    if (cameraMoving === false)
      mpSdk.Mattertag.navigateToTag(tagUuid, mpSdk.Mattertag.Transition.FLY, true)
        .then(() => {
          mpSdk.Tag.open(tagUuid, { force: true }).then(() => {});
        })
        .catch((error) => {
          console.error('Error opening tag:', error);
        });
  };

  const viewerContent = useMemo(() => {
    if (activeMainToolId === null) return null;

    return (
      <ViewerContentContainer
        productDatas={productDatas}
        context="View3D"
        takeScreenShot={takeScreenShot}
        refreshProductData={refreshProductData}
        contentId={activeMainToolId}
        uuidEquipmentSelected={uuidEquipmentSelected}
        setUuidEquipmentSelected={setUuidEquipmentSelected}
        uuidTagSelected={uuidTagSelected}
        setUuidTagSelected={setUuidTagSelected}
        navigateToTag={navigateToTag}
        hideTags={hideTags}
        showTags={showTags}
        cameraMoving={cameraMoving}
      />
    );
  }, [activeMainToolId, productDatas, refreshProductData, takeScreenShot]);

  return (
    <Grid2 container spacing={0} sx={{ height: '100dvh' }}>
      {/* Colonne principale */}
      <Grid2 size={{ xs: 12, md: 'grow' }} sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
        <Box ref={ProductTitleRef} sx={{ flexShrink: 0 }}>
          <ProductTitle data={productDatas} isLandscape={isLandscape} context="View3D" />
        </Box>

        {/* Viewer + Contenu */}
        <Box sx={{ flex: 1, minHeight: 0 }}>
          <Grid2 container spacing={0} sx={{ height: '100%' }}>
            <Grid2 size={activeMainToolId === null || !isLandscape ? 12 : { xs: 6, sm: 7, md: 8 }} sx={{ flex: 1 }}>
              <Box sx={{ height: '100%', position: 'relative', pointerEvents: startDrag ? 'none' : 'auto' }}>
                <MatterportViewer2
                  modelId={productDatas.matterport_scan.model_id}
                  // mpDh={mode === 'equipment-location' ? 0 : 1}
                  mpDh={0}
                  mpLang="fr"
                  mpSearch={0}
                  tags={tags}
                  mpSdk={mpSdk}
                  setMpSdk={setMpSdk}
                />
              </Box>
            </Grid2>

            <Grid2
              id="content-viewer"
              size={12}
              sx={{
                height: contentHeight + 'px',
                overflow: 'auto',
                position: 'absolute',
                top: isLandscape ? '110px' : 'auto',
                bottom: '0px',
                width: isLandscape ? contentWidth + 'px' : '100%',
                right: isLandscape ? '45px' : '0%'
              }}
            >
              {viewerContent}
            </Grid2>

            {/* SubTools + contenu Format Paysage */}
            {isLandscape && activeMainToolId !== null && (
              <>
                <Grid2 size={{ xs: 6, sm: 5, md: 4 }}>
                  <Grid2 container spacing={0} sx={{ height: '100%' }}>
                    {mainToolHasSubTools && (
                      <Grid2
                        size={{ xs: 0 }}
                        sx={{
                          position: 'absolute',
                          display: 'inline',
                          height: '100%',
                          left: contentXPos - 45 + 'px',
                          width: '45px',
                          backgroundColor: '#00000000',
                          zIndex: 1000
                        }}
                      >
                        <Box sx={{ flex: 1, overflow: 'auto', pt: '0px' }}>
                          {subTools[activeMainToolId]?.tools.map((toolId) => (
                            <SubToolIcon
                              key={toolId}
                              toolId={toolId}
                              activeId={activeSubToolId}
                              setActiveIconId={setActiveSubToolId}
                              isLandscape={isLandscape}
                              generateIcon={toolLogo}
                              name={subTools[activeMainToolId].names[toolId]}
                            />
                          ))}
                        </Box>
                      </Grid2>
                    )}
                    <Grid2 size={{ xs: 'grow' }} sx={{ display: 'flex' }}>
                      <Grid2
                        size={12}
                        sx={{
                          overflow: 'hidden',
                          overflowY: 'auto'
                        }}
                        ref={contentLandscapeRef}
                      >
                        <Grid2 container spacing={0} flexDirection="row" sx={{ p: paddingContent + 'px' }}>
                          <Grid2
                            ref={titleLandscapeRef}
                            size={12}
                            sx={{ height: '45px', backgroundColor: 'white', position: 'sticky', textAlign: 'center' }}
                          >
                            {contentTitle()}
                          </Grid2>
                          <Grid2
                            id="content-landscape"
                            size={12}
                            sx={{
                              overflow: 'auto', // Permet le scroll si nécessaire
                              minHeight: 0, // Empêche le conteneur de forcer une hauteur fixe
                              height: contentHeight + 'px'
                            }}
                          >
                            {/* {viewerContentContainerRef.current} */}
                          </Grid2>
                        </Grid2>
                      </Grid2>
                    </Grid2>
                  </Grid2>
                </Grid2>
              </>
            )}
          </Grid2>
        </Box>

        {/* Barre d'outils + contenu en mode portrait */}
        {!isLandscape && (
          <>
            <Grid2
              size={12}
              sx={{
                height: '45px',
                width: '100%',
                display: 'flex',
                backgroundColor: appTheme.palette.primary.main
              }}
            >
              <Box sx={{ flex: 1, overflow: 'auto', display: 'flex' }} style={{ overflow: 'hidden' }}>
                {renderTools()}
              </Box>

              {activeMainToolId !== null && (
                <div
                  onMouseDown={() => setStartDrag(true)}
                  onTouchStart={() => setStartDrag(true)}
                  style={{ cursor: 'grab' }}
                >
                  <DragHandleIcon sx={{ fontSize: '45px', color: 'white', width: '30px' }} />
                </div>
              )}
            </Grid2>
            {activeMainToolId !== null && (
              <>
                <Grid2
                  size={12}
                  ref={contentPortraitRef}
                  sx={{
                    height: separatorHeight === 0 ? '60%' : window.innerHeight - separatorHeight + 'px',
                    overflow: 'hidden',
                    overflowY: 'auto'
                  }}
                >
                  <Grid2 container spacing={0} flexDirection="row" sx={{ p: paddingContent + 'px' }}>
                    <Grid2 ref={titlePortraitRef} size={12} sx={{ height: '45px', textAlign: 'center' }}>
                      {contentTitle()}
                    </Grid2>
                    <Grid2
                      id="content-portrait"
                      size={12}
                      sx={{
                        height: contentHeight + 'px',
                        overflow: 'hidden',
                        overflowY: 'auto'
                      }}
                    >
                      {/* {viewerContentContainerRef.current} */}
                    </Grid2>
                  </Grid2>
                </Grid2>
                {/* SubTools */}
                {mainToolHasSubTools && (
                  <Grid2 size={12} sx={{ height: '45px', backgroundColor: 'white', display: 'flex' }}>
                    {subTools[activeMainToolId]?.tools.map((toolId) => (
                      <SubToolIcon
                        key={toolId}
                        toolId={toolId}
                        activeId={activeSubToolId}
                        setActiveIconId={setActiveSubToolId}
                        isLandscape={isLandscape}
                        generateIcon={toolLogo}
                        name={subTools[activeMainToolId].names[toolId]}
                      />
                    ))}
                  </Grid2>
                )}
              </>
            )}
          </>
        )}
      </Grid2>

      {/* Barre d'outils en mode paysage */}
      {isLandscape && (
        <Grid2
          sx={{
            width: '45px',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: appTheme.palette.primary.main
          }}
        >
          <Box sx={{ flex: 1, overflow: 'auto', pt: '50px' }}>{renderTools()}</Box>
        </Grid2>
      )}
    </Grid2>
  );
};

export default View3d;
