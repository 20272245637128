import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { axiosClient, getOrganizations } from '~/api';
import Breadcrumb from '~/app/components/Breadcrumb/Breadcrumb';
import auth from '~/app/components/Auth/AuthApi';
import './Organizations.scss';

export const Organizations = ({ setSpinnerOpen, spinnerOpen }) => {
  const [selectedIdOrganization, setIdOrganization] = useState('');
  const [selectedJWT, setJWT] = useState('');
  const [selectedRefreshJWT, setRefreshJWT] = useState('');
  const [organizations, setOrganizations] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getOrganizations()
      .then((resp) => {
        setOrganizations(resp);
        setSpinnerOpen(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleChange = (e) => {
    setIdOrganization(e.target.value);
    organizations.forEach((orga) => {
      if (orga.organization.name === e.target.value) {
        setJWT(orga.jwt);
        setRefreshJWT(orga.refresh);
        return;
      }
    });
  };

  const handleSelectOrganisation = (e) => {
    axiosClient.defaults.headers.common['Authorization'] = 'Bearer ' + selectedJWT;
    sessionStorage.setItem('av_jwt', selectedJWT);
    sessionStorage.setItem('av_refresh', selectedRefreshJWT);

    auth.getOrganizationName();
    setSpinnerOpen(true);
    navigate('/');
  };

  const displayOrganizations = () => {
    const arr = [];
    if (organizations) {
      organizations.forEach((orga, index) => {
        arr.push(
          <MenuItem key={index} value={orga.organization.name} data-name={orga.organization.uuid}>
            {orga.organization.name}
          </MenuItem>
        );
      });
    }

    return (
      <Grid container>
        <Grid item xs={5}>
          <FormControl fullWidth>
            <InputLabel id="userType">Selectionner une organisation</InputLabel>
            <Select
              labelId="organization"
              label="Selectionner une organisation"
              value={selectedIdOrganization}
              onChange={(e) => handleChange(e)}
            >
              {arr}
            </Select>
          </FormControl>
        </Grid>
        &nbsp;
        {selectedIdOrganization !== '' && (
          <Button variant="contained" color={'primary'} onClick={handleSelectOrganisation}>
            Continuer
          </Button>
        )}
      </Grid>
    );
  };

  return (
    <div className="Organization">
      <div className="">
        <Box
          sx={{
            my: 2,
            width: 1,
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="h1" sx={{ color: 'primary.main' }}>
              Organisations
            </Typography>
            <Breadcrumb
              data={[{ label: 'Accueil', href: '/' }]}
              last={`Organisations`}
              setSpinnerOpen={setSpinnerOpen}
            />
          </Box>
        </Box>

        <Box
          sx={{
            mb: 4,
            width: 1,
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Typography variant="body1" sx={{ color: 'primary.main' }}>
            Vous devez selectionner une organisation pour laquelle vous ferez des modifications
          </Typography>
        </Box>
        <Box
          sx={{
            mb: 4,
            width: '100%',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Box sx={{ flexGrow: 1 }}>{displayOrganizations()}</Box>
        </Box>
      </div>
    </div>
  );
};
