import { axiosDelete, axiosGet, axiosPatch, axiosPost, axiosPostWithoutData } from '~/api/axiosClient';

// interface INewMessage {
//   {message: string;}
// }

export const addEvent = (data) => {
  return axiosPost('/events', data);
};

export const getEvent = (idEvent: string) => {
  return axiosGet(`/events/${idEvent}`);
};

export const getEvents = () => {
  return axiosGet(`/events`);
};

export const patchEvent = (idEvent: string, data: any) => {
  return axiosPatch(`/events/${idEvent}`, data);
};

export const deleteEvent = (idEvent: string) => {
  return axiosDelete(`/events/${idEvent}`);
};

export const postMessage = (idEvent: string, data: any) => {
  return axiosPost(`/events/${idEvent}/messages`, data);
};

export const deleteMessage = (idEvent: string, idMessage: string) => {
  return axiosDelete(`/events/${idEvent}/messages/${idMessage}`);
};

export const assignEquipmentToEvent = (idEvent: string, idEquipment: string) => {
  return axiosPostWithoutData(`/events/${idEvent}/equipments/${idEquipment}`);
};

export const unassignEquipmentToEvent = (idEvent: string, idEquipment: string) => {
  return axiosDelete(`/events/${idEvent}/equipments/${idEquipment}`);
};
