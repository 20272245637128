import React, { useEffect, FormEvent, useContext } from 'react';
import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import { useLoginFormReducer, EventLoginType } from './LoginFormReducer';
import { useNavigate } from 'react-router-dom';
import { loginUser } from '~/api';
import { setSession, getRedirectURIAfterLogin } from '~/app/components/Auth/AuthApi';
import { checkEmail } from '~/utils/check';
import '../Auth.scss';
import Auth from '~/utils/context/Auth';

// login form component.
export const LoginForm = ({ setSpinnerOpen, spinnerOpen }) => {
  const [state, dispatch] = useLoginFormReducer();
  // navigation.
  const navigate = useNavigate();

  const { setIsAuthenticated } = useContext(Auth);

  // handle submit to the api, this function
  // - is validating the given content,
  // - call the api to login the user,
  // - set the session,
  // - redirect the user to the dashboard.
  // - handle error.
  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // validate the form
    if (state.Values.email === '' || state.Values.password === '') {
      dispatch({ type: EventLoginType.SetError, errorMessage: 'Veuillez remplir tous les champs' });
      return;
    }
    if (!checkEmail(state.Values.email)) {
      dispatch({ type: EventLoginType.SetError, errorMessage: 'Veuillez saisir une adresse email valide' });
      return;
    }
    if (state.Values.password.length < 5) {
      dispatch({ type: EventLoginType.SetError, errorMessage: 'Le mot de passe doit contenir au moins 5 caractères' });
      return;
    }

    // call service.
    try {
      await loginUser(state.Values).then((response) => {
        setSession(response); // set the session.
        setIsAuthenticated(true);
        navigate(getRedirectURIAfterLogin());
      });
    } catch (errResponse) {
      dispatch({ type: EventLoginType.SetError, errorMessage: errResponse.message });
    } finally {
      setSpinnerOpen(false); // close the spinner anyway.
    }
  };

  // handle email change.
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: EventLoginType.SetEmail, email: e.target.value });
  };

  // handle password change.
  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: EventLoginType.SetPassword, password: e.target.value });
  };

  // handle forgott password link.
  const handleForgottPassword = () => {
    navigate('/forgot-password');
  };

  const handleDeleteAccount = () => {
    navigate('/delete-account');
  };

  //const handleHelperTextEmail = (value :string) => :string {
  const handleHelperTextEmail = () => {
    if (state.Values.email === '' && state.Error.AsError) {
      return 'Veuillez remplir ce champ';
    }

    if (state.Values.email !== '' && !checkEmail(state.Values.email)) {
      return 'Veuillez saisir une adresse email valide';
    }
    return '';
  };

  const handleTab = (event: React.KeyboardEvent<HTMLFormElement>) => {
    event.preventDefault();
    const code = event.which || event.keyCode;

    let charCode = String.fromCharCode(code).toLowerCase();
    if ((event.ctrlKey || event.metaKey) && charCode === 's') {
      //setState('CTRL+S');
      alert('CTRL+S Pressed');
    } else if ((event.ctrlKey || event.metaKey) && charCode === 'c') {
      //setState('CTRL+C');
      alert('CTRL+C Pressed');
    } else if ((event.ctrlKey || event.metaKey) && charCode === 'v') {
      //setState('CTRL+V');
      alert('CTRL+V Pressed');
    }
  };

  // on component mount.
  useEffect(() => {
    state.Spinner.setSpinnerOpen = setSpinnerOpen;
    state.Spinner.spinnerOpen = spinnerOpen;
    state.Spinner.setSpinnerOpen(false);
  }, []);

  return (
    <form onSubmit={handleSubmit} noValidate onKeyUp={handleTab}>
      <Box sx={{ mb: 3 }}>
        <TextField
          label="Adresse email"
          fullWidth
          required
          error={state.Error.AsError}
          helperText={handleHelperTextEmail()}
          type={'email'}
          value={state.Values.email}
          onChange={handleEmailChange}
        />
      </Box>
      <Box sx={{ mb: 3 }}>
        <TextField
          label="Votre mot de passe"
          fullWidth
          required
          type={'password'}
          value={state.Values.password}
          onChange={handlePasswordChange}
        />
      </Box>
      {state.Error.AsError && (
        <Box sx={{ textAlign: 'center', color: 'red', fontSize: '13px', my: 1.5 }}>{state.Error.Message}</Box>
      )}

      <SubmitButton
        value={state.ButtonSubmitDisabled}
        submitAction={(e) => handleSubmit(e)}
        forgottPassAction={handleForgottPassword}
        deleteAccountAction={handleDeleteAccount}
      />
    </form>
  );
};

interface SubmitButtonProps {
  value: boolean;
  submitAction: (e: any) => Promise<void>;
  forgottPassAction: () => void;
  deleteAccountAction: () => void;
}

const SubmitButton: React.FC<SubmitButtonProps> = React.memo((prop) => {
  return (
    <Stack className="Auth__submit">
      <Button
        type="submit"
        variant="contained"
        size="medium"
        color={'primary'}
        onClick={prop.submitAction}
        sx={{ mb: 2, px: '32px', py: '12px' }}
        disabled={prop.value}
      >
        Connexion
      </Button>
      <Typography
        sx={{ cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }}
        onClick={prop.forgottPassAction}
      >
        Mot de passe oublié ?
      </Typography>
    </Stack>
  );
});
